import React from "react";

import { CommonEnums } from "c9r-common";
import classNames from "classnames";

import { TimeAgo } from "components/ui/common/TimeAgo";
import { TruncatedText } from "components/ui/common/TruncatedText";
import { Icon } from "components/ui/core/Icon";
import { useCurrentUser, useShouldShowTicketRefs } from "contexts/UserContext";
import { useBuildUnauthorizedDisplayName } from "lib/Nomenclature";
import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";

import styles from "./BlockersSection.module.scss";

const fragments = {
    thread: gql(/* GraphQL */ `
        fragment BlockersSection_thread on threads {
            id
            assigned_at
            blocker_added_at
            blocker_text
            blocker_type

            assignee {
                id
                name
            }

            blocker_ticket {
                id
                ref
                title
            }
        }
    `),

    ticket: gql(/* GraphQL */ `
        fragment BlockersSection_ticket on tickets {
            id

            blocked_threads: threads(
                where: { resolved_at: { _is_null: true }, blocker_type: { _is_null: false } }
            ) {
                id
                assigned_at
                blocker_text
                blocker_type
                opened_at
                resolved_at

                ...BlockersSection_thread
            }
        }
    `),
};

type BlockerDetailProps = {
    thread: FragmentType<typeof fragments.thread>;
};

function BlockerDetail({ thread: _threadFragment }: BlockerDetailProps) {
    const { buildUnauthorizedDisplayName } = useBuildUnauthorizedDisplayName();
    const currentUser = useCurrentUser();
    const shouldShowTicketRefs = useShouldShowTicketRefs();

    const thread = getFragmentData(fragments.thread, _threadFragment);

    if (!thread.blocker_type) {
        return null;
    }

    const isAssigned = !!thread.assignee;
    const isAssignedToCurrentUser = thread.assignee?.id === currentUser.id;
    const assigneeName = thread.assignee
        ? isAssignedToCurrentUser
            ? "me"
            : thread.assignee.name
        : null;
    const captionDate = isAssigned ? thread.assigned_at! : thread.blocker_added_at!;

    return (
        <div className={classNames(styles.blocker)}>
            <div className={styles.blockerTicketIconWrapper}>
                {thread.blocker_type === CommonEnums.BlockerType.TICKET ? (
                    <Icon
                        className={styles.blockerTicketIcon}
                        icon="file-text"
                        iconSet="lucide"
                        iconSize={14}
                        strokeWidthAbsolute={1}
                    />
                ) : null}
            </div>
            <div className={styles.blockerSummaryCaptionWrapper}>
                <div className={styles.blockerSummary}>
                    {thread.blocker_type === CommonEnums.BlockerType.TEXT ? (
                        <TruncatedText as="div" text={thread.blocker_text!} maxLength={100} />
                    ) : thread.blocker_ticket ? (
                        <>
                            <TruncatedText
                                as="span"
                                text={thread.blocker_ticket.title}
                                maxLength={100}
                            />
                            {shouldShowTicketRefs ? (
                                <span className={styles.blockerTicketRef}>
                                    {" "}
                                    (#{thread.blocker_ticket.ref})
                                </span>
                            ) : null}
                        </>
                    ) : (
                        <div>{buildUnauthorizedDisplayName({ abstractName: "workItem" })}</div>
                    )}
                </div>

                <div className={styles.blockerCaption}>
                    {assigneeName ? `assigned to ${assigneeName} ` : null}
                    <TimeAgo abbreviate date={captionDate} />
                </div>
            </div>
        </div>
    );
}

export type BlockersSectionProps = {
    className?: string;
    ticket: FragmentType<typeof fragments.ticket>;
};

export function BlockersSection({ className, ticket: _ticketFragment }: BlockersSectionProps) {
    const ticket = getFragmentData(fragments.ticket, _ticketFragment);
    const blockedThreads = ticket.blocked_threads
        .filter(
            thread => !thread.resolved_at && !!thread.blocker_type // Reapply query filters
        )
        .sort(
            (a, b) =>
                (a.assigned_at
                    ? new Date(a.assigned_at).getTime()
                    : new Date(a.opened_at).getTime()) -
                (b.assigned_at
                    ? new Date(b.assigned_at).getTime()
                    : new Date(b.opened_at).getTime())
        );

    if (!blockedThreads.length) {
        return null;
    }

    return (
        <div className={classNames(className, styles.blockersSection)}>
            <div className={styles.blockerHeaderLabel}>
                <Icon icon="x-octagon" iconSet="lucide" iconSize={16} strokeWeight={1.5} />
                BLOCKED BY
            </div>
            {blockedThreads.map(thread => (
                <BlockerDetail key={thread.id} thread={thread} />
            ))}
        </div>
    );
}
