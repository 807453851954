import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useMutation } from "@apollo/client";
import { CommonEnumValue, CommonEnums, isDefined } from "c9r-common";
import classNames from "classnames";
import { Redirect } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

import { useLiveViewQuery, useSetIsAppReady, useSetIsViewReady } from "components/loading/Loading";
import { useCurrentIdentity } from "contexts/IdentityContext";
import { useBreakpoints } from "lib/Breakpoints";
import { Enums } from "lib/Enums";
import { useDocumentTitle } from "lib/Hooks";
import { Log } from "lib/Log";
import { Queries } from "lib/Queries";
import { useHistory, useLocation } from "lib/Routing";
import { Storage } from "lib/Storage";
import { useBaseUrlBuilders } from "lib/Urls";
import { parsedUserAgent } from "lib/UserAgent";
import { isUserIdInAccessToken, waitForAccessToken } from "lib/auth/AccessToken";
import { useRefreshAuth0AccessToken } from "lib/auth/Auth0AccessTokenManager";
import { getFragmentData, gql } from "lib/graphql/__generated__";
import { LogoPageLayout } from "views/layouts/logoPage/LogoPageLayout";

import { TScreenComponent, TScreenType, TWizardContext, TWizardSpec } from "./OnboardingTypes";
import styles from "./OnboardingView.module.scss";
import { DownloadAppScreen } from "./screens/DownloadAppScreen";
import { SetupAccountScreen } from "./screens/SetupAccountScreen";
import { SetupOrgScreen } from "./screens/SetupOrgScreen";
import { Breadcrumb } from "./ui/Breadcrumb";

const fragments = {
    org: gql(/* GraphQL */ `
        fragment OnboardingView_org on orgs {
            id
            display_name
            provisioned_by_identity_id

            ...SetupOrgScreen_org
        }
    `),

    user: gql(/* GraphQL */ `
        fragment OnboardingView_user on users {
            id

            org {
                id
                ...OnboardingView_org
            }

            ...SetupAccountScreen_user
        }
    `),
};

const screenInfo: Record<
    TScreenType,
    { component: TScreenComponent; defaultTitle: string; defaultSubtitle?: string }
> = {
    SETUP_ACCOUNT: {
        component: SetupAccountScreen,
        defaultTitle: "Let's get you set up",
        defaultSubtitle: "This'll take less than a minute",
    },
    SETUP_ORG: {
        component: SetupOrgScreen,
        defaultTitle: "Create an organization",
    },
    DOWNLOAD_APP: {
        component: DownloadAppScreen,
        defaultTitle: parsedUserAgent.device.isDesktop ? "Get the app" : "Flat is best on desktop",
    },
};

function useWaitForUserAccessToken() {
    const { refreshAccessToken } = useRefreshAuth0AccessToken();

    return useCallback(
        async ({ userId }: { userId: number }) => {
            if (!isUserIdInAccessToken({ userId })) {
                const waitForUserAccessToken = userId;

                await refreshAccessToken({
                    ignoreCache: true,
                    preferredUserId: waitForUserAccessToken,
                });
                await waitForAccessToken({
                    predicates: [() => isUserIdInAccessToken({ userId: waitForUserAccessToken })],
                });
            }
        },
        [refreshAccessToken]
    );
}

function useCreateWizard({
    initialSpec,
    onRequiredStepsComplete,
    onWizardComplete,
}: {
    initialSpec: TWizardSpec;
    onRequiredStepsComplete: (params: { context: Partial<TWizardContext> }) => Promise<void>;
    onWizardComplete: (params: { context: Partial<TWizardContext> }) => Promise<void>;
}) {
    const [spec, setSpec] = useState<TWizardSpec>(initialSpec);
    const [lastDirection, setLastDirection] = useState<CommonEnumValue<"Direction">>(
        CommonEnums.Direction.FORWARD
    );

    useEffect(() => {
        Storage.Session.setItem("onboarding.wizard", spec);
    }, [spec]);

    const goBack = useCallback(() => {
        setLastDirection(CommonEnums.Direction.BACK);
        setSpec(prev => ({
            ...prev,
            ux: {
                ...prev.ux,
                currentScreenIndex: Math.max(prev.ux.currentScreenIndex - 1, 0),
            },
        }));
    }, []);

    const goNext = useCallback(
        async (params?: Partial<TWizardContext>) => {
            if (
                spec.ux.screens
                    .slice(spec.ux.currentScreenIndex + 1)
                    .every(screen => !screen.isRequired)
            ) {
                await onRequiredStepsComplete({ context: { ...spec.context, ...params } });
            }

            if (spec.ux.currentScreenIndex === spec.ux.screens.length - 1) {
                await onWizardComplete({ context: { ...spec.context, ...params } });
                return;
            }

            setLastDirection(CommonEnums.Direction.FORWARD);
            setSpec(prev => ({
                ...prev,
                context: {
                    ...prev.context,
                    ...params,
                },
                ux: {
                    ...prev.ux,
                    currentScreenIndex: Math.min(
                        prev.ux.currentScreenIndex + 1,
                        prev.ux.screens.length - 1
                    ),
                },
            }));
        },
        [spec, onRequiredStepsComplete, onWizardComplete]
    );

    const goToIndex = useCallback(
        (index: number) => {
            setLastDirection(
                index < spec.ux.currentScreenIndex
                    ? CommonEnums.Direction.BACK
                    : CommonEnums.Direction.FORWARD
            );
            setSpec(prev => ({
                ...prev,
                ux: {
                    ...prev.ux,
                    currentScreenIndex: Math.min(Math.max(index, 0), prev.ux.screens.length - 1),
                },
            }));
        },
        [spec.ux.currentScreenIndex]
    );

    const wizard = useMemo(
        () => ({
            lastDirection,
            goBack,
            goNext,
            goToIndex,
            spec,
        }),
        [lastDirection, goBack, goNext, goToIndex, spec]
    );

    return wizard;
}

type OnboardingWizardProps = {
    spec: TWizardSpec;
};

function OnboardingWizard({ spec: initialSpec }: OnboardingWizardProps) {
    const breakpoints = useBreakpoints();
    const waitForUserAccessToken = useWaitForUserAccessToken();
    const { buildOrgBaseUrl } = useBaseUrlBuilders();
    const setIsAppReady = useSetIsAppReady();
    const setIsViewReady = useSetIsViewReady();
    const { history } = useHistory();
    const [completeOnboarding] = useMutation(OnboardingView.mutations.completeOnboarding, {
        context: {
            apiRoleType: Enums.ApiRoleType.IDENTITY,
        },
    });

    const onRequiredStepsComplete = useCallback(
        async ({ context }: { context: Partial<TWizardContext> }) => {
            const userId = context.userId ?? undefined;

            await completeOnboarding({ variables: { userId } });
        },
        [completeOnboarding]
    );

    const onWizardComplete = useCallback(
        async ({ context }: { context: Partial<TWizardContext> }) => {
            // This might look odd, but it's important. After the user finishes onboarding, they
            // should experience a pleasant transition into the app proper. The pleasant transition
            // is for the onboarding page to disappear, they see our loading spinner, and then the
            // app fades in.
            //
            // To achieve that, we pretend that the app's loading state is "not ready". This causes
            // the full-screen loading indicator to appear.
            setIsViewReady(false);
            setIsAppReady(false);

            const { orgSlug, userId } = context;

            if (orgSlug && userId) {
                history.replace(buildOrgBaseUrl({ orgSlug }).pathname);
                await waitForUserAccessToken({ userId });
            } else {
                Log.error("Unexpectedly missing org and user after onboarding");
                history.replace("/");
            }

            Storage.Session.removeItem("onboarding.wizard");
        },
        [buildOrgBaseUrl, history, setIsAppReady, setIsViewReady, waitForUserAccessToken]
    );

    const wizard = useCreateWizard({
        initialSpec,
        onRequiredStepsComplete,
        onWizardComplete,
    });

    const queryResult = useLiveViewQuery({
        query: OnboardingView.queries.component,
        variables: { userId: wizard.spec.context.userId ?? 0 },
        apiRoleType: Enums.ApiRoleType.IDENTITY,
    });

    const user = getFragmentData(fragments.user, queryResult.data?.users[0]) ?? undefined;
    const org = getFragmentData(fragments.org, user?.org) ?? undefined;

    useEffect(() => {
        // See https://github.com/microsoft/TypeScript-DOM-lib-generator/issues/1195
        // @ts-ignore-error
        window.scrollTo({ top: 0, behavior: "instant" });
    }, [wizard.spec.ux.currentScreenIndex]);

    return (
        <div
            className={classNames(
                styles.page,
                styles.animatedWizardWrapper,
                wizard.lastDirection === CommonEnums.Direction.BACK &&
                    styles.animatedWizardWrapperBackward
            )}
        >
            <div className={styles.headersWrapper}>
                {wizard.spec.ux.screens.map(({ type, title, subtitle }, i) => {
                    return (
                        <CSSTransition
                            key={type}
                            appear={wizard.spec.ux.currentScreenIndex === i}
                            in={wizard.spec.ux.currentScreenIndex === i}
                            timeout={{
                                enter: 500,
                                exit: 1000,
                            }}
                            classNames="css-transition"
                            mountOnEnter
                            unmountOnExit
                        >
                            <div className={styles.headers}>
                                <h1>{title ?? screenInfo[type].defaultTitle}</h1>
                                <h2>{subtitle ?? screenInfo[type].defaultSubtitle}</h2>
                            </div>
                        </CSSTransition>
                    );
                })}
            </div>
            <div className={styles.breadcrumbs}>
                {wizard.spec.ux.screens.length > 1
                    ? wizard.spec.ux.screens.map(({ type }, i) => {
                          return (
                              <Breadcrumb
                                  isCurrent={i === wizard.spec.ux.currentScreenIndex}
                                  disabled={i >= wizard.spec.ux.currentScreenIndex}
                                  key={type}
                                  onClick={() => wizard.goToIndex(i)}
                                  small={breakpoints.smMax}
                              />
                          );
                      })
                    : null}
            </div>
            <div className={styles.contentBoxWrapper}>
                {wizard.spec.ux.screens.map(({ type }, i) => {
                    const ScreenComponent = screenInfo[type].component;

                    return (
                        <CSSTransition
                            key={type}
                            appear={wizard.spec.ux.currentScreenIndex === i}
                            in={wizard.spec.ux.currentScreenIndex === i}
                            timeout={{
                                enter: 500,
                                exit: 1000,
                            }}
                            classNames="css-transition"
                            mountOnEnter
                            unmountOnExit
                        >
                            <div className={styles.animatedContent} key={type}>
                                <ScreenComponent
                                    className={styles.screen}
                                    org={org}
                                    user={user}
                                    onComplete={params => {
                                        void wizard.goNext(params);
                                    }}
                                />
                            </div>
                        </CSSTransition>
                    );
                })}
            </div>
        </div>
    );
}

function OnboardingWizardWrapper() {
    const currentIdentity = useCurrentIdentity();
    const { search } = useLocation();
    const searchParams = useMemo(() => new URLSearchParams(search), [search]);
    const orgSlug =
        searchParams.get("o") ??
        // Special case: If somehow we got here and there is only one possible org,
        // use that. As of October 2023, this was added only to handle the edge case
        // when we migrated to the new onboarding flow, as some people may have created
        // an org in the old flow but then never completed it. With this handling, if
        // those people ever come back, they'll go through the new flow, and the flow
        // will use the org they had previously created.
        (!currentIdentity.onboarding_completed_at && currentIdentity.users.length === 1
            ? currentIdentity.users[0].org.slug
            : null);
    const user = orgSlug ? currentIdentity.users.find(u => u.org.slug === orgSlug) : null;
    const org = user ? user.org : null;

    useEffect(() => {
        document.documentElement.classList.add(styles.stableScrollbarGutter);

        return () => {
            document.documentElement.classList.remove(styles.stableScrollbarGutter);
        };
    }, []);

    const createWizardSpec: () => TWizardSpec = () => {
        const shouldOfferDesktopApp = !currentIdentity.onboarding_completed_at && !window.electron;

        if (!org || org.provisioned_by_identity_id === currentIdentity.id) {
            return {
                context: {
                    orgId: org?.id,
                    orgSlug: org?.slug,
                    userId: user?.id,
                },
                ux: {
                    currentScreenIndex: 0,
                    screens: [
                        !currentIdentity.onboarding_completed_at
                            ? ({ type: "SETUP_ACCOUNT", isRequired: true } as const)
                            : null,
                        { type: "SETUP_ORG", isRequired: true } as const,
                        shouldOfferDesktopApp
                            ? ({ type: "DOWNLOAD_APP", isRequired: false } as const)
                            : null,
                    ].filter(isDefined),
                },
            };
        }

        return {
            context: {
                orgId: org.id,
                orgSlug: org?.slug,
                userId: user?.id,
            },
            ux: {
                currentScreenIndex: 0,
                screens: [
                    {
                        type: "SETUP_ACCOUNT",
                        title: `You're joining the ${org.display_name} team`,
                        subtitle: "How do you want to be known here?",
                        isRequired: true,
                    } as const,
                    shouldOfferDesktopApp
                        ? ({ type: "DOWNLOAD_APP", isRequired: false } as const)
                        : null,
                ].filter(isDefined),
            },
        };
    };

    const savedWizardSpec = Storage.Session.getItem("onboarding.wizard") as TWizardSpec | null;
    const isSavedWizardSpecCurrent =
        savedWizardSpec && !(org && org.id !== savedWizardSpec.context.orgId);

    if (savedWizardSpec && !isSavedWizardSpecCurrent) {
        Storage.Session.removeItem("onboarding.wizard");
    }

    const wizardSpec = isSavedWizardSpecCurrent ? savedWizardSpec : createWizardSpec();

    return <OnboardingWizard spec={wizardSpec} />;
}

export function OnboardingNewOrgRedirect() {
    Storage.Session.removeItem("onboarding.wizard");

    return <Redirect to="/organizations/setup" />;
}

export function OnboardingView() {
    useDocumentTitle();

    return (
        <LogoPageLayout>
            <OnboardingWizardWrapper />
        </LogoPageLayout>
    );
}

OnboardingView.queries = {
    component: gql(/* GraphQL */ `
        query OnboardingView($userId: Int!) {
            users(where: { id: { _eq: $userId }, disabled_at: { _is_null: true } }) {
                ...OnboardingView_user
            }
        }
    `),
};

OnboardingView.mutations = {
    completeOnboarding: gql(/* GraphQL */ `
        mutation OnboardingViewCompleteOnboarding($userId: Int) {
            complete_onboarding(user_id: $userId) {
                user {
                    id
                    app_state
                    onboarding_completed_at

                    identity {
                        id
                        app_state
                        onboarding_completed_at
                    }
                }
            }
        }
    `),
};

Queries.register({ component: "OnboardingView", gqlMapByName: OnboardingView.queries });
