import React, { useCallback, useEffect, useRef, useState } from "react";

import { Editor as TCoreEditor } from "@tiptap/core";
import classNames from "classnames";

import { Avatar } from "components/ui/common/Avatar";
import { EllipsisButton } from "components/ui/common/EllipsisButton";
import { TimeAgo } from "components/ui/common/TimeAgo";
import { BorderButton } from "components/ui/core/BorderButton";
import { Icon } from "components/ui/core/Icon";
import { Menu } from "components/ui/core/Menu";
import { MenuItem } from "components/ui/core/MenuItem";
import { MenuPopover } from "components/ui/core/MenuPopover";
import { TextButton } from "components/ui/core/TextButton";
import { Editor } from "components/ui/editor/Editor";
import { useCurrentUser } from "contexts/UserContext";
import { BotUser } from "lib/BotUser";
import { CssClasses } from "lib/Constants";
import { Enums } from "lib/Enums";
import { useClipboard } from "lib/Hooks";
import { FragmentType, getFragmentData, gql, makeFragmentData } from "lib/graphql/__generated__";
import {
    Avatar_userFragmentDoc,
    Comment_ticketFragment,
    UserSelect_userFragment,
} from "lib/graphql/__generated__/graphql";
import { useEditComment, useEditCommentAndAssignThread } from "lib/mutations";
import { TRichTextContentSerializers } from "lib/types/common/richText";

import styles from "./Comment.module.scss";
import { CommentAssignmentControls } from "./CommentAssignmentControls";
import { CommentExplanatoryFooter } from "./CommentExplanatoryFooter";
import { useCommentAssignmentState, useCommentMentions } from "./CommentState";
import { EditorAccordion } from "./EditorAccordion";
import { useDetailView } from "../context/DetailViewContext";
import { useDetailViewDiscussion } from "../context/DetailViewDiscussionContext";

const fragments = {
    comment: gql(/* GraphQL */ `
        fragment Comment_comment on comments {
            id
            posted_at
            last_edited_at
            comment_text
            comment_json

            author {
                id
                name

                ...Avatar_user
            }
        }
    `),

    ticket: gql(/* GraphQL */ `
        fragment Comment_ticket on tickets {
            id

            board {
                id

                ...CommentAssignmentState_board
                ...CommentMentions_board
                ...Editor_board
            }
        }
    `),
};

type EditorWrapperProps = {
    board: Comment_ticketFragment["board"];
    children: React.ReactNode;
    commentTextCurrent: string;
    commentTextOriginal: string;
    disableSubmit?: boolean;
    expandFullText?: boolean;
    handleCancel?: () => void;
    handleSubmit: ({ assignThreadToUserId }: { assignThreadToUserId: number | null }) => void;
    isEditing?: boolean;
    recentUserIds: number[];
    showAssignmentControls?: boolean;
    submitCommentRef?: React.RefObject<HTMLButtonElement>;
    threadAssignedToUserId?: number | null;
};

function EditorWrapper({
    board,
    children,
    commentTextCurrent,
    commentTextOriginal,
    disableSubmit,
    expandFullText,
    handleCancel,
    handleSubmit,
    isEditing,
    recentUserIds,
    showAssignmentControls,
    submitCommentRef,
    threadAssignedToUserId,
}: EditorWrapperProps) {
    const { mentionedUserIds: mentionedUserIdsOriginal } = useCommentMentions({
        board,
        commentText: commentTextOriginal,
    });
    const {
        assignToName,
        isAssigningToCurrentUser,
        mentionedUserIds: mentionedUserIdsCurrent,
        newAssignee,
        possibleAssignmentUsers,
        setNewAssigneeUserId,
        setShouldAssign,
        shouldAssign,
    } = useCommentAssignmentState({
        board,
        commentText: commentTextCurrent,
        recentUserIds,
        threadAssignedToUserId,
    });

    const newlyMentionedUserIds = mentionedUserIdsCurrent.filter(
        userId => !mentionedUserIdsOriginal.includes(userId)
    );

    const handleUpdate = useCallback(() => {
        handleSubmit({ assignThreadToUserId: shouldAssign ? newAssignee?.id ?? null : null });
    }, [handleSubmit, newAssignee, shouldAssign]);

    return (
        <>
            {isEditing ? (
                <div className={styles.editCommentWrapper}>
                    {children}

                    {showAssignmentControls ? (
                        <>
                            <CommentAssignmentControls
                                assignToName={assignToName}
                                className={styles.commentAssignmentControls}
                                checked={shouldAssign}
                                onSelectAssignee={(
                                    userToAssign: UserSelect_userFragment | null
                                ) => {
                                    if (userToAssign) {
                                        setShouldAssign(true);
                                        setNewAssigneeUserId(userToAssign.id);
                                    }
                                }}
                                onToggleShouldAssign={() => {
                                    setShouldAssign(!shouldAssign);
                                }}
                                selectableUsers={possibleAssignmentUsers}
                            />

                            {!isAssigningToCurrentUser && shouldAssign && newAssignee ? (
                                <CommentExplanatoryFooter className={styles.explanatoryFooter}>
                                    {newAssignee.name} will be notified that they are responsible
                                    for following up on and resolving this thread.
                                </CommentExplanatoryFooter>
                            ) : null}
                            {newlyMentionedUserIds.length >= 1 &&
                            !(
                                newlyMentionedUserIds.length === 1 &&
                                newAssignee &&
                                newlyMentionedUserIds.includes(newAssignee?.id) &&
                                shouldAssign
                            ) ? (
                                <CommentExplanatoryFooter className={styles.explanatoryFooter}>
                                    Newly nentioned users will be notified about your comment.
                                </CommentExplanatoryFooter>
                            ) : null}
                        </>
                    ) : null}
                    <div
                        className={classNames(
                            styles.editCommentFooterActions,
                            showAssignmentControls && styles.editCommentFooterActionsExtraSpace
                        )}
                    >
                        <BorderButton
                            onClick={handleCancel}
                            content="Cancel"
                            instrumentation={null}
                        />
                        <BorderButton
                            disabled={disableSubmit}
                            onClick={handleUpdate}
                            content="Update"
                            elementRef={submitCommentRef}
                            instrumentation={null}
                            primary
                        />
                    </div>
                </div>
            ) : (
                <EditorAccordion className={styles.commentText} forceExpand={expandFullText}>
                    {children}
                </EditorAccordion>
            )}
        </>
    );
}

export type CommentProps = {
    className?: string;
    comment: FragmentType<typeof fragments.comment>;
    expandFullText?: boolean;
    isEditingComment?: boolean;
    isMostRecentComment?: boolean;
    onEditingComment?: () => void;
    onResolve?: () => void;
    onReopen?: () => void;
    recentUserIds: number[];
    setIsEditingComment?: (isEditing: boolean) => void;
    threadId: string;
    threadAssignedToUserId?: number | null;
    ticket: FragmentType<typeof fragments.ticket>;
};

export function Comment({
    className,
    comment: _commentFragment,
    expandFullText,
    isEditingComment,
    isMostRecentComment,
    onEditingComment,
    onResolve,
    onReopen,
    recentUserIds,
    setIsEditingComment,
    threadId,
    threadAssignedToUserId,
    ticket: _ticketFragment,
}: CommentProps) {
    const comment = getFragmentData(fragments.comment, _commentFragment);
    const ticket = getFragmentData(fragments.ticket, _ticketFragment);

    const currentUser = useCurrentUser();
    const { ticketUrl } = useDetailView();
    const { didDiscussionJustMount, urlHashId } = useDetailViewDiscussion();
    const commentTextOriginal = comment.comment_text;
    const [commentTextCurrent, setCurrentCommentText] = useState(commentTextOriginal);
    const [isPopulatedCurrent, setIsPopulatedCurrent] = useState(!!commentTextOriginal);
    const [isEditing, setIsEditing] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const commentRef = useRef<HTMLLIElement>(null);
    const submitCommentRef = useRef<HTMLButtonElement>(null);
    const serializers = useRef<TRichTextContentSerializers | null>(null);
    const editorRef = useRef<any | null>(null);

    const { editComment } = useEditComment();
    const { editCommentAndAssignThread } = useEditCommentAndAssignThread();

    const isInUrlHash = urlHashId.value === comment.id;

    const { copyTextToClipboard } = useClipboard();

    useEffect(() => {
        if (isInUrlHash && didDiscussionJustMount) {
            setImmediate(() =>
                commentRef.current?.scrollIntoView({ behavior: "auto", block: "center" })
            );
        }
    }, [didDiscussionJustMount, isInUrlHash]);

    useEffect(() => {
        setIsEditingComment?.(isEditing);

        if (isEditing) {
            // As of December 2021, there seems to be an unfortunate interaction between
            // scrollIntoView and the editor autofocus. Ideally, when a comment is edited,
            // we want the editor to appear and be focused and simultanously to smoothly scroll
            // it into view. But by autofocusing the editor, browser behavior is to *immediately*
            // scroll it into view.
            //
            // As a quick good enough solution, just scroll after a timeout. It doesn't look
            // awful and is better than what we had before.
            setTimeout(() => {
                submitCommentRef.current?.scrollIntoView({ behavior: "smooth", block: "nearest" });
            }, 150);
        }
    }, [isEditing, setIsEditingComment]);

    useEffect(() => {
        // This is needed in the case that a new comment gets added while the comment editor is open.
        if (!isEditingComment && isEditing) {
            setIsEditingComment?.(isEditing);
        }
    }, [isEditing, setIsEditingComment, isEditingComment]);

    const handleCreate = useCallback(
        (newSerializers: TRichTextContentSerializers, editor: TCoreEditor) => {
            serializers.current = newSerializers;
            editorRef.current = editor;
        },
        []
    );

    const handleUpdate = () => {
        if (serializers.current) {
            setCurrentCommentText(serializers.current.getText());
            setIsPopulatedCurrent(serializers.current.isPopulated());
        }
    };

    const handleCancel = useCallback(() => {
        setIsEditing(false);
    }, []);

    const handleSubmit = useCallback(
        async ({ assignThreadToUserId = null }: { assignThreadToUserId?: number | null } = {}) => {
            // As of June 2023, we determined that if `setIsEditing(false)` is executed before
            // `editorRef.current.commands.trimContent()`, then submitting an edit via the keyboard
            // (i.e. Ctrl/Cmd + Enter) fails. The solution below (i.e. moving `setIsEditing(false)`
            // after `editorRef.current.commands.trimContent()`) still yields `Warning: Can't perform
            // a React state update on an unmounted component.` in dev, and we have not been able to
            // identify the cause.
            editorRef.current.commands.trimContent();

            setIsEditing(false);

            const assignedToUserId = assignThreadToUserId ?? null;
            const isPopulated = serializers.current?.isPopulated();

            if (isPopulated && serializers.current) {
                if (assignedToUserId) {
                    await editCommentAndAssignThread({
                        commentId: comment.id,
                        threadId,
                        assignedToUserId,
                        serializers: serializers.current,
                    });
                } else {
                    await editComment({
                        commentId: comment.id,
                        serializers: serializers.current,
                    });
                }
            }
        },
        [comment.id, threadId, editComment, editCommentAndAssignThread]
    );

    return (
        <li
            className={classNames(className, styles.comment, isInUrlHash && styles.inUrlHash)}
            ref={commentRef}
        >
            <div className={styles.content}>
                <header>
                    <Avatar
                        className={styles.avatar}
                        user={comment.author ?? makeFragmentData(BotUser, Avatar_userFragmentDoc)}
                        size={26}
                    />
                    <span className={styles.author}>{(comment.author ?? BotUser).name}</span>
                    &nbsp;
                    <div className={styles.dateAndEditedTextWrapper}>
                        <TimeAgo date={comment.posted_at} />
                        &nbsp;
                        {comment.last_edited_at ? (
                            <span className={styles.editedText}>(edited)</span>
                        ) : null}
                    </div>
                    <div style={{ flex: "0 0 10px" }} />
                    {onResolve ? (
                        <TextButton
                            data-cy="comment-resolve-btn"
                            className={styles.threadActionButton}
                            text="Resolve"
                            instrumentation={{
                                elementName: "thread.comment.resolve_btn",
                                eventData: { commentId: comment.id },
                            }}
                            onClick={onResolve}
                        />
                    ) : null}
                    {onReopen ? (
                        <TextButton
                            data-cy="comment-reopen-btn"
                            className={styles.threadActionButton}
                            text="Reopen"
                            instrumentation={{
                                elementName: "thread.comment.reopen_btn",
                                eventData: { commentId: comment.id },
                            }}
                            onClick={onReopen}
                        />
                    ) : null}
                    <MenuPopover
                        modifiers={{
                            offset: {
                                enabled: true,
                                options: {
                                    offset: [8, -4],
                                },
                            },
                        }}
                        content={
                            <Menu>
                                {comment.author?.id === currentUser.id ? (
                                    <MenuItem
                                        text="Edit"
                                        icon={<Icon icon="edit-2" iconSet="lucide" iconSize={18} />}
                                        instrumentation={{
                                            elementName: "comment.menu.edit",
                                            eventData: {
                                                commentId: comment.id,
                                            },
                                        }}
                                        onClick={() => {
                                            setIsEditing(true);
                                            onEditingComment?.();
                                        }}
                                    />
                                ) : null}
                                <MenuItem
                                    text="Copy link"
                                    icon={<Icon icon="link" iconSet="lucide" iconSize={18} />}
                                    instrumentation={{
                                        elementName: "comment.menu.copy_link",
                                        eventData: {
                                            commentId: comment.id,
                                        },
                                    }}
                                    onClick={async () => {
                                        await copyTextToClipboard({
                                            text: `${ticketUrl}#C${comment.id}`,
                                            successToast: "Link copied to clipboard.",
                                        });
                                    }}
                                />
                            </Menu>
                        }
                        placement="bottom-end"
                        onOpening={() => setIsMenuOpen(true)}
                        onClosing={() => setIsMenuOpen(false)}
                        targetClassName={classNames(
                            styles.menuTarget,
                            isMenuOpen && styles.menuOpen,
                            isMenuOpen && CssClasses.MENU_TARGET_ACTIVE
                        )}
                    >
                        <EllipsisButton vertical instrumentation={null} active={isMenuOpen} />
                    </MenuPopover>
                </header>
                <EditorWrapper
                    board={ticket.board}
                    commentTextCurrent={commentTextCurrent}
                    commentTextOriginal={commentTextOriginal}
                    isEditing={isEditing}
                    expandFullText={expandFullText}
                    handleCancel={handleCancel}
                    handleSubmit={handleSubmit}
                    disableSubmit={!isPopulatedCurrent}
                    recentUserIds={recentUserIds}
                    showAssignmentControls={isMostRecentComment}
                    submitCommentRef={submitCommentRef}
                    threadAssignedToUserId={threadAssignedToUserId}
                >
                    <Editor
                        className={styles.commentEditor}
                        autoFocus={isEditing}
                        board={ticket.board}
                        content={comment.comment_json as any}
                        // Matches line-height in the case of a single line comment.
                        minHeight={24}
                        // We collapse long comments and display their contents when expanded,
                        // so we don't want to set a max height in that case or the contents
                        // will be displayed in a scrollable window.
                        maxHeight={isEditing ? 240 : undefined}
                        onCreate={handleCreate}
                        onUpdate={handleUpdate}
                        onKeyboardCancel={handleCancel}
                        onKeyboardSubmit={handleSubmit}
                        images
                        linkUnfurlTypes={[Enums.LinkUnfurlType.LOOM]}
                        mentions
                        ticketReferences
                        emoji
                        readOnly={!isEditing}
                    />
                </EditorWrapper>
            </div>
        </li>
    );
}
