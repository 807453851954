import React from "react";

import classNames from "classnames";

import { BotUser } from "lib/BotUser";
import { pickUserColor } from "lib/Helpers";
import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";

import styles from "./Avatar.module.scss";

const fragments = {
    user: gql(/* GraphQL */ `
        fragment Avatar_user on users {
            id
            avatar_url
            full_name
            name
        }
    `),
};

export type AvatarProps = {
    className?: string;
    user: FragmentType<typeof fragments.user>;
    size?: number;
    tooltipText?: string;
};

export function Avatar({ className, user: _userFragment, size = 28, tooltipText }: AvatarProps) {
    const user = getFragmentData(fragments.user, _userFragment);

    let content;

    if (user.avatar_url) {
        content = (
            <img
                className={classNames(className, styles.avatar, styles.image)}
                src={user.avatar_url}
                alt={user.name}
                style={{
                    width: size,
                    height: size,
                    borderRadius: user.avatar_url === BotUser.avatar_url ? "0px" : undefined,
                }}
            />
        );
    } else {
        let initials = user.name.slice(0, 2).toUpperCase();

        if (user.full_name && user.full_name.split(" ").length === 2) {
            const [firstName, lastName] = user.full_name.split(" ");
            const [firstInitial, lastInitial] = [firstName[0], lastName[0]];

            if (firstInitial && lastInitial) {
                initials = `${firstInitial.toUpperCase()}${lastInitial.toUpperCase()}`;
            }
        }

        const { color } = pickUserColor({ userId: user.id });

        content = (
            <div
                className={classNames(className, styles.avatar, styles.initials)}
                style={{
                    backgroundColor: color.rgb().toString(),
                    fontSize: `${Math.floor(size * (12 / 28))}px`,
                    width: size,
                    height: size,
                }}
            >
                {initials}
            </div>
        );
    }

    return <span title={tooltipText}>{content}</span>;
}
