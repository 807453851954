import { useCallback } from "react";

import { useMutation } from "@apollo/client";
import { DbColumnTypes } from "c9r-common";

import { useMaybeCurrentIdentity, useMaybeCurrentUser } from "AppState";
import { EnumValue, Enums } from "lib/Enums";
import { gql } from "lib/graphql/__generated__";

const mutations = {
    shallowUpdateIdentityAppState: gql(/* GraphQL */ `
        mutation ShallowUpdateIdentityAppState($identityId: uuid!, $partialAppState: jsonb!) {
            update_identities_by_pk(
                pk_columns: { id: $identityId }
                _append: { app_state: $partialAppState }
            ) {
                id
                app_state
            }
        }
    `),

    shallowUpdateUserAppState: gql(/* GraphQL */ `
        mutation ShallowUpdateUserAppState($userId: Int!, $partialAppState: jsonb!) {
            update_users_by_pk(
                pk_columns: { id: $userId }
                _append: { app_state: $partialAppState }
            ) {
                id
                app_state
            }
        }
    `),
};

export function usePersistedAppState() {
    const currentIdentity = useMaybeCurrentIdentity();
    const currentUser = useMaybeCurrentUser();
    const [identityMutation] = useMutation(mutations.shallowUpdateIdentityAppState);
    const [userMutation] = useMutation(mutations.shallowUpdateUserAppState);

    const shallowUpdateIdentityAppState = useCallback(
        async (
            partialAppState: Partial<DbColumnTypes.IdentitiesAppState>,
            options?: { apiRoleType?: EnumValue<"ApiRoleType"> }
        ) => {
            if (!currentIdentity?.id || currentIdentity?.isReadOnly) {
                return;
            }

            await identityMutation({
                variables: {
                    partialAppState,
                    identityId: currentIdentity.id,
                },
                context: {
                    apiRoleType: options?.apiRoleType ?? Enums.ApiRoleType.IDENTITY,
                },
                optimisticResponse: {
                    update_identities_by_pk: {
                        __typename: "identities",
                        id: currentIdentity.id,
                        app_state: {
                            ...currentIdentity.app_state,
                            ...partialAppState,
                        },
                    },
                },
            });
        },
        [
            currentIdentity?.app_state,
            currentIdentity?.id,
            currentIdentity?.isReadOnly,
            identityMutation,
        ]
    );

    const shallowUpdateUserAppState = useCallback(
        async (
            partialAppState: Partial<DbColumnTypes.UsersAppState>,
            options?: { apiRoleType?: EnumValue<"ApiRoleType"> }
        ) => {
            if (!currentUser?.id || currentUser.isReadOnly) {
                return;
            }

            await userMutation({
                variables: {
                    partialAppState,
                    userId: currentUser.id,
                },
                context: {
                    apiRoleType: options?.apiRoleType,
                },
                optimisticResponse: {
                    update_users_by_pk: {
                        __typename: "users",
                        id: currentUser.id,
                        app_state: {
                            ...currentUser.app_state,
                            ...partialAppState,
                        },
                    },
                },
            });
        },
        [currentUser?.app_state, currentUser?.id, currentUser?.isReadOnly, userMutation]
    );

    return { shallowUpdateIdentityAppState, shallowUpdateUserAppState };
}
