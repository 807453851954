import { useCallback, useEffect } from "react";

import { OAuthError } from "@auth0/auth0-react";
import { useRecoilValue } from "recoil";

import { Config } from "Config";
import { useInterval } from "lib/Hooks";
import { Log } from "lib/Log";
import { isAccessTokenAvailableState, useSetOrReplaceAccessToken } from "lib/auth/AccessToken";
import { useAuth0 } from "lib/auth/Auth0";

let isRefreshing = false;

export const useRefreshAuth0AccessToken = () => {
    const setOrReplaceAccessToken = useSetOrReplaceAccessToken();
    const { getAccessTokenSilently, login } = useAuth0();

    const refreshAccessToken = useCallback(
        async ({
            ignoreCache,
            preferredUserId,
            skipLoginOnFailure,
        }: {
            ignoreCache?: boolean;
            preferredUserId?: number;
            skipLoginOnFailure?: boolean;
        } = {}) => {
            if (isRefreshing) {
                return;
            }

            isRefreshing = true;

            try {
                setOrReplaceAccessToken(
                    // As of October 2023, in Chrome Incognito mode and also Firefox, getAccessTokenSilently
                    // can fail in local dev with error login_required. This can come up when, for example,
                    // switching between orgs or onboarding into a new org. It happens because, in local dev,
                    // we don't have a custom login domain, so the Auth0 cookie is on a separate domain,
                    // which Chrome Incognito and Firefox restrict access to. By contrast, it's not a problem
                    // in production where our custom login domain shares a top-level domain with the app.
                    // See #3474.
                    await getAccessTokenSilently({
                        cacheMode: ignoreCache ? "off" : "on",
                        authorizationParams: {
                            u: preferredUserId,
                        },
                    })
                );
            } catch (error) {
                Log.debug("Failed to get access token", { error });

                if (
                    error instanceof OAuthError &&
                    ["login_required", "consent_required", "interaction_required"].includes(
                        error.error
                    ) &&
                    !skipLoginOnFailure
                ) {
                    await login();
                }
            } finally {
                isRefreshing = false;
            }
        },
        [getAccessTokenSilently, login, setOrReplaceAccessToken]
    );

    return { refreshAccessToken };
};

/**
 * Helper hook to transparently ensure we have an unexpired GraphQL API access token issued by
 * Auth0.
 *
 * Rather than wait to detect that the access token has *actually* expired, we just call
 * getTokenSilently() periodically. getTokenSilently() will never return an expired token, and it
 * will also request a new access token if the one in the cache is *about* to expire (within a
 * minute; see https://github.com/auth0/auth0-spa-js/blob/ab5054811a0ab855b31cc16b9830db271af6d363/src/Auth0Client.ts#L1290.
 */
export const useAuth0AccessTokenManager = () => {
    const { isAuthenticated, isLoading } = useAuth0();
    const isAccessTokenAvailable = useRecoilValue(isAccessTokenAvailableState);
    const { refreshAccessToken } = useRefreshAuth0AccessToken();

    useEffect(() => {
        if (!isLoading && isAuthenticated && !isAccessTokenAvailable) {
            void refreshAccessToken();
        }
    }, [isAuthenticated, isLoading, isAccessTokenAvailable, refreshAccessToken]);

    // As of May 2024, Auth0 has a maximum inactivity timeout of 3 days for our plan.
    // See https://auth0.com/docs/manage-users/sessions/configure-session-lifetime-settings.
    // If a user had Flat open but did nothing Auth0-related, then after 3 days their Auth0
    // session would timeout, and the next time they refreshed, they would be forced to login
    // again, which is a subpar UX.
    //
    // The solution is to bump Auth0 periodically so that the Auth0 session remains active as
    // long as the tab is open. The easiest (perhaps only) way to do that is to periodically
    // forcibly request a new access token (even if the current one is not anywhere close
    // to expiring).
    //
    // Ordinarily a new access token will be issued without requiring the user to login,
    // because we're refreshing frequently enough to avoid the inactivity timeout. However,
    // Auth0 also has a "require login after" setting that the user *must* login once
    // every 30 days, regardless of activity.
    //
    // So, imagine this scenario. A user logs in. Over the course of 30 days, we keep refreshing
    // the access token, so the user is never considered inactive. Just after 30 days, we try to
    // refresh the accesss token again. This time, Auth0 refuses to issue the access token and
    // demands a login. At this moment, the user is in the midst of using the app, so we don't
    // want it to suddenly log out. That's why we set skipLoginOnFailure. The user *will* have
    // to login again when they refresh, but that's much better than suddenly having to login
    // while using the app.
    useInterval(() => {
        if (isAccessTokenAvailable) {
            void refreshAccessToken({ ignoreCache: true, skipLoginOnFailure: true });
        }
    }, Config.accessToken.forceRefreshIntervalMs);

    // The purpose of this interval is to refresh the access token when it's close to expiration.
    // (When ignoreCache is false, the default, refreshing returns the existing access token
    // unless it's close to expiration, in which case it will fetch a new one.) Given the above,
    // it's unlikely we will ever have an access token close to expiration, since we are forcibly
    // fetching fresh ones periodically. There are two scenarios where it might arise:
    //  (1) The user was offline for a long period, so none of the force refreshes succeeded.
    //  (2) The "require loging after" 30 days period has elapsed, *and* the final access token
    //      we got is close to expiration.
    //
    // In either case, it's important to refresh the access token, or the app will simply stop
    // working for the user. If that means logging the user out while they're in the midst of
    // using the app, so be it, they've had a good run of 30+ days!
    useInterval(() => {
        if (isAccessTokenAvailable) {
            void refreshAccessToken();
        }
    }, Config.accessToken.checkIntervalMs);
};
