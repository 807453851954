import React from "react";

import { CommonEnumValue, CommonEnums } from "c9r-common";
import classNames from "classnames";

import { Icon } from "components/ui/core/Icon";
import { Radio, RadioProps } from "components/ui/core/Radio";
import { Tooltip } from "components/ui/core/Tooltip";

import styles from "./BoardAccessTypeRadio.module.scss";

export type BoardAccessTypeRadioProps = {
    boardAccessType: CommonEnumValue<"BoardAccessType">;
    className?: string;
    disablePublic?: boolean;
    orgDisplayName: string | null;
    setBoardAccessType: (accessType: CommonEnumValue<"BoardAccessType">) => void;
} & Omit<RadioProps["Group"], "children">;

export function BoardAccessTypeRadio({
    boardAccessType,
    className,
    disablePublic,
    orgDisplayName,
    setBoardAccessType,
    ...rest
}: BoardAccessTypeRadioProps) {
    const isPrivateBoardWarningDisplayed =
        boardAccessType === CommonEnums.BoardAccessType.PRIVATE && !disablePublic;

    return (
        <Radio.Group
            {...rest}
            className={classNames(className, styles.radio)}
            instrumentation={null}
            name="board_access_type"
            onValueChange={value => setBoardAccessType(value as CommonEnumValue<"BoardAccessType">)}
            value={boardAccessType}
        >
            <Tooltip
                content="Private workspaces cannot be made public."
                disabled={!disablePublic}
                placement="right"
                showFast
                small
            >
                <Radio.Item
                    disabled={!!disablePublic}
                    label={
                        <div className={styles.label}>
                            <div className={styles.title}>Public</div>
                            <div className={styles.captions}>
                                <div className={styles.description}>
                                    {`Anyone in ${
                                        orgDisplayName ?? "your organization"
                                    } can access`}
                                </div>
                            </div>
                        </div>
                    }
                    value={CommonEnums.BoardAccessType.PUBLIC}
                />
            </Tooltip>
            <Radio.Item
                label={
                    <div className={styles.label}>
                        <div className={styles.title}>
                            Private{" "}
                            <Icon
                                icon="lock"
                                iconSet="c9r"
                                iconSize={12}
                                strokeWidthAbsolute={1.5}
                            />
                        </div>
                        <div className={styles.captions}>
                            <div className={styles.description}>
                                Only people added to the workspace as members will have access
                            </div>
                            <div className={styles.warning}>
                                {isPrivateBoardWarningDisplayed ? (
                                    <>
                                        <Icon
                                            icon="alert-triangle"
                                            iconSet="lucide"
                                            iconSize={12}
                                            strokeWidth={2}
                                        />
                                        Private workspaces cannot later be made public
                                    </>
                                ) : null}
                            </div>
                        </div>
                    </div>
                }
                value={CommonEnums.BoardAccessType.PRIVATE}
            />
        </Radio.Group>
    );
}
