import React, { useRef } from "react";

import classNames from "classnames";

import { useBreakpoints } from "lib/Breakpoints";
import { ExternalCssClasses } from "lib/Constants";
import { useResetScrollPosition } from "lib/Hooks";
import { getFragmentData, gql } from "lib/graphql/__generated__";
import { CodeSection } from "views/ticketDetail/content/CodeSection";
import { useDetailView } from "views/ticketDetail/context/DetailViewContext";
import { useDetailViewDiscussion } from "views/ticketDetail/context/DetailViewDiscussionContext";
import { DiscussionContent } from "views/ticketDetail/discussion/DiscussionContent";

import styles from "./DetailViewMain.module.scss";
import { TicketDocument } from "./TicketDocument";

const fragments = {
    ticket: gql(/* GraphQL */ `
        fragment DetailViewMain_ticket on tickets {
            id

            ...CodeSection_ticket
            ...DiscussionContent_ticket
            ...TicketDocument_ticket
        }
    `),
};

type ScrollWrapperProps = {
    children: React.ReactNode;
    className?: string;
    enabled?: boolean;
};

function ScrollWrapper({ children, className, enabled }: ScrollWrapperProps) {
    const { ticket } = useDetailView();
    const ref = useRef(null);

    useResetScrollPosition({ scrollElementRef: ref, id: ticket.id });

    if (!enabled) {
        return <>{children}</>;
    }

    return (
        <div className={classNames(className, styles.scrollWrapper)} ref={ref}>
            {children}
        </div>
    );
}

type ContentProps = {
    className?: string;
};

function Content({ className }: ContentProps) {
    const breakpoints = useBreakpoints();
    const { ticket: _ticketFragment } = useDetailView();
    const ticket = getFragmentData(fragments.ticket, _ticketFragment);

    return (
        <ScrollWrapper className={styles.contentScrollWrapper} enabled={breakpoints.lgMin}>
            <div
                className={classNames(className, styles.content, ExternalCssClasses.TICKET_CONTENT)}
            >
                <TicketDocument />
                <CodeSection className={styles.codeSection} ticket={ticket} />
            </div>
        </ScrollWrapper>
    );
}

type NewThreadClickTargetProps = {
    className?: string;
};

function NewThreadClickTarget({ className }: NewThreadClickTargetProps) {
    const { setIsAddingNewComment } = useDetailViewDiscussion();

    return (
        <div
            className={classNames(className, styles.newThreadClickTarget)}
            onClick={() => setIsAddingNewComment(true)}
        />
    );
}

function Discussion() {
    const breakpoints = useBreakpoints();
    const { isAddingNewComment } = useDetailViewDiscussion();

    return (
        <ScrollWrapper className={styles.discussionScrollWrapper} enabled={breakpoints.lgMin}>
            <>
                <div
                    className={classNames(
                        styles.discussionContentWrapper,
                        !isAddingNewComment && styles.notAddingNewComment
                    )}
                >
                    <DiscussionContent onlyUnresolved />
                </div>
                {!isAddingNewComment ? <NewThreadClickTarget /> : null}
            </>
        </ScrollWrapper>
    );
}

export function DetailViewMain() {
    const breakpoints = useBreakpoints();
    const { isDiscussionPanelPopulated: showDiscussionPanel } = useDetailViewDiscussion();

    return (
        <ScrollWrapper className={styles.mainScrollWrapper} enabled={breakpoints.mdMax}>
            <div
                className={classNames(
                    styles.main,
                    !showDiscussionPanel && styles.noDiscussionPanel
                )}
            >
                {!showDiscussionPanel ? <div className={styles.spacer} /> : null}
                <Content className={classNames(!showDiscussionPanel && styles.noDiscussionPanel)} />
                {showDiscussionPanel ? (
                    <Discussion />
                ) : (
                    <NewThreadClickTarget className={styles.noDiscussionPanel} />
                )}
            </div>
        </ScrollWrapper>
    );
}
