import React, { useMemo } from "react";

import * as Mutations from "lib/mutations";
import { createCtx } from "lib/react/Context";

export type MutationsContextValue = {
    archiveBoard: ReturnType<typeof Mutations.useArchiveBoard>["archiveBoard"];
    archiveTicket: ReturnType<typeof Mutations.useArchiveTicket>["archiveTicket"];
    assignThread: ReturnType<typeof Mutations.useAssignThread>["assignThread"];
    attachGuestToBoard: ReturnType<typeof Mutations.useAttachGuestToBoard>["attachGuestToBoard"];
    attachLabel: ReturnType<typeof Mutations.useAttachLabel>["attachLabel"];
    bulkMutate: ReturnType<typeof Mutations.useBulkMutate>["bulkMutate"];
    changeOrgDomainSignupEnabled: ReturnType<
        typeof Mutations.useChangeOrgDomainSignupEnabled
    >["changeOrgDomainSignupEnabled"];
    changeTicketMembers: ReturnType<typeof Mutations.useChangeTicketMembers>["changeTicketMembers"];
    changeTicketPlans: ReturnType<typeof Mutations.useChangeTicketPlans>["changeTicketPlans"];
    changeUserEmailAddress: ReturnType<
        typeof Mutations.useChangeUserEmailAddress
    >["changeUserEmailAddress"];
    changeUserEnabled: ReturnType<typeof Mutations.useChangeUserEnabled>["changeUserEnabled"];
    changeUserRole: ReturnType<typeof Mutations.useChangeUserRole>["changeUserRole"];
    cloneTicket: ReturnType<typeof Mutations.useCloneTicket>["cloneTicket"];
    createBoard: ReturnType<typeof Mutations.useCreateBoard>["createBoard"];
    createChildTicketTask: ReturnType<
        typeof Mutations.useCreateChildTicketTask
    >["createChildTicketTask"];
    createStage: ReturnType<typeof Mutations.useCreateStage>["createStage"];
    createTask: ReturnType<typeof Mutations.useCreateTask>["createTask"];
    createTasklist: ReturnType<typeof Mutations.useCreateTasklist>["createTasklist"];
    createThreadWithBlocker: ReturnType<
        typeof Mutations.useCreateThreadWithBlocker
    >["createThreadWithBlocker"];
    createThreadWithComment: ReturnType<
        typeof Mutations.useCreateThreadWithComment
    >["createThreadWithComment"];
    createTicket: ReturnType<typeof Mutations.useCreateTicket>["createTicket"];
    createTicketAttachment: ReturnType<
        typeof Mutations.useCreateTicketAttachment
    >["createTicketAttachment"];
    deleteLabel: ReturnType<typeof Mutations.useDeleteLabel>["deleteLabel"];
    deleteStage: ReturnType<typeof Mutations.useDeleteStage>["deleteStage"];
    deleteTask: ReturnType<typeof Mutations.useDeleteTask>["deleteTask"];
    deleteTasklist: ReturnType<typeof Mutations.useDeleteTasklist>["deleteTasklist"];
    deleteTicketAttachment: ReturnType<
        typeof Mutations.useDeleteTicketAttachment
    >["deleteTicketAttachment"];
    detachGuestFromBoard: ReturnType<
        typeof Mutations.useDetachGuestFromBoard
    >["detachGuestFromBoard"];
    detachLabel: ReturnType<typeof Mutations.useDetachLabel>["detachLabel"];
    disposeUser: ReturnType<typeof Mutations.useDisposeUser>["disposeUser"];
    editBlocker: ReturnType<typeof Mutations.useEditBlocker>["editBlocker"];
    editComment: ReturnType<typeof Mutations.useEditComment>["editComment"];
    editCommentAndAssignThread: ReturnType<
        typeof Mutations.useEditCommentAndAssignThread
    >["editCommentAndAssignThread"];
    inviteGuestUser: ReturnType<typeof Mutations.useInviteGuestUser>["inviteGuestUser"];
    inviteUser: ReturnType<typeof Mutations.useInviteUser>["inviteUser"];
    makeBoardPrivate: ReturnType<typeof Mutations.useMakeBoardPrivate>["makeBoardPrivate"];
    moveTicketByDirectionInWorkflow: ReturnType<
        typeof Mutations.useMoveTicketByDirectionInWorkflow
    >["moveTicketByDirectionInWorkflow"];
    moveTicketToEndOfCurrentStage: ReturnType<
        typeof Mutations.useMoveTicketToEndOfCurrentStage
    >["moveTicketToEndOfCurrentStage"];
    moveTicketToStagePosition: ReturnType<
        typeof Mutations.useMoveTicketToStagePosition
    >["moveTicketToStagePosition"];
    moveTicketsToStagePositions: ReturnType<
        typeof Mutations.useMoveTicketsToStagePositions
    >["moveTicketsToStagePositions"];
    moveTicketToStartOfCurrentStage: ReturnType<
        typeof Mutations.useMoveTicketToStartOfCurrentStage
    >["moveTicketToStartOfCurrentStage"];
    promoteTaskToChildTicketTask: ReturnType<
        typeof Mutations.usePromoteTaskToChildTicketTask
    >["promoteTaskToChildTicketTask"];
    provisionOrg: ReturnType<typeof Mutations.useProvisionOrg>["provisionOrg"];
    reinviteUser: ReturnType<typeof Mutations.useReinviteUser>["reinviteUser"];
    removeTicketOwner: ReturnType<typeof Mutations.useRemoveTicketOwner>["removeTicketOwner"];
    renameBoard: ReturnType<typeof Mutations.useRenameBoard>["renameBoard"];
    reopenThread: ReturnType<typeof Mutations.useReopenThread>["reopenThread"];
    replyToThread: ReturnType<typeof Mutations.useReplyToThread>["replyToThread"];
    replyToThreadAndAssign: ReturnType<
        typeof Mutations.useReplyToThreadAndAssign
    >["replyToThreadAndAssign"];
    replyToThreadAndResolve: ReturnType<
        typeof Mutations.useReplyToThreadAndResolve
    >["replyToThreadAndResolve"];
    resolveThread: ReturnType<typeof Mutations.useResolveThread>["resolveThread"];
    saveAppearancePreferences: ReturnType<
        typeof Mutations.useSaveAppearancePreferences
    >["saveAppearancePreferences"];
    saveNotificationPreferences: ReturnType<
        typeof Mutations.useSaveNotificationPreferences
    >["saveNotificationPreferences"];
    saveSettings: ReturnType<typeof Mutations.useSaveSettings>["saveSettings"];
    setOrRemoveUserAvatar: ReturnType<
        typeof Mutations.useSetOrRemoveUserAvatar
    >["setOrRemoveUserAvatar"];
    setTicketOwner: ReturnType<typeof Mutations.useSetTicketOwner>["setTicketOwner"];
    shareFeedback: ReturnType<typeof Mutations.useShareFeedback>["shareFeedback"];
    startWatchingTicket: ReturnType<typeof Mutations.useStartWatchingTicket>["startWatchingTicket"];
    stopWatchingTicket: ReturnType<typeof Mutations.useStopWatchingTicket>["stopWatchingTicket"];
    syncEmailVerification: ReturnType<
        typeof Mutations.useSyncEmailVerification
    >["syncEmailVerification"];
    trashTicket: ReturnType<typeof Mutations.useTrashTicket>["trashTicket"];
    unarchiveBoard: ReturnType<typeof Mutations.useUnarchiveBoard>["unarchiveBoard"];
    unarchiveTicket: ReturnType<typeof Mutations.useUnarchiveTicket>["unarchiveTicket"];
    undeleteLabel: ReturnType<typeof Mutations.useUndeleteLabel>["undeleteLabel"];
    untrashTicket: ReturnType<typeof Mutations.useUntrashTicket>["untrashTicket"];
    updateBoardMembers: ReturnType<typeof Mutations.useUpdateBoardMembers>["updateBoardMembers"];
    updateBoardSettings: ReturnType<typeof Mutations.useUpdateBoardSettings>["updateBoardSettings"];
    updateLabel: ReturnType<typeof Mutations.useUpdateLabel>["updateLabel"];
    updateLastViewedWorkAt: ReturnType<
        typeof Mutations.useUpdateLastViewedWorkAt
    >["updateLastViewedWorkAt"];
    updateOrgDisplayName: ReturnType<
        typeof Mutations.useUpdateOrgDisplayName
    >["updateOrgDisplayName"];
    updateOrgSettings: ReturnType<typeof Mutations.useUpdateOrgSettings>["updateOrgSettings"];
    updateStage: ReturnType<typeof Mutations.useUpdateStage>["updateStage"];
    updateTaskAssignee: ReturnType<typeof Mutations.useUpdateTaskAssignee>["updateTaskAssignee"];
    updateTaskCompletion: ReturnType<
        typeof Mutations.useUpdateTaskCompletion
    >["updateTaskCompletion"];
    updateTaskDueDate: ReturnType<typeof Mutations.useUpdateTaskDueDate>["updateTaskDueDate"];
    updateTasklistStage: ReturnType<typeof Mutations.useUpdateTasklistStage>["updateTasklistStage"];
    updateTasklistTicketPos: ReturnType<
        typeof Mutations.useUpdateTasklistTicketPos
    >["updateTasklistTicketPos"];
    updateTasklistTitle: ReturnType<typeof Mutations.useUpdateTasklistTitle>["updateTasklistTitle"];
    updateTaskTitle: ReturnType<typeof Mutations.useUpdateTaskTitle>["updateTaskTitle"];
    updateTicketDueDate: ReturnType<typeof Mutations.useUpdateTicketDueDate>["updateTicketDueDate"];
    updateTicketSize: ReturnType<typeof Mutations.useUpdateTicketSize>["updateTicketSize"];
    updateTicketTitle: ReturnType<typeof Mutations.useUpdateTicketTitle>["updateTicketTitle"];
    updateUserFullName: ReturnType<typeof Mutations.useUpdateUserFullName>["updateUserFullName"];
    updateUsername: ReturnType<typeof Mutations.useUpdateUsername>["updateUsername"];
};

const [useMutations, ContextProvider] = createCtx<MutationsContextValue>();

export { useMutations };

export type MutationsProviderProps = {
    children: React.ReactNode;
};

export function MutationsProvider({ children }: MutationsProviderProps) {
    const { archiveBoard } = Mutations.useArchiveBoard();
    const { archiveTicket } = Mutations.useArchiveTicket();
    const { assignThread } = Mutations.useAssignThread();
    const { attachGuestToBoard } = Mutations.useAttachGuestToBoard();
    const { attachLabel } = Mutations.useAttachLabel();
    const { bulkMutate } = Mutations.useBulkMutate();
    const { changeOrgDomainSignupEnabled } = Mutations.useChangeOrgDomainSignupEnabled();
    const { changeTicketMembers } = Mutations.useChangeTicketMembers();
    const { changeTicketPlans } = Mutations.useChangeTicketPlans();
    const { changeUserEmailAddress } = Mutations.useChangeUserEmailAddress();
    const { changeUserEnabled } = Mutations.useChangeUserEnabled();
    const { changeUserRole } = Mutations.useChangeUserRole();
    const { cloneTicket } = Mutations.useCloneTicket();
    const { createBoard } = Mutations.useCreateBoard();
    const { createChildTicketTask } = Mutations.useCreateChildTicketTask();
    const { createStage } = Mutations.useCreateStage();
    const { createTask } = Mutations.useCreateTask();
    const { createTasklist } = Mutations.useCreateTasklist();
    const { createThreadWithBlocker } = Mutations.useCreateThreadWithBlocker();
    const { createThreadWithComment } = Mutations.useCreateThreadWithComment();
    const { createTicket } = Mutations.useCreateTicket();
    const { createTicketAttachment } = Mutations.useCreateTicketAttachment();
    const { deleteLabel } = Mutations.useDeleteLabel();
    const { deleteStage } = Mutations.useDeleteStage();
    const { deleteTask } = Mutations.useDeleteTask();
    const { deleteTasklist } = Mutations.useDeleteTasklist();
    const { deleteTicketAttachment } = Mutations.useDeleteTicketAttachment();
    const { detachGuestFromBoard } = Mutations.useDetachGuestFromBoard();
    const { detachLabel } = Mutations.useDetachLabel();
    const { disposeUser } = Mutations.useDisposeUser();
    const { editBlocker } = Mutations.useEditBlocker();
    const { editComment } = Mutations.useEditComment();
    const { editCommentAndAssignThread } = Mutations.useEditCommentAndAssignThread();
    const { inviteGuestUser } = Mutations.useInviteGuestUser();
    const { inviteUser } = Mutations.useInviteUser();
    const { makeBoardPrivate } = Mutations.useMakeBoardPrivate();
    const { moveTicketByDirectionInWorkflow } = Mutations.useMoveTicketByDirectionInWorkflow();
    const { moveTicketToEndOfCurrentStage } = Mutations.useMoveTicketToEndOfCurrentStage();
    const { moveTicketToStagePosition } = Mutations.useMoveTicketToStagePosition();
    const { moveTicketsToStagePositions } = Mutations.useMoveTicketsToStagePositions();
    const { moveTicketToStartOfCurrentStage } = Mutations.useMoveTicketToStartOfCurrentStage();
    const { promoteTaskToChildTicketTask } = Mutations.usePromoteTaskToChildTicketTask();
    const { provisionOrg } = Mutations.useProvisionOrg();
    const { reinviteUser } = Mutations.useReinviteUser();
    const { removeTicketOwner } = Mutations.useRemoveTicketOwner();
    const { renameBoard } = Mutations.useRenameBoard();
    const { reopenThread } = Mutations.useReopenThread();
    const { replyToThread } = Mutations.useReplyToThread();
    const { replyToThreadAndAssign } = Mutations.useReplyToThreadAndAssign();
    const { replyToThreadAndResolve } = Mutations.useReplyToThreadAndResolve();
    const { resolveThread } = Mutations.useResolveThread();
    const { saveAppearancePreferences } = Mutations.useSaveAppearancePreferences();
    const { saveNotificationPreferences } = Mutations.useSaveNotificationPreferences();
    const { saveSettings } = Mutations.useSaveSettings();
    const { setOrRemoveUserAvatar } = Mutations.useSetOrRemoveUserAvatar();
    const { setTicketOwner } = Mutations.useSetTicketOwner();
    const { shareFeedback } = Mutations.useShareFeedback();
    const { startWatchingTicket } = Mutations.useStartWatchingTicket();
    const { stopWatchingTicket } = Mutations.useStopWatchingTicket();
    const { syncEmailVerification } = Mutations.useSyncEmailVerification();
    const { trashTicket } = Mutations.useTrashTicket();
    const { unarchiveBoard } = Mutations.useUnarchiveBoard();
    const { unarchiveTicket } = Mutations.useUnarchiveTicket();
    const { undeleteLabel } = Mutations.useUndeleteLabel();
    const { untrashTicket } = Mutations.useUntrashTicket();
    const { updateBoardMembers } = Mutations.useUpdateBoardMembers();
    const { updateBoardSettings } = Mutations.useUpdateBoardSettings();
    const { updateLabel } = Mutations.useUpdateLabel();
    const { updateLastViewedWorkAt } = Mutations.useUpdateLastViewedWorkAt();
    const { updateOrgDisplayName } = Mutations.useUpdateOrgDisplayName();
    const { updateOrgSettings } = Mutations.useUpdateOrgSettings();
    const { updateStage } = Mutations.useUpdateStage();
    const { updateTaskAssignee } = Mutations.useUpdateTaskAssignee();
    const { updateTaskCompletion } = Mutations.useUpdateTaskCompletion();
    const { updateTaskDueDate } = Mutations.useUpdateTaskDueDate();
    const { updateTasklistStage } = Mutations.useUpdateTasklistStage();
    const { updateTasklistTicketPos } = Mutations.useUpdateTasklistTicketPos();
    const { updateTasklistTitle } = Mutations.useUpdateTasklistTitle();
    const { updateTaskTitle } = Mutations.useUpdateTaskTitle();
    const { updateTicketDueDate } = Mutations.useUpdateTicketDueDate();
    const { updateTicketSize } = Mutations.useUpdateTicketSize();
    const { updateTicketTitle } = Mutations.useUpdateTicketTitle();
    const { updateUserFullName } = Mutations.useUpdateUserFullName();
    const { updateUsername } = Mutations.useUpdateUsername();

    const value = useMemo(
        () => ({
            archiveBoard,
            archiveTicket,
            assignThread,
            attachGuestToBoard,
            attachLabel,
            bulkMutate,
            changeOrgDomainSignupEnabled,
            changeTicketMembers,
            changeTicketPlans,
            changeUserEmailAddress,
            changeUserEnabled,
            changeUserRole,
            cloneTicket,
            createBoard,
            createChildTicketTask,
            createStage,
            createTask,
            createTasklist,
            createThreadWithBlocker,
            createThreadWithComment,
            createTicket,
            createTicketAttachment,
            deleteLabel,
            deleteStage,
            deleteTask,
            deleteTasklist,
            deleteTicketAttachment,
            detachGuestFromBoard,
            detachLabel,
            disposeUser,
            editBlocker,
            editComment,
            editCommentAndAssignThread,
            inviteGuestUser,
            inviteUser,
            makeBoardPrivate,
            moveTicketByDirectionInWorkflow,
            moveTicketToEndOfCurrentStage,
            moveTicketToStagePosition,
            moveTicketsToStagePositions,
            moveTicketToStartOfCurrentStage,
            promoteTaskToChildTicketTask,
            provisionOrg,
            reinviteUser,
            removeTicketOwner,
            renameBoard,
            reopenThread,
            replyToThread,
            replyToThreadAndAssign,
            replyToThreadAndResolve,
            resolveThread,
            saveAppearancePreferences,
            saveNotificationPreferences,
            saveSettings,
            setOrRemoveUserAvatar,
            setTicketOwner,
            shareFeedback,
            startWatchingTicket,
            stopWatchingTicket,
            syncEmailVerification,
            trashTicket,
            unarchiveBoard,
            unarchiveTicket,
            undeleteLabel,
            untrashTicket,
            updateBoardMembers,
            updateBoardSettings,
            updateLabel,
            updateLastViewedWorkAt,
            updateOrgDisplayName,
            updateOrgSettings,
            updateStage,
            updateTaskAssignee,
            updateTaskCompletion,
            updateTaskDueDate,
            updateTasklistStage,
            updateTasklistTicketPos,
            updateTasklistTitle,
            updateTaskTitle,
            updateTicketDueDate,
            updateTicketSize,
            updateTicketTitle,
            updateUserFullName,
            updateUsername,
        }),
        [
            archiveBoard,
            archiveTicket,
            assignThread,
            attachGuestToBoard,
            attachLabel,
            bulkMutate,
            changeOrgDomainSignupEnabled,
            changeTicketMembers,
            changeTicketPlans,
            changeUserEmailAddress,
            changeUserEnabled,
            changeUserRole,
            cloneTicket,
            createBoard,
            createChildTicketTask,
            createStage,
            createTask,
            createTasklist,
            createThreadWithBlocker,
            createThreadWithComment,
            createTicket,
            createTicketAttachment,
            deleteLabel,
            deleteStage,
            deleteTask,
            deleteTasklist,
            deleteTicketAttachment,
            detachGuestFromBoard,
            detachLabel,
            disposeUser,
            editBlocker,
            editComment,
            editCommentAndAssignThread,
            inviteGuestUser,
            inviteUser,
            makeBoardPrivate,
            moveTicketByDirectionInWorkflow,
            moveTicketToEndOfCurrentStage,
            moveTicketToStagePosition,
            moveTicketsToStagePositions,
            moveTicketToStartOfCurrentStage,
            promoteTaskToChildTicketTask,
            provisionOrg,
            reinviteUser,
            removeTicketOwner,
            renameBoard,
            reopenThread,
            replyToThread,
            replyToThreadAndAssign,
            replyToThreadAndResolve,
            resolveThread,
            saveAppearancePreferences,
            saveNotificationPreferences,
            saveSettings,
            setOrRemoveUserAvatar,
            setTicketOwner,
            shareFeedback,
            startWatchingTicket,
            stopWatchingTicket,
            syncEmailVerification,
            trashTicket,
            unarchiveBoard,
            unarchiveTicket,
            undeleteLabel,
            untrashTicket,
            updateBoardMembers,
            updateBoardSettings,
            updateLabel,
            updateLastViewedWorkAt,
            updateOrgDisplayName,
            updateOrgSettings,
            updateStage,
            updateTaskAssignee,
            updateTaskCompletion,
            updateTaskDueDate,
            updateTasklistStage,
            updateTasklistTicketPos,
            updateTasklistTitle,
            updateTaskTitle,
            updateTicketDueDate,
            updateTicketSize,
            updateTicketTitle,
            updateUserFullName,
            updateUsername,
        ]
    );

    return <ContextProvider value={value}>{children}</ContextProvider>;
}
