import React, { useCallback, useRef } from "react";

import * as RadixRadioGroup from "@radix-ui/react-radio-group";

import { Enums } from "lib/Enums";
import { InstrumentationEvent, useInstrumentation } from "lib/Instrumentation";

type PrimitiveRadioInstrumentationEvent = Omit<InstrumentationEvent, "eventType">;

type PrimitiveRadioGroupProps = React.ComponentPropsWithoutRef<typeof RadixRadioGroup.Root> & {
    instrumentation: PrimitiveRadioInstrumentationEvent | null;
};

const PrimitiveRadioGroup = React.forwardRef<
    React.ElementRef<typeof RadixRadioGroup.Root>,
    PrimitiveRadioGroupProps
>(({ instrumentation, onValueChange: _onValueChange, ...rest }, forwardedRef) => {
    const { recordEvent } = useInstrumentation();

    const instrumentationRef = useRef(instrumentation);
    instrumentationRef.current = instrumentation;

    const onValueChange = useCallback(
        (selectedValue: string) => {
            if (instrumentationRef.current?.elementName) {
                void recordEvent({
                    eventType: Enums.InstrumentationEvent.CLICK,
                    elementName: instrumentationRef.current?.elementName,
                    eventData: { ...instrumentationRef.current?.eventData, selectedValue },
                });
            }

            _onValueChange?.(selectedValue);
        },
        [recordEvent, _onValueChange]
    );

    return <RadixRadioGroup.Root onValueChange={onValueChange} {...rest} ref={forwardedRef} />;
});

PrimitiveRadioGroup.displayName = "PrimitiveRadioGroup";

type PrimitiveRadioItemProps = React.ComponentPropsWithoutRef<typeof RadixRadioGroup.Item>;

const PrimitiveRadioItem = React.forwardRef<
    React.ElementRef<typeof RadixRadioGroup.Item>,
    PrimitiveRadioItemProps
>((props, forwardedRef) => {
    return <RadixRadioGroup.Item {...props} ref={forwardedRef} />;
});

PrimitiveRadioItem.displayName = "RadioGroupItem";

type PrimitiveRadioIndicatorProps = React.ComponentPropsWithoutRef<
    typeof RadixRadioGroup.Indicator
>;

const PrimitiveRadioIndicator = React.forwardRef<
    React.ElementRef<typeof RadixRadioGroup.Indicator>,
    PrimitiveRadioIndicatorProps
>((props, forwardedRef) => {
    return <RadixRadioGroup.Indicator {...props} ref={forwardedRef} />;
});

PrimitiveRadioIndicator.displayName = "RadioGroupIndicator";

export const PrimitiveRadio = {
    Group: PrimitiveRadioGroup,
    Item: PrimitiveRadioItem,
    Indicator: PrimitiveRadioIndicator,
};

export type PrimitiveRadioProps = {
    Group: PrimitiveRadioGroupProps;
    Item: PrimitiveRadioItemProps;
    Indicator: PrimitiveRadioIndicatorProps;
};
