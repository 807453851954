import React, { useEffect } from "react";

import { CommonEnums } from "c9r-common";
import classNames from "classnames";
import { useRecoilValue } from "recoil";

import { integrationsSetupStatusState } from "AppState";
import { Config } from "Config";
import { useSetIsViewReady } from "components/loading/Loading";
import { BorderAnchorButton } from "components/ui/core/BorderAnchorButton";
import { BorderButton } from "components/ui/core/BorderButton";
import { Icon } from "components/ui/core/Icon";
import { TextButton } from "components/ui/core/TextButton";
import { useConnectZapierDialog } from "dialogs/ConnectZapierDialog";
import { useFeedbackDialog } from "dialogs/FeedbackDialog";
import discordLogo from "img/discord.png";
import figmaLogo from "img/figma.png";
import githubLogo from "img/github_octocat.png";
import loomLogo from "img/loom.png";
import miroLogo from "img/miro.png";
import muralLogo from "img/mural.png";
import slackLogo from "img/slack.png";
import zapierLogo from "img/zapier.png";
import { Enums } from "lib/Enums";
import { useFeatureFlags } from "lib/Features";
import { useDiscordIntegration } from "lib/Integrations";
import { Queries } from "lib/Queries";
import { Link } from "lib/Routing";
import { useUrlBuilders } from "lib/Urls";

import styles from "./IntegrationsSettings.module.scss";

const staticInfo = {
    DISCORD: {
        name: "Discord",
        logo: discordLogo,
    },
    FIGMA: {
        name: "Figma",
        logo: figmaLogo,
    },
    GITHUB: {
        name: "GitHub",
        logo: githubLogo,
    },
    LOOM: {
        name: "Loom",
        logo: loomLogo,
    },
    MIRO: {
        name: "Miro",
        logo: miroLogo,
    },
    MURAL: {
        name: "Mural",
        logo: muralLogo,
    },
    SLACK: {
        name: "Slack",
        logo: slackLogo,
    },
    ZAPIER: {
        name: "Zapier",
        logo: zapierLogo,
    },
} as const;

type IntegrationType = keyof typeof staticInfo;
type StatusType = "ACTIVE" | "INACTIVE" | "INDIVIDUAL_SETUP";

type IntegrationLogomarkProps = {
    integrationType: IntegrationType;
};

function IntegrationLogomark({ integrationType }: IntegrationLogomarkProps) {
    const info = staticInfo[integrationType];

    return (
        <div className={styles.integrationLogomark}>
            <img alt={info.name} src={info.logo} />
            <span>{info.name}</span>
        </div>
    );
}

type IntegrationStatusProps = {
    statusType: StatusType;
};

function IntegrationStatus({ statusType }: IntegrationStatusProps) {
    return (
        <div
            className={classNames(
                styles.integrationStatus,
                statusType === "ACTIVE" && styles.integrationStatusActive
            )}
        >
            {(() => {
                switch (statusType) {
                    case "ACTIVE":
                        return (
                            <>
                                <Icon
                                    className={styles.integrationStatusIcon}
                                    icon="check"
                                    iconSet="lucide"
                                    iconSize={28}
                                    strokeWidth={2}
                                />
                                Active
                            </>
                        );

                    case "INACTIVE":
                        return (
                            <>
                                <Icon
                                    className={styles.integrationStatusIcon}
                                    icon="x"
                                    iconSet="lucide"
                                    iconSize={28}
                                    strokeWidth={2}
                                />
                                Inactive
                            </>
                        );

                    case "INDIVIDUAL_SETUP":
                        return <>Individual setup</>;

                    default:
                        return null;
                }
            })()}
        </div>
    );
}

type IntegrationBoxProps = {
    caption?: React.ReactNode;
    children?: React.ReactNode;
    connectHref?: string;
    onConnect?: () => void;
    onDisconnect?: () => void;
    integrationType: IntegrationType;
    statusType: StatusType;
};

function IntegrationBox({
    caption,
    children,
    connectHref,
    onConnect,
    onDisconnect,
    integrationType,
    statusType,
}: IntegrationBoxProps) {
    const info = staticInfo[integrationType];
    const connectButtonProps = {
        content: `Connect ${info.name}`,
        instrumentation: {
            elementName: "integrations_settings.connect_btn",
            eventData: { integrationType },
        },
        primary: true,
    };
    const disconnectButtonProps = {
        content: `Disconnect ${info.name}`,
        instrumentation: {
            elementName: "integrations_settings.connect_btn",
            eventData: { integrationType },
        },
        primary: true,
    };

    return (
        <div className={styles.integrationBox}>
            <div className={styles.integrationBoxHeader}>
                <IntegrationLogomark integrationType={integrationType} />
                <IntegrationStatus statusType={statusType} />
            </div>

            <div>{children}</div>

            <div className={styles.integrationBoxCaption}>{caption}</div>

            <div className={styles.integrationBoxFooterActions}>
                {statusType === "ACTIVE" && onDisconnect ? (
                    <BorderButton {...disconnectButtonProps} onClick={onDisconnect} />
                ) : null}
                {statusType === "INACTIVE" ? (
                    <>
                        {onConnect ? (
                            <BorderButton {...connectButtonProps} onClick={onConnect} />
                        ) : null}
                        {connectHref ? (
                            <BorderAnchorButton {...connectButtonProps} href={connectHref} />
                        ) : null}
                    </>
                ) : null}
            </div>
        </div>
    );
}

export function IntegrationsSettings() {
    const { gateFeature, isFeatureEnabled } = useFeatureFlags();
    const integrationsSetupStatus = useRecoilValue(integrationsSetupStatusState);
    const feedbackDialog = useFeedbackDialog();
    const connectZapierDialog = useConnectZapierDialog();
    const setIsViewReady = useSetIsViewReady();
    const { authorizeDiscord, disconnectDiscord } = useDiscordIntegration();
    const { buildSettingsUrl } = useUrlBuilders();

    useEffect(() => {
        setIsViewReady(integrationsSetupStatus.isReady);
    }, [integrationsSetupStatus.isReady, setIsViewReady]);

    if (!integrationsSetupStatus.isReady) {
        return null;
    }

    return (
        <div className={styles.container}>
            <h1>Integrations</h1>
            <ul className={styles.integrations}>
                <li>
                    <IntegrationBox
                        onConnect={() =>
                            authorizeDiscord({ authType: CommonEnums.DiscordAuthType.INTEGRATION })
                        }
                        onDisconnect={() =>
                            disconnectDiscord({ authType: CommonEnums.DiscordAuthType.INTEGRATION })
                        }
                        integrationType="DISCORD"
                        statusType={integrationsSetupStatus.discord ? "ACTIVE" : "INACTIVE"}
                    >
                        {integrationsSetupStatus.discord ? (
                            <>
                                <p>The Flat Discord bot is active on your server.</p>
                                <p>
                                    <strong>Individual setup required.</strong> Have each member of
                                    your team connect Flat to their Discord profile in{" "}
                                    <Link to={`${buildSettingsUrl().pathname}/notifications`}>
                                        Personal Settings | Notifications
                                    </Link>
                                    .
                                </p>
                            </>
                        ) : (
                            <p>
                                Enable Flat to send notifications to your team by adding the Flat
                                Discord bot to your server.
                            </p>
                        )}
                    </IntegrationBox>
                </li>
                <li>
                    <IntegrationBox integrationType="FIGMA" statusType="ACTIVE">
                        <p>
                            <strong>No setup required.</strong> Get live previews of Figma designs
                            just by adding a Figma link to a topic's description.
                        </p>
                    </IntegrationBox>
                </li>
                <li>
                    <IntegrationBox
                        caption={
                            integrationsSetupStatus.github
                                ? "To uninstall the Flat GitHub app, visit your GitHub account's Settings page."
                                : undefined
                        }
                        connectHref={
                            isFeatureEnabled({ feature: Enums.Feature.GITHUB_INTEGRATION })
                                ? Config.githubAppUrl
                                : undefined
                        }
                        onConnect={
                            !isFeatureEnabled({ feature: Enums.Feature.GITHUB_INTEGRATION })
                                ? () => gateFeature({ feature: Enums.Feature.GITHUB_INTEGRATION })
                                : undefined
                        }
                        integrationType="GITHUB"
                        statusType={integrationsSetupStatus.github ? "ACTIVE" : "INACTIVE"}
                    >
                        {integrationsSetupStatus.github ? (
                            <>
                                <p>The Flat GitHub app is installed on your GitHub account.</p>
                                <p>
                                    <strong>Individual setup required.</strong> For additional
                                    benefits, have each member of your team confirm their GitHub
                                    username in{" "}
                                    <Link to={`${buildSettingsUrl().pathname}/profile`}>
                                        Personal Settings | Profile
                                    </Link>
                                    .
                                </p>
                            </>
                        ) : (
                            <>
                                <p>
                                    Enable Flat to automatically link pull requests with topics,
                                    highlight stuck PRs, and more by installing Flat's GitHub app to
                                    your GitHub account.
                                </p>
                            </>
                        )}
                    </IntegrationBox>
                </li>
                <li>
                    <IntegrationBox integrationType="LOOM" statusType="ACTIVE">
                        <p>
                            <strong>No setup required.</strong> Embed Loom videos just by adding a
                            Loom link to a topic's description or a comment.
                        </p>
                    </IntegrationBox>
                </li>
                <li>
                    <IntegrationBox integrationType="MIRO" statusType="ACTIVE">
                        <p>
                            <strong>No setup required.</strong> Get live previews of Miro boards
                            just by adding a Miro link to a topic's description.
                        </p>
                    </IntegrationBox>
                </li>
                <li>
                    <IntegrationBox
                        caption={
                            <>
                                Visit{" "}
                                <a
                                    href="https://support.mural.co/s/article/embed-view-only-murals"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    Mural's documentation
                                </a>{" "}
                                for details on how to enable embedding and generate the embedding
                                link.
                            </>
                        }
                        integrationType="MURAL"
                        statusType="ACTIVE"
                    >
                        <p>
                            <strong>No setup required.</strong> Embed a mural by configuring the
                            mural to allow embedding, then just copy the embedding link and add it
                            to a topic's description.
                        </p>
                    </IntegrationBox>
                </li>
                <li>
                    <IntegrationBox integrationType="SLACK" statusType="INDIVIDUAL_SETUP">
                        <p>
                            <strong>Individual setup required.</strong> To enable Flat to send
                            notifications, each member of your team must connect to Slack for
                            themselves in{" "}
                            <Link to={`${buildSettingsUrl().pathname}/notifications`}>
                                Personal Settings | Notifications
                            </Link>
                            .
                        </p>
                    </IntegrationBox>
                </li>
                <li>
                    <IntegrationBox
                        caption={
                            integrationsSetupStatus.zapier
                                ? "To disconnect Flat from Zapier, visit your Zapier account's Apps page."
                                : undefined
                        }
                        integrationType="ZAPIER"
                        onConnect={connectZapierDialog.open}
                        statusType={integrationsSetupStatus.zapier ? "ACTIVE" : "INACTIVE"}
                    >
                        {integrationsSetupStatus.zapier ? (
                            <>
                                <p>The Flat Zapier integration is enabled for your organization.</p>
                            </>
                        ) : (
                            <>
                                <p>
                                    Build automated workflows between Flat and other apps your team
                                    uses by connecting to Flat in your Zapier account.
                                </p>
                            </>
                        )}
                    </IntegrationBox>
                </li>
            </ul>

            <div className={styles.footer}>
                <p>
                    <strong>More integrations coming soon.&nbsp;</strong>
                    <TextButton
                        text="We'd love to hear your ideas"
                        onClick={feedbackDialog.open}
                        link
                        instrumentation={{
                            elementName: "integrations_settings.feedback_btn",
                        }}
                    />
                    .
                </p>
            </div>
        </div>
    );
}

IntegrationsSettings.queries = {};

Queries.register({ component: "IntegrationsSettings", gqlMapByName: IntegrationsSettings.queries });
