import ExtensionCodeBlock from "@tiptap/extension-code-block";
import { Selection } from "@tiptap/pm/state";

import { serializeText } from "components/ui/editor/extensions/helpers/Serializations";

export default ExtensionCodeBlock.extend({
    addCommands() {
        return {
            ...this.parent?.(),
            toggleCodeBlock: attributes => ({ chain, commands }) => {
                const { selection } = this.editor.state;

                if (selection.from === selection.to) {
                    return commands.toggleNode("codeBlock", "paragraph", attributes);
                }

                const selectedText = serializeText({
                    editor: this.editor,
                    from: selection.from,
                    to: selection.to,
                    leafText: "\n",
                });

                return chain()
                    .deleteSelection()
                    .insertContent({
                        type: "codeBlock",
                        attributes,
                        content: [{ text: selectedText || "\n", type: "text" }],
                    })
                    .run();
            },
        };
    },

    addKeyboardShortcuts() {
        return {
            ...this.parent?.(),

            ArrowUp: () => {
                const { state } = this.editor;
                const { selection } = state;

                if (
                    this.editor.isActive("codeBlock") &&
                    selection.eq(Selection.atStart(state.doc)) &&
                    !!selection.$from.nodeAfter
                ) {
                    this.editor.commands.insertContentAt(0, { type: "paragraph" });
                    return true;
                }

                return false;
            },
            ArrowDown: () => {
                const { state } = this.editor;
                const { selection } = state;

                if (
                    this.editor.isActive("codeBlock") &&
                    selection.eq(Selection.atEnd(state.doc)) &&
                    !!selection.$from.nodeBefore
                ) {
                    this.editor.commands.insertContentAt(state.doc.nodeSize - 2, {
                        type: "paragraph",
                    });
                    return true;
                }

                return false;
            },
        };
    },
});
