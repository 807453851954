import { CommonEnumValue, CommonEnums } from "c9r-common";
import { v4 as uuidv4 } from "uuid";

import JiraLogo from "img/jira.png";
import MondayLogo from "img/monday.png";
import TrelloLogo from "img/trello.png";

export const BrowserSessionId = uuidv4();

export const BuildToolUrlDisplayText = {
    [CommonEnums.MergeRequestBuildStatusUrlType.NETLIFY_PREVIEW]: "Netlify preview",
    [CommonEnums.MergeRequestBuildStatusUrlType.NETLIFY_LOG]: "View log on Netlify",
};

// Global styling helper classes. Should be kept in sync with classes.
export const CssClasses = {
    ALWAYS_SHOW_FOCUS_INDICATOR: "ALWAYS_SHOW_FOCUS_INDICATOR",
    DESKTOP_ONLY: "DESKTOP_ONLY",
    LAYOUT_ROOT: "LAYOUT_ROOT",
    INVISIBLE: "INVISIBLE",
    MENU_TARGET_ACTIVE: "MENU_TARGET_ACTIVE",
    MOBILE_ONLY: "MOBILE_ONLY",
    SCROLLABLE: "SCROLLABLE",
};

export const DefaultStageName = "New Column";

// Non-styling classes referenced by outside tools, e.g., uptime probes.
export const ExternalCssClasses = {
    CARD_TITLE: "CARD_TITLE",
    TICKET_CONTENT: "TICKET_CONTENT",
};

export const ExternalTicketSources: Partial<
    Record<CommonEnumValue<"ImportSource">, { displayName: string; logo: string }>
> = {
    [CommonEnums.ImportSource.JIRA]: {
        displayName: "Jira",
        logo: JiraLogo,
    },
    [CommonEnums.ImportSource.MONDAY]: {
        displayName: "Monday",
        logo: MondayLogo,
    },
    [CommonEnums.ImportSource.TRELLO]: {
        displayName: "Trello",
        logo: TrelloLogo,
    },
};

// Non-styling classes used to help components target specific elements, typically
// because they're controlled by third-party libraries.
export const GlobalCssClasses = {
    RICH_EDITOR: "RICH_EDITOR",
};

export const NewTicketEntryId = "NEW_TICKET_ID";

export const ProductTourElementClasses = {
    LEFT_SIDE: "product-tour__left-side",
    TICKET_CONTENT_DOCUMENT: "product-tour__ticket-content-document",
    TICKET_METADATA_HEADER: "product-tour__ticket-metadata-header",
    TICKET_HEADER_AND_DOCUMENT: "product-tour__ticket-header-and-document",
    TICKET_DISCUSSION_PANEL: "product-tour__ticket-discussion-panel",
    SIDENAV_PEOPLE_SECTION: "product-tour__sidenav-people-section",
    SIDENAV_WORKSPACES_SECTION: "product-tour__sidenav-workspaces-section",
    USER_VIEW: "product-tour__user-view",
    WORKSPACE_STAGE: "product-tour__workspace-stage",
};

export const ThemeClassNames = {
    LIGHT: "c9r-light-mode",
    DARK: "c9r-dark-mode",
    HIGH_CONTRAST: "c9r-dark-high-contrast",
};

export const UserBaseColors = [
    "#EB6048",
    "#F2A107",
    "#58B414",
    "#14B4A4",
    "#1986E6",
    "#B498F4",
    "#EB6BCB",
    "#9E958D",
];
