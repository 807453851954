import { RichText } from "c9r-rich-text";
import MiniSearch from "minisearch";

import { TicketSearchIndex_ticketFragment } from "lib/graphql/__generated__/graphql";

import { defaultSearchOptions } from "./SearchIndexHelpers";

const fields = ["ref", "title", "description", "labels", "tasks", "blockers", "comments"];

export const fieldBoosts = {
    ref: 1000,
    title: 20,
    description: 6,
    labels: 50,
    tasks: 3,
    blockers: 1,
    comments: 1,
};

// https://lucaong.github.io/minisearch/classes/_minisearch_.minisearch.html#options-and-defaults
const indexOptions = {
    fields,
    storeFields: ["id"],
    searchOptions: {
        ...defaultSearchOptions,
        boost: fieldBoosts,
        combineWith: "AND",
    },
};

export function buildTicketDocument({ ticket }: { ticket: TicketSearchIndex_ticketFragment }) {
    return {
        id: ticket.id,
        ref: ticket.ref,
        title: ticket.title,
        // As of April 2024, this conversion is sufficiently performant. For typical
        // description lengths on a typical machine, it should handle thousands per second.
        // And we only fully initialize the ticket search index once, at startup, in batches
        // much smaller than that.
        description: RichText.contentJsonToText(
            RichText.ydocToContentJson({
                ydoc: RichText.ydocFromState(RichText.hexDecodeYdocState(ticket.content_yjs)),
                field: "description",
            })
        ),
        labels: ticket.label_attachments.map(label => label.text).join("\n\n"),
        tasks: ticket.tasklists.flatMap(tl => tl.tasks.map(task => task.title)).join("\n\n"),
        blockers: (ticket.threads || [])
            .map(thread => thread.blocker_text)
            .filter(Boolean)
            .join("\n\n"),
        comments: (ticket.threads || [])
            .flatMap(thread => thread.comments)
            .map(comment => comment.comment_text)
            .join("\n\n"),
    };
}

export function buildIndex({ tickets }: { tickets: readonly TicketSearchIndex_ticketFragment[] }) {
    const miniSearch = new MiniSearch(indexOptions);

    // Rather than use miniSearch.addAll, we loop through the tickets individually and add them.
    // That's actually all the implementation of miniSearch.addAll does anyway, so there's no
    // disadvantage as far as miniSearch is concerned. The advantage of adding the documents
    // individually is to that we don't have to construct in memory all of the concatenated
    // strings (tasks, comments, etc.) at one time.
    for (const ticket of tickets) {
        miniSearch.add(buildTicketDocument({ ticket }));
    }

    return miniSearch;
}
