import React from "react";

import classNames from "classnames";

import { BorderButton } from "components/ui/core/BorderButton";
import { Dialog } from "components/ui/core/Dialog";

import styles from "./ConfirmationModal.module.scss";

export type ConfirmationModalProps = {
    /** The main body of the modal. */
    body: React.ReactNode;

    className?: string;

    /** Whether the modal is open or closed. This is a controlled component, so callers are responsible for setting this. */
    isOpen: boolean;

    /** Callback when the dialog closes for any reason. Use this to update the isOpen prop. */
    onClose: () => void;

    /** Callback when the user clicks the submit button. */
    onSubmit: () => void;

    /** The text of the submit button. */
    submitButtonText?: string;

    /** The title of the modal. */
    title?: string;
};

/**
 * Wrapper around Dialog to create a simple confirmation modal.
 */
export function ConfirmationModal({
    body,
    className,
    isOpen,
    onClose,
    onSubmit,
    submitButtonText,
    title,
}: ConfirmationModalProps) {
    return (
        <Dialog
            title={title}
            isOpen={isOpen}
            className={classNames("confirmation-modal", className)}
            onClose={onClose}
            portalClassName={styles.confirmationModalPortal}
        >
            <Dialog.Body>{body}</Dialog.Body>

            <Dialog.Footer>
                <Dialog.FooterActions>
                    <BorderButton content="Cancel" onClick={onClose} instrumentation={null} />
                    <BorderButton
                        content={submitButtonText ?? "OK"}
                        onClick={onSubmit}
                        instrumentation={null}
                        brandCta
                    />
                </Dialog.FooterActions>
            </Dialog.Footer>
        </Dialog>
    );
}
