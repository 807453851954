import React, { useCallback, useEffect, useState } from "react";

import { CommonEnums, DbColumnTypes } from "c9r-common";
import classNames from "classnames";
import { useRecoilValue } from "recoil";

import { integrationsSetupStatusState } from "AppState";
import { useLiveViewQuery } from "components/loading/Loading";
import { AppToaster } from "components/ui/core/AppToaster";
import { BorderAnchorButton } from "components/ui/core/BorderAnchorButton";
import { BorderButton } from "components/ui/core/BorderButton";
import { Icon } from "components/ui/core/Icon";
import { Toggle } from "components/ui/core/Toggle";
import { useCurrentUser } from "contexts/UserContext";
import DiscordIcon from "img/discord.png";
import SlackIcon from "img/slack.png";
import { useDiscordIntegration, useSlackIntegration } from "lib/Integrations";
import { Log } from "lib/Log";
import { Queries } from "lib/Queries";
import { Link } from "lib/Routing";
import { useUrlBuilders } from "lib/Urls";
import { getFragmentData, gql } from "lib/graphql/__generated__";
import { NotificationSettings_userFragment } from "lib/graphql/__generated__/graphql";
import { useSaveNotificationPreferences } from "lib/mutations";

import styles from "./NotificationsSettings.module.scss";

const fragments = {
    user: gql(/* GraphQL */ `
        fragment NotificationSettings_user on users {
            id

            discord_user_id
            discord_dm_channel_id
            notification_preferences
            slack_user_id
            slack_dm_channel_id
        }
    `),
};

type TUser = NotificationSettings_userFragment;

type NotificationsSettingsContentProps = {
    user: TUser;
};

function NotificationsSettingsContent({ user }: NotificationsSettingsContentProps) {
    const currentUser = useCurrentUser();
    const { buildSettingsUrl } = useUrlBuilders();
    const { authorizeSlack, disconnectSlack } = useSlackIntegration();
    const { authorizeDiscord, disconnectDiscord } = useDiscordIntegration();
    const { saveNotificationPreferences } = useSaveNotificationPreferences();
    const integrationsSetupStatus = useRecoilValue(integrationsSetupStatusState);

    const [notificationPreferences, setNotificationPreferences] = useState(
        user.notification_preferences
    );

    const saveSettings = useCallback(
        async (newNotificationPreferences: DbColumnTypes.UsersNotificationPreferences) => {
            try {
                await saveNotificationPreferences({
                    notificationPreferences: newNotificationPreferences,
                });
            } catch (error) {
                Log.error("Failed to save user profile", { error });
                AppToaster.error({
                    message: "Sorry, something went wrong saving your changes.",
                });
            }
        },
        [saveNotificationPreferences]
    );

    useEffect(() => {
        if (user.notification_preferences) {
            setNotificationPreferences(user.notification_preferences);
        }
    }, [user.notification_preferences]);

    const isSlackConnected = !!user.slack_dm_channel_id;
    const isDiscordConnected = !!user.discord_dm_channel_id;

    return (
        <div className={styles.userProfileView}>
            <div className={styles.settingsWrapper}>
                <div className={styles.notificationSettings}>
                    <h1>Notifications</h1>
                    <div className={styles.notificationsSection}>
                        <div className={styles.notificationLabelToggle}>
                            <Icon
                                className={styles.envelopeIcon}
                                icon="emailEnvelope"
                                iconSet="c9r"
                                iconSize={20}
                            />
                            <h4>Email</h4>
                            <Toggle
                                checked={!!notificationPreferences.EMAIL?.enabled}
                                large
                                onChange={e => {
                                    void saveSettings({
                                        ...notificationPreferences,
                                        EMAIL: {
                                            ...notificationPreferences.EMAIL,
                                            enabled: e.target.checked,
                                        },
                                    });
                                }}
                                instrumentation={{
                                    elementName: "notifications_settings.email_toggle",
                                    eventData: {
                                        enabled: !notificationPreferences.EMAIL?.enabled,
                                    },
                                }}
                            />
                        </div>
                        <p className={styles.notificationDesc}>
                            Flat will email you about threads you're participating in and important
                            changes to topics you own or watch.
                        </p>
                    </div>

                    <div
                        className={classNames(
                            styles.notificationsSection,
                            !isSlackConnected && styles.notificationsSectionDisabled
                        )}
                    >
                        <div className={styles.notificationLabelToggle}>
                            <img alt="Slack" width="20" height="20" src={SlackIcon} />
                            <h4>Slack</h4>
                            <Toggle
                                checked={
                                    !!(notificationPreferences.SLACK?.enabled && isSlackConnected)
                                }
                                disabled={!isSlackConnected}
                                large
                                onChange={e => {
                                    void saveSettings({
                                        ...notificationPreferences,
                                        SLACK: {
                                            ...notificationPreferences.SLACK,
                                            enabled: e.target.checked,
                                        },
                                    });
                                }}
                                instrumentation={{
                                    elementName: "notifications_settings.slack_toggle",
                                    eventData: {
                                        enabled: !notificationPreferences.SLACK?.enabled,
                                    },
                                }}
                            />
                        </div>
                        <p className={styles.notificationDesc}>
                            Flat will message you about threads you're participating in and changes
                            to topics you own or watch.
                        </p>
                    </div>
                    <BorderButton
                        className={styles.notificationIntegrationBtn}
                        content={isSlackConnected ? "Disconnect Slack" : "Connect to Slack"}
                        onClick={() => {
                            if (isSlackConnected) {
                                void disconnectSlack();
                            } else {
                                authorizeSlack();
                            }
                        }}
                        instrumentation={{
                            elementName: isSlackConnected
                                ? "notifications_settings.disconnect_slack_btn"
                                : "notifications_settings.connect_slack_btn",
                        }}
                    />
                    <div
                        className={classNames(
                            styles.notificationsSection,
                            !isDiscordConnected && styles.notificationsSectionDisabled
                        )}
                    >
                        <div className={styles.notificationLabelToggle}>
                            <img alt="Discord" width="20" height="20" src={DiscordIcon} />
                            <h4>Discord</h4>
                            <Toggle
                                checked={
                                    !!(
                                        notificationPreferences.DISCORD?.enabled &&
                                        isDiscordConnected
                                    )
                                }
                                disabled={!isDiscordConnected}
                                large
                                onChange={e => {
                                    void saveSettings({
                                        ...notificationPreferences,
                                        DISCORD: {
                                            ...notificationPreferences.DISCORD,
                                            enabled: e.target.checked,
                                        },
                                    });
                                }}
                                instrumentation={{
                                    elementName: "notifications_settings.discord_toggle",
                                    eventData: {
                                        enabled: !notificationPreferences.DISCORD?.enabled,
                                    },
                                }}
                            />
                        </div>
                        <p className={styles.notificationDesc}>
                            Flat will message you about threads you're participating in and changes
                            to topics you own or watch.
                        </p>
                    </div>
                    {integrationsSetupStatus.discord && (
                        <BorderAnchorButton
                            className={styles.notificationIntegrationBtn}
                            content={
                                isDiscordConnected ? "Disconnect Discord" : "Connect to Discord"
                            }
                            onClick={() => {
                                if (isDiscordConnected) {
                                    void disconnectDiscord({
                                        authType: CommonEnums.DiscordAuthType.NOTIFICATION,
                                    });
                                } else {
                                    authorizeDiscord({
                                        authType: CommonEnums.DiscordAuthType.NOTIFICATION,
                                    });
                                }
                            }}
                            disabled={!integrationsSetupStatus.discord}
                            instrumentation={{
                                elementName: isDiscordConnected
                                    ? "notifications_settings.disconnect_discord_btn"
                                    : "notifications_settings.connect_discord_btn",
                            }}
                        />
                    )}
                    {!integrationsSetupStatus.discord && (
                        <p>
                            {currentUser.role === CommonEnums.UserRole.USER_ORG_ADMIN ? (
                                <>
                                    Connect Discord in&nbsp;
                                    <Link to={`${buildSettingsUrl().pathname}/integrations`}>
                                        Admin Settings | Integrations
                                    </Link>
                                    &nbsp;in order to enable notifications.
                                </>
                            ) : (
                                "Ask one of your team's admins to connect Discord in Admin Settings | Integrations to enable notifications."
                            )}
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
}

export function NotificationsSettings() {
    const currentUser = useCurrentUser();

    const componentQuery = useLiveViewQuery({
        query: NotificationsSettings.queries.component,
        variables: {
            currentUserId: currentUser.id,
        },
    });

    if (componentQuery.loading && !componentQuery.data) {
        return null;
    }

    if (componentQuery.error && !componentQuery.data) {
        throw componentQuery.error;
    }

    const user = getFragmentData(fragments.user, componentQuery.data?.user);

    if (!user) {
        return null;
    }

    return <NotificationsSettingsContent user={user} />;
}

NotificationsSettings.queries = {
    component: gql(/* GraphQL */ `
        query NotiicationsSettings($currentUserId: Int!) {
            user: users_by_pk(id: $currentUserId) {
                ...NotificationSettings_user
            }
        }
    `),
};

Queries.register({
    component: "NotificationsSettings",
    gqlMapByName: NotificationsSettings.queries,
});
