import { useCallback } from "react";

import { TSizeSpec } from "c9r-common";
import { format as formatDate } from "date-fns";

import { useReplicache } from "lib/replicache/Context";

export function useUpdateTicketDueDate() {
    const { replicache } = useReplicache();

    const updateTicketDueDate = useCallback(
        async ({ ticketId, dueDate }: { ticketId: string; dueDate: Date | null }) => {
            const formattedDueDate = dueDate ? formatDate(dueDate, "yyyy-MM-dd") : null;

            await replicache.mutate.updateTicket({ ticketId, dueDate: formattedDueDate });
        },
        [replicache]
    );

    return { updateTicketDueDate };
}

export function useUpdateTicketSize() {
    const { replicache } = useReplicache();

    const updateTicketSize = useCallback(
        async ({ ticketId, sizeSpec }: { ticketId: string; sizeSpec: TSizeSpec | null }) => {
            await replicache.mutate.updateTicket({ ticketId, sizeSpec });
        },
        [replicache]
    );

    return { updateTicketSize };
}

export function useUpdateTicketTitle() {
    const { replicache } = useReplicache();

    const updateTicketTitle = useCallback(
        async ({ ticketId, title }: { ticketId: string; title: string }) => {
            await replicache.mutate.updateTicket({ ticketId, title });
        },
        [replicache]
    );

    return { updateTicketTitle };
}
