import { ValueOf } from "c9r-common";

export type EnumValue<E extends keyof typeof Enums> = ValueOf<typeof Enums[E]>;

export const Enums = {
    AddStageDirection: {
        BEFORE: "BEFORE",
        AFTER: "AFTER",
    } as const,

    ApiRoleType: {
        IDENTITY: "IDENTITY",
        USER: "USER",
        USER_ORG_ADMIN: "USER_ORG_ADMIN",
    } as const,

    AppPage: {
        BOARD: "BOARD",
        SEARCH_RESULTS: "SEARCH_RESULTS",
        SETTINGS: "SETTINGS",
        TICKET_DETAIL: "TICKET_DETAIL",
        USER: "USER",
    } as const,

    AppType: {
        DESKTOP: "DESKTOP",
        WEB: "WEB",
    } as const,

    AttachmentEntityType: {
        TICKET_DETAIL: "TICKET_DETAIL",
        NTD: "NTD",
    } as const,

    // These values are lowercase to match the values in Blueprintjs. They should be passed only
    // directly to Blueprint components (when needed), not to our own components.
    BlueprintIntent: {
        NONE: "none",
        PRIMARY: "primary",
        SUCCESS: "success",
        WARNING: "warning",
        DANGER: "danger",
    } as const,

    ColorScheme: {
        LIGHT_ALWAYS: "LIGHT_ALWAYS",
        DARK_ALWAYS: "DARK_ALWAYS",
        FOLLOW_SYSTEM: "FOLLOW_SYSTEM",
    } as const,

    DndEntityTypes: {
        BOARD: "BOARD",
        NEW_TICKET_ROW: "NEW_TICKET_ROW",
        TICKET_LIST_SECTION: "TICKET_LIST_SECTION",
        STAGE: "STAGE",
        TASK: "TASK",
        TASKLIST: "TASKLIST",
        TASKLISTS_SECTION: "TASKLISTS_SECTION",
        TICKET: "TICKET",
    } as const,

    Feature: {
        ACCOUNT_SETTINGS: "ACCOUNT_SETTINGS",
        BLOCKERS: "BLOCKERS",
        COLLABORATIVE_DOC: "COLLABORATIVE_DOC",
        DEBUG_ROUTES: "DEBUG_ROUTES",
        DISCORD_INTEGRATION: "DISCORD_INTEGRATION",
        GITHUB_INTEGRATION: "GITHUB_INTEGRATION",
        GITHUB_INTEGRATION_FAKE_INSTALLATION: "GITHUB_INTEGRATION_FAKE_INSTALLATION",
        IMPORT_DATA: "IMPORT_DATA",
        IDENTITY_COOKIE: "IDENTITY_COOKIE",
        LIVE_CHAT_SUPPORT: "LIVE_CHAT_SUPPORT",
        MANAGE_USERS: "MANAGE_USERS",
        ONBOARDING: "ONBOARDING",
        REORDERABLE_TASKLISTS: "REORDERABLE_TASKLISTS",
        REPLICACHE_USE_TEST_LICENSE: "REPLICACHE_USE_TEST_LICENSE",
        SLACK_INTEGRATION: "SLACK_INTEGRATION",
        TIP_TOURS: "TIP_TOURS",
        UPLOADS: "UPLOADS",
        USER_SUBMITTED_FEEDBACK: "USER_SUBMITTED_FEEDBACK",
    } as const,

    HideMenuItemIconMode: {
        ALWAYS: "ALWAYS",
        NEVER: "NEVER",
        WHEN_NOTHING_SELECTED: "WHEN_NOTHING_SELECTED",
    } as const,

    HotkeyScope: {
        BOARD_SIDE_DRAWER: "BOARD_SIDE_DRAWER",
        DEFAULT: "DEFAULT",
        DIALOG: "DIALOG",
        MENU: "MENU",
    } as const,

    HotspotKey: {
        BLOCKER_THREADS: "BLOCKER_THREADS",
        BOARD_CARD_CHILD_TICKETS: "BOARD_CARD_CHILD_TICKETS",
        BOARD_CARD_OPEN_BLOCKER: "BOARD_CARD_OPEN_BLOCKER",
        BOARD_CARD_OPEN_PR: "BOARD_CARD_OPEN_PR",
        BOARD_CARD_OPEN_THREAD: "BOARD_CARD_OPEN_THREAD",
        BOARD_CARD_PROGRESS_BAR: "BOARD_CARD_PROGRESS_BAR",
        BOARD_CARD_STALE_PR: "BOARD_CARD_STALE_PR",
        BOARD_FILTER: "BOARD_FILTER",
        CHECKLIST: "CHECKLIST",
        MY_WORK: "MY_WORK",
        OWNER_AND_TEAM: "OWNER_AND_TEAM",
        SUGGESTED_BRANCH_NAME: "SUGGESTED_BRANCH_NAME",
        THREADED_DISCUSSIONS: "THREADED_DISCUSSIONS",
        WORK_STRUCTURES: "WORK_STRUCTURES",
    } as const,

    InstrumentationEvent: {
        BOARD_FILTER: "BOARD_FILTER",
        CLICK: "CLICK",
        DRAG: "DRAG",
        ELEMENT_CANCEL: "ELEMENT_CANCEL",
        ELEMENT_SUBMIT: "ELEMENT_SUBMIT",
        ELEMENT_VIEW: "ELEMENT_VIEW",
        HEARTBEAT: "HEARTBEAT",
        HOTKEY: "HOTKEY",
        HOTSPOT_ACK: "HOTSPOT_ACK",
        HOTSPOT_VIEW: "HOTSPOT_VIEW",
        MULTISELECT_ACTIVE: "MULTISELECT_ACTIVE",
        MULTISELECT_STARTING: "MULTISELECT_STARTING",
        PAGE_VIEW: "PAGE_VIEW",
        SEARCH: "SEARCH",
        SUBPAR_UI: "SUBPAR_UI",
    } as const,

    LinkUnfurlType: {
        FIGMA: "FIGMA",
        GOOGLE_DRIVE: "GOOGLE_DRIVE",
        LOOM: "LOOM",
        MIRO: "MIRO",
        MURAL: "MURAL",
    } as const,

    LogoutType: {
        INTERNAL: "INTERNAL",
        USER_INITIATED: "USER_INITIATED",
    } as const,

    MultiselectMode: {
        OFF: "OFF",
        STARTING: "STARTING",
        ACTIVE: "ACTIVE",
    } as const,

    OAuthKey: {
        DISCORD: "DISCORD",
        GITHUB: "GITHUB",
        SLACK: "SLACK",
        TRELLO: "TRELLO",
    } as const,

    SideDrawerWidthType: {
        FIXED: "FIXED",
        VARIABLE: "VARIABLE",
    } as const,

    SubparUIType: {
        OAUTH_REQUIRES_WEBAPP: "OAUTH_REQUIRES_WEBAPP",
    } as const,

    StatusIconType: {
        BLOCKER: "BLOCKER",
        PR_REVIEW: "PR_REVIEW",
        THREAD: "THREAD",
    } as const,

    TextCommitMethod: {
        BLUR: "BLUR",
        ENTER: "ENTER",
    } as const,

    TicketSource: {
        JIRA: "JIRA",
        TRELLO: "TRELLO",
    } as const,

    TourStopContext: {
        BOARD_VIEW: "BOARD_VIEW",
        LIST_VIEW: "LIST_VIEW",
    } as const,

    TodoType: {
        THREAD: "THREAD",
    } as const,
};
