import React, { useCallback } from "react";

import * as Popover from "@radix-ui/react-popover";
import { CommonEnumValue, CommonEnums, DbColumnTypes, Errors } from "c9r-common";
import classNames from "classnames";

import { BorderButton } from "components/ui/core/BorderButton";
import { Hotkey } from "components/ui/core/Hotkey";
import { Icon } from "components/ui/core/Icon";
import { useCurrentUser } from "contexts/UserContext";
import { useOnboardingWelcomeDialogState } from "dialogs/OnboardingWelcomeDialog";
import { useBreakpoints } from "lib/Breakpoints";
import { EnumValue, Enums } from "lib/Enums";
import { useFeatureFlags } from "lib/Features";
import { Log } from "lib/Log";
import { usePersistedAppState } from "lib/PersistedAppState";

import styles from "./TourStop.module.scss";

type Tour = { itinerary: { subject: CommonEnumValue<"TourSubject"> }[] };

const tours = new Map<CommonEnumValue<"TourId">, Tour>([
    [
        CommonEnums.TourId.BOARD_VIEW,
        {
            itinerary: [
                { subject: CommonEnums.TourSubject.WORKSPACE_CONFIGURATION },
                { subject: CommonEnums.TourSubject.TOPIC_CREATION_VIA_CLICK },
                { subject: CommonEnums.TourSubject.MULTISELECT },
            ],
        },
    ],
    [
        CommonEnums.TourId.DETAIL_VIEW,
        {
            itinerary: [
                { subject: CommonEnums.TourSubject.CHECKLISTS },
                { subject: CommonEnums.TourSubject.BLOCKERS },
                { subject: CommonEnums.TourSubject.DESCRIPTION },
                { subject: CommonEnums.TourSubject.LABELS },
            ],
        },
    ],
    [
        CommonEnums.TourId.PLAN_VIEW,
        {
            itinerary: [
                { subject: CommonEnums.TourSubject.PLAN_VIEW_OVERVIEW },
                { subject: CommonEnums.TourSubject.PLAN_VIEW_PRIORITIZATION },
                { subject: CommonEnums.TourSubject.PLAN_VIEW_INBOX },
            ],
        },
    ],
]);

function buildTourStateKey({ tourId }: { tourId: CommonEnumValue<"TourId"> }) {
    return `tour.${tourId}` as `tour.${CommonEnumValue<"TourId">}`;
}

function getTip({
    subject,
    context,
}: {
    subject: CommonEnumValue<"TourSubject">;
    context?: EnumValue<"TourStopContext">;
}) {
    switch (subject) {
        case CommonEnums.TourSubject.BLOCKERS: {
            return {
                title: <>Capture what's blocking progress</>,
                body: (
                    <>
                        If the topic is stuck and can't move forward, add a blocker to prominently
                        record why. You can even assign and discuss it.
                    </>
                ),
            };
        }

        case CommonEnums.TourSubject.CHECKLISTS: {
            return {
                title: <>Break topics down into tasks</>,
                body: (
                    <>
                        Add checklists with simple, assignable tasks. Or type <Hotkey text="#" /> to
                        link another topic as a subtopic.
                    </>
                ),
            };
        }

        case CommonEnums.TourSubject.DESCRIPTION: {
            return {
                title: <>Collaborate in real-time</>,
                body: (
                    <>
                        Every topic has a real-time collaborative document where you and your
                        teammates can flesh out what needs to be done, record notes and ideas, and
                        link to other resources.
                    </>
                ),
            };
        }

        case CommonEnums.TourSubject.LABELS: {
            return {
                title: <>Categorize topics with labels</>,
                body: (
                    <>
                        Add labels to make it easier to filter, search for, and identity topics. A
                        topic can have as many labels as you like.
                    </>
                ),
            };
        }

        case CommonEnums.TourSubject.MULTISELECT: {
            return {
                title: <>Quickly edit multiple topics together</>,
                body: (
                    <>
                        Hold <Hotkey text="Shift" /> and click topics to select them, then type what
                        you'd like to change, and Flat will offer suggested edits.
                    </>
                ),
            };
        }

        case CommonEnums.TourSubject.PLAN_VIEW_INBOX: {
            return {
                title: <>See what's new</>,
                body: (
                    <>
                        Your <i>Inbox</i> has everything newly assigned to you that you haven't yet
                        prioritized.
                    </>
                ),
            };
        }

        case CommonEnums.TourSubject.PLAN_VIEW_OVERVIEW: {
            return {
                title: <>Meet your personal planner</>,
                body: (
                    <>
                        Your planner is an optional view where you can see and organize everything
                        on your plate across all your workspaces.
                    </>
                ),
            };
        }

        case CommonEnums.TourSubject.PLAN_VIEW_PRIORITIZATION: {
            return {
                title: <>Hit that prioritization sweet spot</>,
                body: (
                    <>
                        Drag and drop topics into simple priority buckets <i>Now</i>, <i>Next</i>,
                        and <i>Later</i> to stay organized without overcomplicating things. Topics
                        disappear from your planner when they're completed.
                    </>
                ),
            };
        }

        case CommonEnums.TourSubject.TOPIC_CREATION_VIA_CLICK: {
            switch (context) {
                case Enums.TourStopContext.BOARD_VIEW: {
                    return {
                        title: <>Create a topic with one click</>,
                        body: <>Just click anywhere in the empty space to add a new topic there.</>,
                    };
                }

                case Enums.TourStopContext.LIST_VIEW: {
                    return {
                        title: <>Create a topic with one click</>,
                        body: (
                            <>
                                Just click in the empty space between sections to add a new topic
                                there.
                            </>
                        ),
                    };
                }

                default: {
                    throw new Errors.UnexpectedCaseError({ context });
                }
            }
        }

        case CommonEnums.TourSubject.WORKSPACE_CONFIGURATION: {
            return {
                title: <>Configure your workspace</>,
                body: (
                    <>Click on a workspace's name to adjust columns, privacy, and other settings.</>
                ),
            };
        }

        default: {
            throw new Errors.UnexpectedCaseError({ subject });
        }
    }
}

export function useBuildInitialTourStates() {
    const { isFeatureEnabled } = useFeatureFlags();

    const buildInitialTourStates = useCallback(() => {
        if (!isFeatureEnabled({ feature: Enums.Feature.TIP_TOURS })) {
            return {};
        }

        const now = new Date(Date.now()).toISOString();

        return Array.from(tours).reduce(
            (_tourStates, [tourId, tour]) => ({
                ..._tourStates,

                [buildTourStateKey({ tourId })]: {
                    currentSubject: tour.itinerary[0].subject,
                    lastStartedAt: now,
                    lastCompletedAt: null,
                    lastDismissedAt: null,
                },
            }),
            {}
        );
    }, [isFeatureEnabled]);

    return { buildInitialTourStates };
}

export const TourStopAnchor = Popover.Anchor;

type TourStopProps = {
    children: React.ReactNode;
    tourId: CommonEnumValue<"TourId">;
    subject: CommonEnumValue<"TourSubject">;
    context?: EnumValue<"TourStopContext">;
    showArrow?: boolean;
} & Popover.PopoverContentProps;

export function TourStop({
    children,
    tourId,
    subject: tourStopSubject,
    context,
    showArrow = true,
    ...popoverContentProps
}: TourStopProps) {
    const currentUser = useCurrentUser();
    const { shallowUpdateUserAppState } = usePersistedAppState();
    const breakpoints = useBreakpoints();
    const { isOpen: isOnboardingWelcomeDialogOpen } = useOnboardingWelcomeDialogState();

    if (isOnboardingWelcomeDialogOpen) {
        return <Popover.Root open={false}>{children}</Popover.Root>;
    }

    const tourState = currentUser.app_state[buildTourStateKey({ tourId })];

    if (!tourState) {
        return <Popover.Root open={false}>{children}</Popover.Root>;
    }

    const { currentSubject, lastStartedAt, lastCompletedAt, lastDismissedAt } = tourState;

    if (
        (lastCompletedAt && Date.parse(lastCompletedAt) > Date.parse(lastStartedAt)) ||
        (lastDismissedAt && Date.parse(lastDismissedAt) > Date.parse(lastStartedAt)) ||
        currentSubject !== tourStopSubject
    ) {
        return <Popover.Root open={false}>{children}</Popover.Root>;
    }

    const tour = tours.get(tourId);

    if (!tour) {
        Log.error("Tour does not exist", { tourId, tours });

        return <Popover.Root open={false}>{children}</Popover.Root>;
    }

    const tourSubjectsInOrder = tour.itinerary.map(({ subject }) => subject) ?? [];
    const tourStopSubjectIndex = tourSubjectsInOrder.indexOf(tourStopSubject);

    if (tourStopSubjectIndex === -1) {
        Log.error("Tour stop subject not part of tour", { tourStopSubject, tour });

        return <Popover.Root open={false}>{children}</Popover.Root>;
    }

    const previousTourSubject = tourSubjectsInOrder[tourStopSubjectIndex - 1];
    const nextTourSubject = tourSubjectsInOrder[tourStopSubjectIndex + 1];
    const tourLength = tourSubjectsInOrder.length;

    const shallowUpdateTourState = (partialTourState: Partial<DbColumnTypes.TourState>) =>
        shallowUpdateUserAppState({
            [buildTourStateKey({ tourId })]: {
                ...tourState,
                ...partialTourState,
            },
        });

    const tip = getTip({ subject: tourStopSubject, context });

    return (
        <Popover.Root open={breakpoints.lgMin}>
            {children}
            <Popover.Portal>
                <Popover.Content
                    asChild
                    avoidCollisions={false}
                    className={styles.tourStop}
                    updatePositionStrategy="always"
                    {...popoverContentProps}
                >
                    {/* Stop propagation of click event to avoid triggering wrapping component's `onClick` handler. */}
                    {/* (An example of a wrapping component whose `onClick` handler we would like to avoid triggering is `PlanView` > `TicketListHeader`.) */}
                    <div className={styles.wrapper} onClick={e => e.stopPropagation()}>
                        <Popover.Close asChild className={styles.closeButton}>
                            <BorderButton
                                content={<Icon icon="x" iconSet="lucide" iconSize={16} />}
                                flush
                                instrumentation={{
                                    elementName: "tour_stop.close_btn",
                                    eventData: { tourId, tourStopSubject },
                                }}
                                minimal
                                onClick={() =>
                                    shallowUpdateTourState({
                                        lastDismissedAt: new Date(Date.now()).toISOString(),
                                    })
                                }
                                square
                                themeDarkHighContrast
                                tighterer
                            />
                        </Popover.Close>
                        {showArrow ? (
                            <Popover.Arrow asChild>
                                <div className={styles.arrow} />
                            </Popover.Arrow>
                        ) : null}
                        <div className={styles.tip}>
                            <div className={styles.title}>{tip.title}</div>
                            <div className={styles.body}>{tip.body}</div>
                        </div>
                        <div className={styles.footer}>
                            <div className={styles.breadcrumbs}>
                                {new Array(tourLength).fill(null).map((_, index) => (
                                    <div
                                        // eslint-disable-next-line react/no-array-index-key
                                        key={index}
                                        className={classNames(
                                            styles.breadcrumb,
                                            tourStopSubjectIndex === index && styles.current
                                        )}
                                    />
                                ))}
                            </div>
                            <div className={styles.actions}>
                                {previousTourSubject ? (
                                    <BorderButton
                                        content="Back"
                                        instrumentation={{
                                            elementName: "tour_stop.back_btn",
                                            eventData: { tourId, tourStopSubject },
                                        }}
                                        minimal
                                        onClick={() =>
                                            shallowUpdateTourState({
                                                currentSubject: previousTourSubject,
                                            })
                                        }
                                        themeDarkHighContrast
                                    />
                                ) : null}
                                {nextTourSubject ? (
                                    <BorderButton
                                        content="Next"
                                        instrumentation={{
                                            elementName: "tour_stop.next_btn",
                                            eventData: { tourId, tourStopSubject },
                                        }}
                                        onClick={() =>
                                            shallowUpdateTourState({
                                                currentSubject: nextTourSubject,
                                            })
                                        }
                                        themeDarkHighContrast
                                    />
                                ) : (
                                    <BorderButton
                                        content="Done"
                                        instrumentation={{
                                            elementName: "tour_stop.done_btn",
                                            eventData: { tourId, tourStopSubject },
                                        }}
                                        onClick={() =>
                                            shallowUpdateTourState({
                                                currentSubject: null,
                                                lastCompletedAt: new Date(Date.now()).toISOString(),
                                            })
                                        }
                                        themeDarkHighContrast
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </Popover.Content>
            </Popover.Portal>
        </Popover.Root>
    );
}
