import React, { useState } from "react";

import { CommonEnums } from "c9r-common";
import classNames from "classnames";

import { EllipsisButton } from "components/ui/common/EllipsisButton";
import { Icon } from "components/ui/core/Icon";
import { Menu } from "components/ui/core/Menu";
import { MenuDivider } from "components/ui/core/MenuDivider";
import { MenuItem } from "components/ui/core/MenuItem";
import { MenuPopover } from "components/ui/core/MenuPopover";
import { useTicketSelectionContext } from "contexts/TicketSelectionContext";
import { divideAndFlattenGroups } from "lib/Helpers";
import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";
import { isDefined } from "lib/types/guards";

import { useBoardViewFilter } from "./BoardFilterContext";
import { useBoardView } from "./BoardViewContext";
import styles from "./StageMenu.module.scss";

const fragments = {
    stage: gql(/* GraphQL */ `
        fragment StageMenu_stage on stages {
            id
            display_name
            role
        }
    `),

    ticket: gql(/* GraphQL */ `
        fragment StageMenu_ticket on tickets {
            id
        }
    `),
};

export type StageMenuProps = {
    buttonClassName?: string;
    className?: string;
    popoverClassName?: string;
    stage: FragmentType<typeof fragments.stage>;
    tickets: FragmentType<typeof fragments.ticket>[];
};

export function StageMenu({
    buttonClassName,
    className,
    popoverClassName,
    stage: _stageFragment,
    tickets: _ticketFragments,
}: StageMenuProps) {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const stage = getFragmentData(fragments.stage, _stageFragment);
    const tickets = _ticketFragments.map(t => getFragmentData(fragments.ticket, t));

    const { filterExpression } = useBoardViewFilter();
    const { onArchiveStageTickets } = useBoardView();
    const { deselectTickets, selectTickets, selection } = useTicketSelectionContext();

    const isSomeTicketSelected = tickets.some(t => selection.ticketIds.has(t.id));
    const isSomeTicketNotSelected = tickets.some(t => !selection.ticketIds.has(t.id));

    const menuItemGroups = [
        [
            isSomeTicketNotSelected ? (
                <MenuItem
                    data-cy="board-column-menu-select-all"
                    key="selectAll"
                    icon={<Icon icon="check-circle-2" iconSet="lucide" iconSize={18} />}
                    text={filterExpression ? "Select visible" : "Select all"}
                    instrumentation={{
                        elementName: "stage.menu.select_all",
                        eventData: {
                            stageId: stage.id,
                        },
                    }}
                    onClick={() => selectTickets({ ticketIds: tickets.map(t => t.id) })}
                />
            ) : null,
            isSomeTicketSelected ? (
                <MenuItem
                    data-cy="board-column-menu-deselect-all"
                    key="deselectAll"
                    icon={<Icon icon="circle" iconSet="lucide" iconSize={18} />}
                    text={filterExpression ? "Deselect visible" : "Deselect all"}
                    instrumentation={{
                        elementName: "stage.menu.deselect_all",
                        eventData: {
                            stageId: stage.id,
                        },
                    }}
                    onClick={() => deselectTickets({ ticketIds: tickets.map(t => t.id) })}
                />
            ) : null,
        ].filter(isDefined),
        [
            stage.role === CommonEnums.StageRole.COMPLETE ? (
                <MenuItem
                    key="archive"
                    icon={<Icon icon="archiveArrow" iconSet="c9r" iconSize={18} />}
                    text={filterExpression ? "Archive visible" : "Archive all"}
                    instrumentation={{
                        elementName: "stage.menu.archive_all",
                        eventData: {
                            stageId: stage.id,
                        },
                    }}
                    onClick={() =>
                        onArchiveStageTickets({
                            stageId: stage.id,
                        })
                    }
                />
            ) : null,
        ].filter(isDefined),
    ];

    if (menuItemGroups.every(g => !g.length)) {
        return null;
    }

    const menuItems = divideAndFlattenGroups({
        itemGroups: menuItemGroups,
        divider: i => <MenuDivider key={i} />,
    });

    return (
        <div
            className={className}
            onClick={e => {
                e.stopPropagation();
            }}
        >
            <MenuPopover
                popoverClassName={popoverClassName}
                modifiers={{
                    offset: {
                        enabled: true,
                        options: {
                            offset: [8, -4],
                        },
                    },
                    preventOverflow: { enabled: false },
                }}
                content={<Menu>{menuItems}</Menu>}
                placement="bottom-end"
                onOpening={() => setIsMenuOpen(true)}
                onClosing={() => setIsMenuOpen(false)}
            >
                <EllipsisButton
                    data-cy="board-column-menu-btn"
                    className={classNames(
                        buttonClassName,
                        styles.menuBtn,
                        isMenuOpen && styles.menuOpen
                    )}
                    vertical
                    instrumentation={null}
                    active={isMenuOpen}
                />
            </MenuPopover>
        </div>
    );
}
