import React, { useCallback, useState } from "react";

import { CommonEnums, sortStages, sortTasks } from "c9r-common";
import classNames from "classnames";
import {
    Draggable,
    DraggableProvidedDraggableProps,
    DraggableStateSnapshot,
} from "react-beautiful-dnd";

import {
    TasksProgress,
    TasksProgressBar,
    TasksProgressButton,
    TasksProgressReport,
} from "components/shared/TasksProgress";
import { BorderButton } from "components/ui/core/BorderButton";
import { DropdownButton } from "components/ui/core/DropdownButton";
import { EditableText } from "components/ui/core/EditableText";
import { Icon } from "components/ui/core/Icon";
import { Select } from "components/ui/core/Select";
import { Tooltip } from "components/ui/core/Tooltip";
import { useSemanticBreakpoints } from "lib/Breakpoints";
import { Enums } from "lib/Enums";
import { useFeatureFlags } from "lib/Features";
import { useBuildUnauthorizedDisplayName } from "lib/Nomenclature";
import { useGetTaskStatusInfo } from "lib/TicketInfo";
import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";
import { TasklistSection_tasklistFragment } from "lib/graphql/__generated__/graphql";
import { useDeleteTasklist, useUpdateTasklistStage, useUpdateTasklistTitle } from "lib/mutations";

import { Tasklist } from "./Tasklist";
import styles from "./TasklistSection.module.scss";
import { useTasklistsSectionContext } from "./TasklistsSectionContext";

const fragments = {
    tasklist: gql(/* GraphQL */ `
        fragment TasklistSection_tasklist on tasklists {
            id
            title

            stage {
                id
                display_name

                board {
                    id
                    display_name
                }
            }

            tasks(where: { deleted_at: { _is_null: true } }) {
                id
                tasklist_pos

                ...TaskStatusInfo_task
                ...TasksProgress_task
            }

            ticket {
                id

                board {
                    id

                    stages(where: { deleted_at: { _is_null: true } }) {
                        id
                        board_pos
                        deleted_at
                        display_name
                        role
                    }
                }
            }

            ...Tasklist_tasklist
        }
    `),
};

type TSelectableStage = TasklistSection_tasklistFragment["ticket"]["board"]["stages"][number];

export type TasklistSectionProps = {
    className?: string;
    index: number;
    isAlone: boolean;
    tasklist: FragmentType<typeof fragments.tasklist>;
};

export function TasklistSection({
    className,
    index,
    isAlone,
    tasklist: _tasklistFragment,
}: TasklistSectionProps) {
    const { isFeatureEnabled } = useFeatureFlags();
    const { isDetailViewDocumentPaddingTight } = useSemanticBreakpoints();
    const tasklist = getFragmentData(fragments.tasklist, _tasklistFragment);
    const [isStageSelectOpen, setIsStageSelectOpen] = useState(false);

    const { deleteTasklist } = useDeleteTasklist();
    const { updateTasklistStage } = useUpdateTasklistStage();
    const { updateTasklistTitle } = useUpdateTasklistTitle();

    const { getTaskStatusInfo } = useGetTaskStatusInfo();
    const { toggleTasklistCollapse, getIsTasklistCollapsed } = useTasklistsSectionContext();
    const { buildUnauthorizedDisplayName } = useBuildUnauthorizedDisplayName();
    const tasks = tasklist.tasks
        .filter(task => !getTaskStatusInfo({ task }).isHidden)
        .sort(sortTasks());
    const stagesWithChecklists = tasklist.ticket.board.stages
        .filter(s => !s.deleted_at && s.role === CommonEnums.StageRole.IMPLEMENTATION)
        .sort(sortStages());
    const isTasklistCollapsible = tasks.some(task => getTaskStatusInfo({ task }).isComplete);
    const isTasklistCollapsed = getIsTasklistCollapsed(tasklist.id);
    const isTasklistOnCurrentBoard =
        !!tasklist.stage && tasklist.ticket.board.id === tasklist.stage.board.id;

    const handleDeleteTasklist = useCallback(async () => {
        await deleteTasklist({ tasklistId: tasklist.id });
    }, [deleteTasklist, tasklist.id]);

    const handleTasklistStageChange = useCallback(
        async (stage: TSelectableStage | null) => {
            if (!stage) {
                return;
            }

            await updateTasklistStage({
                tasklistId: tasklist.id,
                stageId: stage.id,
            });
        },
        [tasklist.id, updateTasklistStage]
    );

    const handleTasklistTitleChange = useCallback(
        async ({ value: title }: { value?: string | null }) => {
            if (!title) {
                return;
            }

            await updateTasklistTitle({
                tasklistId: tasklist.id,
                title,
            });
        },
        [tasklist.id, updateTasklistTitle]
    );

    const isDragDisabled =
        !isFeatureEnabled({ feature: Enums.Feature.REORDERABLE_TASKLISTS }) ||
        isAlone ||
        isDetailViewDocumentPaddingTight;

    function getStyle(
        style: DraggableProvidedDraggableProps["style"],
        snapshot: DraggableStateSnapshot
    ): React.CSSProperties {
        if (!snapshot.isDropAnimating || !snapshot.dropAnimation) {
            return { ...style };
        }

        const { duration } = snapshot.dropAnimation;

        return {
            ...style,
            transition: `all cubic-bezier(0.2,1.0,0.9,1.0) ${duration / 1.5}s`,
        };
    }

    return (
        <Draggable draggableId={tasklist.id} index={index} isDragDisabled={isDragDisabled}>
            {(provided, snapshot) => (
                <div
                    className={classNames(
                        className,
                        styles.tasklistSection,
                        snapshot.isDragging && !snapshot.isDropAnimating && styles.isDragging,
                        snapshot.isDropAnimating && styles.isDropAnimating
                    )}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    style={getStyle(provided.draggableProps.style, snapshot)}
                >
                    <header>
                        {isDragDisabled ? null : (
                            <Icon
                                className={styles.dragHandle}
                                icon="dragHandleLarge"
                                iconSet="c9r"
                                {...provided.dragHandleProps}
                            />
                        )}

                        <h3>
                            <EditableText
                                cancelIfEmpty
                                className={styles.editableTitle}
                                onConfirm={handleTasklistTitleChange}
                                placeholder="Enter title..."
                                value={tasklist.title}
                            />
                        </h3>

                        <div className={styles.headerDetail}>
                            {stagesWithChecklists.length > 1 || !isTasklistOnCurrentBoard ? (
                                <Select
                                    className={styles.stagePickerSelect}
                                    targetClassName={styles.stagePickerSelectTarget}
                                    placement="bottom-start"
                                    initiallySelectedItemsIDs={
                                        tasklist.stage ? [tasklist.stage.id] : undefined
                                    }
                                    items={stagesWithChecklists}
                                    menuItemTextRenderer={item => item.display_name}
                                    itemPredicate={(q, item) =>
                                        item.display_name.toLowerCase().includes(q.toLowerCase())
                                    }
                                    placeholder="Filter stages"
                                    getInstrumentation={stage => ({
                                        elementName: "tasklist.stage_menu",
                                        eventData: {
                                            tasklistId: tasklist.id,
                                            stageId: stage.id,
                                        },
                                    })}
                                    onSelect={handleTasklistStageChange}
                                    onOpening={() => setIsStageSelectOpen(true)}
                                    onClosing={() => setIsStageSelectOpen(false)}
                                >
                                    {() => {
                                        return (
                                            <Tooltip
                                                className={styles.stagePickerTooltip}
                                                content="This list's progress will be shown on the board when the topic is in this stage."
                                                disabled={
                                                    isStageSelectOpen || !isTasklistOnCurrentBoard
                                                }
                                                openOnTargetFocus={false}
                                                placement="bottom"
                                                small
                                            >
                                                <DropdownButton
                                                    className={styles.stagePicker}
                                                    small
                                                    minimal
                                                    text={
                                                        isTasklistOnCurrentBoard
                                                            ? tasklist.stage.display_name
                                                            : tasklist.stage
                                                            ? `[${tasklist.stage.board.display_name}] ${tasklist.stage.display_name}`
                                                            : `[${buildUnauthorizedDisplayName({
                                                                  abstractName: "space",
                                                              })}] ${buildUnauthorizedDisplayName({
                                                                  abstractName: "workflowStage",
                                                              })}`
                                                    }
                                                    instrumentation={null}
                                                />
                                            </Tooltip>
                                        );
                                    }}
                                </Select>
                            ) : null}
                            <div style={{ flex: "1 1 auto" }} />
                            {tasks.length === 0 ? (
                                <BorderButton
                                    minimal
                                    instrumentation={{
                                        elementName: "tasklist.trash_btn",
                                        eventData: { tasklistId: tasklist.id },
                                    }}
                                    content={<Icon icon="trash" iconSet="lucide" />}
                                    onClick={handleDeleteTasklist}
                                />
                            ) : isTasklistCollapsible ? (
                                <TasksProgressButton
                                    className={styles.progress}
                                    handleClick={() => toggleTasklistCollapse(tasklist.id)}
                                    isTasklistCollapsed={isTasklistCollapsed}
                                    instrumentation={{
                                        elementName: "tasklist.tasks_progress_btn",
                                        eventData: { tasklistId: tasklist.id, isTasklistCollapsed },
                                    }}
                                >
                                    <TasksProgressBar tasks={tasks} />
                                    <TasksProgressReport
                                        className={styles.progressReport}
                                        tasks={tasks}
                                    />
                                </TasksProgressButton>
                            ) : (
                                <TasksProgress className={styles.progress}>
                                    <TasksProgressBar tasks={tasks} />
                                    <TasksProgressReport
                                        className={styles.progressReport}
                                        tasks={tasks}
                                    />
                                </TasksProgress>
                            )}
                        </div>
                    </header>
                    <Tasklist
                        className={styles.tasklist}
                        tasklist={tasklist}
                        onDelete={handleDeleteTasklist}
                    />
                </div>
            )}
        </Draggable>
    );
}
