import React, { useCallback, useEffect, useRef, useState } from "react";

import { CommonEnumValue, CommonEnums, sortTasks } from "c9r-common";
import classNames from "classnames";
import { parse as parseDate } from "date-fns";
import { Droppable } from "react-beautiful-dnd";
import { CSSTransition } from "react-transition-group";

import {
    TicketSelector,
    TicketSelectorItem,
    TicketSelectorItemType,
} from "components/shared/TicketSelector";
import { AppToaster } from "components/ui/core/AppToaster";
import { Checkbox } from "components/ui/core/Checkbox";
import { MenuPopover } from "components/ui/core/MenuPopover";
import { useCurrentUser, useShouldShowTicketRefs } from "contexts/UserContext";
import { dragAndDropEntity } from "lib/DragAndDrop";
import {
    generatePositionsBetween,
    moveToLastPosition,
    moveToPositionByIndex,
} from "lib/EntityPositioning";
import { Enums } from "lib/Enums";
import { getTextPartitionedBySelection } from "lib/Helpers";
import { useAsyncWatcher, useDialog } from "lib/Hooks";
import { useRecordTicketSearch } from "lib/Instrumentation";
import { Queries } from "lib/Queries";
import { useHistory } from "lib/Routing";
import { useGetTaskStatusInfo } from "lib/TicketInfo";
import { useRecordTicketView } from "lib/TicketViews";
import { useUrlBuilders } from "lib/Urls";
import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";
import { Tasklist_tasklistFragment } from "lib/graphql/__generated__/graphql";
import { usePrefetchQuery } from "lib/graphql/usePrefetchQuery";
import {
    useCreateChildTicketTask,
    useCreateTask,
    useCreateTicket,
    useDeleteTask,
    usePromoteTaskToChildTicketTask,
    useUpdateTaskCompletion,
    useUpdateTaskTitle,
} from "lib/mutations";
import { isDefined } from "lib/types/guards";

import styles from "./Tasklist.module.scss";
import { TasklistChildTicket } from "./TasklistChildTicket";
import { TasklistItem, TasklistItemProps } from "./TasklistItem";
import { useTasklistsSectionContext } from "./TasklistsSectionContext";

const fragments = {
    tasklist: gql(/* GraphQL */ `
        fragment Tasklist_tasklist on tasklists {
            id
            added_at
            stage_id
            uuid

            tasks(where: { deleted_at: { _is_null: true } }) {
                id
                assigned_to_user_id
                due_date
                is_complete
                tasklist_pos
                task_type
                title

                child_ticket {
                    id
                    title
                }

                ...TasklistItem_task
                ...TasklistChildTicket_task
                ...TaskStatusInfo_task
            }

            ticket {
                id

                board {
                    id
                    access_type
                }

                org {
                    id

                    boards(where: { archived_at: { _is_null: true } }) {
                        id
                        display_name

                        stages(where: { deleted_at: { _is_null: true } }) {
                            id
                            role
                            display_name
                            max_ticket_stage_pos
                        }
                    }

                    ...TasklistItem_org
                }
            }
        }
    `),
};

type NewTasklistItemProps = {
    /** Whether to autofocus the input. */
    autoFocus?: boolean;

    board: Tasklist_tasklistFragment["ticket"]["board"];

    createTextTasksAtIndex: (params: {
        index: number;
        titles: string[];
    }) => Promise<{ ids: (string | null)[] }>;

    /** Callback if the user cancels the input. */
    onCancel?: () => void;

    /** Callback if the user commits the input. */
    onSave?: (params: {
        title: string | null;
        isComplete: boolean;

        childTicketId: string | null;

        /** Whether to show another input area after this one is committed. */
        another: boolean;
    }) => void;

    index: number;

    /** Ref for the input element. */
    inputRef?: React.RefObject<HTMLInputElement>;

    /** Whether the input is the input at the end of a section. */
    isTrailingInput?: boolean;

    setAutoFocusTask: (params: {
        id: string;
        autoFocus: NonNullable<TasklistItemProps["autoFocus"]>;
    }) => void;

    /** Tickets that should be excluded from the search results. */
    ticketIdsToBeExcludedFromSearchResults: string[];
};

function NewTasklistItem({
    autoFocus = false,
    board,
    createTextTasksAtIndex,
    onCancel = () => undefined,
    onSave = () => undefined,
    index,
    inputRef: externalRef,
    isTrailingInput = false,
    setAutoFocusTask,
    ticketIdsToBeExcludedFromSearchResults,
}: NewTasklistItemProps) {
    const [isComplete, setIsComplete] = useState(false);
    const [query, setQuery] = useState("");
    const { focusPreviousTask } = useTasklistsSectionContext();
    const internalRef = useRef<HTMLInputElement>(null);
    const inputRef = externalRef ?? internalRef;
    const ticketSelectorPopover = useDialog();
    const submission = useAsyncWatcher();
    const { recordTicketSearch } = useRecordTicketSearch({ elementName: "tasklist.target_input" });

    useEffect(() => recordTicketSearch(query), [recordTicketSearch, query]);

    useEffect(() => {
        const inputElement = inputRef.current;

        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === "ArrowDown" && !ticketSelectorPopover.isOpen) {
                // There is a container that wraps the "arrow navigable" task items which are denoted by the
                // "data-tasklists-arrow-nav" attribute and when an item is added or removed, there is a useEffect that updates
                // the "data-tasklists-arrow-nav" indices that are used for navigation, thus when a new task list item input is
                // created or removed in between two items, the indices are also updated. The issue that appears
                // to be happening is that when it's removed via the down arrow, the arrow navigation handler fires at a
                // point where the DOM has not fully updated yet and/ or the indices have not updated yet, which results
                // in that element not being found. This is not happening for the up arrow, as the previous item and its
                // index remains unchanged, just the items below the new task item input changes. So here, because
                // this effect is only cleaned up after the component has been unmounted, we are briefly focusing
                // the previous element, after which the useArrowNavigation handler will fire, which will then focus the
                // next navigable element.
                // Note, this issue only becames apparent when you try and navigate down to a child ticket task item.
                const arrowNavAttr = inputRef.current?.getAttribute("data-tasklists-arrow-nav");

                if (!arrowNavAttr) {
                    return;
                }

                (document.querySelector(
                    `[data-tasklists-arrow-nav="${parseInt(arrowNavAttr) - 1}"]`
                ) as HTMLElement)?.focus();
            }
        };

        if (!isTrailingInput) {
            inputElement?.addEventListener("keydown", handleKeyDown, true);
        }

        return () => {
            if (!isTrailingInput) {
                inputElement?.removeEventListener("keydown", handleKeyDown);
            }
        };
    }, [inputRef, ticketSelectorPopover.isOpen, isTrailingInput]);

    const clear = useCallback(() => {
        setQuery("");
        setIsComplete(false);
    }, []);

    const handleCancel = useCallback(() => {
        clear();
        onCancel();
    }, [clear, onCancel]);

    const handleSave = useCallback(
        async ({
            another,
            title = null,
            childTicketId = null,
        }: {
            another: boolean;
            title?: string | null;
            childTicketId?: string | null;
        }) => {
            if (!title?.trim() && !childTicketId) {
                handleCancel();
                return;
            }

            await onSave({ title, isComplete, another, childTicketId });

            clear();

            if (another) {
                setImmediate(() => inputRef.current?.focus());
            }
        },
        [clear, inputRef, isComplete, handleCancel, onSave]
    );

    const handleClose = useCallback(() => {
        ticketSelectorPopover.close();
        setQuery("");
    }, [ticketSelectorPopover]);

    const handleClosed = useCallback(() => {
        inputRef.current?.focus();
    }, [inputRef]);

    return (
        <MenuPopover
            canEscapeKeyClose={query === ""}
            popoverClassName={styles.ticketSelectorPopover}
            content={
                <TicketSelector
                    autoFocus
                    excludedTicketIds={ticketIdsToBeExcludedFromSearchResults}
                    onQueryChange={setQuery}
                    onSelect={(selectedItem: TicketSelectorItem) => {
                        if (selectedItem.type === TicketSelectorItemType.TICKET) {
                            void submission.watch(() =>
                                handleSave({ childTicketId: selectedItem.ticket.id, another: true })
                            )();
                            ticketSelectorPopover.close();
                            inputRef.current?.focus();
                        }
                    }}
                    query={query}
                    showRecentTicketsOnEmptyQuery
                    ticketReferenceContextAndOriginBoard={{
                        context: CommonEnums.TicketReferenceContext.HIERARCHY,
                        originBoard: board,
                    }}
                />
            }
            fill
            placement="bottom-start"
            modifiers={{
                offset: {
                    enabled: true,
                    options: {
                        offset: [51, -30],
                    },
                },
            }}
            isOpen={ticketSelectorPopover.isOpen}
            onClose={handleClose}
            onClosed={handleClosed}
        >
            <li className={styles.newTask} onClick={() => inputRef.current?.focus()}>
                <Checkbox
                    disabled
                    checked={isComplete}
                    inline
                    onChange={e => {
                        setIsComplete(e.target.checked);
                    }}
                    instrumentation={null}
                />
                <input
                    placeholder="(type '#' to attach a child topic)"
                    {...(!ticketSelectorPopover.isOpen && { "data-tasklists-arrow-nav": true })}
                    autoFocus={autoFocus}
                    disabled={submission.isInFlight}
                    type="text"
                    value={query}
                    onChange={e => {
                        if (!(e.target.value === "#" && !query)) {
                            setQuery(e.target.value);
                        }
                    }}
                    onBlur={() => {
                        if (!ticketSelectorPopover.isOpen) {
                            void submission.watch(() =>
                                handleSave({ title: query, another: false })
                            )();
                        }
                    }}
                    onKeyDown={e => {
                        if (e.key === "#" && !query) {
                            e.preventDefault();
                            ticketSelectorPopover.open();
                        }

                        if (e.key === "Backspace" && !query) {
                            e.preventDefault();

                            handleCancel();
                            focusPreviousTask({ shouldWrap: true });
                        }

                        if (e.key === "Escape") {
                            handleCancel();

                            if (!query) {
                                inputRef.current?.blur();
                            }
                        }

                        if (e.key === "Enter" && !ticketSelectorPopover.isOpen) {
                            void submission.watch(() =>
                                handleSave({ title: query, another: true })
                            )();
                        }
                    }}
                    onPaste={async e => {
                        const anchorOffset = inputRef.current?.selectionStart;
                        const focusOffset = inputRef.current?.selectionEnd;

                        if (!isDefined(anchorOffset) || !isDefined(focusOffset)) {
                            return;
                        }

                        const { leftText, rightText } = getTextPartitionedBySelection({
                            text: query,
                            selection: { anchorOffset, focusOffset },
                        });

                        const lines = [leftText, e.clipboardData.getData("text"), rightText]
                            .join("")
                            .split("\n")
                            .map(line => line.trim())
                            .filter(Boolean);

                        if (lines.length > 1) {
                            e.preventDefault();

                            const { ids } = await createTextTasksAtIndex({ index, titles: lines });

                            // `handleCancel` must be called after the new tasks are created but before the last
                            // of the new tasks is focused.
                            //   - If `handleCancel` is called before the new tasks are created, then the paste
                            //   isn't "seamless", i.e. the new task input goes blank before the new tasks appear.
                            //   - If `handleCancel` is called after the last of the new tasks is focused, then
                            //   the content of the new task input is saved via blur (and not cancelled).

                            handleCancel();

                            const lastCreatedTaskId = ids[ids.length - 1];

                            if (lastCreatedTaskId) {
                                setAutoFocusTask({
                                    id: lastCreatedTaskId,
                                    autoFocus: { at: "END" },
                                });
                            }
                        }
                    }}
                    ref={inputRef}
                />
            </li>
        </MenuPopover>
    );
}

type ExitAndUnmountOnCompleteProps = {
    children: React.ReactNode;
    task: Tasklist_tasklistFragment["tasks"][number];
};

function ExitAndUnmountOnComplete({ children, task }: ExitAndUnmountOnCompleteProps) {
    const { getTaskStatusInfo } = useGetTaskStatusInfo();

    return (
        <CSSTransition
            key={task.id}
            classNames={{ ...styles }}
            enter={false}
            in={!getTaskStatusInfo({ task }).isComplete}
            // If updating, ensure matches CSS transition duration.
            timeout={300}
            unmountOnExit
        >
            {children}
        </CSSTransition>
    );
}

type TTaskDisplayItem = {
    type: CommonEnumValue<"TaskType">;
    tasklistPos: number;
    id: string;
    task: Tasklist_tasklistFragment["tasks"][number];
    taskIndex: number;
};

type TNewItemDisplayItem = {
    type: "NEW_ITEM";
    tasklistPos: number;
    id: number;
};

type TDisplayItem = TTaskDisplayItem | TNewItemDisplayItem;

export type TasklistProps = {
    className?: string;
    onDelete: () => void;
    tasklist: FragmentType<typeof fragments.tasklist>;
};

export function Tasklist({ className, onDelete, tasklist: _tasklistFragment }: TasklistProps) {
    const tasklist = getFragmentData(fragments.tasklist, _tasklistFragment);
    const currentUser = useCurrentUser();
    const shouldShowTicketRefs = useShouldShowTicketRefs();
    const inputRef = useRef<HTMLInputElement>(null);
    const [autoFocusTask, setAutoFocusTask] = useState<{
        id: string;
        autoFocus: NonNullable<TasklistItemProps["autoFocus"]>;
    }>();
    const { getTaskStatusInfo } = useGetTaskStatusInfo();
    const { history } = useHistory();
    const prefetchQuery = usePrefetchQuery();
    const { recordTicketView } = useRecordTicketView();
    const { getIsTasklistCollapsed } = useTasklistsSectionContext();
    const { buildTicketUrl } = useUrlBuilders();
    const { createTask } = useCreateTask();
    const { createTicket } = useCreateTicket();
    const { createChildTicketTask } = useCreateChildTicketTask();
    const { promoteTaskToChildTicketTask } = usePromoteTaskToChildTicketTask();
    const { deleteTask } = useDeleteTask();
    const { updateTaskCompletion } = useUpdateTaskCompletion();
    const { updateTaskTitle } = useUpdateTaskTitle();
    const [newItemTasklistPos, setNewItemTasklistPos] = useState<number | null>(null);
    const ticketIdsToBeExcludedFromSearchResults = tasklist.tasks
        .map(task => task.child_ticket?.id)
        .filter(isDefined)
        .concat(tasklist.ticket.id);

    const tasklistWasJustCreated = Date.now() - Date.parse(tasklist.added_at) < 1000;

    const allTasks = tasklist.tasks.concat().sort(sortTasks());
    const nonHiddenTasks = allTasks.filter(task => !getTaskStatusInfo({ task }).isHidden);
    const tasksToDisplay = nonHiddenTasks.filter(task => !!task.tasklist_pos);

    const displayItems = ([] as TDisplayItem[])
        .concat(
            tasksToDisplay.reduce(
                (acc, task) => {
                    const { items, nextTaskIndex } = acc;

                    const isTaskCollapsed =
                        getIsTasklistCollapsed(tasklist.id) &&
                        getTaskStatusInfo({ task }).isComplete;

                    const item = {
                        type: task.task_type,
                        tasklistPos: task.tasklist_pos!,
                        id: task.id,
                        task,
                        // This is the index of the task in the list of tasks (needed for drag/drop and
                        // entity position calculations), *not* the index in the list of displayed items.
                        taskIndex: isTaskCollapsed ? -1 : nextTaskIndex,
                    };

                    return {
                        items: [...items, item],
                        nextTaskIndex: nextTaskIndex + (item.taskIndex >= 0 ? 1 : 0),
                    };
                },
                { items: [] as TDisplayItem[], nextTaskIndex: 0 }
            ).items
        )
        .concat(
            [
                newItemTasklistPos
                    ? ({
                          type: "NEW_ITEM",
                          tasklistPos: newItemTasklistPos,
                          id: -1,
                      } as const)
                    : null,
            ].filter(isDefined)
        )
        .sort((a, b) => a.tasklistPos - b.tasklistPos);

    const handleTaskIsCompleteChange = async ({
        taskId,
        isComplete,
    }: {
        taskId: string;
        isComplete: boolean;
    }) => {
        const task = allTasks.find(t => t.id === taskId);

        if (!task || !!isComplete === !!task.is_complete) {
            return;
        }

        await updateTaskCompletion({ taskId, isComplete });
    };

    const handleDeleteTask = async ({ taskId }: { taskId: string }) => {
        await deleteTask({ taskId });

        // Delete the entire list if the task being deleted was the last one.
        // Note: This uses tasklist.tasks, which has all the tasks (including hidden ones)
        // whereas tasks includes only those being displayed.
        if (nonHiddenTasks.length === 1) {
            onDelete();
        }
    };

    const handleTaskTitleChange = async ({
        taskId,
        title,
    }: {
        taskId: string;
        title: string | null;
    }) => {
        const task = allTasks.find(t => t.id === taskId);

        if (!task || title === task.title) {
            return;
        }

        if (!title?.trim()) {
            await handleDeleteTask({ taskId });
            return;
        }

        await updateTaskTitle({ taskId, title });
    };

    const handleNewTasklistItemCancel = () => {
        setNewItemTasklistPos(null);
    };

    const commitNewTask = async ({
        newTaskTitle,
        newTaskChecked,
        newTasklistPos,
        newTaskChildTicketId,
    }: {
        newTaskTitle: string | null;
        newTaskChecked: boolean;
        newTasklistPos?: number | null;
        newTaskChildTicketId?: string | null;
    }) => {
        if (newTaskTitle !== "") {
            const tasklistId = tasklist.id;
            const tasklistPos =
                newTasklistPos ||
                moveToPositionByIndex({
                    sortedEntities: displayItems,
                    posFieldName: "tasklistPos",
                    toIndex: displayItems.length,
                });

            if (newTaskChildTicketId) {
                const taskId = await createChildTicketTask({
                    ticketId: tasklist.ticket.id,
                    tasklistId,
                    tasklistPos,
                    childTicketId: newTaskChildTicketId,
                });

                return taskId;
            }

            if (newTaskTitle) {
                const taskId = await createTask({
                    ticketId: tasklist.ticket.id,
                    tasklistId,
                    tasklistPos,
                    title: newTaskTitle,
                    isComplete: newTaskChecked,
                });

                return taskId;
            }
        }

        return null;
    };

    const createTextTasksAtIndex = async ({
        index,
        titles,
        autoFocusLastTask,
    }: {
        index: number;
        titles: string[];
        autoFocusLastTask?: boolean;
    }) => {
        const positions = generatePositionsBetween({
            fromPos: displayItems[index - 1]?.tasklistPos,
            toPos: displayItems[index]?.tasklistPos,
            count: titles.length,
        });

        const ids = await Promise.all(
            titles.map((title, titleIndex) =>
                commitNewTask({
                    newTaskTitle: title,
                    newTaskChecked: false,
                    newTasklistPos: positions[titleIndex],
                })
            )
        );

        if (autoFocusLastTask) {
            const lastCreatedTaskId = ids[ids.length - 1];

            if (lastCreatedTaskId) {
                setAutoFocusTask({ id: lastCreatedTaskId, autoFocus: { at: "END" } });
            }
        }

        return { ids };
    };

    const handlePromoteToTicket = async ({
        boardId,
        stageId,
        taskId,
    }: {
        boardId: string;
        stageId: string;
        taskId: string;
    }) => {
        const task = allTasks.find(t => t.id === taskId);

        if (!task) {
            return;
        }

        const { title } = task;
        const board = tasklist.ticket.org.boards.find(b => b.id === boardId);

        if (!board) {
            return;
        }

        const selectedStage = board.stages.find(s => s.id === stageId);

        if (!selectedStage) {
            return;
        }

        if (title) {
            const newMinimalTicket = await createTicket({
                boardId,
                stageId: selectedStage.id,
                title,
                stagePos: moveToLastPosition({ maxPos: selectedStage.max_ticket_stage_pos }),
                dueDate: task.due_date
                    ? parseDate(task.due_date, "yyyy-MM-dd", new Date())
                    : undefined,
                ownerUserId: task.assigned_to_user_id ?? undefined,
                makeCurrentUserWatcher: true,
            });

            if (!newMinimalTicket) {
                AppToaster.error({
                    message: "Something went wrong creating that topic. Please try again.",
                });

                return;
            }

            void recordTicketView({ ticketId: newMinimalTicket.id });

            // Cache details page in case the user immediately navigates to it.
            void prefetchQuery({
                query: Queries.get({ component: "DetailView", name: "component" }),
                variables: {
                    orgId: currentUser.org_id,
                    ref: newMinimalTicket.ref,
                },
            });

            await promoteTaskToChildTicketTask({
                taskId,
                childTicketId: newMinimalTicket.id,
            });

            AppToaster.success({
                message: `Topic${
                    shouldShowTicketRefs ? ` #${newMinimalTicket.ref}` : ""
                } created in ${selectedStage.display_name}.`,
                action: {
                    text: "View topic",
                    onClick: () => {
                        history.push(
                            buildTicketUrl({
                                ticketSlug: newMinimalTicket.slug,
                            }).pathname
                        );
                    },
                },
            });
        }
    };

    const startComposing = ({ atIndex }: { atIndex: number }) => {
        if (atIndex >= displayItems.length) {
            inputRef?.current?.focus();

            return;
        }

        setNewItemTasklistPos(
            moveToPositionByIndex({
                sortedEntities: displayItems,
                posFieldName: "tasklistPos",
                toIndex: atIndex,
            })
        );
    };

    const handleTextCommit = async ({
        task,
        index,
        method,
        value,
        selection,
    }: {
        task: TTaskDisplayItem["task"];
        index: number;
    } & Parameters<TasklistItemProps["onTextCommit"]>[0]) => {
        if (method === Enums.TextCommitMethod.ENTER && value && selection) {
            const { leftText, rightText } = getTextPartitionedBySelection({
                text: value,
                selection,
            });

            if (leftText && rightText) {
                const [, newTaskId] = await Promise.all([
                    handleTaskTitleChange({
                        taskId: task.id,
                        title: leftText,
                    }),
                    commitNewTask({
                        newTaskTitle: rightText,
                        newTaskChecked: false,
                        newTasklistPos: moveToPositionByIndex({
                            sortedEntities: displayItems,
                            posFieldName: "tasklistPos",
                            toIndex: index + 1,
                        }),
                    }),
                ]);

                if (!newTaskId) {
                    return;
                }

                setAutoFocusTask({
                    id: newTaskId,
                    autoFocus: { at: "START" },
                });

                return;
            }

            if (leftText || rightText) {
                void handleTaskTitleChange({
                    taskId: task.id,
                    title: leftText || rightText,
                });

                startComposing({ atIndex: leftText ? index + 1 : index });

                return;
            }

            void handleDeleteTask({ taskId: task.id });
            startComposing({ atIndex: index + 1 });

            return;
        }

        void handleTaskTitleChange({
            taskId: task.id,
            title: value,
        });
    };

    return (
        <Droppable
            droppableId={dragAndDropEntity.getDndId(Enums.DndEntityTypes.TASKLIST, tasklist.id)}
            type={Enums.DndEntityTypes.TASKLIST}
        >
            {provided => (
                <ul
                    className={classNames(className, styles.tasks)}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                >
                    {displayItems.map((displayItem, index) => {
                        switch (displayItem.type) {
                            case "TASK": {
                                const { task } = displayItem;

                                const tasklistItem = (
                                    <TasklistItem
                                        key={displayItem.id}
                                        task={task}
                                        org={tasklist.ticket.org}
                                        index={displayItem.taskIndex}
                                        autoFocus={
                                            task.id === autoFocusTask?.id
                                                ? autoFocusTask.autoFocus
                                                : undefined
                                        }
                                        onIsCompleteChange={isComplete => {
                                            void handleTaskIsCompleteChange({
                                                taskId: task.id,
                                                isComplete,
                                            });
                                        }}
                                        onTextCommit={args =>
                                            handleTextCommit({ task, index, ...args })
                                        }
                                        onDelete={() => {
                                            void handleDeleteTask({ taskId: task.id });
                                        }}
                                        onPasteMultilineContent={async ({ additionalLines }) => {
                                            await createTextTasksAtIndex({
                                                index: index + 1,
                                                titles: additionalLines,
                                                autoFocusLastTask: true,
                                            });
                                        }}
                                        onPromoteToTicket={handlePromoteToTicket}
                                    />
                                );

                                return getIsTasklistCollapsed(tasklist.id) ? (
                                    <ExitAndUnmountOnComplete task={task}>
                                        {tasklistItem}
                                    </ExitAndUnmountOnComplete>
                                ) : (
                                    tasklistItem
                                );
                            }

                            case "CHILD_TICKET": {
                                const { task } = displayItem;

                                const tasklistChildTicket = (
                                    <TasklistChildTicket
                                        key={displayItem.id}
                                        task={task}
                                        index={displayItem.taskIndex}
                                        onDelete={() => handleDeleteTask({ taskId: task.id })}
                                        onKeyDown={e => {
                                            if (e.key === "Enter") {
                                                // Special case: If this is the last item, don't create
                                                // a new input below it, just focus the one that's
                                                // already at the bottom.
                                                if (index === displayItems.length - 1) {
                                                    inputRef?.current?.focus();
                                                } else {
                                                    setNewItemTasklistPos(
                                                        moveToPositionByIndex({
                                                            sortedEntities: displayItems,
                                                            posFieldName: "tasklistPos",
                                                            toIndex: index + 1,
                                                        })
                                                    );
                                                }
                                            }
                                        }}
                                    />
                                );

                                return getIsTasklistCollapsed(tasklist.id) ? (
                                    <ExitAndUnmountOnComplete task={task}>
                                        {tasklistChildTicket}
                                    </ExitAndUnmountOnComplete>
                                ) : (
                                    tasklistChildTicket
                                );
                            }

                            case "NEW_ITEM":
                                return (
                                    <NewTasklistItem
                                        autoFocus
                                        board={tasklist.ticket.board}
                                        key={displayItem.id}
                                        index={index}
                                        ticketIdsToBeExcludedFromSearchResults={
                                            ticketIdsToBeExcludedFromSearchResults
                                        }
                                        createTextTasksAtIndex={createTextTasksAtIndex}
                                        setAutoFocusTask={setAutoFocusTask}
                                        onCancel={handleNewTasklistItemCancel}
                                        onSave={async ({
                                            title,
                                            isComplete,
                                            another,
                                            childTicketId,
                                        }) => {
                                            await commitNewTask({
                                                newTaskTitle: title,
                                                newTaskChecked: isComplete,
                                                newTasklistPos: newItemTasklistPos,
                                                newTaskChildTicketId: childTicketId,
                                            });

                                            setNewItemTasklistPos(
                                                another
                                                    ? moveToPositionByIndex({
                                                          sortedEntities: displayItems,
                                                          posFieldName: "tasklistPos",
                                                          toIndex: index + 1,
                                                      })
                                                    : null
                                            );
                                        }}
                                    />
                                );

                            default:
                                throw new Error("Unexpected item type");
                        }
                    })}
                    {provided.placeholder}
                    <NewTasklistItem
                        autoFocus={tasklistWasJustCreated}
                        board={tasklist.ticket.board}
                        index={displayItems.length}
                        isTrailingInput
                        ticketIdsToBeExcludedFromSearchResults={
                            ticketIdsToBeExcludedFromSearchResults
                        }
                        createTextTasksAtIndex={createTextTasksAtIndex}
                        setAutoFocusTask={setAutoFocusTask}
                        onCancel={handleNewTasklistItemCancel}
                        onSave={async ({ title, isComplete, childTicketId }) => {
                            await commitNewTask({
                                newTaskTitle: title,
                                newTaskChecked: isComplete,
                                newTaskChildTicketId: childTicketId,
                            });
                        }}
                        inputRef={inputRef}
                    />
                </ul>
            )}
        </Droppable>
    );
}
