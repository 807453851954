import React from "react";

import { useRecoilValue } from "recoil";

import { ImportNotes } from "components/shared/ImportNotes";
import { Dialog, dialogStateFamily, useDialogSingleton } from "components/ui/core/Dialog";
import { BoardSettings_orgFragment } from "lib/graphql/__generated__/graphql";

type BoardImportNotesDialogProps = {
    board: BoardSettings_orgFragment["all_boards"][number];
};

const dialogState = dialogStateFamily<BoardImportNotesDialogProps>("BoardImportNotesDialogState");

export function useBoardImportNotesDialog() {
    return useDialogSingleton(dialogState);
}

export function BoardImportNotesDialog() {
    const { isOpen, props } = useRecoilValue(dialogState);
    const dialog = useBoardImportNotesDialog();

    if (!props?.board.import?.notes) {
        return null;
    }

    return (
        <Dialog
            title={`Import notes for "${props.board.display_name}"`}
            isOpen={isOpen}
            onClose={dialog.close}
        >
            <Dialog.Body>
                <ImportNotes importNotes={props.board.import.notes} />
            </Dialog.Body>
        </Dialog>
    );
}
