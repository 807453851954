import React from "react";

import classNames from "classnames";

import { Avatar } from "components/ui/common/Avatar";
import { useCollaborativeDocConnectedUsers } from "components/ui/editor/collaboration/useCollaborativeDoc";
import { useCurrentUser } from "contexts/UserContext";

import styles from "./TicketViewerStatus.module.scss";
import { useDetailView } from "../context/DetailViewContext";

type TicketViewerStatusProps = {
    className?: string;
};

export function TicketViewerStatus({ className }: TicketViewerStatusProps) {
    const { ticketDoc } = useDetailView();
    const collaborativeDocUsers = useCollaborativeDocConnectedUsers({
        collaborativeDoc: ticketDoc,
    });
    const connectedUserIds = new Set(collaborativeDocUsers.map(u => u.id));
    const currentUser = useCurrentUser();
    const connectedUsers = currentUser.org.all_users
        .filter(u => connectedUserIds.has(u.id))
        .filter(u => u.id !== currentUser.id)
        .sort((a, b) => a.name.localeCompare(b.name));

    // We'll display at most 5 avatars, then show a count of the remaining. But importantly,
    // the count of the extra should never be just 1, because surely in the space it takes
    // to write "+1" we could have just shown that additional avatar.
    const MAX_USER_AVATARS_TO_DISPLAY = 5;
    const connectedUsersToDisplay =
        connectedUsers.length <= MAX_USER_AVATARS_TO_DISPLAY
            ? connectedUsers
            : connectedUsers.slice(0, MAX_USER_AVATARS_TO_DISPLAY - 1);
    const extraCount = connectedUsers.length - connectedUsersToDisplay.length;

    if (!connectedUsers.length) {
        return null;
    }

    return (
        <div className={classNames(className, styles.ticketViewerStatus)}>
            <ul className={styles.userList}>
                {connectedUsersToDisplay.reverse().map(user => (
                    <li key={user.id} className={styles.avatarWrapper}>
                        <Avatar
                            size={26}
                            tooltipText={`${user.name} is currently viewing`}
                            user={user}
                        />
                    </li>
                ))}
                {extraCount > 0 ? (
                    <span className={styles.extraCount}>+{extraCount.toLocaleString()}</span>
                ) : null}
            </ul>
        </div>
    );
}
