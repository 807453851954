import React from "react";

import { SideDrawer } from "components/ui/common/SideDrawer";
import { BorderButton } from "components/ui/core/BorderButton";
import { Icon } from "components/ui/core/Icon";
import { useCurrentUser } from "contexts/UserContext";
import { Enums } from "lib/Enums";
import { useDetailView } from "views/ticketDetail/context/DetailViewContext";
import { TicketHistoryEvents } from "views/ticketDetail/history/TicketHistoryEvents";

import styles from "./TicketHistorySideDrawer.module.scss";

function Content() {
    const { toggleIsTicketHistoryDisplayed, ticketId } = useDetailView();

    return (
        <div className={styles.content}>
            <div className={styles.header}>
                <span className={styles.title}>History</span>
                <BorderButton
                    data-cy="ticket-history-close-btn"
                    content={<Icon icon="x" iconSet="lucide" iconSize={20} strokeWidth={1} />}
                    instrumentation={{
                        elementName: "ticket_detail.history_drawer_close_btn",
                    }}
                    onClick={toggleIsTicketHistoryDisplayed}
                    flush
                    minimal
                    square
                    tighter
                />
            </div>

            <TicketHistoryEvents className={styles.historyEvents} ticketId={ticketId} />
        </div>
    );
}

export function TicketHistorySideDrawer() {
    const currentUser = useCurrentUser();
    const { isTicketHistoryDisplayed } = useDetailView();

    return (
        <SideDrawer
            className={styles.drawer}
            id={`user.${currentUser.id}.ticketHistorySideDrawer`}
            isOpen={isTicketHistoryDisplayed}
            widthSpec={{ type: Enums.SideDrawerWidthType.FIXED, value: 452 }}
        >
            <Content />
        </SideDrawer>
    );
}
