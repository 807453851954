import { useCallback, useEffect, useMemo, useRef } from "react";

import {
    CommonEnumValue,
    CommonEnums,
    TLabel,
    TSizeSpec,
    TicketSizes,
    ValueOf,
    sortStages,
} from "c9r-common";
import * as chrono from "chrono-node";
import { format as formatDate } from "date-fns";

import { useMutations } from "contexts/MutationsContext";
import { isFuzzyCommandMatch } from "lib/FuzzyMatch";
import { generateLabelKey, getUniqueLabels, pickLabelColor } from "lib/Helpers";
import { useMoveTicketsUX } from "lib/MutationUX";
import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";
import { isDefined } from "lib/types/guards";

const fragments = {
    board: gql(/* GraphQL */ `
        fragment TicketActions_board on boards {
            id
            access_type
            labels_config
            settings

            authorized_users {
                user {
                    id
                    avatar_url
                    full_name
                    name
                }
            }

            org {
                id
                settings

                all_boards: boards {
                    id
                    access_type
                    archived_at
                    display_name

                    all_stages: stages {
                        id
                        board_id
                        board_pos
                        deleted_at
                        display_name
                    }
                }
            }

            stages(where: { deleted_at: { _is_null: true } }) {
                id
                display_name
                board_pos
            }

            tickets {
                id
                stage_id

                label_attachments {
                    color
                    text
                }

                owners {
                    ticket_id
                    user_id
                    type
                }
            }
        }
    `),
};

const FUZZY_MATCH_THRESHOLD = 0.01;
const RELATIVE_DATE_TOKENS = ["today", "tomorrow", "yesterday"];

// Enums
export const TicketActionType = {
    ADD_LABEL: "ADD_LABEL",
    ADD_MEMBER: "ADD_MEMBER",
    ADD_OWNER: "ADD_OWNER",
    ARCHIVE: "ARCHIVE",
    CHANGE_BOARD: "CHANGE_BOARD",
    CHANGE_DUE_DATE: "CHANGE_DUE_DATE",
    CHANGE_SIZE: "CHANGE_SIZE",
    CHANGE_STAGE_FORWARD: "CHANGE_STAGE_FORWARD",
    CHANGE_STAGE_BACK: "CHANGE_STAGE_BACK",
    CHANGE_TITLE: "CHANGE_TITLE",
    CREATE_LABEL: "CREATE_LABEL",
    REMOVE_LABEL: "REMOVE_LABEL",
    REMOVE_MEMBER: "REMOVE_MEMBER",
    REMOVE_OWNER: "REMOVE_OWNER",
    TRASH: "TRASH",
} as const;

export const TicketActionSectionType = {
    LABELS: "LABELS",
    LOCATION: "LOCATION",
    MISC: "MISC",
    OWNERSHIP: "OWNERSHIP",
    FREE_TEXT: "FREE_TEXT",
} as const;

export type TicketActionSpec = {
    matching: {
        predicate?: (query: string) => boolean;
        texts?: string[];
    };
} & (
    | // LABELS section
    {
          sectionType: typeof TicketActionSectionType["LABELS"];
          type: typeof TicketActionType["ADD_LABEL"];
          target: { label: TLabel };
      }
    | {
          sectionType: typeof TicketActionSectionType["LABELS"];
          type: typeof TicketActionType["REMOVE_LABEL"];
          target: { label: TLabel };
      }

    // LOCATION section
    | {
          sectionType: typeof TicketActionSectionType["LOCATION"];
          type: typeof TicketActionType["ARCHIVE"];
          target?: {};
      }
    | {
          sectionType: typeof TicketActionSectionType["LOCATION"];
          type: typeof TicketActionType["CHANGE_BOARD"];
          target: {
              board: {
                  id: string;
                  accessType: CommonEnumValue<"BoardAccessType">;
                  displayName: string;
              };
              stage: { id: string; displayName: string };
          };
      }
    | {
          sectionType: typeof TicketActionSectionType["LOCATION"];
          type: typeof TicketActionType["CHANGE_STAGE_FORWARD"];
          target: {
              stage: { id: string; displayName: string };
          };
      }
    | {
          sectionType: typeof TicketActionSectionType["LOCATION"];
          type: typeof TicketActionType["CHANGE_STAGE_BACK"];
          target: {
              stage: { id: string; displayName: string };
          };
      }
    | {
          sectionType: typeof TicketActionSectionType["LOCATION"];
          type: typeof TicketActionType["TRASH"];
          target?: {};
      }

    // MISC section
    | {
          sectionType: typeof TicketActionSectionType["MISC"];
          type: typeof TicketActionType["CHANGE_DUE_DATE"];
          target: { dueDate: Date };
      }
    | {
          sectionType: typeof TicketActionSectionType["MISC"];
          type: typeof TicketActionType["CHANGE_SIZE"];
          target: { sizeSpec: TSizeSpec };
      }

    // OWNERSHIP section
    | {
          sectionType: typeof TicketActionSectionType["OWNERSHIP"];
          type: typeof TicketActionType["ADD_MEMBER"];
          target: {
              user: { id: number; fullName: string | null; name: string; avatarUrl: string | null };
          };
      }
    | {
          sectionType: typeof TicketActionSectionType["OWNERSHIP"];
          type: typeof TicketActionType["ADD_OWNER"];
          target: {
              user: { id: number; fullName: string | null; name: string; avatarUrl: string | null };
          };
      }
    | {
          sectionType: typeof TicketActionSectionType["OWNERSHIP"];
          type: typeof TicketActionType["REMOVE_MEMBER"];
          target: {
              user: { id: number; fullName: string | null; name: string; avatarUrl: string | null };
          };
      }
    | {
          sectionType: typeof TicketActionSectionType["OWNERSHIP"];
          type: typeof TicketActionType["REMOVE_OWNER"];
          target: {
              user: { id: number; fullName: string | null; name: string; avatarUrl: string | null };
          };
      }

    // FREE_TEXT section
    | {
          sectionType: typeof TicketActionSectionType["FREE_TEXT"];
          type: typeof TicketActionType["CHANGE_TITLE"];
          target: { title: string };
      }
    | {
          sectionType: typeof TicketActionSectionType["FREE_TEXT"];
          type: typeof TicketActionType["CREATE_LABEL"];
          target: { label: TLabel };
      }
);

export function buildAvailableTicketActions({
    query,
    sectionTypes,

    data: { board: _boardFragment, ticketIds },
}: {
    query: string;
    sectionTypes: ValueOf<typeof TicketActionSectionType>[];

    data: {
        board: FragmentType<typeof fragments.board>;
        ticketIds: string[];
    };
}) {
    const board = getFragmentData(fragments.board, _boardFragment);
    const ticketIdsSet = new Set(ticketIds);
    const tickets = board.tickets.filter(ticket => ticketIdsSet.has(ticket.id));

    const authorizedUsers = board.authorized_users
        .map(au => au.user)
        .filter(isDefined)
        .sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1));
    const deletedLabelsSet = new Set(
        board.labels_config.deleted.map(label => generateLabelKey({ label }))
    );
    const availableLabels = getUniqueLabels({
        labels: board.tickets.map(ticket => ticket.label_attachments).flat() ?? [],
    });
    const availableLabelsByText = Object.fromEntries(
        availableLabels.map(label => [label.text, label])
    );
    const nextLabelColor = pickLabelColor({ labels: availableLabels });

    const stagesById = Object.fromEntries(board.stages.map(s => [s.id, s]));
    const minTicketStageBoardPos = tickets
        .map(t => stagesById[t.stage_id])
        .map(s => s?.board_pos)
        .filter(isDefined)
        .reduce((a, b) => Math.min(a, b), Infinity);

    const reverseIndexes = {
        byOwnerUserId: tickets
            .flatMap(ticket => ticket.owners)
            .filter(to => to.type === CommonEnums.TicketOwnerType.OWNER)
            .reduce((acc, to) => {
                acc[to.user_id] = (acc[to.user_id] ?? new Set()).add(to.ticket_id);

                return acc;
            }, Object.fromEntries(authorizedUsers.map(user => [user.id, new Set<string>()]))),
        byMemberUserId: tickets
            .flatMap(ticket => ticket.owners)
            .filter(to => to.type === CommonEnums.TicketOwnerType.MEMBER)
            .reduce((acc, to) => {
                acc[to.user_id] = (acc[to.user_id] ?? new Set()).add(to.ticket_id);

                return acc;
            }, Object.fromEntries(authorizedUsers.map(user => [user.id, new Set<string>()]))),
        byLabelKey: tickets.reduce((acc, ticket) => {
            const labelKeys = ticket.label_attachments.map(label => generateLabelKey({ label }));

            for (const labelKey of labelKeys) {
                acc[labelKey] = (acc[labelKey] ?? new Set()).add(ticket.id);
            }

            return acc;
            // eslint-disable-next-line max-len
        }, Object.fromEntries(availableLabels.map(label => [generateLabelKey({ label }), new Set<string>()]))),
    };

    // Build the action that are available to appear. Only a subset will actually be displayed,
    // based on the user's query.
    //
    // The array must list the available actions in the order they will appear.
    return sectionTypes.flatMap((sectionType): TicketActionSpec[] => {
        switch (sectionType) {
            case TicketActionSectionType.LABELS:
                return [TicketActionType.ADD_LABEL, TicketActionType.REMOVE_LABEL].flatMap(type =>
                    availableLabels
                        .filter(
                            label =>
                                (type === TicketActionType.ADD_LABEL &&
                                    reverseIndexes.byLabelKey[generateLabelKey({ label })].size <
                                        tickets.length &&
                                    !deletedLabelsSet.has(generateLabelKey({ label }))) ||
                                (type === TicketActionType.REMOVE_LABEL &&
                                    reverseIndexes.byLabelKey[generateLabelKey({ label })].size > 0)
                        )
                        .map(label => ({
                            sectionType,
                            type,
                            target: { label },
                            matching: { texts: [label.text] },
                        }))
                );

            case TicketActionSectionType.LOCATION:
                return [
                    ...board.stages.sort(sortStages()).map(s => ({
                        sectionType,
                        type:
                            s.board_pos < minTicketStageBoardPos
                                ? TicketActionType.CHANGE_STAGE_BACK
                                : TicketActionType.CHANGE_STAGE_FORWARD,
                        target: {
                            stage: { id: s.id, displayName: s.display_name },
                        },
                        // Intentionally only match the stage display name for moving between
                        // stages *within* a board.
                        matching: { texts: [s.display_name] },
                    })),

                    ...(board.access_type === CommonEnums.BoardAccessType.PRIVATE
                        ? []
                        : board.org.all_boards ?? []
                    )
                        .filter(b => b.id !== board.id && !b.archived_at)
                        .sort((a, b) =>
                            a.display_name.toLowerCase() < b.display_name.toLowerCase() ? -1 : 1
                        )
                        .flatMap(b =>
                            b.all_stages
                                .filter(s => !s.deleted_at)
                                .sort(sortStages())
                                .map(s => ({
                                    sectionType,
                                    type: TicketActionType.CHANGE_BOARD,
                                    target: {
                                        board: {
                                            id: b.id,
                                            accessType: b.access_type as CommonEnumValue<"BoardAccessType">,
                                            displayName: b.display_name,
                                        },
                                        stage: { id: s.id, displayName: s.display_name },
                                    },
                                    // Intentionally only match the board display name for moving
                                    // *between* boards.
                                    matching: { texts: [b.display_name] },
                                }))
                        ),

                    {
                        sectionType,
                        type: TicketActionType.ARCHIVE,
                        matching: { texts: ["archive"] },
                    },

                    {
                        sectionType,
                        type: TicketActionType.TRASH,
                        matching: { texts: ["trash"] },
                    },
                ].filter(isDefined);

            case TicketActionSectionType.MISC: {
                const boardTicketSizes = board.settings[CommonEnums.BoardSettingType.SIZES];

                return [
                    ...(board.settings[CommonEnums.BoardSettingType.DUE_DATES]?.enabled
                        ? [
                              // Match explicit dates like "5 Jun".
                              {
                                  sectionType,
                                  type: TicketActionType.CHANGE_DUE_DATE,
                                  target: {
                                      dueDate: chrono.parseDate(query),
                                  },
                                  matching: {
                                      predicate: (q: string) =>
                                          !!(
                                              q &&
                                              q.length >= 2 &&
                                              !RELATIVE_DATE_TOKENS.some(dateToken =>
                                                  isFuzzyCommandMatch({
                                                      query: q,
                                                      text: dateToken,
                                                      threshold: FUZZY_MATCH_THRESHOLD,
                                                  })
                                              ) &&
                                              !!chrono.parseDate(q)
                                          ),
                                  },
                              },
                              // Match special relative date strings like "tomorrow".
                              ...RELATIVE_DATE_TOKENS.map(dateToken => ({
                                  sectionType,
                                  type: TicketActionType.CHANGE_DUE_DATE,
                                  target: {
                                      dueDate: chrono.parseDate(dateToken),
                                  },
                                  matching: {
                                      predicate: (q: string) =>
                                          !!(q && q.length >= 2 && !!chrono.parseDate(dateToken)),
                                      texts: [dateToken],
                                  },
                              })),
                          ]
                        : []),
                    ...(boardTicketSizes?.enabled
                        ? boardTicketSizes.scheme.values.map(value => {
                              const sizeSpec = {
                                  value,
                                  unit: boardTicketSizes.scheme.unit,
                              };

                              return {
                                  sectionType,
                                  type: TicketActionType.CHANGE_SIZE,
                                  target: { sizeSpec },
                                  matching: {
                                      texts: [TicketSizes.format(sizeSpec)],
                                  },
                              };
                          })
                        : []),
                ];
            }

            case TicketActionSectionType.OWNERSHIP:
                return [
                    TicketActionType.ADD_OWNER,
                    TicketActionType.REMOVE_OWNER,
                    TicketActionType.ADD_MEMBER,
                    TicketActionType.REMOVE_MEMBER,
                ].flatMap(type =>
                    authorizedUsers
                        .filter(
                            user =>
                                (type === TicketActionType.ADD_OWNER &&
                                    reverseIndexes.byOwnerUserId[user.id].size < tickets.length) ||
                                (type === TicketActionType.REMOVE_OWNER &&
                                    reverseIndexes.byOwnerUserId[user.id].size > 0) ||
                                (type === TicketActionType.ADD_MEMBER &&
                                    reverseIndexes.byMemberUserId[user.id].size < tickets.length &&
                                    // Special case: If the user is the owner of *every* ticket,
                                    // we'll suggest removing them as owner, which is very likely
                                    // what they want to do. It's highly unlikely the want to add
                                    // themselves as a collaborator, so we don't suggest it.
                                    reverseIndexes.byOwnerUserId[user.id].size < tickets.length) ||
                                (type === TicketActionType.REMOVE_MEMBER &&
                                    reverseIndexes.byMemberUserId[user.id].size > 0)
                        )
                        .map(user => ({
                            sectionType,
                            type,
                            target: {
                                user: {
                                    id: user.id,
                                    fullName: user.full_name,
                                    name: user.name,
                                    avatarUrl: user.avatar_url,
                                },
                            },
                            matching: {
                                texts: [user.full_name, user.name].filter(isDefined),
                            },
                        }))
                );

            case TicketActionSectionType.FREE_TEXT:
                return [
                    query && tickets.length === 1
                        ? {
                              sectionType,
                              type: TicketActionType.CHANGE_TITLE,
                              target: { title: query },
                              matching: { predicate: () => true },
                          }
                        : null,
                    query
                        ? {
                              sectionType,
                              type: TicketActionType.CREATE_LABEL,
                              target: { label: { color: nextLabelColor, text: query } },
                              matching: { predicate: () => !availableLabelsByText[query] },
                          }
                        : null,
                ].filter(isDefined);

            default:
                return [];
        }
    });
}

export function suggestTicketActions({
    query,
    sectionTypes,
    data,
}: {
    query: string;
    sectionTypes: ValueOf<typeof TicketActionSectionType>[];
    data: {
        board: FragmentType<typeof fragments.board>;
        ticketIds: string[];
    };
}) {
    const availableActions = buildAvailableTicketActions({
        query,
        sectionTypes,
        data,
    });

    return availableActions.filter(
        action =>
            (action.matching.predicate?.(query) ?? true) &&
            (action.matching.texts?.some(text =>
                isFuzzyCommandMatch({ query, text, threshold: FUZZY_MATCH_THRESHOLD })
            ) ??
                true)
    );
}

export function useExecuteTicketAction({
    board: _boardFragment,
    ticketIds,
}: {
    board: FragmentType<typeof fragments.board>;
    ticketIds: string[];
}) {
    const board = getFragmentData(fragments.board, _boardFragment);
    const ticketIdsSet = new Set(ticketIds);
    const _tickets = board.tickets.filter(ticket => ticketIdsSet.has(ticket.id));

    const { bulkMutate } = useMutations();
    const { moveTicketsUX } = useMoveTicketsUX();
    const tickets = useRef(_tickets);

    useEffect(() => {
        tickets.current = _tickets;
    }, [_tickets]);

    const executeAction = useCallback(
        async ({ action }: { action: TicketActionSpec }) => {
            switch (action.type) {
                case TicketActionType.ADD_LABEL:
                case TicketActionType.CREATE_LABEL:
                    await bulkMutate({
                        mutations: tickets.current.map(ticket => ({
                            name: "updateTicketLabels",
                            args: { ticketId: ticket.id, labelsToAdd: [action.target.label] },
                        })),
                    });
                    return null;

                case TicketActionType.ADD_MEMBER:
                    await bulkMutate({
                        mutations: tickets.current.map(ticket => ({
                            name: "updateTicketMembers",
                            args: { ticketId: ticket.id, userIdsToAdd: [action.target.user.id] },
                        })),
                    });
                    return null;

                case TicketActionType.ADD_OWNER:
                    await bulkMutate({
                        mutations: tickets.current.map(ticket => ({
                            name: "updateTicketOwner",
                            args: { ticketId: ticket.id, userId: action.target.user.id },
                        })),
                    });
                    return null;

                case TicketActionType.ARCHIVE:
                    await bulkMutate({
                        mutations: tickets.current.map(ticket => ({
                            name: "archiveTicket",
                            args: { ticketId: ticket.id },
                        })),
                    });
                    return null;

                case TicketActionType.CHANGE_BOARD:
                    await moveTicketsUX({
                        ticketIds: tickets.current.map(ticket => ticket.id),
                        toStageId: action.target.stage.id,
                    });
                    return null;

                case TicketActionType.CHANGE_DUE_DATE:
                    await bulkMutate({
                        mutations: tickets.current.map(ticket => ({
                            name: "updateTicket",
                            args: {
                                ticketId: ticket.id,
                                dueDate: formatDate(action.target.dueDate, "yyyy-MM-dd"),
                            },
                        })),
                    });
                    return null;

                case TicketActionType.CHANGE_SIZE:
                    await bulkMutate({
                        mutations: tickets.current.map(ticket => ({
                            name: "updateTicket",
                            args: {
                                ticketId: ticket.id,
                                sizeSpec: action.target.sizeSpec,
                            },
                        })),
                    });
                    return null;

                case TicketActionType.CHANGE_TITLE:
                    await bulkMutate({
                        mutations: tickets.current.map(ticket => ({
                            name: "updateTicket",
                            args: {
                                ticketId: ticket.id,
                                title: action.target.title,
                            },
                        })),
                    });
                    return null;

                case TicketActionType.CHANGE_STAGE_FORWARD:
                case TicketActionType.CHANGE_STAGE_BACK:
                    await moveTicketsUX({
                        ticketIds: tickets.current.map(ticket => ticket.id),
                        toStageId: action.target.stage.id,
                        showToast: false,
                    });
                    return null;

                case TicketActionType.REMOVE_LABEL:
                    await bulkMutate({
                        mutations: tickets.current.map(ticket => ({
                            name: "updateTicketLabels",
                            args: { ticketId: ticket.id, labelsToRemove: [action.target.label] },
                        })),
                    });
                    return null;

                case TicketActionType.REMOVE_MEMBER:
                    await bulkMutate({
                        mutations: tickets.current.map(ticket => ({
                            name: "updateTicketMembers",
                            args: { ticketId: ticket.id, userIdsToRemove: [action.target.user.id] },
                        })),
                    });
                    return null;

                case TicketActionType.REMOVE_OWNER:
                    await bulkMutate({
                        mutations: tickets.current
                            .filter(ticket =>
                                ticket.owners.some(
                                    to =>
                                        to.type === CommonEnums.TicketOwnerType.OWNER &&
                                        to.user_id === action.target.user.id
                                )
                            )
                            .map(ticket => ({
                                name: "updateTicketOwner",
                                args: { ticketId: ticket.id, userId: null },
                            })),
                    });
                    return null;

                case TicketActionType.TRASH:
                    await bulkMutate({
                        mutations: tickets.current.map(ticket => ({
                            name: "trashTicket",
                            args: { ticketId: ticket.id },
                        })),
                    });
                    return null;

                default:
                    return null;
            }
        },
        [bulkMutate, moveTicketsUX]
    );

    return useMemo(() => ({ executeAction }), [executeAction]);
}
