import React from "react";

import { CommonEnums } from "c9r-common";
import classNames from "classnames";

import { TourStop, TourStopAnchor } from "components/shared/TourStop";
import { BorderButton, BorderButtonProps } from "components/ui/core/BorderButton";
import { Icon, IconProps } from "components/ui/core/Icon";
import { TextButton } from "components/ui/core/TextButton";
import { useShouldShowTicketRefs } from "contexts/UserContext";
import { useSemanticBreakpoints } from "lib/Breakpoints";
import { ProductTourElementClasses } from "lib/Constants";
import { Enums } from "lib/Enums";
import { useHotkey } from "lib/Hotkeys";
import { useInstrumentation } from "lib/Instrumentation";
import { NavLink, useHistory, useLocation } from "lib/Routing";
import { useUrlBuilders } from "lib/Urls";
import { getFragmentData, gql } from "lib/graphql/__generated__";
import { useDetailView } from "views/ticketDetail/context/DetailViewContext";
import { useDetailViewDiscussion } from "views/ticketDetail/context/DetailViewDiscussionContext";
import { TicketLabelsPicker } from "views/ticketDetail/header/TicketLabelsPicker";
import { TicketMenuButton } from "views/ticketDetail/header/TicketMenuButton";
import { TicketStateCaption } from "views/ticketDetail/header/TicketStateCaption";
import { TicketViewerStatus } from "views/ticketDetail/header/TicketViewerStatus";
import { TitleEditor } from "views/ticketDetail/header/TitleEditor";
import { WatchingToggle } from "views/ticketDetail/header/WatchingToggle";
import {
    DueDateButton,
    MembersList,
    OwnerButton,
    SizeButton,
    StageButton,
} from "views/ticketDetail/metadata/TicketMetadataPickers";

import styles from "./DetailViewHeader.module.scss";
import { TicketAncestry } from "./TicketAncestry";

const fragments = {
    ticket: gql(/* GraphQL */ `
        fragment DetailViewHeader_ticket on tickets {
            id
            ref

            board {
                id
                access_type
                display_name
                slug
            }

            ...DueDateButton_ticket
            ...MembersList_ticket
            ...OwnerButton_ticket
            ...SizeButton_ticket
            ...StageButton_ticket
            ...TitleEditor_ticket
            ...TicketAncestry_ticket
            ...TicketLabelsPicker_ticket
            ...TicketStateCaption_ticket
        }
    `),
};

function useBackToPathname() {
    const { ticket: _ticketFragment } = useDetailView();
    const ticket = getFragmentData(fragments.ticket, _ticketFragment);
    const { buildBoardUrl } = useUrlBuilders();

    return {
        backToPathname: buildBoardUrl({
            boardSlug: ticket.board.slug,
            vanity: {
                boardDisplayName: ticket.board.display_name,
            },
        }).pathname,
    };
}

function BannerExplanation({ iconProps, text }: { iconProps?: IconProps; text: React.ReactNode }) {
    return (
        <div className={styles.bannerExplanation}>
            {iconProps ? <Icon {...iconProps} /> : null}
            <span className={styles.bannerExplanationText}>{text}</span>
        </div>
    );
}

function BannerActionButton({
    content,
    instrumentation,
    onClick,
}: Pick<BorderButtonProps, "content" | "instrumentation" | "onClick">) {
    return (
        <BorderButton
            content={content}
            instrumentation={instrumentation}
            onClick={onClick}
            primary
            small
            tighter
        />
    );
}

function Banner() {
    const { isArchived, isTrashed, ticketId, unarchiveTicket, untrashTicket } = useDetailView();

    if (isArchived) {
        return (
            <div className={styles.banner}>
                <BannerExplanation
                    iconProps={{
                        icon: "archive",
                        iconSet: "lucide",
                        iconSize: 20,
                        strokeWeight: 1,
                    }}
                    text="This topic has been archived."
                />
                <BannerActionButton
                    content="Unarchive"
                    instrumentation={{
                        elementName: "ticket_details.banner.unarchive_btn",
                        eventData: { ticketId },
                    }}
                    onClick={unarchiveTicket}
                />
            </div>
        );
    }

    if (isTrashed) {
        return (
            <div className={classNames(styles.banner, styles.bannerWarn)}>
                <BannerExplanation
                    iconProps={{
                        icon: "trash",
                        iconSet: "lucide",
                        iconSize: 20,
                        strokeWeight: 1,
                    }}
                    text="This item has been trashed."
                />
                <BannerActionButton
                    content="Restore from trash"
                    instrumentation={{
                        elementName: "ticket_details.banner.untrash_btn",
                        eventData: { ticketId },
                    }}
                    onClick={untrashTicket}
                />
            </div>
        );
    }

    return null;
}

function BackButton() {
    const location = useLocation();
    const { backToPathname } = useBackToPathname();
    const { history } = useHistory();
    const { recordCallback } = useInstrumentation();

    const to = location.state?.from?.location ?? backToPathname;

    useHotkey("Esc", () => history.push(to), [history, to]);

    return (
        <NavLink
            className={styles.viewBoardNavLink}
            to={to}
            onClick={recordCallback({
                eventType: Enums.InstrumentationEvent.CLICK,
                elementName: "ticket_detail.back_button",
            })}
            role="link"
        >
            <Icon
                className={styles.backButtonArrow}
                icon="arrow-left"
                iconSet="lucide"
                iconSize={20}
                strokeWidth={1.5}
            />
        </NavLink>
    );
}

function BoardButton() {
    const { ticket: _ticketFragment } = useDetailView();
    const ticket = getFragmentData(fragments.ticket, _ticketFragment);
    const { recordCallback } = useInstrumentation();
    const { backToPathname } = useBackToPathname();

    return (
        <NavLink
            className={styles.viewBoardNavLink}
            to={backToPathname}
            onClick={recordCallback({
                eventType: Enums.InstrumentationEvent.CLICK,
                elementName: "ticket_detail.view_board",
            })}
            role="link"
        >
            {ticket.board.access_type === CommonEnums.BoardAccessType.PRIVATE ? (
                <Icon icon="lock" iconSet="c9r" iconSize={14} strokeWidthAbsolute={1.5} />
            ) : (
                <Icon icon="spaceFrame" iconSet="c9r" iconSize={14} strokeWeight={1} />
            )}
            <span>{ticket.board.display_name}</span>
        </NavLink>
    );
}

function TicketRef() {
    const { ticket: _ticketFragment } = useDetailView();
    const ticket = getFragmentData(fragments.ticket, _ticketFragment);

    return <span className={styles.ticketRef}>{ticket.ref}</span>;
}

function TicketLocation() {
    const shouldShowTicketRefs = useShouldShowTicketRefs();

    return (
        <div className={styles.ticketLocation}>
            <div className={styles.boardAndRef}>
                <BackButton />
                <BoardButton />
                {shouldShowTicketRefs ? <TicketRef /> : null}
            </div>
            <TicketAncestry />
        </div>
    );
}

function TicketStatus() {
    const { ticket: _ticketFragment, toggleIsTicketHistoryDisplayed } = useDetailView();
    const ticket = getFragmentData(fragments.ticket, _ticketFragment);

    return (
        <div className={styles.ticketStatus}>
            <TicketViewerStatus />
            <TextButton
                data-cy="toggle-history-panel-btn"
                className={styles.ticketStatusButton}
                onClick={toggleIsTicketHistoryDisplayed}
                instrumentation={{
                    elementName: "ticket_details.history_panel_toggle_btn",
                    eventData: { showResolved: true, ticketId: ticket.id },
                }}
                text={
                    <TicketStateCaption
                        abbreviate
                        className={styles.ticketStateCaption}
                        ticket={ticket}
                    />
                }
            />
            <WatchingToggle className={styles.watchingToggle} />
            <TicketMenuButton />
        </div>
    );
}

function Headline() {
    return (
        <div className={styles.headline}>
            <TicketLocation />
            <TicketStatus />
        </div>
    );
}

function TitleSection() {
    const { ticket: _ticketFragment } = useDetailView();
    const ticket = getFragmentData(fragments.ticket, _ticketFragment);

    return (
        <div>
            <TitleEditor
                className={styles.titleEditor}
                inputClassName={styles.titleEditorInput}
                ticket={ticket}
            />
            <div className={styles.ticketLabelsPickerWrapper}>
                <TourStop
                    tourId={CommonEnums.TourId.DETAIL_VIEW}
                    subject={CommonEnums.TourSubject.LABELS}
                    side="bottom"
                    align="start"
                    alignOffset={-43}
                >
                    <TourStopAnchor className={styles.tourStopAnchor} />
                    <TicketLabelsPicker className={styles.ticketLabelsPicker} ticket={ticket} />
                </TourStop>
            </div>
        </div>
    );
}

function MetadataPickers() {
    const { isClosed, ticket: _ticketFragment } = useDetailView();
    const ticket = getFragmentData(fragments.ticket, _ticketFragment);

    return (
        <div className={styles.metadataPickers}>
            {!isClosed ? <StageButton ticket={ticket} /> : null}
            <OwnerButton ticket={ticket} />
            <MembersList ticket={ticket} />
            <SizeButton ticket={ticket} />
            <DueDateButton ticket={ticket} />
        </div>
    );
}

function DiscussionControls() {
    const { isTopicMetadataPickersCondensed } = useSemanticBreakpoints();
    const { ticket: _ticketFragment } = useDetailView();
    const ticket = getFragmentData(fragments.ticket, _ticketFragment);
    const {
        resolvedCount,
        setIsAddingNewBlocker,
        setIsAddingNewComment,
        setShowResolved,
    } = useDetailViewDiscussion();

    return (
        <div className={styles.discussionControls}>
            <BorderButton
                data-cy="new-thread-btn"
                minimal
                small
                onClick={() => setIsAddingNewComment(true)}
                leftIconProps={{
                    icon: "chatPlus",
                    iconSet: "c9r",
                    iconSize: isTopicMetadataPickersCondensed ? 18 : 23,
                    strokeWidth: 1,
                }}
                iconGap={8}
                instrumentation={{
                    elementName: "ticket_details.new_thread_btn",
                    eventData: { ticketId: ticket.id },
                }}
                flush
                content="New&nbsp;thread"
            />

            <TourStop
                tourId={CommonEnums.TourId.DETAIL_VIEW}
                subject={CommonEnums.TourSubject.BLOCKERS}
                side="bottom"
                sideOffset={4}
                align="end"
            >
                <div className={styles.tourStopAnchorWrapper}>
                    <BorderButton
                        data-cy="new-blocker-btn"
                        minimal
                        small
                        onClick={() => setIsAddingNewBlocker(true)}
                        leftIconProps={{
                            icon: "octagonPlus",
                            iconSet: "c9r",
                            iconSize: isTopicMetadataPickersCondensed ? 18 : 23,
                            strokeWidth: 1,
                        }}
                        iconGap={8}
                        instrumentation={{
                            elementName: "ticket_details.new_blocker_btn",
                            eventData: { ticketId: ticket.id },
                        }}
                        flush
                        content="New&nbsp;blocker"
                    />
                    <TourStopAnchor />
                </div>
            </TourStop>

            {!!resolvedCount && (
                <TextButton
                    data-cy="toggle-resolved-threads-btn"
                    className={styles.toggleResolvedBtn}
                    onClick={() => setShowResolved(true)}
                    instrumentation={{
                        elementName: "ticket_details.resolved_threads_toggle_btn",
                        eventData: { showResolved: true, ticketId: ticket.id },
                    }}
                    text={`${resolvedCount.toLocaleString()} resolved`}
                />
            )}
        </div>
    );
}

function Controls() {
    return (
        <div className={styles.controls}>
            <MetadataPickers />
            <DiscussionControls />
        </div>
    );
}

export function DetailViewHeader() {
    return (
        <div className={styles.header}>
            <Banner />
            <div
                className={classNames(
                    styles.headerContent,
                    ProductTourElementClasses.TICKET_METADATA_HEADER
                )}
            >
                <Headline />
                <TitleSection />
                <Controls />
            </div>
        </div>
    );
}
