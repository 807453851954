import React, { useEffect, useState } from "react";

import classNames from "classnames";
import { useRecoilValue } from "recoil";

import { BorderButton } from "components/ui/core/BorderButton";
import { Dialog, dialogStateFamily, useDialogSingleton } from "components/ui/core/Dialog";
import { TextInput } from "components/ui/core/TextInput";

import styles from "./InputPromptDialog.module.scss";

export type InputPromptDialogProps = {
    className?: string;
    inputType?: "url";
    onCancel?: () => void;
    onSubmit?: ({ text }: { text: string }) => void;
    promptText?: string;
    shouldReturnFocusOnClose?: boolean;
    submitButtonText?: string;
};

const dialogState = dialogStateFamily<InputPromptDialogProps>("InputPromptDialog");

export function useInputPromptDialog() {
    return useDialogSingleton(dialogState);
}

export function InputPromptDialog() {
    const { isOpen, props } = useRecoilValue(dialogState);
    const [inputText, setInputText] = useState("");
    const dialog = useInputPromptDialog();

    useEffect(() => {
        if (isOpen) {
            setInputText("");
        }
    }, [isOpen]);

    const handleClose = () => {
        props?.onCancel?.();
        dialog.close();
    };

    const handleSubmit = () => {
        props?.onSubmit?.({ text: inputText });
        dialog.close();
    };

    return (
        <Dialog
            isOpen={isOpen}
            onClose={handleClose}
            className={classNames(props?.className, styles.dialog)}
            shouldReturnFocusOnClose={props?.shouldReturnFocusOnClose}
        >
            <form
                onSubmit={e => {
                    e.preventDefault();
                    handleSubmit();
                }}
            >
                <Dialog.Body>
                    {props?.promptText ? (
                        <label className={styles.label} htmlFor="prompt-dialog-input">
                            {props.promptText}
                        </label>
                    ) : null}
                    <TextInput
                        id="prompt-dialog-input"
                        autoFocus
                        className={styles.input}
                        fill
                        onChange={e => {
                            setInputText(e.target.value);
                        }}
                        type={props?.inputType}
                        value={inputText}
                    />
                </Dialog.Body>

                <Dialog.Footer className={styles.footer}>
                    <Dialog.FooterActions>
                        <BorderButton
                            content="Cancel"
                            onClick={handleClose}
                            instrumentation={{
                                elementName: "input_prompt_dialog.close_btn",
                            }}
                        />
                        <BorderButton
                            content={props?.submitButtonText ?? "OK"}
                            disabled={!inputText}
                            instrumentation={{
                                elementName: "input_prompt_dialog.submit_btn",
                            }}
                            primary
                            type="submit"
                        />
                    </Dialog.FooterActions>
                </Dialog.Footer>
            </form>
        </Dialog>
    );
}
