import React, { useState } from "react";

import { Editor, mergeAttributes } from "@tiptap/core";
import ExtensionLink from "@tiptap/extension-link";
import { Plugin, PluginKey } from "@tiptap/pm/state";
import { BubbleMenu } from "@tiptap/react";
import isURL from "validator/lib/isURL";

import { TruncatedText } from "components/ui/common/TruncatedText";
import { BorderButton } from "components/ui/core/BorderButton";
import { Icon } from "components/ui/core/Icon";
import { TextInput } from "components/ui/core/TextInput";
import { serializeText } from "components/ui/editor/extensions/helpers/Serializations";
import { useClipboard } from "lib/Hooks";
import { THistory } from "lib/Routing";

import styles from "./Link.module.scss";
import editorStyles from "../Editor.module.scss";

const pasteRegexExact = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z]{2,}\b(?:[-a-zA-Z0-9@:%._+~#=?!&/]*)(?:[-a-zA-Z0-9@:%._+~#=?!&/]*)$/gi;

const isRelativeUrl = (url?: string) => url && url.startsWith("/");
const isEditable = (url?: string) => !isRelativeUrl(url);

function LinkPopup({ editor }: { editor: Editor }) {
    const { copyTextToClipboard } = useClipboard();
    const [isEditing, setIsEditing] = useState(false);
    const [href, setHref] = useState("");
    const [, setForceRerender] = useState(0);

    const handleSaveEdit = () => {
        setIsEditing(false);
        editor.chain().extendMarkRange("link").updateAttributes("link", { href }).focus().run();
    };

    const handleCancelEdit = () => {
        setIsEditing(false);
    };

    if (!editor) {
        return null;
    }

    return (
        <BubbleMenu
            className={styles.linkPopup}
            editor={editor}
            pluginKey="linkPopup"
            shouldShow={() =>
                editor.isActive("link") &&
                editor.getAttributes("link").href &&
                isEditable(editor.getAttributes("link").href)
            }
            tippyOptions={{
                duration: 100,
                hideOnClick: true,
                maxWidth: 700,
                offset: [-10, 0],
                placement: "bottom-start",
                onClickOutside: instance => {
                    instance.hide();
                },
                onShow: () => {
                    setIsEditing(false);
                    setImmediate(() => setHref(editor.getAttributes("link").href));

                    // HACK HACK HACK:
                    // Forcing a rerender when the popup is about to be shown resolved an issue
                    // where the popup was not always positioned as expected. In particular, the
                    // scenario was this:
                    // - Create a comment with a link in it near the very right side, and submit
                    // - Edit the comment
                    // - Put the cursor in the comment, either via keyboard or mouse. The popup is
                    //   placed correctly.
                    // - Move the cursor out, then put it back in. The popup is not placed correctly
                    //   and overflows the viewport. It snaps back to the correct position only
                    //   after moving the cursor within the link.
                    setForceRerender(Math.random());
                },
                popperOptions: {
                    // Ensure the rendered popup is limited only by the viewport, not the nearest
                    // container.
                    strategy: "fixed",
                    modifiers: [
                        {
                            name: "flip",
                            enabled: false,
                        },
                        {
                            name: "preventOverflow",
                            options: {
                                boundary: document,
                            },
                        },
                    ],
                },
            }}
        >
            {editor.isActive("link") ? (
                isEditing ? (
                    <>
                        <Icon
                            className={styles.icon}
                            icon="link"
                            iconSet="lucide"
                            strokeWeight={1.5}
                        />
                        <TextInput
                            autoFocus
                            className={styles.linkInput}
                            onChange={e => setHref(e.target.value)}
                            onKeyboardCancel={handleCancelEdit}
                            onKeyboardSubmit={handleSaveEdit}
                            value={href}
                        />
                        <div style={{ flex: "1 1 auto" }} />
                        <BorderButton
                            content={<Icon icon="check" iconSet="lucide" />}
                            instrumentation={{ elementName: "editor.link_popup.save_btn" }}
                            minimal
                            onClick={handleSaveEdit}
                            small
                        />
                        <BorderButton
                            content={<Icon icon="x" iconSet="lucide" />}
                            instrumentation={{ elementName: "editor.link_popup.cancel_btn" }}
                            minimal
                            onClick={handleCancelEdit}
                            small
                        />
                    </>
                ) : (
                    <>
                        <Icon
                            className={styles.icon}
                            icon="link"
                            iconSet="lucide"
                            strokeWeight={1.5}
                        />
                        <a
                            href={editor.getAttributes("link").href}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <TruncatedText
                                as="span"
                                text={editor.getAttributes("link").href}
                                maxLength={256}
                            />
                        </a>
                        <div style={{ flex: "1 1 auto" }} />
                        <BorderButton
                            content={<Icon icon="copy" iconSet="lucide" />}
                            instrumentation={{ elementName: "editor.link_popup.copy_btn" }}
                            minimal
                            onClick={() => {
                                void copyTextToClipboard({
                                    text: href,
                                    successToast: "Link copied to clipboard.",
                                });
                            }}
                            small
                        />
                        <BorderButton
                            content={<Icon icon="edit-2" iconSet="lucide" />}
                            instrumentation={{ elementName: "editor.link_popup.edit_btn" }}
                            minimal
                            onClick={() => setIsEditing(true)}
                            small
                        />
                        <BorderButton
                            content={<Icon icon="trash" iconSet="lucide" />}
                            instrumentation={{ elementName: "editor.link_popup.remove_btn" }}
                            minimal
                            onClick={() => {
                                setIsEditing(false);
                                editor.chain().extendMarkRange("link").unsetLink().focus().run();
                            }}
                            small
                        />
                    </>
                )
            ) : null}
        </BubbleMenu>
    );
}

declare module "@tiptap/core" {
    interface Commands<ReturnType> {
        linkExtension: {
            toggleOrCreateLink: (params?: { url?: string }) => ReturnType;
        };
    }
}

declare module "@tiptap/extension-link" {
    interface LinkOptions {
        history: THistory;
        showPrompt: ({ editor, selectedText }: { editor: Editor; selectedText?: string }) => void;
    }
}

export default ExtensionLink.extend({
    addCommands() {
        return {
            ...this.parent?.(),

            toggleOrCreateLink: params => ({ chain }) => {
                const url = params?.url;

                if (this.editor.isActive("link")) {
                    chain().focus().extendMarkRange("link").unsetLink().run();

                    return true;
                }

                const { selection } = this.editor.state;
                const selectedText = serializeText({
                    editor: this.editor,
                    from: selection.from,
                    to: selection.to,
                });

                const maybeUrl = isURL(selectedText, {
                    require_protocol: false,
                    validate_length: false,
                })
                    ? selectedText
                    : url;

                if (
                    !maybeUrl ||
                    !isURL(maybeUrl, { require_protocol: false, validate_length: false })
                ) {
                    chain().focus().run();
                    this.options.showPrompt({ editor: this.editor, selectedText });

                    return true;
                }

                const href = isURL(maybeUrl, { require_protocol: true, validate_length: false })
                    ? maybeUrl
                    : `https://${maybeUrl}`;

                if (selection.from === selection.to) {
                    chain()
                        .focus()
                        .insertContent({
                            type: "text",
                            marks: [
                                {
                                    type: "link",
                                    attrs: { href, target: "_blank" },
                                },
                            ],
                            text: href,
                        })
                        .run();
                } else {
                    chain().focus().setLink({ href }).run();
                }

                return true;
            },
        };
    },

    addKeyboardShortcuts() {
        return {
            "Mod-k": () => this.editor.commands.toggleOrCreateLink(),
        };
    },

    addProseMirrorPlugins() {
        const { history } = this.options;
        const getCurrentLocation = () => ({
            pathname: history?.location?.pathname,
            search: history?.location?.search,
            hash: history?.location?.hash,
            state: undefined,
        });

        const plugins = [];

        const handleClickLinkPluginKey = new PluginKey("handleClickLink");

        plugins.push(
            new Plugin({
                key: handleClickLinkPluginKey,
                props: {
                    handleDOMEvents: {
                        mousedown(_, event) {
                            if (event.button !== 0) {
                                return false;
                            }

                            const link = event.target && (event.target as Element)?.closest("a");
                            const href = link?.getAttribute("href");

                            if (!href) {
                                return false;
                            }

                            if (history && isRelativeUrl(href)) {
                                // Opening a link may navigate away from the editor. Wait until we're
                                // done executing the current transaction first.
                                setImmediate(() => {
                                    history.push(href, {
                                        from: { location: getCurrentLocation() },
                                    });
                                });

                                event.preventDefault();
                                return true;
                            }

                            return false;
                        },
                    },
                },
            })
        );

        plugins.push(
            new Plugin({
                key: new PluginKey("handlePasteLink"),
                props: {
                    handlePaste: (view, event, slice) => {
                        const { state } = view;
                        const { selection } = state;
                        const { empty } = selection;

                        if (empty) {
                            return false;
                        }

                        let textContent = "";

                        slice.content.forEach(node => {
                            textContent += node.textContent;
                        });

                        if (!textContent || !textContent.match(pasteRegexExact)) {
                            return false;
                        }

                        this.editor.commands.setMark(this.type, {
                            href: textContent,
                        });

                        return true;
                    },
                },
            })
        );

        return plugins;
    },

    renderHTML({ HTMLAttributes }) {
        // Internal relative links are not editable.
        return [
            "a",
            mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
                class: isEditable(HTMLAttributes.href) ? editorStyles.editableLink : undefined,
            }),
            0,
        ];
    },
});

export { LinkPopup };
