import { useCallback } from "react";

import { CommonEnumValue, P } from "c9r-common";
import jsonwebtoken from "jsonwebtoken";
import { atom, useSetRecoilState } from "recoil";

import { useSetCurrentUserId } from "AppState";

// The current access token is a singleton. There's only ever one at a time.
// We manage that "state" via a local variable because there's never a need for the app
// to trigger a rerender once the first access token is available. After that,
// the getCurrentAccessToken() function provides access to the current access token.
// The exception is when an identity switches orgs.
let accessToken: string | null = null;

export const isAccessTokenAvailableState = atom({
    key: "IsAccessTokenAvailable",
    default: false,
});

export const getCurrentAccessToken = () => accessToken;

export const useSetOrReplaceAccessToken = () => {
    const setCurrentUserId = useSetCurrentUserId();
    const setIsAccessTokenAvailable = useSetRecoilState(isAccessTokenAvailableState);
    const setOrReplaceAccessToken = useCallback(
        (newAccessToken: string | null) => {
            accessToken = newAccessToken;
            setIsAccessTokenAvailable(!!accessToken);

            const decodedAccessToken = accessToken
                ? (jsonwebtoken.decode(accessToken) as any)
                : null;
            const userId = Number(
                decodedAccessToken?.["https://hasura.io/jwt/claims"]?.["x-hasura-flat-user-id"]
            );

            setCurrentUserId(userId ?? null);
        },
        [setCurrentUserId, setIsAccessTokenAvailable]
    );

    return setOrReplaceAccessToken;
};

export function isAccessTokenAvailable() {
    return !!accessToken;
}

export function isUserIdInAccessToken({ userId }: { userId: number }) {
    if (!accessToken) {
        return false;
    }

    const decodedAccessToken = jsonwebtoken.decode(accessToken) as any;

    return (
        String(decodedAccessToken?.["https://hasura.io/jwt/claims"]?.["x-hasura-flat-user-id"]) ===
        String(userId)
    );
}

export function isUserRoleInAccessToken({ role }: { role: CommonEnumValue<"UserRole"> }) {
    if (!accessToken) {
        return false;
    }

    const decodedAccessToken = jsonwebtoken.decode(accessToken) as any;

    return decodedAccessToken?.["https://hasura.io/jwt/claims"]?.[
        "x-hasura-allowed-roles"
    ]?.includes(role);
}

export async function waitForAccessToken({
    predicates,
    intervalMs = 500,
    timeoutMs = 10000,
}: {
    predicates: (() => boolean)[];
    intervalMs?: number;
    timeoutMs?: number;
}) {
    const deferred = P.defer();
    const startTime = Date.now();

    const onSuccess = () => {
        clearInterval(interval);
        deferred.resolve(undefined);
    };

    const onError = (msg: string) => {
        clearInterval(interval);
        deferred.reject(new Error(msg));
    };

    const interval = setInterval(() => {
        if (Date.now() - startTime > timeoutMs) {
            onError("Timed out out waiting for access token");
        }

        if (predicates.every(predicate => predicate())) {
            onSuccess();
        }
    }, intervalMs);

    return deferred.promise;
}
