import React from "react";

import { Intent as BlueprintIntent, Classes } from "@blueprintjs/core";
import classNames from "classnames";
import {
    AlertTriangle as LucideAlertTriangle,
    Archive as LucideArchive,
    ArrowDown as LucideArrowDown,
    ArrowLeft as LucideArrowLeft,
    ArrowLeftRight as LucideArrowLeftRight,
    ArrowRight as LucideArrowRight,
    ArrowUp as LucideArrowUp,
    BarChart as LucideBarChart,
    Bold as LucideBold,
    Book as LucideBook,
    Calendar as LucideCalendar,
    CalendarCheck as LucideCalendarCheck,
    CalendarDays as LucideCalendarDays,
    CalendarMinus as LucideCalendarMinus,
    CalendarPlus as LucideCalendarPlus,
    Check as LucideCheck,
    CheckCircle as LucideCheckCircle,
    CheckCircle2 as LucideCheckCircle2,
    CheckSquare as LucideCheckSquare,
    ChevronDown as LucideChevronDown,
    ChevronLeft as LucideChevronLeft,
    ChevronRight as LucideChevronRight,
    ChevronsUpDown as LucideChevronsUpDown,
    Circle as LucideCircle,
    Clock as LucideClock,
    Code as LucideCode,
    Columns as LucideColumns,
    Copy as LucideCopy,
    Download as LucideDownload,
    Edit2 as LucideEdit2,
    ExternalLink as LucideExternalLink,
    Eye as LucideEye,
    EyeOff as LucideEyeOff,
    File as LucideFile,
    FileEdit as LucideFileEdit,
    FileMinus as LucideFileMinus,
    FilePlus as LucideFilePlus,
    FileText as LucideFileText,
    FileUp as LucideFileUp,
    Files as LucideFiles,
    Filter as LucideFilter,
    Folder as LucideFolder,
    GitMerge as LucideGitMerge,
    GitPullRequest as LucideGitPullRequest,
    Heading as LucideHeading,
    HelpCircle as LucideHelpCircle,
    Image as LucideImage,
    Inbox as LucideInbox,
    Info as LucideInfo,
    Italic as LucideItalic,
    LifeBuoy as LucideLifeBuoy,
    Link as LucideLink,
    List as LucideList,
    LogOut as LucideLogOut,
    Map as LucideMap,
    MessagesSquare as LucideMessagesSquare,
    Minus as LucideMinus,
    Package as LucidePackage,
    Paperclip as LucidePaperclip,
    Plus as LucidePlus,
    PlusCircle as LucidePlusCircle,
    PlusSquare as LucidePlusSquare,
    LucideProps,
    RefreshCw as LucideRefreshCw,
    Rocket as LucideRocket,
    Send as LucideSend,
    Settings as LucideSettings,
    SidebarClose as LucideSidebarClose,
    SidebarOpen as LucideSidebarOpen,
    Square as LucideSquare,
    Tag as LucideTag,
    Trash as LucideTrash,
    Trash2 as LucideTrash2,
    Truck as LucideTruck,
    User as LucideUser,
    UserMinus as LucideUserMinus,
    UserPlus as LucideUserPlus,
    X as LucideX,
    XOctagon as LucideXOctagon,
} from "lucide-react";

type IconSvgBuilderProps = {
    color?: string;
    icon: string;
    size?: number;
    strokeWeight?: number;
    strokeWidth?: number;
    strokeWidthAbsolute?: number;
};

const svgBuilders = {
    c9r: ({
        colors,
        paths,
        title,
        svgProps,
        viewBoxSize,
    }: {
        colors: Array<"stroke" | "fill">;
        paths: React.ReactFragment;
        title?: string;
        svgProps?: React.ComponentPropsWithoutRef<"svg">;
        viewBoxSize: number;
    }) => {
        return ({
            color,
            size,
            strokeWeight,
            strokeWidth,
            strokeWidthAbsolute,
        }: IconSvgBuilderProps) => {
            // All custom icons are defined according our standard for a 16px viewbox is
            // a strokeWeight of 1.
            const finalStrokeWidth =
                size && strokeWidthAbsolute
                    ? strokeWidthAbsolute * (viewBoxSize / size)
                    : strokeWidth ?? ((strokeWeight || 1) * viewBoxSize) / 16;
            const viewBox = `0 0 ${viewBoxSize} ${viewBoxSize}`;

            return (
                <svg
                    stroke={colors.includes("stroke") ? color || "currentColor" : "transparent"}
                    fill={colors.includes("fill") ? color || "currentColor" : "transparent"}
                    data-icon={title}
                    width={size}
                    height={size}
                    viewBox={viewBox}
                    {...svgProps}
                    strokeWidth={finalStrokeWidth}
                >
                    {title && <desc>{title}</desc>}
                    {paths}
                </svg>
            );
        };
    },

    lucide: (LucideIcon: (props: LucideProps) => JSX.Element) => {
        return ({
            color,
            size,
            strokeWeight,
            strokeWidth,
            strokeWidthAbsolute,
            icon,
        }: IconSvgBuilderProps) => {
            // All Lucide icons have a viewbox of 24px, so to confirm to our standard where
            // a 16px icon has a stroke weight of 1, the base stroke width here is 1.5 (24/16).
            const finalStrokeWidth =
                size && strokeWidthAbsolute
                    ? strokeWidthAbsolute * (24 / size)
                    : strokeWidth ?? 1.5 * (strokeWeight || 1);

            return (
                <LucideIcon
                    data-icon={icon}
                    color={color}
                    size={size}
                    strokeWidth={finalStrokeWidth}
                />
            );
        };
    },
};

const icons = {
    c9r: {
        archive: svgBuilders.c9r({
            colors: ["stroke"],
            paths: (
                <>
                    <path d="M17 7V16.75H3.02V7" />
                    <path d="M18.58 3H1.52V7H18.57V3.25Z" />
                    <path d="M8.5 10H11.5" />
                </>
            ),
            svgProps: {
                strokeLinecap: "round",
                strokeLinejoin: "round",
            },
            title: "archive",
            viewBoxSize: 20,
        }),

        archiveArrow: svgBuilders.c9r({
            colors: ["stroke"],
            paths: (
                <>
                    <path d="M14 5.33331V14H2V5.33331" />
                    <path d="M15.3333 2H0.666626V5.33333H15.3333V2Z" />
                    <path d="M8 6.66669V11.3334" />
                    <path d="M10.3333 9L7.99996 11.3333L5.66663 9" />
                </>
            ),
            svgProps: {
                strokeLinecap: "round",
                strokeLinejoin: "round",
            },
            title: "archiveArrow",
            viewBoxSize: 16,
        }),

        attachChild: svgBuilders.c9r({
            colors: ["stroke"],
            paths: (
                <>
                    <path
                        d="M5 1C2.79086 1 0.999999 2.79086 0.999999 5C0.999999 7.20914 2.79086 9 5 9C7.20914 9 9 7.20914 9 5C9 2.79086 7.20914 1 5 1Z"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path d="M12 15V9" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M9 12H15" strokeLinecap="round" strokeLinejoin="round" />
                </>
            ),
            title: "attachChild",
            viewBoxSize: 16,
        }),

        attachParent: svgBuilders.c9r({
            colors: ["stroke"],
            paths: (
                <>
                    <path
                        d="M11 15C13.2091 15 15 13.2091 15 11C15 8.79086 13.2091 7 11 7C8.79086 7 7 8.79086 7 11C7 13.2091 8.79086 15 11 15Z"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path d="M4 1V7" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M7 4H1" strokeLinecap="round" strokeLinejoin="round" />
                </>
            ),
            title: "attachParent",
            viewBoxSize: 16,
        }),

        blank: svgBuilders.c9r({
            colors: [],
            paths: <></>,
            // Intentionally don't set title, because the "blank" icon is just a spacer and has
            // no visible content, so neither should it have a title (<desc> tag).
            viewBoxSize: 24,
        }),

        blockquote: svgBuilders.c9r({
            colors: ["fill"],
            paths: (
                <>
                    <path d="M3.60754 12C3.44553 12 3.30377 11.9544 3.18226 11.8631C3.06075 11.7719 3 11.6236 3 11.4183C3 11.2357 3.05063 11.0646 3.15188 10.9049C3.27339 10.7452 3.42528 10.5741 3.60754 10.3916C4.05306 9.93536 4.3872 9.42205 4.60997 8.85171C4.83273 8.25855 4.94411 7.77947 4.94411 7.41445C4.94411 7.25475 4.90361 7.12928 4.8226 7.03802C4.76185 6.92395 4.60997 6.80989 4.36695 6.69582C4.10369 6.51331 3.90118 6.27377 3.75942 5.97719C3.63791 5.68061 3.57716 5.38403 3.57716 5.08745C3.57716 4.56274 3.74929 4.08365 4.09356 3.65019C4.45808 3.21673 4.92386 3 5.49089 3C6.05792 3 6.51357 3.20532 6.85784 3.61597C7.20211 4.0038 7.37424 4.64259 7.37424 5.53232C7.37424 6.26236 7.25274 7.0038 7.00972 7.75665C6.78696 8.48669 6.49332 9.1597 6.1288 9.77567C5.78453 10.3688 5.43014 10.8479 5.06562 11.2129C4.8226 11.4639 4.56946 11.6578 4.3062 11.7947C4.04293 11.9316 3.81005 12 3.60754 12ZM10.1993 12C10.0373 12 9.88538 11.9544 9.74362 11.8631C9.62211 11.7719 9.56136 11.6236 9.56136 11.4183C9.56136 11.2357 9.61199 11.0646 9.71324 10.9049C9.83475 10.7452 9.99676 10.5741 10.1993 10.3916C10.6245 9.93536 10.9486 9.42205 11.1713 8.85171C11.4143 8.25855 11.5358 7.77947 11.5358 7.41445C11.5358 7.25475 11.4953 7.12928 11.4143 7.03802C11.3536 6.92395 11.1916 6.80989 10.9283 6.69582C10.665 6.51331 10.4727 6.27377 10.3512 5.97719C10.2296 5.68061 10.1689 5.38403 10.1689 5.08745C10.1689 4.56274 10.341 4.08365 10.6853 3.65019C11.0498 3.21673 11.5156 3 12.0826 3C12.6294 3 13.0749 3.20532 13.4192 3.61597C13.7635 4.0038 13.9356 4.64259 13.9356 5.53232C13.9356 6.26236 13.8141 7.0038 13.5711 7.75665C13.3483 8.48669 13.0547 9.1597 12.6902 9.77567C12.3459 10.3688 12.0016 10.8479 11.6574 11.2129C11.1308 11.7376 10.6448 12 10.1993 12Z" />
                </>
            ),
            title: "blockquote",
            viewBoxSize: 16,
        }),

        buildFailing: svgBuilders.lucide(LucideX),
        buildPassing: svgBuilders.lucide(LucideCheck),
        buildPending: svgBuilders.c9r({
            colors: ["fill"],
            paths: <circle cx="12" cy="12" r="7" />,
            title: "circle",
            viewBoxSize: 24,
        }),

        bulletList: svgBuilders.c9r({
            colors: ["stroke"],
            paths: (
                <>
                    <path d="M6.66663 5H17.5" />
                    <path d="M6.66663 10H17.5" />
                    <path d="M6.66663 15H17.5" />
                    <path d="M2.5 5H2.51" strokeWidth="3" />
                    <path d="M2.5 10H2.51" strokeWidth="3" />
                    <path d="M2.5 15H2.51" strokeWidth="3" />
                </>
            ),
            svgProps: {
                strokeLinecap: "round",
                strokeLinejoin: "round",
            },
            title: "bulletList",
            viewBoxSize: 20,
        }),

        chatBubble: svgBuilders.c9r({
            colors: ["fill"],
            paths: (
                <path d="M12.5 0H1.5C0.671572 0 0 0.671577 0 1.50001V9.50008C0 10.3285 0.671572 11.0001 1.5 11.0001H3.27501L3.00302 13.4451C2.97269 13.7196 3.17062 13.9667 3.4451 13.997C3.58684 14.0126 3.72852 13.967 3.83452 13.8716L7.0255 11.0001H12.5C13.3284 11.0001 14 10.3285 14 9.50008V1.50001C14 0.671577 13.3284 0 12.5 0ZM13 9.50008C13 9.77623 12.7761 10.0001 12.5 10.0001H6.83349C6.70995 10.0001 6.5908 10.0459 6.49898 10.1286L4.14199 12.2501L4.33048 10.5556C4.36113 10.2812 4.16352 10.0338 3.88907 10.0032C3.87061 10.0011 3.85207 10.0001 3.83349 10.0001H1.5C1.22385 10.0001 0.999989 9.77623 0.999989 9.50008V1.50001C0.999989 1.22386 1.22385 0.999998 1.5 0.999998H12.5C12.7762 0.999998 13 1.22386 13 1.50001V9.50008H13Z" />
            ),
            title: "chatBubble",
            viewBoxSize: 14,
        }),

        chatBubble2: svgBuilders.c9r({
            colors: ["stroke"],
            paths: (
                <path d="M4.2398 12.6268L4.30157 12.0715H3.74287H1.71429C1.04366 12.0715 0.5 11.5279 0.5 10.8572V1.7143C0.5 1.04366 1.04366 0.5 1.71429 0.5H14.2857C14.9563 0.5 15.5 1.04366 15.5 1.7143V10.8572C15.5 11.5279 14.9563 12.0715 14.2857 12.0715H8.02914H7.83729L7.69468 12.1999L4.04785 15.4816L4.04782 15.4816C4.03268 15.4952 4.01236 15.5018 3.99196 15.4996C3.95294 15.4952 3.92474 15.46 3.92898 15.4209C3.92899 15.4208 3.92899 15.4208 3.929 15.4207L4.2398 12.6268Z" />
            ),
            title: "chatBubble2",
            viewBoxSize: 16,
        }),

        chatBubbleWithLines: svgBuilders.c9r({
            colors: ["stroke"],
            title: "chatBubbleWithLines",
            paths: (
                <>
                    <path d="m 21 15 a 2 2 0 0 1 -2 2 h -8 l -4 4 v -4 h -2 a 2 2 0 0 1 -2 -2 v -10 a 2 2 0 0 1 2 -2 h 14 a 2 2 0 0 1 2 2 z" />
                    <line x1="7" y1="8" x2="17" y2="8" />
                    <line x1="7" y1="12" x2="13" y2="12" />
                </>
            ),
            viewBoxSize: 24,
        }),

        chatBubbleWithoutLines: svgBuilders.c9r({
            colors: ["stroke"],
            title: "chatBubbleWithoutLines",
            paths: (
                <path d="m 21 15 a 2 2 0 0 1 -2 2 h -8 l -4 4 v -4 h -2 a 2 2 0 0 1 -2 -2 v -10 a 2 2 0 0 1 2 -2 h 14 a 2 2 0 0 1 2 2 z" />
            ),
            viewBoxSize: 24,
        }),

        chatPlus: svgBuilders.c9r({
            colors: ["stroke"],
            title: "chatPlus",
            paths: (
                <>
                    <path d="M 6.5,18.5554 V 17.5 H 5.9125 3.25 C 2.2835,17.5 1.5,16.7165 1.5,15.75 V 3.25 C 1.5,2.2835 2.2835,1.5 3.25,1.5 h 16.5 c 0.9665,0 1.75,0.7835 1.75,1.75 v 12.5 c 0,0.9665 -0.785288,1.691237 -1.75,1.75 h -8.2118 -0.1918 c -1.5634292,1.622848 -3.1567524,2.505243 -4.8464,4 0,-1.2225 0,-1.7221 0,-2.9446 z" />
                    <line x1="11.5" y1="5.5" x2="11.5" y2="13.5" />
                    <line x1="7.5" y1="9.5" x2="15.5" y2="9.5" />
                </>
            ),
            svgProps: {
                strokeLinecap: "round",
                strokeLinejoin: "round",
            },
            viewBoxSize: 23,
        }),

        checkCircle: svgBuilders.c9r({
            colors: ["stroke"],
            paths: (
                <>
                    <circle cx="12" cy="12" r="11.25" />
                    <path d="M18 8L11.0441 17L7 11.965" />
                </>
            ),
            svgProps: {
                strokeLinecap: "round",
                strokeLinejoin: "round",
            },
            title: "checkCircle",
            viewBoxSize: 24,
        }),

        checkSquare: svgBuilders.c9r({
            colors: ["stroke"],
            paths: (
                <>
                    <path d="M5.25 6.41671L7 8.16671L12.8333 2.33337" />
                    <path d="M12.25 7V11.0833C12.25 11.3928 12.1271 11.6895 11.9083 11.9083C11.6895 12.1271 11.3928 12.25 11.0833 12.25H2.91667C2.60725 12.25 2.3105 12.1271 2.09171 11.9083C1.87292 11.6895 1.75 11.3928 1.75 11.0833V2.91667C1.75 2.60725 1.87292 2.3105 2.09171 2.09171C2.3105 1.87292 2.60725 1.75 2.91667 1.75H9.33333" />
                </>
            ),
            svgProps: {
                strokeLinecap: "round",
                strokeLinejoin: "round",
            },
            title: "checkSquare",
            viewBoxSize: 14,
        }),

        circle: svgBuilders.c9r({
            colors: ["stroke"],
            paths: <circle cx="4.5" cy="4" r="3.5" />,
            title: "circle",
            viewBoxSize: 9,
        }),

        clearFormatting: svgBuilders.c9r({
            colors: ["stroke"],
            paths: (
                <>
                    <path d="M7.5 6V5H18.5V6" />
                    <path d="M7.5 19H11.5" />
                    <path d="M13.5 5L11.9 11M9.5 19L11.4 13" />
                    <path d="M4.42863 5.07141L17.3623 19" />
                </>
            ),
            svgProps: {
                strokeLinecap: "round",
                strokeLinejoin: "round",
            },
            title: "clearFormatting",
            viewBoxSize: 25,
        }),

        codeBranch: svgBuilders.c9r({
            colors: ["stroke"],
            paths: (
                <>
                    <path d="M6 8L6 17" strokeLinecap="round" strokeLinejoin="round" />
                    <path
                        d="M17 5C17 6.10457 16.1046 7 15 7C13.8954 7 13 6.10457 13 5C13 3.89543 13.8954 3 15 3C16.1046 3 17 3.89543 17 5Z"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M8 5C8 6.10457 7.10457 7 6 7C4.89543 7 4 6.10457 4 5C4 3.89543 4.89543 3 6 3C7.10457 3 8 3.89543 8 5Z"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M8 20C8 21.1046 7.10457 22 6 22C4.89543 22 4 21.1046 4 20C4 18.8954 4.89543 18 6 18C7.10457 18 8 18.8954 8 20Z"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M15 7.5C15.4178 10 13.9044 13 12 13C8.90929 13 7.75334 13.5 6.5 15"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </>
            ),
            title: "codeBranch",
            viewBoxSize: 24,
        }),

        codeMergeRequest: svgBuilders.c9r({
            colors: ["stroke"],
            paths: (
                <>
                    <path
                        d="M20 19C20 20.1046 19.1046 21 18 21C16.8954 21 16 20.1046 16 19C16 17.8954 16.8954 17 18 17C19.1046 17 20 17.8954 20 19Z"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M8 19C8 20.1046 7.10457 21 6 21C4.89543 21 4 20.1046 4 19C4 17.8954 4.89543 17 6 17C7.10457 17 8 17.8954 8 19Z"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M8 6C8 7.10457 7.10457 8 6 8C4.89543 8 4 7.10457 4 6C4 4.89543 4.89543 4 6 4C7.10457 4 8 4.89543 8 6Z"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M13 6H16C16.5304 6 17.0391 6.21071 17.4142 6.58579C17.7893 6.96086 18 7.46957 18 8V16"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path d="M6 9V16" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M14 3L11 6L14 9" strokeLinecap="round" strokeLinejoin="round" />
                </>
            ),
            title: "codeMergeRequest",
            viewBoxSize: 24,
        }),

        codeMerged: svgBuilders.c9r({
            colors: ["stroke"],
            paths: (
                <>
                    <path
                        d="M21 11C21 12.1046 20.1046 13 19 13C17.8954 13 17 12.1046 17 11C17 9.89543 17.8954 9 19 9C20.1046 9 21 9.89543 21 11Z"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M10 3C10 4.10457 9.10457 5 8 5C6.89543 5 6 4.10457 6 3C6 1.89543 6.89543 1 8 1C9.10457 1 10 1.89543 10 3Z"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M10 19C10 20.1046 9.10457 21 8 21C6.89543 21 6 20.1046 6 19C6 17.8954 6.89543 17 8 17C9.10457 17 10 17.8954 10 19Z"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M8 17V6C11 10.5 13.8783 11.5 16 11.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </>
            ),
            title: "codeMerged",
            viewBoxSize: 24,
        }),

        columnLeft: svgBuilders.c9r({
            colors: ["stroke"],
            paths: (
                <>
                    <rect width="5" height="13.5" rx="2" transform="matrix(-1 0 0 1 15 1.25)" />
                    <path d="M2.28906 8L6.78906 8" />
                    <path d="M3.85156 10.8125L1.03906 8L3.85156 5.1875" />
                </>
            ),
            svgProps: {
                strokeLinecap: "round",
                strokeLinejoin: "round",
            },
            title: "columnLeft",
            viewBoxSize: 16,
        }),

        columnPlusLeft: svgBuilders.c9r({
            colors: ["stroke"],
            paths: (
                <>
                    <rect x="10.125" y="1.25" width="5" height="13.5" rx="2" />
                    <path d="M1.2891 8L5.7891 8" />
                    <path d="M3.7891 10.25L3.7891 5.75" />
                </>
            ),
            svgProps: {
                strokeLinecap: "round",
                strokeLinejoin: "round",
            },
            title: "columnPlus",
            viewBoxSize: 16,
        }),

        columnPlusRight: svgBuilders.c9r({
            colors: ["stroke"],
            paths: (
                <>
                    <rect x="0.875" y="1.25" width="5" height="13.5" rx="2" />
                    <path d="M14.7109 8L10.2109 8" />
                    <path d="M12.4609 10.25L12.4609 5.75" />
                </>
            ),
            svgProps: {
                strokeLinecap: "round",
                strokeLinejoin: "round",
            },
            title: "columnPlus",
            viewBoxSize: 16,
        }),

        columnRight: svgBuilders.c9r({
            colors: ["stroke"],
            paths: (
                <>
                    <rect x="0.875" y="1.25" width="5" height="13.5" rx="2" />
                    <path d="M13.7109 8L9.2109 8" />
                    <path d="M12.1484 10.8125L14.9609 8L12.1484 5.1875" />
                </>
            ),
            svgProps: {
                strokeLinecap: "round",
                strokeLinejoin: "round",
            },
            title: "columnRight",
            viewBoxSize: 16,
        }),

        columnsThree: svgBuilders.c9r({
            colors: ["stroke"],
            paths: (
                <path d="m 7.49999,2.5 h -4.4 c -0.33137,0 -0.6,0.26863 -0.6,0.6 v 13.8 c 0,0.3314 0.26863,0.6 0.6,0.6 h 4.4 m 0,-15 v 15 m 0,-15 h 5.00002 m -5.00002,15 h 5.00002 m 0,-15 h 4.4 c 0.3313,0 0.6,0.26863 0.6,0.6 v 13.8 c 0,0.3314 -0.2687,0.6 -0.6,0.6 h -4.4 m 0,-15 v 15" />
            ),
            title: "columnsThree",
            viewBoxSize: 20,
        }),

        dashedLine: svgBuilders.c9r({
            colors: ["stroke"],
            paths: (
                <line
                    x1="50%"
                    y1="1"
                    x2="50%"
                    y2="25"
                    strokeLinecap="round"
                    strokeDasharray="3 3"
                />
            ),
            title: "dashedLine",
            viewBoxSize: 24,
        }),

        detachChild: svgBuilders.c9r({
            colors: ["stroke"],
            paths: (
                <>
                    <path
                        d="M5 1C2.79086 1 0.999999 2.79086 0.999999 5C0.999999 7.20914 2.79086 9 5 9C7.20914 9 9 7.20914 9 5C9 2.79086 7.20914 1 5 1Z"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M9.87866 9.87868L14.1213 14.1213"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M14.1213 9.87868L9.8787 14.1213"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </>
            ),
            title: "detachChild",
            viewBoxSize: 16,
        }),

        detachParent: svgBuilders.c9r({
            colors: ["stroke"],
            paths: (
                <>
                    <path
                        d="M11 15C13.2091 15 15 13.2091 15 11C15 8.79086 13.2091 7 11 7C8.79086 7 7 8.79086 7 11C7 13.2091 8.79086 15 11 15Z"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M6.12134 6.12131L1.8787 1.87867"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M1.87866 6.12131L6.1213 1.87867"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </>
            ),
            title: "detachParent",
            viewBoxSize: 16,
        }),

        dragHandle: svgBuilders.c9r({
            colors: ["fill"],
            title: "dragHandle",
            paths: (
                <>
                    <circle cx="4.5" cy="4.74841" r="1.5" />
                    <circle cx="4.5" cy="11.7484" r="1.5" />
                    <circle cx="11.5" cy="4.74841" r="1.5" />
                    <circle cx="11.5" cy="11.7484" r="1.5" />
                </>
            ),
            viewBoxSize: 16,
        }),

        dragHandleLarge: svgBuilders.c9r({
            colors: ["fill"],
            title: "dragHandleLarge",
            paths: (
                <>
                    <path d="M7 2C7 1.0335 7.7835 0.25 8.75 0.25C9.7165 0.25 10.5 1.0335 10.5 2C10.5 2.9665 9.7165 3.75 8.75 3.75C7.7835 3.75 7 2.9665 7 2Z" />
                    <path d="M7 9.00003C7 8.03353 7.7835 7.25003 8.75 7.25003C9.7165 7.25003 10.5 8.03353 10.5 9.00003C10.5 9.96653 9.7165 10.75 8.75 10.75C7.7835 10.75 7 9.96653 7 9.00003Z" />
                    <path d="M7 16C7 15.0335 7.7835 14.25 8.75 14.25C9.7165 14.25 10.5 15.0335 10.5 16C10.5 16.9665 9.7165 17.75 8.75 17.75C7.7835 17.75 7 16.9665 7 16Z" />
                    <path d="M0 2C0 1.0335 0.783502 0.25 1.75 0.25C2.7165 0.25 3.5 1.0335 3.5 2C3.5 2.9665 2.7165 3.75 1.75 3.75C0.783502 3.75 0 2.9665 0 2Z" />
                    <path d="M0 9.00003C0 8.03353 0.783502 7.25003 1.75 7.25003C2.7165 7.25003 3.5 8.03353 3.5 9.00003C3.5 9.96653 2.7165 10.75 1.75 10.75C0.783502 10.75 0 9.96653 0 9.00003Z" />
                    <path d="M0 16C0 15.0335 0.783502 14.25 1.75 14.25C2.7165 14.25 3.5 15.0335 3.5 16C3.5 16.9665 2.7165 17.75 1.75 17.75C0.783503 17.75 0 16.9665 0 16Z" />
                </>
            ),
            viewBoxSize: 18,
        }),

        ellipsis: svgBuilders.c9r({
            colors: ["fill"],
            title: "ellipsis",
            paths: (
                <>
                    <circle cx="2.5" cy="8" r="1.35" />
                    <circle cx="8" cy="8" r="1.35" />
                    <circle cx="13.5" cy="8" r="1.35" />
                </>
            ),
            viewBoxSize: 16,
        }),

        ellipsisVertical: svgBuilders.c9r({
            colors: ["fill"],
            title: "ellipsisVertical",
            paths: (
                <>
                    <circle cx="8" cy="3.5" r="1.35" />
                    <circle cx="8" cy="8" r="1.35" />
                    <circle cx="8" cy="12.5" r="1.35" />
                </>
            ),
            viewBoxSize: 16,
        }),

        emailEnvelope: svgBuilders.c9r({
            colors: ["fill"],
            title: "emailEnvelope",
            paths: (
                <path d="M17.4724 0.675293H2.52761C1.13391 0.675293 0 1.80921 0 3.2029V12.7976C0 14.1913 1.13391 15.3252 2.52761 15.3252H17.4724C18.8661 15.3252 20 14.1913 20 12.7976V3.2029C20 1.80921 18.8661 0.675293 17.4724 0.675293ZM17.0775 2.30573L10 7.77301L2.9225 2.30573H17.0775ZM17.4724 13.6949H2.52761C2.03293 13.6949 1.63043 13.2924 1.63043 12.7977V3.3679L9.50163 9.44834C9.64837 9.56171 9.82424 9.61834 10 9.61834C10.1758 9.61834 10.3516 9.56171 10.4984 9.44834L18.3696 3.3679V12.7976C18.3696 13.2924 17.9671 13.6949 17.4724 13.6949Z" />
            ),
            viewBoxSize: 20,
        }),

        format: svgBuilders.c9r({
            colors: ["fill"],
            title: "format",
            paths: (
                <>
                    <rect x="6" y="17.5" width="12" height="2" />
                    <path d="M 8.9787 14.5 H 7.348 L 11.0121 4.3182 H 12.7869 L 16.451 14.5 H 14.8203 L 11.9418 6.1676 H 11.8622 L 8.9787 14.5 Z M 9.2521 10.5128 H 14.5419 V 11.8054 H 9.2521 V 10.5128 Z" />
                </>
            ),
            viewBoxSize: 24,
        }),

        tabbedFolder: svgBuilders.c9r({
            colors: ["stroke"],
            paths: (
                <path d="M 2,11 V 4.6 C 2,4.26863 2.26863,4 2.6,4 h 6.17805 c 0.14322,0 0.28172,0.05124 0.39047,0.14445 l 3.16298,2.7111 C 12.4402,6.94876 12.5787,7 12.722,7 H 21.4 C 21.7314,7 22,7.26863 22,7.6 V 11 M 2,11 v 8.4 C 2,19.7314 2.26863,20 2.6,20 H 21.4 C 21.7314,20 22,19.7314 22,19.4 V 11 M 2,11 h 20" />
            ),
            svgProps: {
                strokeLinecap: "round",
                strokeLinejoin: "round",
            },
            title: "tabbedFolder",
            viewBoxSize: 24,
        }),

        filledCircle: svgBuilders.c9r({
            colors: ["fill"],
            paths: <circle cx="12" cy="12" r="8" />,
            title: "filledCircle",
            viewBoxSize: 24,
        }),

        formatText: svgBuilders.c9r({
            colors: ["stroke", "fill"],
            paths: (
                <>
                    <path
                        d="M5.34717 8.472H9.64517L7.50317 3.264L5.34717 8.472ZM3.87717 12H2.70117L7.01317 2.088H7.99317L12.3052 12H11.1292L10.0372 9.396H4.96917L3.87717 12Z"
                        stroke="transparent"
                    />
                    <path d="M1 15H14" fill="transparent" />
                </>
            ),
            svgProps: {
                strokeLinecap: "round",
            },
            title: "formatText",
            viewBoxSize: 16,
        }),

        highlight: svgBuilders.c9r({
            colors: ["stroke"],
            title: "highlight",
            paths: (
                <>
                    <path
                        d="M5.72729 16.626L4.38677 18.4711L6.83941 19.2474L7.70176 18.0605"
                        strokeLinecap="round"
                    />
                    <line x1="6.09594" y1="12.5499" x2="11.464" y2="16.45" strokeLinecap="round" />
                    <path d="M12.3133 3.40863C12.638 2.96182 13.2633 2.86277 13.7101 3.1874L18.0155 6.31542C18.4623 6.64004 18.5613 7.26541 18.2367 7.71222L11.3992 17.1232C11.254 17.3231 11.04 17.4621 10.7983 17.5135L8.39774 18.0244C8.12046 18.0834 7.83117 18.0219 7.60183 17.8553L5.95308 16.6574C5.72374 16.4908 5.57589 16.2346 5.54632 15.9527L5.29032 13.5117C5.26455 13.266 5.33064 13.0195 5.47585 12.8196L12.3133 3.40863Z" />
                </>
            ),
            svgProps: {
                strokeLinecap: "round",
                strokeLinejoin: "round",
            },
            viewBoxSize: 21,
        }),

        layers: svgBuilders.c9r({
            colors: ["stroke"],
            title: "layers",
            paths: (
                <>
                    <path d="M7.00008 1.16663L1.16675 4.08329L7.00008 6.99996L12.8334 4.08329L7.00008 1.16663Z" />
                    <path d="M1.16675 9.91663L7.00008 12.8333L12.8334 9.91663" />
                    <path d="M1.16675 7L7.00008 9.91667L12.8334 7" />
                </>
            ),
            svgProps: {
                strokeLinecap: "round",
                strokeLinejoin: "round",
            },
            viewBoxSize: 14,
        }),

        lock: svgBuilders.c9r({
            colors: ["stroke", "fill"],
            title: "lock",
            paths: (
                <>
                    <path
                        d="M7 11V6.25C7 3 10 1.5 12 1.5C14 1.5 17 3 17 6.25V11"
                        fill="transparent"
                    />
                    <path
                        vectorEffect="non-scaling-stroke"
                        strokeWidth="1"
                        d="M18.5 11H5.5C4.5 11 3.5 12 3.5 13V20.5C3.5 21.5 4.5 22.5 5.5 22.5H18.5C19.5 22.5 20.5 21.5 20.5 20.5V13C20.5 12 19.5 11 18.5 11Z"
                    />
                </>
            ),
            svgProps: {
                strokeLinecap: "round",
                strokeLinejoin: "round",
            },
            viewBoxSize: 24,
        }),

        octagonPlus: svgBuilders.c9r({
            colors: ["stroke"],
            title: "octagonPlus",
            paths: (
                <>
                    <polygon points="7.36 1.5 15.64 1.5 21.5 7.36 21.5 15.64 15.64 21.5 7.36 21.5 1.5 15.64 1.5 7.36" />
                    <line x1="11.5" y1="7.5" x2="11.5" y2="15.5" />
                    <line x1="7.5" y1="11.5" x2="15.5" y2="11.5" />
                </>
            ),
            svgProps: {
                strokeLinecap: "round",
                strokeLinejoin: "round",
            },
            viewBoxSize: 23,
        }),

        orderedList: svgBuilders.c9r({
            colors: ["stroke"],
            title: "orderedList",
            paths: (
                <>
                    <path d="M4.4238 11.3873H3C3 10.6754 4.4238 9.96347 4.4238 9.25157C4.4238 8.53967 3.7119 8.18371 3 8.53967" />
                    <path d="M3 13.5392C3.7119 13.1832 4.70857 13.5419 4.70857 14.2538C4.70857 14.2538 4.70857 14.4729 3.85428 14.9L4.70857 15.5352C5 15.9 4.36685 17.073 3 16.3895" />
                    <path d="M3 6.34766H4.4238" />
                    <path d="M3 3.5H3.7119V6.34761" />
                    <path d="M6.66663 5H17.5" />
                    <path d="M6.66663 10H17.5" />
                    <path d="M6.66663 15H17.5" />
                </>
            ),
            svgProps: {
                strokeLinecap: "round",
                strokeLinejoin: "round",
            },
            viewBoxSize: 20,
        }),

        promote: svgBuilders.c9r({
            colors: ["stroke"],
            paths: (
                <>
                    <path
                        d="M9 14L1.6 14C1.26863 14 1 13.7314 1 13.4L1 2.6C1 2.26863 1.26863 2 1.6 2L9 2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M11.3333 10.6667L14 8.00001L11.3333 5.33334"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path d="M14 8H5.33333" strokeLinecap="round" strokeLinejoin="round" />
                </>
            ),
            title: "promote",
            viewBoxSize: 16,
        }),

        ready: svgBuilders.c9r({
            colors: ["stroke"],
            paths: (
                <>
                    <path
                        d="M16 16.4722V20C16 21.1045 15.1046 22 14 22H10C8.89543 22 8 21.1045 8 20V16.4722"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M8 7.52779V4C8 2.89543 8.89543 2 10 2H14C15.1046 2 16 2.89543 16 4V7.52779"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M18 12C18 8.68629 15.3137 6 12 6C8.68629 6 6 8.68629 6 12C6 15.3137 8.68629 18 12 18C15.3137 18 18 15.3137 18 12Z"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path d="M14 12H12V10" strokeLinecap="round" strokeLinejoin="round" />
                </>
            ),
            title: "ready",
            viewBoxSize: 23,
        }),

        search: svgBuilders.c9r({
            colors: ["stroke"],
            paths: (
                <path d="M19.9999 19.9999L14.7497 14.7497M16.6005 10.3002C16.6005 13.7797 13.7798 16.6005 10.3003 16.6005C6.82072 16.6005 4 13.7797 4 10.3002C4 6.82066 6.82072 3.99994 10.3003 3.99994C13.7798 3.99994 16.6005 6.82066 16.6005 10.3002Z" />
            ),
            svgProps: {
                strokeLinecap: "round",
                strokeLinejoin: "round",
            },
            title: "search",
            viewBoxSize: 24,
        }),

        smallFilledCircle: svgBuilders.c9r({
            colors: ["fill"],
            paths: <circle cx="12" cy="12" r="4" />,
            title: "filledCircle",
            viewBoxSize: 24,
        }),

        spaceFrame: svgBuilders.c9r({
            colors: ["stroke"],
            paths: (
                <path d="M12.0479 11.6818C12.4248 11.6281 12.7047 11.3054 12.7047 10.9248V3.07519C12.7047 2.69455 12.4248 2.37188 12.0479 2.31821L2.87244 1.00764C2.65305 0.976398 2.4309 1.04187 2.26352 1.18709C2.09614 1.33232 2 1.54302 2 1.76462V12.2354C2 12.457 2.09614 12.6677 2.26352 12.8129C2.4309 12.9581 2.65305 13.0236 2.87244 12.9924M12.0479 11.6818L2.87244 12.9924M12.0479 11.6818V11.3398L2.87244 12.4V12.9924" />
            ),
            svgProps: {
                strokeLinecap: "round",
                strokeLinejoin: "round",
            },
            title: "spaceFrame",
            viewBoxSize: 14,
        }),

        spaceFramePlus: svgBuilders.c9r({
            colors: ["stroke"],
            paths: (
                <>
                    <path d="M12.0479 11.6818C12.4248 11.6281 12.7047 11.3054 12.7047 10.9248V3.07519C12.7047 2.69455 12.4248 2.37188 12.0479 2.31821L2.87244 1.00764C2.65305 0.976398 2.4309 1.04187 2.26352 1.18709C2.09614 1.33232 2 1.54302 2 1.76462V12.2354C2 12.457 2.09614 12.6677 2.26352 12.8129C2.4309 12.9581 2.65305 13.0236 2.87244 12.9924M12.0479 11.6818L2.87244 12.9924M12.0479 11.6818V11.3398L2.87244 12.4V12.9924" />
                    <path d="M7.25 4.75V9.25" />
                    <path d="M5 7H9.5" />
                </>
            ),
            svgProps: {
                strokeLinecap: "round",
                strokeLinejoin: "round",
            },
            title: "spaceFramePlus",
            viewBoxSize: 14,
        }),

        strikethrough: svgBuilders.c9r({
            colors: ["stroke"],
            paths: (
                <>
                    <path d="M12.439 10.6666C12.439 12.6917 10.7974 14.3333 8.77238 14.3333C6.74734 14.3333 5.10571 12.6917 5.10571 10.6666" />
                    <path d="M12.439 5C12.439 3.34315 10.7974 2 8.77238 2C6.74734 2 5.10571 3.34315 5.10571 5C5.10571 6.65685 6.74734 8 8.77238 8" />
                    <path d="M3.10571 8H14.439" />
                </>
            ),
            svgProps: {
                strokeLinecap: "round",
                strokeLinejoin: "round",
            },
            title: "strikethrough",
            viewBoxSize: 16,
        }),

        support: svgBuilders.c9r({
            colors: ["fill"],
            paths: (
                <path d="M17.073 8.1H17.1C17.1 3.627 13.473 0 9 0C4.527 0 0.9 3.627 0.9 8.1H0.927C0.369 8.757 0 9.72 0 10.8C0 12.366 0.756 13.68 1.8 14.184V14.4C1.8 15.894 3.006 16.6459 4.5 16.6459H5.79511H7.2C7.2 17.1409 7.605 17.5 8.1 17.5H9.9C10.395 17.5 10.8 17.1409 10.8 16.6459V15.3C10.8 14.805 10.395 14.5 9.9 14.5H8.1C7.605 14.5 7.2 15.005 7.2 15.5H6H3.6C3.105 15.5 3 14.995 3 14.5C3.495 14.5 4.5 13.995 4.5 13.5V8.1C4.5 7.605 4.095 7.2 3.6 7.2H2.772C2 4.02226 5.823 1 9 1C12.177 1 16 4 15.228 7.2H14.4C13.905 7.2 13.5 7.605 13.5 8.1V13.5C13.5 13.995 13.905 14.4 14.4 14.4H15.3C16.794 14.4 18 12.789 18 10.8C18 9.72 17.631 8.757 17.073 8.1Z" />
            ),
            svgProps: {
                fillRule: "evenodd",
                clipRule: "evenodd",
            },
            title: "support",
            viewBoxSize: 18,
        }),

        upload: svgBuilders.c9r({
            colors: ["stroke"],
            paths: (
                <>
                    <path d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5Z" />
                    <path d="M16.198 11.2L12.198 7.2L8.198 11.2" />
                    <path d="M12.198 16.4V8.4" />
                </>
            ),
            svgProps: {
                strokeLinecap: "round",
                strokeLinejoin: "round",
            },
            title: "upload",
            viewBoxSize: 24,
        }),

        unarchive: svgBuilders.c9r({
            colors: ["stroke"],
            paths: (
                <>
                    <path d="M17.5 6.66668V17.5H2.5V6.66668" />
                    <path d="M19.1668 2.5H0.833496V6.66667H19.1668V2.5Z" />
                    <path d="M10.1372 15.5528V8.61111" />
                    <path d="M6.6665 12.0819L10.1373 8.61111L13.6082 12.0819" />
                </>
            ),
            svgProps: {
                strokeLinecap: "round",
                strokeLinejoin: "round",
            },
            title: "unarchive",
            viewBoxSize: 20,
        }),
    } as const,

    lucide: {
        "alert-triangle": svgBuilders.lucide(LucideAlertTriangle),
        archive: svgBuilders.lucide(LucideArchive),
        "arrow-down": svgBuilders.lucide(LucideArrowDown),
        "arrow-left": svgBuilders.lucide(LucideArrowLeft),
        "arrow-left-right": svgBuilders.lucide(LucideArrowLeftRight),
        "arrow-right": svgBuilders.lucide(LucideArrowRight),
        "arrow-up": svgBuilders.lucide(LucideArrowUp),
        "bar-chart": svgBuilders.lucide(LucideBarChart),
        bold: svgBuilders.lucide(LucideBold),
        book: svgBuilders.lucide(LucideBook),
        calendar: svgBuilders.lucide(LucideCalendar),
        "calendar-check": svgBuilders.lucide(LucideCalendarCheck),
        "calendar-days": svgBuilders.lucide(LucideCalendarDays),
        "calendar-minus": svgBuilders.lucide(LucideCalendarMinus),
        "calendar-plus": svgBuilders.lucide(LucideCalendarPlus),
        check: svgBuilders.lucide(LucideCheck),
        "check-circle": svgBuilders.lucide(LucideCheckCircle),
        "check-circle-2": svgBuilders.lucide(LucideCheckCircle2),
        "check-square": svgBuilders.lucide(LucideCheckSquare),
        "chevron-down": svgBuilders.lucide(LucideChevronDown),
        "chevron-left": svgBuilders.lucide(LucideChevronLeft),
        "chevron-right": svgBuilders.lucide(LucideChevronRight),
        "chevrons-up-down": svgBuilders.lucide(LucideChevronsUpDown),
        circle: svgBuilders.lucide(LucideCircle),
        clock: svgBuilders.lucide(LucideClock),
        code: svgBuilders.lucide(LucideCode),
        columns: svgBuilders.lucide(LucideColumns),
        copy: svgBuilders.lucide(LucideCopy),
        download: svgBuilders.lucide(LucideDownload),
        eye: svgBuilders.lucide(LucideEye),
        "eye-off": svgBuilders.lucide(LucideEyeOff),
        file: svgBuilders.lucide(LucideFile),
        "file-edit": svgBuilders.lucide(LucideFileEdit),
        "file-minus": svgBuilders.lucide(LucideFileMinus),
        "file-plus": svgBuilders.lucide(LucideFilePlus),
        "file-text": svgBuilders.lucide(LucideFileText),
        "file-up": svgBuilders.lucide(LucideFileUp),
        files: svgBuilders.lucide(LucideFiles),
        filter: svgBuilders.lucide(LucideFilter),
        folder: svgBuilders.lucide(LucideFolder),
        heading: svgBuilders.lucide(LucideHeading),
        "help-circle": svgBuilders.lucide(LucideHelpCircle),
        image: svgBuilders.lucide(LucideImage),
        inbox: svgBuilders.lucide(LucideInbox),
        info: svgBuilders.lucide(LucideInfo),
        italic: svgBuilders.lucide(LucideItalic),
        "git-merge": svgBuilders.lucide(LucideGitMerge),
        "git-pull-request": svgBuilders.lucide(LucideGitPullRequest),
        "life-buoy": svgBuilders.lucide(LucideLifeBuoy),
        link: svgBuilders.lucide(LucideLink),
        list: svgBuilders.lucide(LucideList),
        "log-out": svgBuilders.lucide(LucideLogOut),
        "edit-2": svgBuilders.lucide(LucideEdit2),
        "external-link": svgBuilders.lucide(LucideExternalLink),
        map: svgBuilders.lucide(LucideMap),
        "messages-square": svgBuilders.lucide(LucideMessagesSquare),
        minus: svgBuilders.lucide(LucideMinus),
        package: svgBuilders.lucide(LucidePackage),
        paperclip: svgBuilders.lucide(LucidePaperclip),
        plus: svgBuilders.lucide(LucidePlus),
        "plus-circle": svgBuilders.lucide(LucidePlusCircle),
        "plus-square": svgBuilders.lucide(LucidePlusSquare),
        "refresh-cw": svgBuilders.lucide(LucideRefreshCw),
        rocket: svgBuilders.lucide(LucideRocket),
        send: svgBuilders.lucide(LucideSend),
        settings: svgBuilders.lucide(LucideSettings),
        "sidebar-close": svgBuilders.lucide(LucideSidebarClose),
        "sidebar-open": svgBuilders.lucide(LucideSidebarOpen),
        square: svgBuilders.lucide(LucideSquare),
        tag: svgBuilders.lucide(LucideTag),
        trash: svgBuilders.lucide(LucideTrash),
        "trash-2": svgBuilders.lucide(LucideTrash2),
        truck: svgBuilders.lucide(LucideTruck),
        user: svgBuilders.lucide(LucideUser),
        "user-minus": svgBuilders.lucide(LucideUserMinus),
        "user-plus": svgBuilders.lucide(LucideUserPlus),
        x: svgBuilders.lucide(LucideX),
        "x-octagon": svgBuilders.lucide(LucideXOctagon),
    } as const,
} as const;

type IconSelector =
    | { iconSet: "c9r"; icon: keyof typeof icons.c9r }
    | { iconSet: "lucide"; icon: keyof typeof icons.lucide };

type IconSvgProps = {
    color?: string;
    iconSize?: number;

    /** Dynamically compute a final stroke width based on the icon size and our defaults. */
    strokeWeight?: number;

    /** Set a fixed stroke width regardless of icon size. Overrides strokeWeight. */
    strokeWidth?: number;

    /** Set an absolute stroke width. Overrides strokeWeight and strokeWidth. */
    strokeWidthAbsolute?: number;
} & IconSelector;

export function IconSvg({
    color,
    icon,
    iconSet,
    iconSize = 16,
    strokeWeight,
    strokeWidth,
    strokeWidthAbsolute,
}: IconSvgProps) {
    switch (iconSet) {
        case "c9r":
            return icons.c9r[icon]({
                color,
                icon,
                size: iconSize,
                strokeWeight,
                strokeWidth,
                strokeWidthAbsolute,
            });
        case "lucide":
            return icons.lucide[icon]({
                color,
                icon,
                size: iconSize,
                strokeWeight,
                strokeWidth,
                strokeWidthAbsolute,
            });
        default:
            throw new Error(`Unknown iconSet "${iconSet}"`);
    }
}

export type IconProps = {
    className?: string;
    htmlTitle?: string;
    intent?: BlueprintIntent;
    textInline?: boolean;
} & IconSvgProps &
    React.ComponentPropsWithoutRef<"span">;

export function Icon({
    className,
    color,
    htmlTitle,
    icon,
    iconSet,
    iconSize = 16,
    intent,
    strokeWeight,
    strokeWidth,
    strokeWidthAbsolute,
    textInline,
    ...htmlProps
}: IconProps) {
    let iconSvgElement: JSX.Element;

    switch (iconSet) {
        case "c9r":
            iconSvgElement = IconSvg({
                color,
                icon,
                iconSet,
                iconSize,
                strokeWeight,
                strokeWidth,
                strokeWidthAbsolute,
            });
            break;
        case "lucide":
            iconSvgElement = IconSvg({
                color,
                icon,
                iconSet,
                iconSize,
                strokeWeight,
                strokeWidth,
                strokeWidthAbsolute,
            });
            break;
        default:
            throw new Error(`Unknown iconSet "${iconSet}"`);
    }

    return React.createElement(
        "span",
        {
            ...htmlProps,
            className: classNames(Classes.ICON, Classes.intentClass(intent), className),
            title: htmlTitle,
        },
        // As of December 2023, this invisible non-breaking space ensures the height of the icon
        // element is 1 line-height. Recently, Firefox started supporting the lh CSS unit. Once support
        // is widespread enough, we could use that instead.
        [textInline && "\u200b", iconSvgElement].filter(Boolean)
    );
}
