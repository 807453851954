import React from "react";

import classNames from "classnames";

import { Config } from "Config";
import { Attachments } from "components/shared/Attachments";
import { ProductTourElementClasses } from "lib/Constants";
import { Enums } from "lib/Enums";
import { useUrlBuilders } from "lib/Urls";
import { getFragmentData, gql } from "lib/graphql/__generated__";
import { ContentBox } from "views/ticketDetail/content/ContentBox";
import { DescriptionEditor } from "views/ticketDetail/content/DescriptionEditor";
import { DetailContentPalette } from "views/ticketDetail/content/DetailContentPalette";
import { TasklistsSection } from "views/ticketDetail/content/TasklistsSection";
import { TasklistsSectionContextProvider } from "views/ticketDetail/content/TasklistsSectionContext";
import { useTicketDetailAttachments } from "views/ticketDetail/content/useTicketDetailAttachments";
import { useDetailView } from "views/ticketDetail/context/DetailViewContext";

import styles from "./TicketDocument.module.scss";

const fragments = {
    ticket: gql(/* GraphQL */ `
        fragment TicketDocument_ticket on tickets {
            id
            slug

            attachments {
                id
                title
                key
                url
                attached_at
                deleted_at
            }

            ...DetailContentPalette_ticket
            ...DescriptionEditor_ticket

            ...TasklistsSection_ticket
        }
    `),
};

export function TicketDocument() {
    const { ticket: _ticketFragment } = useDetailView();
    const ticket = getFragmentData(fragments.ticket, _ticketFragment);
    const { handleAttachmentUpload, handleAttachmentDelete } = useTicketDetailAttachments({
        ticketId: ticket.id,
    });
    const { buildTicketAttachmentUrl } = useUrlBuilders();

    const attachments = ticket.attachments
        .filter(ta => !ta.deleted_at)
        .map(ta => ({
            ...ta,
            href: ta.key
                ? `${Config.urls.public}${
                      buildTicketAttachmentUrl({
                          ticketSlug: ticket.slug,
                          key: ta.key,
                          filename: ta.title,
                      }).pathname
                  }`
                : ta.url,
        }))
        .sort((a, b) => new Date(a.attached_at).getTime() - new Date(b.attached_at).getTime());

    return (
        <ContentBox
            className={classNames(
                styles.document,
                ProductTourElementClasses.TICKET_CONTENT_DOCUMENT
            )}
        >
            <DescriptionEditor className={styles.descriptionEditor} ticket={ticket} />

            <Attachments
                className={styles.attachments}
                attachments={attachments}
                handleAttachmentDelete={handleAttachmentDelete}
                entityId={ticket.id}
                entityType={Enums.AttachmentEntityType.TICKET_DETAIL}
            />

            <TasklistsSectionContextProvider>
                <TasklistsSection className={styles.tasklists} ticket={ticket} />
            </TasklistsSectionContextProvider>

            <DetailContentPalette
                className={styles.palette}
                handleAttachmentUpload={handleAttachmentUpload}
                ticket={ticket}
            />
        </ContentBox>
    );
}
