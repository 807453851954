import React, { useRef } from "react";

import classNames from "classnames";
import { v4 as uuidv4 } from "uuid";

import { PrimitiveRadio, PrimitiveRadioProps } from "components/ui/core/primitives/PrimitiveRadio";

import styles from "./Radio.module.scss";

type RadioGroupProps = PrimitiveRadioProps["Group"];

const RadioGroup = React.forwardRef<React.ElementRef<typeof PrimitiveRadio.Group>, RadioGroupProps>(
    ({ ...rest }, forwardedRef) => {
        return <PrimitiveRadio.Group {...rest} ref={forwardedRef} />;
    }
);

type RadioItemProps = Omit<PrimitiveRadioProps["Item"], "children"> & {
    label?: React.ReactNode;
    labelClassName?: string;
};

const RadioItem = React.forwardRef<React.ElementRef<typeof PrimitiveRadio.Item>, RadioItemProps>(
    ({ className, label, labelClassName, ...rest }, forwardedRef) => {
        const uuidRef = useRef(uuidv4());
        const id = rest.id ?? uuidRef.current;

        return (
            <div className={styles.radio}>
                <PrimitiveRadio.Item className={styles.item} {...rest} id={id} ref={forwardedRef}>
                    <PrimitiveRadio.Indicator className={styles.indicator} />
                </PrimitiveRadio.Item>
                {label ? (
                    <label htmlFor={id} className={classNames(labelClassName, styles.label)}>
                        {label}
                    </label>
                ) : null}
            </div>
        );
    }
);

export const Radio = {
    Group: RadioGroup,
    Item: RadioItem,
};

export type RadioProps = {
    Group: RadioGroupProps;
    Item: RadioItemProps;
};
