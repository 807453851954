import React, { useCallback, useState } from "react";

import { Popover2, Popover2InteractionKind, Popover2Props } from "@blueprintjs/popover2";
import classNames from "classnames";

import { Enums } from "lib/Enums";
import { useHotkeyScope } from "lib/Hotkeys";

import styles from "./MenuPopover.module.scss";

export type MenuPopoverProps = {
    children: React.ReactNode;
    targetClassName?: string;
} & Omit<Popover2Props, "className">;

export function MenuPopover({ children, targetClassName, ...popoverProps }: MenuPopoverProps) {
    const windowPadding = 24; // If changing, make corresponding change to max-width in styles.
    const [isActive, setIsActive] = useState(!!popoverProps.isOpen);

    const _onOpening = popoverProps?.onOpening;
    const onOpening = useCallback(
        (node: HTMLElement) => {
            setIsActive(true);
            _onOpening?.(node);
        },
        [_onOpening]
    );

    const _onClosed = popoverProps?.onClosed;
    const onClosed = useCallback(
        (node: HTMLElement) => {
            setIsActive(false);
            _onClosed?.(node);
        },
        [_onClosed]
    );

    useHotkeyScope({ scope: Enums.HotkeyScope.MENU, isEnabled: isActive });

    const getHasBackdrop = () => {
        const { usePortal, interactionKind, hasBackdrop } = popoverProps;

        // If the popover is not using a portal, we can't use the backdrop and in addition, quoting from the docs,
        // "This prop is only available when interactionKind is PopoverInteractionKind.CLICK."
        // usePortal defaults to true, so we only need to apply handling if it is explicitly set to false
        // and interactionKind defaults to "click", so we only need to apply handling
        // if it is explicitly set to a different interaction.
        if (
            usePortal === false ||
            (interactionKind && interactionKind !== Popover2InteractionKind.CLICK)
        ) {
            return false;
        }

        return hasBackdrop ?? true;
    };

    return (
        <Popover2
            shouldReturnFocusOnClose
            {...popoverProps}
            className={targetClassName}
            isOpen={popoverProps.isOpen}
            hasBackdrop={getHasBackdrop()}
            minimal
            modifiers={{
                preventOverflow: {
                    enabled: true,
                    options: {
                        padding: windowPadding,
                    },
                },
                ...popoverProps?.modifiers,
            }}
            onClosed={onClosed}
            onOpening={onOpening}
            popoverClassName={classNames(popoverProps.popoverClassName, styles.menuPopover)}
        >
            {children}
        </Popover2>
    );
}
