import React from "react";

import { useRecoilValue } from "recoil";

import { ConfirmationModal } from "components/ui/common/ConfirmationModal";
import { dialogStateFamily, useDialogSingleton } from "components/ui/core/Dialog";

export type ArchiveBoardDialogProps = {
    boardDisplayName: string;
    onSubmit: () => void;
};

const dialogState = dialogStateFamily<ArchiveBoardDialogProps>("ArchiveBoardDialogState");

export function useArchiveBoardDialog() {
    return useDialogSingleton(dialogState);
}

export function ArchiveBoardDialog() {
    const { isOpen, props } = useRecoilValue(dialogState);
    const dialog = useArchiveBoardDialog();

    if (!props) {
        return null;
    }

    return (
        <ConfirmationModal
            isOpen={isOpen}
            body={
                <>
                    Archiving this workspace will immediately hide it from all users. It can be
                    restored at any time in <em>Settings | Workspaces</em>
                </>
            }
            title="Just so you know..."
            submitButtonText={`Archive ${props.boardDisplayName} workspace`}
            onClose={dialog.close}
            onSubmit={props.onSubmit}
        />
    );
}
