import React, { useEffect, useRef, useState } from "react";

import { TSearchIndexTicket } from "components/search/TicketSearchIndex";
import {
    TicketMenuItemClassName,
    TicketMenuItemLayout,
    TicketMenuWrapper,
} from "components/shared/TicketMenu";
import { BorderButton } from "components/ui/core/BorderButton";
import { Hotkey } from "components/ui/core/Hotkey";
import { Icon } from "components/ui/core/Icon";
import { MenuItem, MenuItemProps } from "components/ui/core/MenuItem";
import { MenuPopover } from "components/ui/core/MenuPopover";
import { SelectorMenu } from "components/ui/core/SelectorMenu";
import { Tooltip } from "components/ui/core/Tooltip";
import { useTicketSearch } from "contexts/TicketSearchContext";
import { useCurrentUser } from "contexts/UserContext";
import { Enums } from "lib/Enums";
import { useHotkey } from "lib/Hotkeys";
import { useInstrumentation } from "lib/Instrumentation";
import { Queries } from "lib/Queries";
import { useHistory } from "lib/Routing";
import { useTicketViewsLocal } from "lib/TicketViewsLocal";
import { useUrlBuilders } from "lib/Urls";
import { usePrefetchQuery } from "lib/graphql/usePrefetchQuery";

import styles from "./History.module.scss";

type ItemListProps = {
    items: TSearchIndexTicket[];
    renderItem: ({
        item,
        menuItemProps,
    }: {
        item: TSearchIndexTicket;
        menuItemProps?: MenuItemProps;
    }) => React.ReactNode;
    isTicketSearchIndexLoading?: boolean;
};

function ItemList({ items, renderItem, isTicketSearchIndexLoading }: ItemListProps) {
    return (
        <>
            {items.length ? (
                <>
                    <header>Recent</header>
                    {items.map(item => renderItem({ item }))}
                </>
            ) : isTicketSearchIndexLoading ? (
                <MenuItem
                    className={styles.menuItem}
                    disabled
                    text="Loading..."
                    instrumentation={null}
                />
            ) : (
                <MenuItem
                    className={styles.menuItem}
                    disabled
                    text="No results."
                    instrumentation={null}
                />
            )}
        </>
    );
}

export function History() {
    const [recentTickets, setRecentTickets] = useState<TSearchIndexTicket[]>([]);
    const [isOpen, setIsOpen] = useState(false);
    const currentUser = useCurrentUser();
    const prefetchQuery = usePrefetchQuery();
    const { history } = useHistory();
    const { recordEvent } = useInstrumentation();
    const { buildTicketUrl } = useUrlBuilders();
    const { findTickets, isTicketSearchIndexLoading } = useTicketSearch();
    const targetRef = useRef<HTMLButtonElement>(null);
    const { hasBeenViewedLocally } = useTicketViewsLocal();

    useHotkey("R", () => setIsOpen(prev => !prev));

    useEffect(() => {
        setRecentTickets(findTickets().filter(t => hasBeenViewedLocally({ ticketId: t.id })));
    }, [findTickets, hasBeenViewedLocally]);

    const handleOnItemSelect = ({ ticket }: { ticket: TSearchIndexTicket }) => {
        history.push(
            buildTicketUrl({
                ticketSlug: ticket.slug,
                vanity: {
                    boardDisplayName: ticket.board.display_name,
                    ticketRef: ticket.ref,
                    ticketTitle: ticket.title,
                },
            }).pathname
        );
        void recordEvent({
            eventType: Enums.InstrumentationEvent.CLICK,
            elementName: "history.recent_tickets_result",
            eventData: {
                ticketId: ticket.id,
                isArchived: !!ticket.archived_at,
            },
        });
        setIsOpen(false);
    };

    const prefetchTicketDetails = ({ ticket }: { ticket: TSearchIndexTicket }) => {
        void prefetchQuery({
            query: Queries.get({ component: "DetailView", name: "component" }),
            variables: {
                orgId: currentUser.org_id,
                ref: ticket.ref,
            },
        });
    };

    const handleOnOpening = () => {
        // By including this, any time the popover is opened, we ensure we've refreshed to
        // the latest results.
        if (!isTicketSearchIndexLoading) {
            setRecentTickets(findTickets().filter(t => hasBeenViewedLocally({ ticketId: t.id })));
        }
    };

    return (
        <div className={styles.history}>
            <MenuPopover
                isOpen={isOpen}
                minimal
                modifiers={{
                    offset: {
                        enabled: true,
                        options: {
                            offset: [4, 4],
                        },
                    },
                }}
                onInteraction={setIsOpen}
                onOpening={handleOnOpening}
                placement="bottom-end"
                popoverClassName={styles.popover}
                transitionDuration={0}
                content={
                    <TicketMenuWrapper dependencies={[recentTickets]}>
                        <SelectorMenu
                            allowNoActiveItem
                            filterable={false}
                            items={recentTickets}
                            itemListRenderer={({ items, renderItem }) => (
                                <ItemList
                                    items={items}
                                    renderItem={renderItem}
                                    isTicketSearchIndexLoading={isTicketSearchIndexLoading}
                                />
                            )}
                            menuItemClassName={TicketMenuItemClassName}
                            menuItemTextRenderer={ticket => (
                                <TicketMenuItemLayout showBoardName={false} ticket={ticket} />
                            )}
                            menuProps={{ className: styles.menu }}
                            onActiveItemChange={item => {
                                if (item) {
                                    prefetchTicketDetails({ ticket: item });
                                }
                            }}
                            onSelect={item => (item ? handleOnItemSelect({ ticket: item }) : null)}
                        />
                    </TicketMenuWrapper>
                }
            >
                <Tooltip
                    className={styles.historyTooltipTarget}
                    content={
                        <span>
                            Recent <Hotkey text="R" />
                        </span>
                    }
                    disabled={isOpen}
                    openOnTargetFocus={false}
                    placement="bottom"
                    small
                >
                    <BorderButton
                        elementRef={targetRef}
                        className={styles.historyButton}
                        content={
                            <Icon icon="clock" iconSet="lucide" iconSize={20} strokeWidth={1.75} />
                        }
                        onClick={() => setIsOpen(prev => !prev)}
                        minimal
                        small
                        textHover
                        themeDark
                        instrumentation={{
                            elementName: "recent_tickets",
                            eventData: { isOpen: !isOpen },
                        }}
                    />
                </Tooltip>
            </MenuPopover>
        </div>
    );
}
