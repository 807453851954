import React, { useCallback, useEffect, useRef } from "react";

import classNames from "classnames";

import { Menu, MenuProps } from "components/ui/core/Menu";
import { MenuItem, MenuItemProps } from "components/ui/core/MenuItem";
import { TextInputGroup, TextInputGroupProps } from "components/ui/core/TextInputGroup";
import { useNavigableItemList, useNavigableItemListParams } from "lib/Hooks";
import { isDefined } from "lib/types/guards";

import styles from "./SelectorMenu.module.scss";
import { Tooltip, TooltipProps } from "./Tooltip";

export type SelectorMenuProps<TItem> = {
    /**
     * Whether to include an embedded TextInputGroup to enable filtering displayed items.
     */
    filterable?: boolean;

    /**
     * Props to spread onto the TextInputGroup. Ignored unless filterable is true.
     */
    filterInputProps?: TextInputGroupProps;

    /**
     * Render prop to render the list of items. You must call renderItem for an item to
     * be selectable. It already wraps items in a MenuItem component. You may render other
     * menu content (such as dividers or zero-states) without calling renderItem.
     */
    itemListRenderer?: ({
        items,
        renderItem,
    }: {
        items: TItem[];
        renderItem: ({
            item,
            menuItemProps,
            tooltipProps,
        }: {
            item: TItem;
            menuItemProps?: Partial<MenuItemProps>;
            tooltipProps?: Partial<TooltipProps>;
        }) => React.ReactNode;
    }) => React.ReactNode;

    /**
     * Props to spread onto the underlying Menu component.
     */
    menuProps?: Omit<MenuProps, "children">;

    /**
     * Class name to apply to each menu item. This is a convenience prop, as it can also be
     * supplied by the menuItemProps passed to renderItem.
     */
    menuItemClassName?: string;

    /**
     * Class name for the wrapper div around the menu items (i.e., the section below the
     * TextInputGroup).
     */
    menuItemsClassName?: string;

    /**
     * Render prop to render the content of a selectable MenuItem.
     */
    menuItemTextRenderer?: (item: TItem) => React.ReactNode;

    /**
     * Callback when user presses Enter in the filter when no active item is selected.
     * Ignored unless allowNoActiveItem and filterable are true.
     */
    onNoActiveItemSelect?: () => void;

    /**
     * Callback when the filter query changes. Ignored unless filterable is true.
     */
    onQueryChange?: (value: string) => void;

    /**
     * Callback when an item is selected.
     */
    onSelect?: (item: TItem) => void;

    /**
     * Filter query. Ignored unless filterable is true.
     */
    query?: string;

    /**
     * Controls whether the active item resets to its default when the query is empty.
     * The default active item is the first item if allowNoActive item is omitted/false, and
     * no item if allowNoActive item is true.
     */
    resetActiveItemOnEmptyQuery?: boolean;
} & useNavigableItemListParams<TItem>;

export function SelectorMenu<TItem>({
    allowNoActiveItem,
    filterable,
    filterInputProps,
    items,
    itemsEqual,
    itemListRenderer,
    itemsPerPage,
    menuProps,
    menuItemClassName,
    menuItemsClassName,
    menuItemTextRenderer,
    onActiveItemChange,
    onNoActiveItemSelect,
    onQueryChange,
    onSelect,
    query,
    resetActiveItemOnEmptyQuery,
}: SelectorMenuProps<TItem>) {
    const itemIndexMap = new Map(items.map((item, i) => [item, i]));
    const { activeItem, resetActiveItem, setActiveItemIndex } = useNavigableItemList({
        allowNoActiveItem,
        items,
        itemsEqual,
        itemsPerPage,
        onActiveItemChange,
    });

    const inputRefInternal = useRef<HTMLInputElement>(null);
    const inputRef = filterInputProps?.inputRef ?? inputRefInternal;

    const handleSelect = useCallback(
        (item: TItem) => {
            onSelect?.(item);
            onQueryChange?.("");
            inputRef.current?.focus();
        },
        [inputRef, onSelect, onQueryChange]
    );

    useEffect(() => {
        if (!query && resetActiveItemOnEmptyQuery) {
            resetActiveItem();
        }
    }, [query, resetActiveItem, resetActiveItemOnEmptyQuery]);

    useEffect(() => {
        const handleKeyEvent = (e: KeyboardEvent) => {
            if (["Enter", "Tab"].includes(e.key) && items.length && activeItem) {
                e.preventDefault();
                handleSelect(activeItem);

                return true;
            }

            return false;
        };

        document.addEventListener("keydown", handleKeyEvent);

        return () => {
            document.removeEventListener("keydown", handleKeyEvent);
        };
    }, [activeItem, items, handleSelect, query]);

    const renderItem = ({
        item,
        menuItemProps,
        tooltipProps,
    }: {
        item: TItem;
        menuItemProps?: Partial<MenuItemProps>;
        tooltipProps?: Partial<TooltipProps>;
    }) => {
        const itemIndex = itemIndexMap.get(item);
        const isActiveItem = item === activeItem;

        return (
            <Tooltip
                content=""
                disabled={!menuItemProps?.disabled}
                {...tooltipProps}
                className={classNames(tooltipProps?.className, styles.menuItemWrapper)}
            >
                <MenuItem
                    data-cy="selector-menu-menu-item"
                    instrumentation={null}
                    shouldDismissPopover={false}
                    key={JSON.stringify(item)}
                    {...menuItemProps}
                    className={menuItemClassName}
                    text={menuItemTextRenderer ? menuItemTextRenderer(item) : item}
                    active={isActiveItem}
                    disableHoverEffect // Because we update the active item to follow the mouse, below.
                    onMouseMove={e => {
                        if (isDefined(itemIndex) && !isActiveItem) {
                            setActiveItemIndex(itemIndex);
                        }

                        menuItemProps?.onMouseMove?.(e);
                    }}
                    onClick={e => {
                        handleSelect(item);
                        menuItemProps?.onClick?.(e);
                    }}
                    scrollIntoView={isActiveItem}
                />
            </Tooltip>
        );
    };

    return (
        <Menu {...menuProps}>
            {filterable && (
                <TextInputGroup
                    data-cy="selector-menu-input"
                    {...filterInputProps}
                    inputRef={inputRef}
                    value={query}
                    onChange={e => onQueryChange?.(e.target.value)}
                    onKeyDown={e => {
                        if (e.key === "Escape") {
                            if (query) {
                                onQueryChange?.("");
                            }
                        }

                        if (e.key === "Enter") {
                            if (allowNoActiveItem && !activeItem) {
                                onNoActiveItemSelect?.();
                            }
                        }

                        filterInputProps?.onKeyDown?.(e);
                    }}
                />
            )}
            <div className={menuItemsClassName}>{itemListRenderer?.({ items, renderItem })}</div>
        </Menu>
    );
}
