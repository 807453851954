import React from "react";

import classNames from "classnames";

import { TimeAgo } from "components/ui/common/TimeAgo";
import { ExternalTicketSources } from "lib/Constants";
import { isSimultaneous } from "lib/Helpers";
import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";
import { isDefined } from "lib/types/guards";

import styles from "./TicketStateCaption.module.scss";

const fragments = {
    ticket: gql(/* GraphQL */ `
        fragment TicketStateCaption_ticket on tickets {
            id
            archived_at
            external_source_display_id
            trashed_at

            create_or_import_event: ticket_history_events(
                where: { event_type: { _in: ["CREATE_TICKET", "IMPORT_TICKET"] } }
                order_by: { event_at: asc }
                limit: 1
            ) {
                id
                event_at
                event_details
                event_type

                import {
                    id
                    source
                }

                user {
                    id
                    name
                }
            }

            archive_or_trash_event: ticket_history_events(
                where: { event_type: { _in: ["ARCHIVE", "TRASH"] } }
                order_by: { event_at: desc }
                limit: 1
            ) {
                id
                event_at
                event_type

                user {
                    id
                    name
                }
            }
        }
    `),
};

export type TicketStateCaptionProps = {
    abbreviate?: boolean;
    className?: string;
    ticket: FragmentType<typeof fragments.ticket>;
};

export function TicketStateCaption({
    abbreviate,
    className,
    ticket: _ticketFragment,
}: TicketStateCaptionProps) {
    const ticket = getFragmentData(fragments.ticket, _ticketFragment);
    // It's possible the event doesn't exist yet, since it's created by the backend asynchronously.
    const [createOrImportTicketEvent] = ticket.create_or_import_event;
    const [archiveOrTrashEvent] = ticket.archive_or_trash_event;
    const archiveOrTrashEventUserName = archiveOrTrashEvent?.user?.name;

    const source = createOrImportTicketEvent?.import?.source ?? null;
    const sourceInfo = source ? ExternalTicketSources[source] : null;
    const externalSourceDisplayId = ticket.external_source_display_id;

    let statusText = null;

    if (ticket.archived_at) {
        statusText = (
            <span>
                Archived{" "}
                {archiveOrTrashEvent &&
                isSimultaneous(archiveOrTrashEvent.event_at, ticket.archived_at) &&
                archiveOrTrashEvent.event_type === "ARCHIVE" &&
                archiveOrTrashEventUserName
                    ? `by ${archiveOrTrashEventUserName}`
                    : null}{" "}
                <TimeAgo abbreviate={abbreviate} date={ticket.archived_at} />
            </span>
        );
    } else if (ticket.trashed_at) {
        statusText = (
            <span>
                Trashed{" "}
                {archiveOrTrashEvent &&
                isSimultaneous(archiveOrTrashEvent.event_at, ticket.trashed_at) &&
                archiveOrTrashEvent.event_type === "TRASH" &&
                archiveOrTrashEventUserName
                    ? `by ${archiveOrTrashEventUserName}`
                    : null}{" "}
                <TimeAgo abbreviate={abbreviate} date={ticket.trashed_at} />
            </span>
        );
    } else {
        statusText = createOrImportTicketEvent
            ? {
                  CREATE_TICKET: (
                      <span>
                          {[
                              "Created",
                              sourceInfo ? `in ${sourceInfo.displayName}` : null,
                              sourceInfo && externalSourceDisplayId
                                  ? `(${externalSourceDisplayId})`
                                  : null,
                              `by ${createOrImportTicketEvent.user?.name || "Flat"}`,
                          ]
                              .filter(isDefined)
                              .join(" ")}{" "}
                          <TimeAgo
                              abbreviate={abbreviate}
                              date={createOrImportTicketEvent.event_at}
                          />
                      </span>
                  ),
                  IMPORT_TICKET: (
                      <span>
                          {[
                              "Imported",
                              sourceInfo ? `from ${sourceInfo.displayName}` : null,
                              sourceInfo && externalSourceDisplayId
                                  ? `(${externalSourceDisplayId})`
                                  : null,
                          ]
                              .filter(isDefined)
                              .join(" ")}{" "}
                          <TimeAgo
                              abbreviate={abbreviate}
                              date={createOrImportTicketEvent.event_at}
                          />
                      </span>
                  ),
              }[createOrImportTicketEvent.event_type]
            : null;
    }

    return statusText ? (
        <span className={classNames(className, styles.ticketStateCaption)}>{statusText}</span>
    ) : null;
}
