import React from "react";

import { SideDrawer } from "components/ui/common/SideDrawer";
import { BorderButton } from "components/ui/core/BorderButton";
import { Icon } from "components/ui/core/Icon";
import { useCurrentUser } from "contexts/UserContext";
import { Enums } from "lib/Enums";
import { getFragmentData, gql } from "lib/graphql/__generated__";
import { useDetailView } from "views/ticketDetail/context/DetailViewContext";
import { useDetailViewDiscussion } from "views/ticketDetail/context/DetailViewDiscussionContext";
import { CommentThread } from "views/ticketDetail/discussion/CommentThread";

import styles from "./ResolvedThreadsSideDrawer.module.scss";

const fragments = {
    ticket: gql(/* GraphQL */ `
        fragment ResolvedThreadsSideDrawer_ticket on tickets {
            id

            threads {
                id
                opened_at
                resolved_at
                ...CommentThread_thread
            }

            ...CommentThread_ticket
        }
    `),
};

function Content() {
    const { ticket: _ticketFragment } = useDetailView();
    const ticket = getFragmentData(fragments.ticket, _ticketFragment);
    const { setShowResolved } = useDetailViewDiscussion();

    const threadsToDisplay = ticket.threads
        .filter(thread => thread.resolved_at)
        .sort((a, b) => new Date(b.resolved_at!).getTime() - new Date(a.resolved_at!).getTime());

    return (
        <div className={styles.content}>
            <div className={styles.header}>
                <span className={styles.title}>Resolved threads</span>
                <BorderButton
                    data-cy="resolved-threads-close-btn"
                    content={<Icon icon="x" iconSet="lucide" iconSize={20} strokeWidth={1} />}
                    instrumentation={{
                        elementName: "ticket_detail.resolved_threads_drawer_close_btn",
                    }}
                    onClick={() => setShowResolved(false)}
                    flush
                    minimal
                    square
                    tighter
                />
            </div>
            <ul className={styles.threads}>
                {threadsToDisplay.map(thread => (
                    <CommentThread key={thread.id} thread={thread} ticket={ticket} />
                ))}
            </ul>
        </div>
    );
}

export function ResolvedThreadsSideDrawer() {
    const currentUser = useCurrentUser();
    const { showResolved } = useDetailViewDiscussion();

    return (
        <SideDrawer
            className={styles.drawer}
            id={`user.${currentUser.id}.resolvedThreadsSideDrawer`}
            isOpen={showResolved}
            widthSpec={{ type: Enums.SideDrawerWidthType.FIXED, value: 452 }}
        >
            <Content />
        </SideDrawer>
    );
}
