import React, { useEffect, useRef, useState } from "react";

import { isDefined } from "c9r-common";
import classNames from "classnames";

import { EnumValue, Enums } from "lib/Enums";
import { getTextPartitionedBySelection } from "lib/Helpers";
import { useResettingState } from "lib/Hooks";

import styles from "./EditableText.module.scss";

export type EditableTextProps = {
    autoFocus?: { at: "START" | "END" };
    cancelIfEmpty?: boolean;
    className?: string;
    contentEditable?: boolean;
    disabled?: boolean;
    elementRef?: React.RefObject<HTMLDivElement>;
    onChange?: (value: string | null) => void;
    onConfirm?: ({
        method,
        value,
        selection,
    }: {
        method: EnumValue<"TextCommitMethod">;
        value: string | null;
        selection: Selection | null;
    }) => void;
    onKeyDown?: (
        e: React.KeyboardEvent<HTMLDivElement>,
        { value }: { value: string | null }
    ) => void;
    onPasteMultilineContent?: ({
        confirmedLine,
        additionalLines,
    }: {
        confirmedLine: string;
        additionalLines: string[];
    }) => void;
    placeholder?: string;
    value?: string;
} & Omit<React.ComponentPropsWithoutRef<"div">, "onKeyDown">;

export function EditableText({
    autoFocus,
    cancelIfEmpty,
    className,
    contentEditable = true,
    disabled = false,
    elementRef,
    onChange,
    onConfirm,
    onKeyDown,
    onPasteMultilineContent,
    placeholder,
    value: initialValue,
    ...htmlDivProps
}: EditableTextProps) {
    const internalRef = useRef<HTMLDivElement>(null);
    const lastConfirmedValue = useRef<string | null>(null);
    const ref = elementRef || internalRef;
    const [moveCaretToEnd, setMoveCaretToEnd] = useResettingState(true, 350);
    const [hasAutoFocused, setHasAutoFocused] = useState(false);

    const handleCancel = () => {
        if (!ref.current) {
            return;
        }

        ref.current.textContent = initialValue ?? null;
        ref.current.blur();
    };

    const handleConfirm = ({
        method,
        _value,
    }: {
        method: EnumValue<"TextCommitMethod">;
        _value?: string;
    }) => {
        if (!ref.current) {
            return;
        }

        const value = _value ?? ref.current.textContent;

        if (value === lastConfirmedValue.current) {
            return;
        }

        if (cancelIfEmpty && !value?.trim()) {
            handleCancel();
            return;
        }

        // It's important to save the last confirmed value before onConfirm is called.
        // Otherwise, the onConfirm handler could do something, like trigger a blur,
        // which would (synchronously) cause handleConfirm to fire again, causing
        // onConfirm to be called again with the same value.
        lastConfirmedValue.current = value;
        onConfirm?.({ method, value, selection: window.getSelection() });
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (!ref.current) {
            return;
        }

        if (e.key === "Escape") {
            handleCancel();
            return;
        }

        if (e.key === "Enter") {
            e.preventDefault();
            handleConfirm({ method: Enums.TextCommitMethod.ENTER });
            ref.current.blur();
        }

        onKeyDown?.(e, { value: ref.current.textContent });
    };

    useEffect(() => {
        if (autoFocus && !hasAutoFocused) {
            ref.current?.focus();
            setHasAutoFocused(true);
        }
    }, [autoFocus, hasAutoFocused, ref]);

    return (
        <div
            {...htmlDivProps}
            ref={ref}
            onInput={e => onChange?.(e.currentTarget.textContent)}
            className={classNames(className, styles.editableText, disabled && styles.disabled)}
            onKeyDown={handleKeyDown}
            contentEditable={contentEditable && !disabled}
            tabIndex={contentEditable && !disabled ? 0 : -1}
            // On focus, move caret to end unless we've focused via click or are autofocusing at the start.
            onMouseDown={() => {
                setMoveCaretToEnd(false);
            }}
            onFocus={() => {
                lastConfirmedValue.current = null;

                if (!ref.current) {
                    return;
                }

                if (autoFocus?.at === "START" && !hasAutoFocused) {
                    return;
                }

                if (
                    moveCaretToEnd &&
                    ref.current.childNodes[0] &&
                    ref.current.childNodes[0].textContent
                ) {
                    const range = document.createRange();
                    const selection = window.getSelection();

                    range.setStart(
                        ref.current.childNodes[0],
                        ref.current.childNodes[0].textContent.length
                    );
                    range.collapse(true);

                    if (selection) {
                        selection.removeAllRanges();
                        selection.addRange(range);
                    }
                }
            }}
            // Ensure that any rich text pasted is transformed to plaintext.
            // An alternative would be to set the content-editable attribute to plaintext-only
            // (https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/contenteditable).
            // However, as of October 2023, this wasn't pursued because:
            //   - It's not supported by Firefox
            //   - It caused a surprising regression in Chrome (and possibly other browsers) where
            //     typing a key that we have a hotkey shortcut assigned to triggered the shortcut
            //     instead of inserting the character.
            onPasteCapture={async event => {
                const text = ref.current?.textContent;
                const selection = window.getSelection();

                if (onPasteMultilineContent && isDefined(text) && selection) {
                    const { leftText, rightText } = getTextPartitionedBySelection({
                        text,
                        selection,
                    });

                    const lines = [leftText, event.clipboardData.getData("text"), rightText]
                        .join("")
                        .split("\n")
                        .map(line => line.trim())
                        .filter(Boolean);

                    if (lines.length > 1) {
                        event.preventDefault();

                        handleConfirm({ method: Enums.TextCommitMethod.BLUR, _value: lines[0] });
                        onPasteMultilineContent({
                            confirmedLine: lines[0],
                            additionalLines: lines.slice(1),
                        });

                        return;
                    }
                }

                // document.execCommand has been marked deprecated for a while, but major browsers
                // still support it and probably will for a long time, particularly for
                // "insertText".
                if (document.execCommand) {
                    document.execCommand(
                        "insertText",
                        false,
                        event.clipboardData.getData("text/plain")
                    );
                    event.preventDefault();
                }
            }}
            onBlur={() => {
                handleConfirm({ method: Enums.TextCommitMethod.BLUR });
            }}
            data-text={placeholder ?? "Type text..."}
            // https://stackoverflow.com/questions/49639144/why-does-react-warn-against-an-contenteditable-component-having-children-managed
            suppressContentEditableWarning
        >
            {initialValue}
        </div>
    );
}
