import React, { useCallback, useEffect, useMemo, useState } from "react";

import { CommonEnums, ValueOf } from "c9r-common";
import classNames from "classnames";
import { Resizable, ResizeCallback } from "re-resizable";
import { CSSTransition } from "react-transition-group";
import { useRecoilValue } from "recoil";

import { currentBoardIdState } from "AppState";
import { Config } from "Config";
import { networkStatusState } from "components/monitors/NetworkStatusMonitor";
import { AppToaster } from "components/ui/core/AppToaster";
import { BorderButton } from "components/ui/core/BorderButton";
import { Icon } from "components/ui/core/Icon";
import { Menu } from "components/ui/core/Menu";
import { MenuDivider } from "components/ui/core/MenuDivider";
import { MenuItem } from "components/ui/core/MenuItem";
import { MenuPopover } from "components/ui/core/MenuPopover";
import { useCurrentUser } from "contexts/UserContext";
import { useCreateBoardDialog } from "dialogs/CreateBoardDialog";
import { useInvitePeopleDialog } from "dialogs/InvitePeopleDialog";
import { ProductTourElementClasses } from "lib/Constants";
import { Enums } from "lib/Enums";
import { canCurrentUserViewUserPage, divideAndFlattenGroups } from "lib/Helpers";
import { useNomenclature } from "lib/Nomenclature";
import { Link, useHistory, useLocation, useRouteMatch } from "lib/Routing";
import { Storage } from "lib/Storage";
import {
    RoutePathType,
    RoutePatternsByPathType,
    useRedirectToBoard,
    useUrlBuilders,
} from "lib/Urls";
import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";
import {
    SideNav_boardFragment,
    SideNav_orgFragment,
    SideNav_userFragment,
} from "lib/graphql/__generated__/graphql";
import { createCtx } from "lib/react/Context";
import { isDefined } from "lib/types/guards";

import styles from "./SideNav.module.scss";
import { useAppLayout } from "../AppLayoutContext";

const fragments = {
    board: gql(/* GraphQL */ `
        fragment SideNav_board on boards {
            id
            access_type
            archived_at
            display_name
            slug
        }
    `),

    org: gql(/* GraphQL */ `
        fragment SideNav_org on orgs {
            id
            display_name

            boards(where: { archived_at: { _is_null: true } }) {
                id
                ...SideNav_board
            }

            users(where: { disabled_at: { _is_null: true } }) {
                id
                ...SideNav_user
            }
        }
    `),

    user: gql(/* GraphQL */ `
        fragment SideNav_user on users {
            id
            disabled_at
            name
            slug
            ...Avatar_user
        }
    `),
};

const MIN_WIDTH = 186;
const MAX_WIDTH = 400;
const DEFAULT_WIDTH = 200;

const SectionType = {
    BOARDS: "BOARDS",
    PEOPLE: "PEOPLE",
} as const;

type TBoardNavLinkItem = {
    sectionType: typeof SectionType.BOARDS;
    board: SideNav_boardFragment;
    isCurrentNavLinkItem: boolean;
    pathname: string;
};

type TPeopleNavLinkItem = {
    sectionType: typeof SectionType.PEOPLE;
    user: SideNav_userFragment;
    isCurrentNavLinkItem: boolean;
    pathname: string;
};

type TNavLinkItem = TBoardNavLinkItem | TPeopleNavLinkItem;

type SideNavContextValue = {
    isCurrentNavItem: ({
        pathname,
        sectionType,
        itemId,
    }: {
        pathname: string;
        sectionType: ValueOf<typeof SectionType>;
        itemId: string | number;
    }) => boolean;
    navLinkItems: TNavLinkItem[];
    org: SideNav_orgFragment;
};

const [useSideNav, ContextProvider] = createCtx<SideNavContextValue>();

type SideNavProviderProps = {
    children: React.ReactNode;
    org: SideNav_orgFragment;
};

function SideNavProvider({ children, org }: SideNavProviderProps) {
    const currentUser = useCurrentUser();
    const currentBoardId = useRecoilValue(currentBoardIdState);
    const isAppInDetailView = !!useRouteMatch(RoutePatternsByPathType[RoutePathType.TOPIC]);
    const location = useLocation();
    const { buildBoardUrl, buildUserUrl } = useUrlBuilders();

    const isCurrentNavItem = useCallback(
        ({
            pathname,
            sectionType,
            itemId,
        }: {
            pathname: string;
            sectionType: ValueOf<typeof SectionType>;
            itemId: string | number;
        }) => {
            if (isAppInDetailView) {
                if (location.state?.from?.pageDetails?.appPage === Enums.AppPage.USER) {
                    return (
                        sectionType === SectionType.PEOPLE &&
                        location.state?.from.pageDetails.userId === itemId
                    );
                }

                return sectionType === SectionType.BOARDS && itemId === currentBoardId;
            }

            return location.pathname === pathname;
        },
        [currentBoardId, isAppInDetailView, location]
    );

    const navLinkItems = useMemo(
        () =>
            ([] as TNavLinkItem[])
                .concat(
                    org.boards
                        .map(_boardFragment => getFragmentData(fragments.board, _boardFragment))
                        .filter(board => !board.archived_at)
                        .sort((a, b) =>
                            a.display_name.toLowerCase() < b.display_name.toLowerCase() ? -1 : 1
                        )
                        .map(board => {
                            const sectionType = SectionType.BOARDS;
                            const { pathname } = buildBoardUrl({
                                boardSlug: board.slug,
                                vanity: {
                                    boardDisplayName: board.display_name,
                                },
                            });
                            const isCurrentNavLinkItem = isCurrentNavItem({
                                pathname,
                                sectionType,
                                itemId: board.id,
                            });

                            return {
                                sectionType,
                                board,
                                pathname,
                                isCurrentNavLinkItem,
                            };
                        })
                )
                .concat(
                    org.users
                        .map(_userFragment => getFragmentData(fragments.user, _userFragment))
                        .filter(user => !user.disabled_at)
                        .filter(user =>
                            canCurrentUserViewUserPage({
                                currentUser,
                                userIdToView: user.id,
                            })
                        )
                        .sort((a, b) => {
                            if (a.id === currentUser.id) {
                                return -1;
                            }

                            if (b.id === currentUser.id) {
                                return 1;
                            }

                            return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
                        })
                        .map(user => {
                            const sectionType = SectionType.PEOPLE;
                            const { pathname } = buildUserUrl({
                                userSlug: user.slug,
                                vanity: { username: user.name },
                            });
                            const isCurrentNavLinkItem = isCurrentNavItem({
                                pathname,
                                sectionType,
                                itemId: user.id,
                            });

                            return {
                                sectionType,
                                user,
                                isCurrentNavLinkItem,
                                pathname,
                            };
                        })
                ),
        [buildBoardUrl, buildUserUrl, currentUser, isCurrentNavItem, org.boards, org.users]
    );

    const value = useMemo(() => ({ isCurrentNavItem, navLinkItems, org }), [
        isCurrentNavItem,
        navLinkItems,
        org,
    ]);

    return <ContextProvider value={value}>{children}</ContextProvider>;
}

function Title() {
    const currentUser = useCurrentUser();
    const isOnline = useRecoilValue(networkStatusState);
    const { getCurrentLocation } = useHistory();
    const { buildSettingsUrl } = useUrlBuilders();
    const invitePeopleDialog = useInvitePeopleDialog();
    const createBoardDialog = useCreateBoardDialog();
    const { redirectToBoard } = useRedirectToBoard();
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const orgDisplayName = currentUser.org.display_name;

    const menuItems = divideAndFlattenGroups({
        itemGroups: [
            [
                currentUser.role === CommonEnums.UserRole.USER_ORG_ADMIN ? (
                    <MenuItem
                        key="invite-people"
                        text={`Invite people to ${orgDisplayName}`}
                        icon={<Icon icon="user-plus" iconSet="lucide" iconSize={18} />}
                        instrumentation={{
                            elementName: "side_nav.org_menu.invite_people",
                        }}
                        onClick={() => {
                            if (!isOnline) {
                                AppToaster.danger({
                                    message: "Sorry, inviting people is not available offline.",
                                });

                                return;
                            }

                            invitePeopleDialog.open();
                        }}
                    />
                ) : null,
                currentUser.role === CommonEnums.UserRole.USER_ORG_ADMIN ? (
                    <MenuItem
                        key="create-workspace"
                        text="Create a workspace"
                        icon={<Icon icon="spaceFramePlus" iconSet="c9r" iconSize={18} />}
                        instrumentation={{
                            elementName: "side_nav.org_menu.create_workspace",
                        }}
                        onClick={() =>
                            createBoardDialog.openWithProps({ onCreate: redirectToBoard })
                        }
                    />
                ) : null,
            ].filter(isDefined),
            [
                currentUser.role === CommonEnums.UserRole.USER_ORG_ADMIN ? (
                    <MenuItem
                        key="settings"
                        text="Admin settings"
                        icon={<Icon icon="settings" iconSet="lucide" iconSize={18} />}
                        instrumentation={{
                            elementName: "side_nav.org_menu.admin_settings",
                        }}
                        to={{
                            pathname: `${buildSettingsUrl().pathname}/general`,
                            state: {
                                from: { location: getCurrentLocation() },
                            },
                        }}
                    />
                ) : null,
            ].filter(isDefined),
            [
                Config.isMultiOrgEnabled ? (
                    <MenuItem
                        key="switch-org"
                        text="Switch organization"
                        icon={<Icon icon="arrow-left-right" iconSet="lucide" iconSize={18} />}
                        instrumentation={{
                            elementName: "side_nav.org_menu.switch_org",
                        }}
                        onClick={event => {
                            if (!isOnline) {
                                AppToaster.danger({
                                    message:
                                        "Sorry, switching organizations is not available offline.",
                                });
                                event.preventDefault();
                            }
                        }}
                        to="/organizations"
                    />
                ) : null,
            ].filter(isDefined),
        ],
        divider: i => <MenuDivider key={i} />,
    });

    return (
        <div className={styles.title}>
            {menuItems.length ? (
                <MenuPopover
                    targetClassName={styles.orgNameTarget}
                    modifiers={{
                        offset: {
                            enabled: true,
                            options: {
                                offset: [0, -12],
                            },
                        },
                    }}
                    content={<Menu>{menuItems}</Menu>}
                    placement="bottom-end"
                    onOpening={() => setIsMenuOpen(true)}
                    onClosing={() => setIsMenuOpen(false)}
                >
                    <BorderButton
                        className={classNames(
                            styles.orgNameWrapper,
                            styles.orgNameButton,
                            isMenuOpen && styles.menuOpen
                        )}
                        content={<span className={styles.orgName}>{orgDisplayName}</span>}
                        instrumentation={{ elementName: "side_nav.org_menu" }}
                        minimal
                        fill
                        iconSeparate
                        useHoverEffect={false}
                        rightIconProps={{
                            className: styles.titleIcon,
                            icon: "chevron-down",
                            iconSet: "lucide",
                            iconSize: 20,
                            strokeWidth: 1.75,
                        }}
                    />
                </MenuPopover>
            ) : (
                <h1 className={classNames(styles.orgNameWrapper, styles.orgName)}>
                    {orgDisplayName}
                </h1>
            )}
        </div>
    );
}

type SectionProps = {
    children?: React.ReactNode;
    className?: string;
    headerClassName?: string;
    headerRightElement?: React.ReactNode;
    title: string;
};

function Section({
    children,
    className,
    headerClassName,
    headerRightElement,
    title,
}: SectionProps) {
    const [isCollapsed, setIsCollapsed] = useState(false);

    return (
        <section className={classNames(className, styles.section)}>
            <h2 className={classNames(headerClassName, styles.sectionHeader)}>
                <BorderButton
                    className={styles.sectionHeaderButton}
                    minimal
                    square
                    tighter
                    flush
                    instrumentation={{ elementName: "side_nav.section_collapse_btn" }}
                    content={<span className={styles.sectionHeaderText}>{title}</span>}
                    leftIconProps={{
                        icon: isCollapsed ? "chevron-right" : "chevron-down",
                        iconSet: "lucide",
                        iconSize: 16,
                        strokeWidth: 1.75,
                    }}
                    iconGap={8}
                    onClick={() => setIsCollapsed(prev => !prev)}
                />
                {headerRightElement}
            </h2>

            {!isCollapsed ? children : null}
        </section>
    );
}

type BoardNavLinkItemProps = {
    board: SideNav_boardFragment;
    isCurrentNavLinkItem: boolean;
    pathname: string;
};

function BoardNavLinkItem({ board, isCurrentNavLinkItem, pathname }: BoardNavLinkItemProps) {
    const boardDisplayName = board.display_name;

    return (
        <li>
            <Link
                role="link"
                className={classNames(
                    styles.navLinkItem,
                    isCurrentNavLinkItem && styles.navLinkItemCurrent
                )}
                to={pathname}
            >
                <span className={styles.navLinkItemText}>{boardDisplayName}</span>
                {board.access_type === CommonEnums.BoardAccessType.PRIVATE ? (
                    <Icon
                        className={styles.navLinkItemAnnotation}
                        icon="lock"
                        iconSet="c9r"
                        iconSize={12}
                        strokeWidthAbsolute={1.5}
                    />
                ) : null}
            </Link>
        </li>
    );
}

function BoardsSection() {
    const { navLinkItems } = useSideNav();
    const createBoardDialog = useCreateBoardDialog();
    const { nomenclature } = useNomenclature();
    const { redirectToBoard } = useRedirectToBoard();

    return (
        <Section
            className={ProductTourElementClasses.SIDENAV_WORKSPACES_SECTION}
            headerClassName={styles.headerWithCreateButton}
            headerRightElement={
                <BorderButton
                    data-cy="sidenav-create-board-btn"
                    className={styles.createButton}
                    minimal
                    instrumentation={{ elementName: "side_nav.new_board" }}
                    content={<Icon icon="plus" iconSet="lucide" iconSize={16} />}
                    onClick={() => createBoardDialog.openWithProps({ onCreate: redirectToBoard })}
                />
            }
            title={nomenclature.space.plural}
        >
            <ul>
                {navLinkItems
                    .filter(
                        (navLinkItem): navLinkItem is TBoardNavLinkItem =>
                            navLinkItem.sectionType === SectionType.BOARDS
                    )
                    .map(({ board, isCurrentNavLinkItem, pathname }) => (
                        <BoardNavLinkItem
                            key={board.id}
                            board={board}
                            isCurrentNavLinkItem={isCurrentNavLinkItem}
                            pathname={pathname}
                        />
                    ))}
            </ul>
        </Section>
    );
}

type UserNavLinkItemProps = {
    isCurrentNavLinkItem: boolean;
    pathname: string;
    user: SideNav_userFragment;
};

function UserNavLinkItem({ isCurrentNavLinkItem, pathname, user }: UserNavLinkItemProps) {
    const currentUser = useCurrentUser();
    const isCurrentUser = currentUser.id === user.id;
    const displayName = `${user.name}${isCurrentUser ? " (me)" : ""}`;

    return (
        <li>
            <Link
                role="link"
                className={classNames(
                    styles.navLinkItem,
                    isCurrentNavLinkItem && styles.navLinkItemCurrent
                )}
                to={pathname}
            >
                <span className={styles.navLinkItemText}>{displayName}</span>
            </Link>
        </li>
    );
}

function PeopleSection() {
    const currentUser = useCurrentUser();
    const isOnline = useRecoilValue(networkStatusState);
    const invitePeopleDialog = useInvitePeopleDialog();
    const { navLinkItems } = useSideNav();

    return (
        <Section
            className={ProductTourElementClasses.SIDENAV_PEOPLE_SECTION}
            headerClassName={styles.headerWithCreateButton}
            headerRightElement={
                currentUser.role === CommonEnums.UserRole.USER_ORG_ADMIN ? (
                    <BorderButton
                        className={styles.createButton}
                        minimal
                        instrumentation={{ elementName: "side_nav.new_people" }}
                        content={<Icon icon="plus" iconSet="lucide" iconSize={16} />}
                        onClick={() => {
                            if (!isOnline) {
                                AppToaster.danger({
                                    message: "Sorry, inviting people is not available offline.",
                                });

                                return;
                            }

                            invitePeopleDialog.open();
                        }}
                    />
                ) : null
            }
            title="People"
        >
            <ul>
                {navLinkItems
                    .filter(
                        (navLinkItem): navLinkItem is TPeopleNavLinkItem =>
                            navLinkItem.sectionType === SectionType.PEOPLE
                    )
                    .map(({ user, isCurrentNavLinkItem, pathname }) => (
                        <UserNavLinkItem
                            key={user.id}
                            isCurrentNavLinkItem={isCurrentNavLinkItem}
                            pathname={pathname}
                            user={user}
                        />
                    ))}
            </ul>
        </Section>
    );
}

export type SideNavLayoutProps = {
    className?: string;
    children?: React.ReactNode;
};

function SideNavLayout({ className, children }: SideNavLayoutProps) {
    const currentUser = useCurrentUser();
    const key = `user.${currentUser.id}.sideNavLayout`;
    const storedWidth = Storage.Local.getItem(key)?.width;

    const [width, setWidth] = useState(
        typeof storedWidth === "number"
            ? Math.max(Math.min(storedWidth, MAX_WIDTH), MIN_WIDTH)
            : DEFAULT_WIDTH
    );

    const handleResizeEnd = useCallback<ResizeCallback>(
        (event, direction, refToElement, delta) => setWidth(width + delta.width),
        [width]
    );

    useEffect(() => {
        Storage.Local.setItem(key, { ...Storage.Local.getItem(key), width });
    }, [key, width]);

    const ENTER_TRANSITION_DURATION = 300;
    const EXIT_TRANSITION_DURATION = 150;

    const { appLayoutState } = useAppLayout();

    const [style, setStyle] = useState<React.CSSProperties>({});

    return (
        <CSSTransition
            in={!appLayoutState.isSideNavCollapsed}
            timeout={{
                enter: ENTER_TRANSITION_DURATION,
                exit: EXIT_TRANSITION_DURATION,
            }}
            classNames={{ ...styles }}
            onEnter={() =>
                setStyle({
                    transform: `translateX(-${width}px)`,
                    marginRight: `-${width}px`,
                })
            }
            onEntering={() =>
                setStyle({
                    transform: "translateX(0px)",
                    marginRight: "0px",
                    transition: `transform ${ENTER_TRANSITION_DURATION}ms cubic-bezier(0.25, 1, 0.25, 1), margin-right ${ENTER_TRANSITION_DURATION}ms cubic-bezier(0.25, 1, 0.25, 1)`,
                })
            }
            onExit={() =>
                setStyle({
                    transform: "translateX(0px)",
                    marginRight: "0px",
                })
            }
            onExiting={() =>
                setStyle({
                    transform: `translateX(-${width}px)`,
                    marginRight: `-${width}px`,
                    transition: `margin-right ${EXIT_TRANSITION_DURATION}ms linear, transform ${EXIT_TRANSITION_DURATION}ms linear`,
                })
            }
            mountOnEnter
            unmountOnExit
        >
            <Resizable
                as="nav"
                className={classNames(className, styles.sideNav, "data-cy-sidenav")}
                enable={{
                    bottom: false,
                    bottomLeft: false,
                    bottomRight: false,
                    left: false,
                    right: true,
                    top: false,
                    topLeft: false,
                    topRight: false,
                }}
                maxWidth={MAX_WIDTH}
                minWidth={MIN_WIDTH}
                onResizeStop={handleResizeEnd}
                size={{ height: "100%", width }}
                style={style}
            >
                <Title />
                <div className={styles.sections}>{children}</div>
            </Resizable>
        </CSSTransition>
    );
}

export type SideNavProps = {
    className?: string;
    org?: FragmentType<typeof fragments.org>;
};

export function SideNav({ className, org: _orgFragment }: SideNavProps) {
    const org = getFragmentData(fragments.org, _orgFragment);

    if (!org) {
        return <SideNavLayout />;
    }

    return (
        <SideNavProvider org={org}>
            <SideNavLayout className={className}>
                <BoardsSection />
                <PeopleSection />
            </SideNavLayout>
        </SideNavProvider>
    );
}
