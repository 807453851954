import React, { useCallback, useState } from "react";

import { CommonEnumValue, CommonEnums } from "c9r-common";
import classNames from "classnames";
import { useRecoilValue } from "recoil";

import { networkStatusState } from "components/monitors/NetworkStatusMonitor";
import { BoardAccessTypeRadio } from "components/shared/BoardAccessTypeRadio";
import { AppToaster } from "components/ui/core/AppToaster";
import { BorderButton } from "components/ui/core/BorderButton";
import { Dialog, dialogStateFamily, useDialogSingleton } from "components/ui/core/Dialog";
import { TextInputBorderBox } from "components/ui/core/TextInputBorderBox";
import { useCurrentUser } from "contexts/UserContext";
import { useAsyncWatcher } from "lib/Hooks";
import { useNomenclature } from "lib/Nomenclature";
import { Queries } from "lib/Queries";
import { useCreateBoard } from "lib/mutations";

import styles from "./CreateBoardDialog.module.scss";

export type CreateBoardDialogProps = {
    onCreate?: ({
        id,
        slug,
        displayName,
    }: {
        id: string;
        slug: string;
        displayName: string;
    }) => void;
};

const dialogState = dialogStateFamily<CreateBoardDialogProps>("CreateBoardDialog");

export function useCreateBoardDialog() {
    return useDialogSingleton(dialogState);
}

export function CreateBoardDialog() {
    const isOnline = useRecoilValue(networkStatusState);
    const currentUser = useCurrentUser();
    const { isOpen, props } = useRecoilValue(dialogState);
    const { onCreate } = props ?? {};
    const dialog = useCreateBoardDialog();
    const [newBoardDisplayName, setNewBoardDisplayName] = useState("");
    const [newBoardAccessType, setNewBoardAccessType] = useState<
        CommonEnumValue<"BoardAccessType">
    >(CommonEnums.BoardAccessType.PUBLIC);
    const submission = useAsyncWatcher();
    const { createBoard } = useCreateBoard();
    const { nomenclature } = useNomenclature();

    const handleSubmit = useCallback(async () => {
        if (!isOnline) {
            AppToaster.danger({
                message: "Sorry, creating a workspace is not available offline.",
            });

            return;
        }

        const displayName = newBoardDisplayName.trim();

        if (!displayName) {
            return;
        }

        const result = await createBoard(
            { displayName, accessType: newBoardAccessType },
            {
                refetchQueries: [
                    {
                        query: Queries.get({ component: "UserContext", name: "component" }),
                        variables: { userId: currentUser.id },
                    },
                ],
                awaitRefetchQueries: true,
            }
        );

        if (!result.data?.create_board.ok) {
            throw new Error((result.data?.create_board.error as any).code);
        }

        const board = result.data?.create_board.board!;

        await onCreate?.({
            id: board.id,
            displayName: board.display_name,
            slug: board.slug,
        });

        setNewBoardDisplayName("");
        dialog.close();
    }, [
        createBoard,
        currentUser.id,
        dialog,
        isOnline,
        newBoardAccessType,
        newBoardDisplayName,
        onCreate,
    ]);

    return (
        <Dialog
            title={`Create a ${nomenclature.space.singular.toLowerCase()}`}
            isOpen={isOpen}
            onClose={dialog.close}
            className={styles.dialog}
        >
            <Dialog.Body>
                <p>
                    Stay organized by using separate {nomenclature.space.plural.toLowerCase()} for
                    departments, major projects, or different categories of work.
                </p>
                <label
                    className={classNames(styles.topLevelLabel, styles.nameLabel)}
                    htmlFor="new-board-name"
                >
                    Name
                </label>
                <TextInputBorderBox
                    id="new-board-name"
                    className={styles.newBoardNameInput}
                    autoFocus
                    placeholder="e.g. hiring"
                    fill
                    onChange={e => setNewBoardDisplayName(e.target.value)}
                    onKeyboardSubmit={submission.watch(handleSubmit)}
                    value={newBoardDisplayName}
                />
                <label
                    className={classNames(styles.topLevelLabel, styles.visibilityLabel)}
                    htmlFor="new-board-access-type"
                >
                    Visibility
                </label>
                <BoardAccessTypeRadio
                    boardAccessType={newBoardAccessType}
                    className={styles.boardAccessTypeRadio}
                    id="new-board-access-type"
                    instrumentation={null}
                    orgDisplayName={currentUser.org.display_name}
                    setBoardAccessType={setNewBoardAccessType}
                />
            </Dialog.Body>

            <Dialog.Footer>
                <Dialog.FooterActions>
                    <div className={styles.footerActionsWrapper}>
                        <BorderButton
                            className={classNames(styles.button, styles.cancelButton)}
                            content="Cancel"
                            onClick={dialog.close}
                            instrumentation={{
                                elementName: "create_board_dialog.cancel_btn",
                            }}
                        />
                        <BorderButton
                            className={classNames(styles.button, styles.createButton)}
                            data-cy="create-board-dialog-submit-btn"
                            brandCta
                            content="Create"
                            disabled={!newBoardDisplayName.trim()}
                            loading={submission.isInFlight}
                            onClick={submission.watch(handleSubmit)}
                            instrumentation={{
                                elementName: "create_board_dialog.submit_btn",
                            }}
                        />
                    </div>
                </Dialog.FooterActions>
            </Dialog.Footer>
        </Dialog>
    );
}
