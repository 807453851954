import React, { useCallback, useEffect, useMemo, useState } from "react";

import {
    CommonEnumValue,
    CommonEnums,
    DbColumnTypes,
    ValueOf,
    isDefined,
    sortStages,
} from "c9r-common";
import classNames from "classnames";
import stringify from "fast-json-stable-stringify";
import { DragDropContext, Draggable, DropResult, Droppable } from "react-beautiful-dnd";
import { useRecoilValue } from "recoil";

import { Config } from "Config";
import { networkStatusState } from "components/monitors/NetworkStatusMonitor";
import {
    BoardAccessTypeRadio,
    BoardAccessTypeRadioProps,
} from "components/shared/BoardAccessTypeRadio";
import { BoardSettings } from "components/shared/BoardSettings";
import {
    UserSelector,
    UserSelectorItem,
    UserSelectorItemType,
} from "components/shared/UserSelector";
import { Avatar } from "components/ui/common/Avatar";
import { AppToaster } from "components/ui/core/AppToaster";
import { BorderAnchorButton } from "components/ui/core/BorderAnchorButton";
import { BorderButton } from "components/ui/core/BorderButton";
import { Dialog, dialogStateFamily, useDialogSingleton } from "components/ui/core/Dialog";
import { Icon } from "components/ui/core/Icon";
import { MenuPopover } from "components/ui/core/MenuPopover";
import { TextInputBorderBox } from "components/ui/core/TextInputBorderBox";
import { Tooltip } from "components/ui/core/Tooltip";
import { useMutations } from "contexts/MutationsContext";
import { useCurrentUser } from "contexts/UserContext";
import { useArchiveBoardDialog } from "dialogs/ArchiveBoardDialog";
import {
    MakeBoardPrivateDialog,
    useMakeBoardPrivateDialogState,
} from "dialogs/MakeBoardPrivateDialog";
import { CssClasses } from "lib/Constants";
import { dragAndDropEntity } from "lib/DragAndDrop";
import { moveToPositionByIndex } from "lib/EntityPositioning";
import { Enums } from "lib/Enums";
import { generateFakeStringId, isFakeId } from "lib/GraphQL";
import { useAsyncWatcher, useToggle } from "lib/Hooks";
import { useNomenclature } from "lib/Nomenclature";
import { useHistory } from "lib/Routing";
import { useUrlBuilders } from "lib/Urls";
import { getFragmentData, gql, makeFragmentData } from "lib/graphql/__generated__";
import {
    Avatar_userFragmentDoc,
    EditBoardDialog_boardFragment,
    EditBoardDialog_userFragment,
} from "lib/graphql/__generated__/graphql";
import { createCtx } from "lib/react/Context";

import styles from "./EditBoardDialog.module.scss";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const fragments = {
    board: gql(/* GraphQL */ `
        fragment EditBoardDialog_board on boards {
            id
            access_type
            archived_at
            display_name
            settings
            slug

            attached_users {
                user {
                    id

                    ...EditBoardDialog_user
                }
            }

            stages(where: { deleted_at: { _is_null: true } }) {
                id
                board_pos
                display_name
                is_empty
                role
            }
        }
    `),

    user: gql(/* GraphQL */ `
        fragment EditBoardDialog_user on users {
            id
            name
            role

            identity {
                id
                email_address
            }

            ...Avatar_user
            ...UserFilter_user
        }
    `),
};

type TCode = DbColumnTypes.BoardsSettings[typeof CommonEnums.BoardSettingType.CODE];
type TDueDates = DbColumnTypes.BoardsSettings[typeof CommonEnums.BoardSettingType.DUE_DATES];
type TSizes = DbColumnTypes.BoardsSettings[typeof CommonEnums.BoardSettingType.SIZES];

type BoardMember =
    | { type: "EXISTING_USER"; user: EditBoardDialog_userFragment }
    | { type: "NEW_GUEST"; emailAddress: string };

function areBoardMembersEqual(a: BoardMember, b: BoardMember) {
    return (
        (a.type === "EXISTING_USER" && b.type === "EXISTING_USER" && a.user.id === b.user.id) ||
        (a.type === "NEW_GUEST" && b.type === "NEW_GUEST" && a.emailAddress === b.emailAddress)
    );
}

type EditingBoardStage = EditBoardDialog_boardFragment["stages"][number] & {
    board_pos: number;
    _is_dirty?: boolean;
    _should_autofocus?: boolean;
};

type BoardSetupState = {
    accessType: CommonEnumValue<"BoardAccessType">;
    code: TCode;
    displayName: string;
    dueDates: TDueDates;
    members: BoardMember[];
    sizes: TSizes;
    stages: EditingBoardStage[];
};

function isDisplayNameValid({ displayName }: { displayName: string }) {
    return !!displayName.trim();
}

type NewGuestInvitationResult = {
    ok: boolean;
};

type EditBoardContextValue = {
    addBoardMember: ({ boardMember }: { boardMember: BoardMember }) => void;
    archiveBoard: () => Promise<void>;
    createStage: () => void;
    deleteStage: ({ stageId }: { stageId: string }) => void;
    desiredBoardSetupState: BoardSetupState | undefined;
    existingBoardSetupState: BoardSetupState | undefined;
    isSomeExistingGuest: boolean;
    isSomeNewGuest: boolean;
    isSomeOtherChange: boolean;
    moveStage: ({ stageId, boardPos }: { stageId: string; boardPos: number }) => void;
    newGuestCount: number;
    removeBoardMember: ({ boardMember }: { boardMember: BoardMember }) => void;
    renameBoard: ({ displayName }: { displayName: string }) => void;
    renameStage: ({ stageId, displayName }: { stageId: string; displayName: string }) => void;
    saveChanges: () => Promise<{ newGuestInvitationResults: NewGuestInvitationResult[] }>;
    startEditing: (board: EditBoardDialog_boardFragment) => void;
    unarchiveBoard: () => Promise<void>;
    updateBoardAccessType: ({
        accessType,
    }: {
        accessType: CommonEnumValue<"BoardAccessType">;
    }) => void;
    updateBoardCode: ({ code }: { code: TCode }) => void;
    updateBoardDueDates: ({ dueDates }: { dueDates: TDueDates }) => void;
    updateBoardSizes: ({ sizes }: { sizes: TSizes }) => void;
};

const [useEditBoard, ContextProvider] = createCtx<EditBoardContextValue>();

type EditBoardProviderProps = {
    children: React.ReactNode;
};

function EditBoardProvider({ children }: EditBoardProviderProps) {
    const currentUser = useCurrentUser();
    const [boardId, setBoardId] = useState<string>();
    const [existingBoardSetupState, setExistingBoardSetupState] = useState<BoardSetupState>();
    const [desiredBoardSetupState, setDesiredBoardSetupState] = useState<BoardSetupState>();
    const {
        renameBoard: renameBoardMutation,
        makeBoardPrivate: makeBoardPrivateMutation,
        inviteGuestUser: inviteGuestUserMutation,
        updateBoardMembers: updateBoardMembersMutation,
        updateBoardSettings: updateBoardSettingsMutation,
        archiveBoard: archiveBoardMutation,
        unarchiveBoard: unarchiveBoardMutation,
        createStage: createStageMutation,
        updateStage: updateStageMutation,
        deleteStage: deleteStageMutation,
    } = useMutations();

    const startEditing = useCallback((board: EditBoardDialog_boardFragment) => {
        setBoardId(board.id);

        const attachedUsers = board.attached_users.map(au =>
            getFragmentData(fragments.user, au.user)
        );

        const _existingBoardSetupState = {
            accessType: board.access_type as CommonEnumValue<"BoardAccessType">,
            code: board.settings[CommonEnums.BoardSettingType.CODE],
            displayName: board.display_name,
            dueDates: board.settings[CommonEnums.BoardSettingType.DUE_DATES],
            members: (board.access_type === CommonEnums.BoardAccessType.PUBLIC
                ? // A user may have been attached as a guest and later promoted.
                  attachedUsers.filter(user => user.role === CommonEnums.UserRole.USER_ORG_GUEST)
                : attachedUsers
            ).map(user => ({ type: "EXISTING_USER", user } as BoardMember)),
            sizes: board.settings[CommonEnums.BoardSettingType.SIZES],
            stages: board.stages.concat().sort(sortStages()) as EditingBoardStage[],
        };

        setExistingBoardSetupState({ ..._existingBoardSetupState });
        setDesiredBoardSetupState({ ..._existingBoardSetupState });
    }, []);

    const renameBoard = useCallback(({ displayName }: { displayName: string }) => {
        setDesiredBoardSetupState(prev => prev && { ...prev, displayName });
    }, []);

    const updateBoardAccessType = useCallback(
        ({ accessType }: { accessType: CommonEnumValue<"BoardAccessType"> }) => {
            const currentUserAsBoardMember = {
                type: "EXISTING_USER",
                user: getFragmentData(fragments.user, currentUser),
            } as BoardMember;

            setDesiredBoardSetupState(
                prev =>
                    prev &&
                    existingBoardSetupState && {
                        ...prev,
                        accessType,
                        members:
                            accessType === CommonEnums.BoardAccessType.PRIVATE
                                ? existingBoardSetupState.members
                                      .filter(
                                          member =>
                                              !areBoardMembersEqual(
                                                  member,
                                                  currentUserAsBoardMember
                                              )
                                      )
                                      .concat(currentUserAsBoardMember)
                                : existingBoardSetupState.members,
                    }
            );
        },
        [currentUser, existingBoardSetupState]
    );

    const addBoardMember = useCallback(({ boardMember }: { boardMember: BoardMember }) => {
        setDesiredBoardSetupState(
            prev =>
                prev && {
                    ...prev,
                    members: prev.members
                        .filter(member => !areBoardMembersEqual(member, boardMember))
                        .concat(boardMember),
                }
        );
    }, []);

    const removeBoardMember = useCallback(({ boardMember }: { boardMember: BoardMember }) => {
        setDesiredBoardSetupState(
            prev =>
                prev && {
                    ...prev,
                    members: prev.members.filter(
                        member => !areBoardMembersEqual(member, boardMember)
                    ),
                }
        );
    }, []);

    const updateBoardCode = useCallback(({ code }: { code: TCode }) => {
        setDesiredBoardSetupState(prev => prev && { ...prev, code });
    }, []);

    const updateBoardDueDates = useCallback(({ dueDates }: { dueDates: TDueDates }) => {
        setDesiredBoardSetupState(prev => prev && { ...prev, dueDates });
    }, []);

    const updateBoardSizes = useCallback(({ sizes }: { sizes: TSizes }) => {
        setDesiredBoardSetupState(prev => prev && { ...prev, sizes });
    }, []);

    const archiveBoard = useCallback(async () => {
        if (boardId) {
            await archiveBoardMutation({ boardId });
        }
    }, [archiveBoardMutation, boardId]);

    const unarchiveBoard = useCallback(async () => {
        if (boardId) {
            await unarchiveBoardMutation({ boardId });
        }
    }, [unarchiveBoardMutation, boardId]);

    const createStage = useCallback(() => {
        setDesiredBoardSetupState(
            prev =>
                prev && {
                    ...prev,
                    stages: [
                        ...prev.stages,
                        {
                            id: generateFakeStringId(),
                            board_pos: moveToPositionByIndex({
                                sortedEntities: prev.stages,
                                posFieldName: "board_pos",
                                toIndex: prev.stages.length - 1,
                            }),
                            display_name: "New stage",
                            is_empty: true,
                            role: CommonEnums.StageRole.IMPLEMENTATION,
                            _should_autofocus: true,
                        },
                    ].sort(sortStages()),
                }
        );
    }, []);

    const renameStage = useCallback(
        ({ stageId, displayName }: { stageId: string; displayName: string }) => {
            setDesiredBoardSetupState(
                prev =>
                    prev && {
                        ...prev,
                        stages: prev.stages.map(stage => {
                            if (stage.id !== stageId) {
                                return stage;
                            }

                            return {
                                ...stage,
                                display_name: displayName,
                                _is_dirty: true,
                            };
                        }),
                    }
            );
        },
        []
    );

    const moveStage = useCallback(
        ({ stageId, boardPos }: { stageId: string; boardPos: number }) => {
            setDesiredBoardSetupState(
                prev =>
                    prev && {
                        ...prev,
                        stages: prev.stages
                            .map(stage => {
                                if (stage.id !== stageId) {
                                    return stage;
                                }

                                return {
                                    ...stage,
                                    board_pos: boardPos,
                                    _is_dirty: true,
                                };
                            })
                            .sort(sortStages()),
                    }
            );
        },
        []
    );

    const deleteStage = useCallback(({ stageId }: { stageId: string }) => {
        setDesiredBoardSetupState(
            prev => prev && { ...prev, stages: prev.stages.filter(stage => stage.id !== stageId) }
        );
    }, []);

    const buildNormalizedPartialBoardSetupState = useCallback(
        ({ boardSetupState }: { boardSetupState: BoardSetupState | undefined }) =>
            boardSetupState
                ? stringify({
                      ...boardSetupState,
                      members: boardSetupState.members
                          .map(member =>
                              member.type === "EXISTING_USER" ? { userId: member.user.id } : null
                          )
                          .filter(isDefined)
                          .sort((a, b) => a.userId - b.userId),
                      stages: boardSetupState.stages.map((stage, index) => ({
                          ...stage,
                          board_pos: index,
                          _is_dirty: undefined,
                      })),
                  })
                : undefined,
        []
    );

    const newGuestCount = useMemo(
        () =>
            desiredBoardSetupState?.members.filter(member => member.type === "NEW_GUEST").length ??
            0,
        [desiredBoardSetupState?.members]
    );

    const isSomeNewGuest = !!newGuestCount;

    const isSomeOtherChange = useMemo(
        () =>
            buildNormalizedPartialBoardSetupState({ boardSetupState: desiredBoardSetupState }) !==
            buildNormalizedPartialBoardSetupState({ boardSetupState: existingBoardSetupState }),
        [buildNormalizedPartialBoardSetupState, desiredBoardSetupState, existingBoardSetupState]
    );

    const isSomeExistingGuest = useMemo(
        () =>
            !!desiredBoardSetupState?.members.some(
                member =>
                    member.type === "EXISTING_USER" &&
                    member.user.role === CommonEnums.UserRole.USER_ORG_GUEST
            ),
        [desiredBoardSetupState?.members]
    );

    const saveChanges = useCallback<
        () => Promise<{ newGuestInvitationResults: NewGuestInvitationResult[] }>
    >(async () => {
        const isEditing = boardId && existingBoardSetupState && desiredBoardSetupState;

        if (
            !isEditing ||
            !isDisplayNameValid({ displayName: desiredBoardSetupState.displayName })
        ) {
            return { newGuestInvitationResults: [] };
        }

        const { stages } = desiredBoardSetupState;

        const finalStages = stages.map(stage => {
            const role =
                stage === stages[stages.length - 1]
                    ? CommonEnums.StageRole.COMPLETE
                    : stage === stages[0]
                    ? CommonEnums.StageRole.PRE_IMPLEMENTATION
                    : CommonEnums.StageRole.IMPLEMENTATION;

            return {
                ...stage,
                role,
                _is_dirty: stage._is_dirty || role !== stage.role,
            };
        });

        const stagesToCreate = finalStages.filter(stage => isFakeId(stage.id));
        const stagesToUpdate = finalStages.filter(
            stage => !isFakeId(stage.id) && !!stage._is_dirty
        );
        const stageIdsToDelete = existingBoardSetupState.stages
            .map(s => s.id)
            .filter(id => !stages.map(s => s.id).includes(id));

        const newGuestEmailAddresses = desiredBoardSetupState.members
            .map(member => (member.type === "NEW_GUEST" ? member.emailAddress : null))
            .filter(isDefined);

        const invitationResults = await Promise.all(
            newGuestEmailAddresses.map(emailAddress => inviteGuestUserMutation({ emailAddress }))
        );

        const newGuestUserIds = invitationResults
            .map(result => result.data?.invite_user.user_id)
            .filter(isDefined);

        const desiredMemberUserIds = desiredBoardSetupState.members
            .map(member => (member.type === "EXISTING_USER" ? member.user.id : null))
            .filter(isDefined)
            .concat(newGuestUserIds);

        const existingMemberUserIds = existingBoardSetupState.members
            .map(member => (member.type === "EXISTING_USER" ? member.user.id : null))
            .filter(isDefined);

        await Promise.all([
            desiredBoardSetupState.displayName !== existingBoardSetupState.displayName
                ? renameBoardMutation({ boardId, displayName: desiredBoardSetupState.displayName })
                : null,

            desiredBoardSetupState.accessType === CommonEnums.BoardAccessType.PRIVATE &&
            existingBoardSetupState.accessType === CommonEnums.BoardAccessType.PUBLIC
                ? makeBoardPrivateMutation({ boardId, userId: currentUser.id })
                : null,

            updateBoardMembersMutation({
                boardId,
                userIdsToAdd: desiredMemberUserIds.filter(
                    id => !existingMemberUserIds.includes(id)
                ),
                userIdsToRemove: existingMemberUserIds.filter(
                    id => !desiredMemberUserIds.includes(id)
                ),
            }),

            stringify(desiredBoardSetupState.code) !== stringify(existingBoardSetupState.code)
                ? updateBoardSettingsMutation({
                      boardId,
                      code: desiredBoardSetupState.code,
                  })
                : null,

            stringify(desiredBoardSetupState.dueDates) !==
            stringify(existingBoardSetupState.dueDates)
                ? updateBoardSettingsMutation({
                      boardId,
                      dueDates: desiredBoardSetupState.dueDates,
                  })
                : null,

            stringify(desiredBoardSetupState.sizes) !== stringify(existingBoardSetupState.sizes)
                ? updateBoardSettingsMutation({
                      boardId,
                      sizes: desiredBoardSetupState.sizes,
                  })
                : null,

            // As of April 2023, we found that on one dev's local environment, the following
            // sequence of actions would result in the app crashing due to a database
            // serializability failure:
            //   1. Open a workspace.
            //   2. Open the workspace setup dialog.
            //   3. Create a new stage.
            //   4. Ensure that the new stage is the last stage.
            //   5. Save.
            //   6. Open the workspace setup dialog.
            //   7. Delete the stage that was just created.
            //   8. Save.
            // (Note that deletion of the last stage requires both the deletion of that stage as
            // well as an update of the role of the penultimate stage to "complete".) Manually
            // serializing mutations by moving them into separate `Promise.all`s by mutation
            // "type" (i.e. create, delete and update) resolved the issue. However, we later found
            // that with or without this change, the following sequence of actions would also
            // result in the app crashing due to a database serializability failure:
            //   1. Open a workspace.
            //   2. Open the workspace setup dialog.
            //   3. Create two new stages.
            //   5. Save.
            //   6. Open the workspace setup dialog.
            //   7. Delete the two stages that were just created.
            //   8. Save.
            // We were not able to reproduce either issue in production or in another dev's local
            // environment, and ultimately decided to undo the change described above.

            ...stagesToCreate.map(stage =>
                createStageMutation({
                    boardId,
                    displayName: stage.display_name,
                    boardPos: stage.board_pos,
                    role: stage.role,
                })
            ),

            ...stagesToUpdate.map(stage =>
                updateStageMutation({
                    stageId: stage.id,
                    displayName: stage.display_name.trim(),
                    boardPos: stage.board_pos,
                    role: stage.role,
                })
            ),

            ...stageIdsToDelete.map(stageId => deleteStageMutation({ stageId })),
        ]);

        return {
            newGuestInvitationResults: invitationResults.map(result => ({
                ok: !!result.data?.invite_user.ok,
            })),
        };
    }, [
        boardId,
        createStageMutation,
        currentUser.id,
        deleteStageMutation,
        desiredBoardSetupState,
        existingBoardSetupState,
        inviteGuestUserMutation,
        makeBoardPrivateMutation,
        renameBoardMutation,
        updateBoardMembersMutation,
        updateBoardSettingsMutation,
        updateStageMutation,
    ]);

    const value = useMemo(
        () => ({
            addBoardMember,
            archiveBoard,
            createStage,
            deleteStage,
            desiredBoardSetupState,
            existingBoardSetupState,
            isSomeExistingGuest,
            isSomeNewGuest,
            isSomeOtherChange,
            moveStage,
            newGuestCount,
            removeBoardMember,
            renameBoard,
            renameStage,
            saveChanges,
            startEditing,
            unarchiveBoard,
            updateBoardAccessType,
            updateBoardCode,
            updateBoardDueDates,
            updateBoardSizes,
        }),
        [
            addBoardMember,
            archiveBoard,
            createStage,
            deleteStage,
            desiredBoardSetupState,
            existingBoardSetupState,
            isSomeExistingGuest,
            isSomeNewGuest,
            isSomeOtherChange,
            moveStage,
            newGuestCount,
            removeBoardMember,
            renameBoard,
            renameStage,
            saveChanges,
            startEditing,
            unarchiveBoard,
            updateBoardAccessType,
            updateBoardCode,
            updateBoardDueDates,
            updateBoardSizes,
        ]
    );

    return <ContextProvider value={value}>{children}</ContextProvider>;
}

const TabId = {
    GENERAL: "GENERAL",
    VISIBILITY: "VISIBILITY",
    MEMBERS: "MEMBERS",
    OPTIONS: "OPTIONS",
} as const;

type TabLabelProps = {
    active?: boolean;
    disabled?: boolean;
    onClick: () => void;
    text: string;
};

function TabLabel({ active, disabled, onClick, text }: TabLabelProps) {
    return (
        <BorderButton
            className={classNames(
                styles.tabLabel,
                active && styles.active,
                disabled && styles.disabled
            )}
            content={
                <div className={styles.tabLabelTextWrapper} data-text={text}>
                    {text}
                </div>
            }
            disabled={disabled}
            instrumentation={null}
            minimal
            onClick={onClick}
            sharpest
            useHoverEffect={false}
        />
    );
}

type TabLabelsProps = {
    activeTabId: ValueOf<typeof TabId>;
    setActiveTabId: (tabId: ValueOf<typeof TabId>) => void;
};

function TabLabels({ activeTabId, setActiveTabId }: TabLabelsProps) {
    const { desiredBoardSetupState } = useEditBoard();
    const currentUser = useCurrentUser();
    const isOnline = useRecoilValue(networkStatusState);

    if (!desiredBoardSetupState) {
        return null;
    }

    const { accessType, members } = desiredBoardSetupState;

    const membersTabLabel = (
        <Tooltip
            content="Managing workspace members is not available offline."
            disabled={!!isOnline}
            small
            wide
        >
            <TabLabel
                active={activeTabId === TabId.MEMBERS}
                disabled={!isOnline}
                onClick={() => setActiveTabId(TabId.MEMBERS)}
                text={`${
                    accessType === CommonEnums.BoardAccessType.PRIVATE ? "Members" : "Guests"
                } (${members.length})`}
            />
        </Tooltip>
    );

    return (
        <div className={styles.tabLabels}>
            <TabLabel
                active={activeTabId === TabId.GENERAL}
                onClick={() => setActiveTabId(TabId.GENERAL)}
                text="General"
            />
            <TabLabel
                active={activeTabId === TabId.VISIBILITY}
                onClick={() => setActiveTabId(TabId.VISIBILITY)}
                text="Visibility"
            />
            {accessType === CommonEnums.BoardAccessType.PRIVATE ||
            currentUser.role === CommonEnums.UserRole.USER_ORG_ADMIN
                ? membersTabLabel
                : null}
            <TabLabel
                active={activeTabId === TabId.OPTIONS}
                onClick={() => setActiveTabId(TabId.OPTIONS)}
                text="Options"
            />
        </div>
    );
}

type GeneralTabProps = {
    handleSubmit: () => Promise<void>;
};

function GeneralTab({ handleSubmit }: GeneralTabProps) {
    const {
        createStage,
        deleteStage,
        desiredBoardSetupState,
        moveStage,
        renameBoard,
        renameStage,
    } = useEditBoard();
    const submission = useAsyncWatcher();

    if (!desiredBoardSetupState) {
        return null;
    }

    const { displayName, stages } = desiredBoardSetupState;

    const hasMinimumNumberOfStages = stages.length <= Config.minStagesPerBoard;

    const handleDragEnd = (result: DropResult) => {
        const { draggableId, destination, source } = result;

        if (!destination) {
            return;
        }

        if (destination.index === source.index) {
            return;
        }

        const stageId = dragAndDropEntity.getRootId(draggableId);
        const toIndex = destination.index;

        const boardPos = moveToPositionByIndex({
            sortedEntities: stages,
            posFieldName: "board_pos",
            toIndex,
            entityId: stageId,
        });

        if (!boardPos) {
            return;
        }

        moveStage({ stageId, boardPos });
    };

    return (
        <>
            <section>
                <h2>
                    <label htmlFor="new-board-name">Name</label>
                </h2>
                <TextInputBorderBox
                    className={classNames(
                        CssClasses.ALWAYS_SHOW_FOCUS_INDICATOR,
                        styles.boardNameInput
                    )}
                    id="new-board-name"
                    autoFocus
                    placeholder="e.g. hiring"
                    fill
                    onChange={e => renameBoard({ displayName: e.target.value })}
                    onKeyboardSubmit={submission.watch(handleSubmit)}
                    value={displayName}
                />
            </section>

            <section>
                <h2>Workflow</h2>
                <div className={styles.workflow}>
                    <DragDropContext onDragEnd={handleDragEnd}>
                        <Droppable droppableId="EDIT_BOARD_DIALOG">
                            {droppableProvided => (
                                <ul
                                    className={styles.stagesList}
                                    ref={droppableProvided.innerRef}
                                    {...droppableProvided.droppableProps}
                                >
                                    {stages.map((stage, i) => (
                                        <Draggable
                                            key={stage.id}
                                            draggableId={dragAndDropEntity.getDndId(
                                                Enums.DndEntityTypes.STAGE,
                                                stage.id
                                            )}
                                            index={i}
                                        >
                                            {draggableProvided => (
                                                <li
                                                    className={classNames(
                                                        styles.stageListItem,
                                                        CssClasses.ALWAYS_SHOW_FOCUS_INDICATOR
                                                    )}
                                                    {...draggableProvided.draggableProps}
                                                    ref={draggableProvided.innerRef}
                                                >
                                                    <Icon
                                                        className={styles.dragHandle}
                                                        icon="dragHandleLarge"
                                                        iconSet="c9r"
                                                        {...draggableProvided.dragHandleProps}
                                                    />
                                                    <TextInputBorderBox
                                                        className={styles.stageNameTextInput}
                                                        value={stage.display_name}
                                                        onChange={e =>
                                                            renameStage({
                                                                stageId: stage.id,
                                                                displayName: e.target.value,
                                                            })
                                                        }
                                                        autoFocus={!!stage._should_autofocus}
                                                    />
                                                    <Tooltip
                                                        disabled={
                                                            !!(
                                                                stage.is_empty &&
                                                                !hasMinimumNumberOfStages
                                                            )
                                                        }
                                                        content={
                                                            stage.is_empty &&
                                                            hasMinimumNumberOfStages
                                                                ? "Your workspace needs to have at least two stages."
                                                                : "Only empty stages can be deleted."
                                                        }
                                                        placement="top"
                                                        small
                                                        showFast
                                                        wide
                                                    >
                                                        <BorderAnchorButton
                                                            content={
                                                                <Icon
                                                                    icon="trash-2"
                                                                    iconSet="lucide"
                                                                    iconSize={14}
                                                                />
                                                            }
                                                            contentClassName={
                                                                styles.trashIconWrapper
                                                            }
                                                            disabled={
                                                                !stage.is_empty ||
                                                                hasMinimumNumberOfStages
                                                            }
                                                            flushRight
                                                            minimal
                                                            onClick={() => {
                                                                deleteStage({
                                                                    stageId: stage.id,
                                                                });
                                                            }}
                                                            instrumentation={{
                                                                elementName:
                                                                    "edit_board_dialog.delete_stage_btn",
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </li>
                                            )}
                                        </Draggable>
                                    ))}
                                    {droppableProvided.placeholder}
                                </ul>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
                <BorderButton
                    content={
                        <>
                            <Icon icon="plus" iconSet="lucide" iconSize={20} strokeWeight={1} />
                            <div>Add stage</div>
                        </>
                    }
                    contentClassName={styles.addStageButtonContent}
                    flushTop
                    minimal
                    onClick={() => createStage()}
                    instrumentation={{
                        elementName: "edit_board_dialog.create_stage_btn",
                    }}
                />
            </section>
        </>
    );
}

type VisibilityTabProps = {
    handleArchiveBoardSubmit: () => Promise<void>;
    isBoardArchived: boolean;
} & Pick<BoardAccessTypeRadioProps, "orgDisplayName">;

function VisibilityTab({
    handleArchiveBoardSubmit,
    isBoardArchived,
    ...boardAccessTypeRadioProps
}: VisibilityTabProps) {
    const {
        desiredBoardSetupState,
        existingBoardSetupState,
        updateBoardAccessType,
    } = useEditBoard();
    const archiveBoardDialog = useArchiveBoardDialog();
    const { nomenclature } = useNomenclature();

    if (!desiredBoardSetupState || !existingBoardSetupState) {
        return null;
    }

    const { accessType: desiredAccessType } = desiredBoardSetupState;
    const { accessType: existingAccessType } = existingBoardSetupState;

    return (
        <>
            <BoardAccessTypeRadio
                boardAccessType={desiredAccessType}
                className={styles.boardAccessTypeRadio}
                disablePublic={existingAccessType === CommonEnums.BoardAccessType.PRIVATE}
                instrumentation={null}
                setBoardAccessType={accessType => updateBoardAccessType({ accessType })}
                {...boardAccessTypeRadioProps}
            />
            <BorderButton
                className={styles.archiveBoardButton}
                contentClassName={styles.archiveBoardButtonContent}
                content={
                    <>
                        <Icon
                            icon={isBoardArchived ? "unarchive" : "archive"}
                            iconSet="c9r"
                            iconSize={18}
                        />
                        {isBoardArchived ? "Unarchive" : "Archive"}{" "}
                        {nomenclature.space.singular.toLowerCase()} for everyone
                    </>
                }
                onClick={() =>
                    !isBoardArchived
                        ? archiveBoardDialog.openWithProps({
                              boardDisplayName: existingBoardSetupState.displayName,
                              onSubmit: handleArchiveBoardSubmit,
                          })
                        : handleArchiveBoardSubmit()
                }
                instrumentation={{
                    elementName: "edit_board_dialog.archive_board_btn",
                }}
            />
        </>
    );
}

type MemberAdderProps = {
    query: string;
    setQuery: (query: string) => void;
};

function MemberAdder({ query, setQuery }: MemberAdderProps) {
    const { addBoardMember, desiredBoardSetupState, newGuestCount } = useEditBoard();
    const currentUser = useCurrentUser();
    const popover = useToggle();

    const handleSelect = useCallback(
        (item: UserSelectorItem) => {
            addBoardMember({
                boardMember:
                    item.type === UserSelectorItemType.USER
                        ? {
                              type: "EXISTING_USER",
                              user: getFragmentData(fragments.user, item.user),
                          }
                        : { type: "NEW_GUEST", emailAddress: item.emailAddress },
            });
        },
        [addBoardMember]
    );

    const handleClose = useCallback(() => {
        popover.close();
        setQuery("");
    }, [popover, setQuery]);

    if (!desiredBoardSetupState) {
        return null;
    }

    const { accessType, members } = desiredBoardSetupState;

    const users = currentUser.org.users.map(user => getFragmentData(fragments.user, user));

    const excludedUserIds = members
        .map(member => (member.type === "EXISTING_USER" ? member.user.id : null))
        .filter(isDefined)
        .concat(
            accessType === CommonEnums.BoardAccessType.PUBLIC
                ? users
                      .filter(user => user.role !== CommonEnums.UserRole.USER_ORG_GUEST)
                      .map(user => user.id)
                : []
        );

    const excludedEmailAddresses = [
        ...users.map(user => user.identity?.email_address).filter(isDefined),
        ...members
            .map(member => (member.type === "NEW_GUEST" ? member.emailAddress : null))
            .filter(isDefined),
    ];

    return (
        <MenuPopover
            canEscapeKeyClose={!query}
            popoverClassName={styles.userSelectorPopover}
            content={
                <UserSelector
                    disableInviteGuest={newGuestCount > Config.maxInvitesPerBatch}
                    excludedEmailAddresses={excludedEmailAddresses}
                    excludedUserIds={excludedUserIds}
                    filterIconProps={{
                        icon: "user-plus",
                        iconSet: "lucide",
                        iconSize: 20,
                        strokeWidthAbsolute: 1,
                    }}
                    onClose={handleClose}
                    onQueryChange={setQuery}
                    onSelect={handleSelect}
                    query={query}
                    showGuestIndicator
                />
            }
            hasBackdrop={!!query}
            isOpen={popover.isOpen}
            modifiers={{
                offset: {
                    enabled: true,
                    options: {
                        offset: [0, -63],
                    },
                },
            }}
            onClose={handleClose}
            placement="bottom"
            targetClassName={styles.addMemberButtonWrapper}
            transitionDuration={0}
        >
            <BorderButton
                alignContentLeft
                className={styles.addMemberButton}
                content={
                    accessType === CommonEnums.BoardAccessType.PUBLIC
                        ? "Add a guest"
                        : `Add a team member${
                              currentUser.role === CommonEnums.UserRole.USER_ORG_ADMIN
                                  ? " or invite a guest"
                                  : ""
                          }`
                }
                fill
                iconGap={8}
                instrumentation={null}
                leftIconProps={{
                    icon: "user-plus",
                    iconSet: "lucide",
                    iconSize: 18,
                    strokeWidthAbsolute: 1,
                }}
                onClick={() => popover.open()}
            />
        </MenuPopover>
    );
}

function MemberList() {
    const { desiredBoardSetupState, removeBoardMember } = useEditBoard();
    const currentUser = useCurrentUser();

    const isCurrentUser = useCallback(
        (user: EditBoardDialog_userFragment) => user.id === currentUser.id,
        [currentUser.id]
    );

    if (!desiredBoardSetupState) {
        return null;
    }

    const { members } = desiredBoardSetupState;

    const sortedMembers = members.concat().sort((a, b) => {
        if (a.type === "EXISTING_USER" && b.type === "NEW_GUEST") {
            return -1;
        }

        if (a.type === "NEW_GUEST" && b.type === "EXISTING_USER") {
            return 1;
        }

        if (a.type === "EXISTING_USER" && b.type === "EXISTING_USER") {
            return isCurrentUser(a.user)
                ? -1
                : isCurrentUser(b.user)
                ? 1
                : a.user.name.localeCompare(b.user.name);
        }

        if (a.type === "NEW_GUEST" && b.type === "NEW_GUEST") {
            return a.emailAddress.localeCompare(b.emailAddress);
        }

        return 0;
    });

    const buildListItemData = ({ member }: { member: BoardMember }) => {
        if (member.type === "EXISTING_USER") {
            const { user } = member;

            return {
                avatarUser: user,
                listItemKey: user.id,
                memberGuestIndicator:
                    user.role === CommonEnums.UserRole.USER_ORG_GUEST ? "Guest" : "",
                memberIdentifier: `${user.name} ${isCurrentUser(user) ? " (me)" : ""}`,
                shouldShowRemoveButton: !isCurrentUser(user),
            };
        }

        const { emailAddress } = member;

        return {
            avatarUser: makeFragmentData(
                {
                    id: 0,
                    avatar_url: null,
                    full_name: emailAddress,
                    name: emailAddress,
                },
                Avatar_userFragmentDoc
            ),
            listItemKey: emailAddress,
            memberGuestIndicator: "Guest (new)",
            memberIdentifier: <b>{emailAddress}</b>,
            shouldShowRemoveButton: true,
        };
    };

    return (
        <ul className={styles.memberList}>
            {sortedMembers.map(member => {
                const {
                    avatarUser,
                    listItemKey,
                    memberGuestIndicator,
                    memberIdentifier,
                    shouldShowRemoveButton,
                } = buildListItemData({ member });

                return (
                    <li key={listItemKey} className={styles.memberListItem}>
                        <div className={styles.memberAvatarAndText}>
                            <Avatar size={32} user={avatarUser} />
                            <div className={styles.memberText}>
                                <div className={styles.memberIdentifier}>{memberIdentifier}</div>
                                <div className={styles.memberGuestIndicator}>
                                    &nbsp;&nbsp;&nbsp;{memberGuestIndicator}
                                </div>
                            </div>
                        </div>
                        {shouldShowRemoveButton ? (
                            <BorderButton
                                content="Remove"
                                contentClassName={styles.removeButton}
                                instrumentation={null}
                                minimal
                                onClick={() => removeBoardMember({ boardMember: member })}
                            />
                        ) : null}
                    </li>
                );
            })}
        </ul>
    );
}

type MembersTabProps = {
    memberAdderQuery: string;
    setMemberAdderQuery: (query: string) => void;
};

function MembersTab({ memberAdderQuery, setMemberAdderQuery }: MembersTabProps) {
    return (
        <>
            <MemberAdder query={memberAdderQuery} setQuery={setMemberAdderQuery} />
            <MemberList />
        </>
    );
}

function OptionsTab() {
    const {
        desiredBoardSetupState,
        updateBoardCode,
        updateBoardDueDates,
        updateBoardSizes,
    } = useEditBoard();

    return (
        <BoardSettings
            code={desiredBoardSetupState?.code}
            dueDates={desiredBoardSetupState?.dueDates}
            sizes={desiredBoardSetupState?.sizes}
            onCodeChange={updateBoardCode}
            onDueDatesChange={updateBoardDueDates}
            onSizesChange={updateBoardSizes}
        />
    );
}

export type EditBoardDialogProps = {
    board: EditBoardDialog_boardFragment;
    redirectOnNameChange: boolean;
};

const dialogState = dialogStateFamily<EditBoardDialogProps>("EditBoardDialog");

export function useEditBoardDialog() {
    return useDialogSingleton(dialogState);
}

function EditBoardDialogImpl() {
    const currentUser = useCurrentUser();
    const { isOpen, props } = useRecoilValue(dialogState);
    const dialog = useEditBoardDialog();
    const [activeTabId, setActiveTabId] = useState<ValueOf<typeof TabId>>(TabId.GENERAL);
    const [memberAdderQuery, setMemberAdderQuery] = useState("");
    const makeBoardPrivateDialog = useMakeBoardPrivateDialogState();
    const archiveBoardDialog = useArchiveBoardDialog();
    const submission = useAsyncWatcher();
    const { history } = useHistory();
    const { nomenclature } = useNomenclature();
    const { buildBoardUrl } = useUrlBuilders();
    const {
        archiveBoard,
        desiredBoardSetupState,
        existingBoardSetupState,
        isSomeExistingGuest,
        isSomeNewGuest,
        isSomeOtherChange,
        newGuestCount,
        saveChanges,
        startEditing,
        unarchiveBoard,
    } = useEditBoard();

    const isActive =
        isOpen && props && activeTabId && existingBoardSetupState && desiredBoardSetupState;

    const handleSubmit = useCallback(async () => {
        if (!isActive) {
            return;
        }

        const { newGuestInvitationResults } = await saveChanges();

        if (
            props.redirectOnNameChange &&
            desiredBoardSetupState.displayName !== existingBoardSetupState.displayName
        ) {
            history.replace({
                pathname: buildBoardUrl({
                    boardSlug: props.board.slug,
                    vanity: {
                        boardDisplayName: desiredBoardSetupState.displayName,
                    },
                }).pathname,
            });
        }

        dialog.close();

        if (!newGuestInvitationResults.length) {
            return;
        }

        newGuestInvitationResults.some(result => !result.ok)
            ? AppToaster.error({
                  message:
                      "Sorry, something went wrong sending your invitations. We're looking into it.",
              })
            : AppToaster.info({
                  icon: <Icon icon="check" iconSet="lucide" iconSize={24} />,
                  message: "Your invitations are on the way.",
              });
    }, [
        buildBoardUrl,
        desiredBoardSetupState?.displayName,
        dialog,
        existingBoardSetupState?.displayName,
        history,
        isActive,
        props?.board.slug,
        props?.redirectOnNameChange,
        saveChanges,
    ]);

    const maybeHandleSubmit = useCallback(async () => {
        if (!isActive) {
            return;
        }

        if (
            desiredBoardSetupState.accessType === CommonEnums.BoardAccessType.PRIVATE &&
            existingBoardSetupState.accessType === CommonEnums.BoardAccessType.PUBLIC
        ) {
            makeBoardPrivateDialog.open();

            return;
        }

        await handleSubmit();
    }, [
        desiredBoardSetupState?.accessType,
        existingBoardSetupState?.accessType,
        handleSubmit,
        isActive,
        makeBoardPrivateDialog,
    ]);

    const handleMakeBoardPrivateSubmit = useCallback(async () => {
        await handleSubmit();

        makeBoardPrivateDialog.close();
    }, [handleSubmit, makeBoardPrivateDialog]);

    const handleArchiveBoardSubmit = useCallback(async () => {
        if (!isActive) {
            return;
        }

        await (props.board.archived_at ? unarchiveBoard() : archiveBoard());

        archiveBoardDialog.close();
        dialog.close();
    }, [
        archiveBoard,
        archiveBoardDialog,
        dialog,
        isActive,
        props?.board.archived_at,
        unarchiveBoard,
    ]);

    useEffect(() => {
        if (isOpen && props) {
            setActiveTabId("GENERAL");
            startEditing(props.board);
        }
    }, [isOpen, props, startEditing]);

    const submitButtonContent = (() => {
        if (isSomeNewGuest && isSomeOtherChange) {
            return `Invite ${newGuestCount === 1 ? "1 guest" : `${newGuestCount} guests`} and save`;
        }

        if (isSomeNewGuest) {
            return `Invite ${newGuestCount === 1 ? "1 guest" : `${newGuestCount} guests`}`;
        }

        return "Save all changes";
    })();

    const shouldShowLearnAboutGuestsButton =
        activeTabId === TabId.MEMBERS &&
        (currentUser.role === CommonEnums.UserRole.USER_ORG_ADMIN || isSomeExistingGuest);

    return (
        <Dialog
            canEscapeKeyClose={!memberAdderQuery}
            className={styles.dialog}
            isOpen={isOpen}
            onClose={dialog.close}
            title={`${nomenclature.space.singular} setup`}
        >
            <Dialog.Header>
                <TabLabels activeTabId={activeTabId} setActiveTabId={setActiveTabId} />
            </Dialog.Header>

            <Dialog.Body>
                {(() => {
                    switch (activeTabId) {
                        case TabId.GENERAL: {
                            return <GeneralTab handleSubmit={handleSubmit} />;
                        }

                        case TabId.VISIBILITY: {
                            return (
                                <VisibilityTab
                                    handleArchiveBoardSubmit={handleArchiveBoardSubmit}
                                    isBoardArchived={!!props?.board.archived_at}
                                    orgDisplayName={currentUser.org.display_name}
                                />
                            );
                        }

                        case TabId.MEMBERS: {
                            return (
                                <MembersTab
                                    memberAdderQuery={memberAdderQuery}
                                    setMemberAdderQuery={setMemberAdderQuery}
                                />
                            );
                        }

                        case TabId.OPTIONS: {
                            return <OptionsTab />;
                        }

                        default: {
                            return null;
                        }
                    }
                })()}
            </Dialog.Body>

            <Dialog.Footer className={styles.footer}>
                <Dialog.FooterActions>
                    {shouldShowLearnAboutGuestsButton ? (
                        <BorderAnchorButton
                            className={styles.learnAboutGuestsButton}
                            content="Learn about guests"
                            href={Config.urls.docs.workspaceVisibility}
                            iconGap={7}
                            instrumentation={null}
                            minimal
                            rightIconProps={{
                                className: styles.learnAboutGuestsButtonIcon,
                                icon: "external-link",
                                iconSet: "lucide",
                                iconSize: 13,
                            }}
                            small
                            target="_blank"
                        />
                    ) : null}
                    <div className={styles.spacer} />
                    <BorderButton
                        content="Cancel"
                        onClick={dialog.close}
                        instrumentation={{
                            elementName: "edit_board_dialog.cancel_btn",
                        }}
                    />
                    <BorderButton
                        brandCta
                        content={submitButtonContent}
                        disabled={
                            !desiredBoardSetupState ||
                            !isDisplayNameValid({
                                displayName: desiredBoardSetupState.displayName,
                            }) ||
                            desiredBoardSetupState.stages.some(
                                stage => !stage.display_name.trim()
                            ) ||
                            (!isSomeNewGuest && !isSomeOtherChange)
                        }
                        loading={submission.isInFlight}
                        onClick={submission.watch(maybeHandleSubmit)}
                        instrumentation={{
                            elementName: "edit_board_dialog.submit_btn",
                        }}
                    />
                </Dialog.FooterActions>
            </Dialog.Footer>
            <MakeBoardPrivateDialog onSubmit={handleMakeBoardPrivateSubmit} />
        </Dialog>
    );
}

export function EditBoardDialog() {
    return (
        <EditBoardProvider>
            <EditBoardDialogImpl />
        </EditBoardProvider>
    );
}
