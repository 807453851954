import {
    CommonEnumValue,
    CommonEnums,
    MutationArgs,
    MutationSpec,
    P,
    isTextBlocker,
} from "c9r-common";
import { RichText } from "c9r-rich-text";
import { format as formatDate } from "date-fns";
import { WriteTransaction } from "replicache";
import { SetRequired } from "type-fest";

import { AppData } from "AppData";
import {
    generateDefaultPos,
    interpolateMissingPositions,
    moveToPosition,
} from "lib/EntityPositioning";
import { Log } from "lib/Log";
import { generateTicketSlug } from "lib/Slugs";
import { wouldCreateTicketCycle } from "lib/TicketGraph";
import { isDefined } from "lib/types/guards";

import { WriteApi } from "./Api";
import { EntryKeys } from "./entries/EntryKeys";

function logMissingEntity({
    mutationName,
    ...context
}: {
    mutationName: string;
} & Record<string, any>) {
    Log.error("Could not find needed entity in mutator from context", { mutationName, ...context });
}

type MutationCtx = {
    currentUserId: number;
    currentUserOrgId: number;
};

async function appendChildOfTaskToTicket(
    { ticketId, childOfTaskId }: { ticketId: string; childOfTaskId: string },
    api: WriteApi,
    ctx: MutationCtx
) {
    const ticket = await api.tickets.getByIdOrThrow({ id: ticketId });

    await api.tickets.shallowUpdateById({
        id: ticketId,
        fields: {
            child_of_tasks: ticket.child_of_tasks
                .filter(task => task.id !== childOfTaskId)
                .concat([{ __typename: "tasks", id: childOfTaskId }]),
        },
    });
}

const mutatorImpls = {
    async archiveBoard({ boardId }: MutationArgs<"archiveBoard">, api: WriteApi, ctx: MutationCtx) {
        await api.boards.shallowUpdateById({
            id: boardId,
            fields: {
                archived_at: new Date(Date.now()).toISOString(),
            },
        });
    },

    async archiveTicket(
        { ticketId }: MutationArgs<"archiveTicket">,
        api: WriteApi,
        ctx: MutationCtx
    ) {
        await api.tickets.shallowUpdateById({
            id: ticketId,
            fields: {
                archived_at: new Date(Date.now()).toISOString(),
                trashed_at: null,
            },
        });
    },

    async createChildTicketTask(
        { taskId, tasklistId, tasklistPos, childTicketId }: MutationArgs<"createChildTicketTask">,
        api: WriteApi,
        ctx: MutationCtx
    ) {
        const ticketId = (await api.tasklists.getById({ id: tasklistId }))?.ticket_id;

        if (!ticketId) {
            logMissingEntity({ mutationName: "createChildTicketTask", tasklistId });
            return;
        }

        if (
            await wouldCreateTicketCycle({
                api,
                parentTicketId: ticketId,
                childTicketId,
            })
        ) {
            throw new Error("Cycle detected");
        }

        await api.tasks.insert({
            key: EntryKeys.buildKey({
                type: EntryKeys.Type.TASK,
                ids: { taskId, ticketId },
            }),
            entry: {
                __typename: "tasks",
                id: taskId,
                assigned_to_user_id: null,
                child_ticket_id: childTicketId,
                deleted_at: null,
                due_date: null,
                is_complete: false,
                tasklist_id: tasklistId,
                tasklist_pos: tasklistPos,
                task_type: CommonEnums.TaskType.CHILD_TICKET,
                ticket_id: ticketId,
                title: null,
            },
        });

        await appendChildOfTaskToTicket(
            { ticketId: childTicketId, childOfTaskId: taskId },
            api,
            ctx
        );
    },

    async createStage(
        { stageId, boardId, displayName, boardPos, role }: MutationArgs<"createStage">,
        api: WriteApi,
        ctx: MutationCtx
    ) {
        await api.stages.insert({
            key: EntryKeys.buildKey({ type: EntryKeys.Type.STAGE, ids: { boardId, stageId } }),
            entry: {
                __typename: "stages",
                id: stageId,
                board_id: boardId,
                board_pos: boardPos,
                deleted_at: null,
                display_name: displayName,
                role: role || CommonEnums.StageRole.IMPLEMENTATION,
            },
        });
    },

    async createTask(
        { taskId, tasklistId, tasklistPos, title }: MutationArgs<"createTask">,
        api: WriteApi,
        ctx: MutationCtx
    ) {
        const ticketId = (await api.tasklists.getById({ id: tasklistId }))?.ticket_id;

        if (!ticketId) {
            logMissingEntity({ mutationName: "createTask", tasklistId });
            return;
        }

        await api.tasks.insert({
            key: EntryKeys.buildKey({
                type: EntryKeys.Type.TASK,
                ids: { taskId, ticketId },
            }),
            entry: {
                __typename: "tasks",
                id: taskId,
                assigned_to_user_id: null,
                child_ticket_id: null,
                deleted_at: null,
                due_date: null,
                is_complete: false,
                tasklist_id: tasklistId,
                tasklist_pos: tasklistPos,
                task_type: CommonEnums.TaskType.TASK,
                ticket_id: ticketId,
                title,
            },
        });
    },

    async createTasklist(
        {
            tasklistId,
            ticketId,
            stageId,
            ticketPos,
            title,
            legacyUuid,
        }: MutationArgs<"createTasklist">,
        api: WriteApi,
        ctx: MutationCtx
    ) {
        await api.tasklists.insert({
            key: EntryKeys.buildKey({
                type: EntryKeys.Type.TASKLIST,
                ids: { tasklistId, ticketId },
            }),
            entry: {
                __typename: "tasklists",
                id: tasklistId,
                ticket_id: ticketId,
                stage_id: stageId,
                added_at: new Date(Date.now()).toISOString(),
                deleted_at: null,
                ticket_pos: ticketPos,
                title,
                uuid: legacyUuid,
            },
        });
    },

    async createThread(
        {
            threadId,
            ticketId,
            assignedToUserId,
            blocker,
            comment,
            commentId,
        }: MutationArgs<"createThread">,
        api: WriteApi,
        ctx: MutationCtx
    ) {
        const now = new Date(Date.now()).toISOString();

        await api.threads.insert({
            key: EntryKeys.buildKey({
                type: EntryKeys.Type.THREAD,
                ids: { threadId, ticketId },
            }),
            entry: {
                __typename: "threads",
                id: threadId,
                opened_at: now,
                resolved_at: null,
                resolved_by_user_id: null,
                created_by_user_id: ctx.currentUserId,
                ticket_id: ticketId,

                assigned_at: null,
                assigned_by_user_id: null,
                assigned_to_user_id: null,

                blocker_added_at: null,
                blocker_author_user_id: null,
                blocker_text: null,
                blocker_ticket_id: null,
                blocker_type: null,
            },
        });

        if (isDefined(assignedToUserId)) {
            await api.threads.shallowUpdateById({
                id: threadId,
                fields: {
                    assigned_at: now,
                    assigned_by_user_id: ctx.currentUserId,
                    assigned_to_user_id: assignedToUserId,
                },
            });
        }

        if (isDefined(blocker)) {
            await api.threads.shallowUpdateById({
                id: threadId,
                fields: {
                    blocker_added_at: now,
                    blocker_author_user_id: ctx.currentUserId,
                    ...(isTextBlocker(blocker)
                        ? {
                              blocker_type: CommonEnums.BlockerType.TEXT,
                              blocker_text: blocker.value.text,
                          }
                        : {
                              blocker_type: CommonEnums.BlockerType.TICKET,
                              blocker_ticket_id: blocker.value.ticket.id,
                          }),
                },
            });
        }

        if (isDefined(comment) && isDefined(commentId)) {
            await api.comments.insert({
                key: EntryKeys.buildKey({
                    type: EntryKeys.Type.COMMENT,
                    ids: { commentId, threadId, ticketId },
                }),
                entry: {
                    __typename: "comments",
                    id: commentId,
                    posted_at: now,
                    last_edited_at: null,
                    author_user_id: ctx.currentUserId,
                    comment_json: comment,
                    comment_text: RichText.contentJsonToText(comment),
                    thread_id: threadId,
                    ticket_id: ticketId,
                },
            });
        }
    },

    async createTicket(
        {
            ticketId,
            stageId,
            stagePos,
            title,
            slug,
            contentYjs: _contentYjs,
            description: _description,
            dueDate,
            labels,
            ownerUserId,
            memberUserIds,
            attachments,
            sizeSpec,
            makeCurrentUserWatcher,
            tasklists,
        }: SetRequired<MutationArgs<"createTicket">, "stagePos">,
        api: WriteApi,
        ctx: MutationCtx
    ) {
        const now = new Date(Date.now()).toISOString();

        const boardId = (await api.stages.getById({ id: stageId }))?.board_id;

        if (!boardId) {
            return undefined;
        }

        // As per MDN, `Math.max` will fail if called with too many arguments, and the solution using `reduce` below circumvents this problem.
        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/max#getting_the_maximum_element_of_an_array
        const ref = Math.max(
            (await api.tickets.findAll())
                .map(t => parseInt(t.ref))
                .filter(r => !Number.isNaN(r))
                .reduce((a, b) => Math.max(a, b), 0) + 1,
            parseInt(AppData.currentOrg?.next_ticket_ref ?? "0")
        ).toString();

        const stagePositions = (await api.tickets.findByStageId({ stageId })).map(t => t.stage_pos);
        const minStagePos = Math.max(
            stagePositions.reduce((a, b) => Math.min(a, b), 0),
            Infinity
        );
        const maxStagePos = Math.max(
            stagePositions.reduce((a, b) => Math.max(a, b), 0),
            -Infinity
        );

        const contentYjs =
            _contentYjs ??
            RichText.hexEncodeYdocState(
                RichText.ydocToState(
                    RichText.contentJsonToYdoc({ contentJson: _description, field: "description" })
                )
            );

        await api.tickets.insert({
            key: EntryKeys.buildKey({ type: EntryKeys.Type.TICKET, ids: { ticketId } }),
            entry: {
                __typename: "tickets",
                id: ticketId,
                archived_at: null,
                board_id: boardId,
                content_yjs: contentYjs,
                created_by_user_id: ctx.currentUserId,
                due_date: dueDate ?? null,
                external_source_display_id: null,
                org_id: ctx.currentUserOrgId,
                ref,
                size_spec: sizeSpec || null,
                slug: slug ?? generateTicketSlug(),
                stage_id: stageId,
                stage_pos: moveToPosition({
                    pos: stagePos,
                    minPos: minStagePos,
                    maxPos: maxStagePos,
                }),
                title,
                trashed_at: null,
                blocker_of_threads: [],
                child_of_tasks: [],
                label_attachments: (labels ?? []).map(({ color, text }) => ({
                    __typename: "ticket_label_attachments",
                    color,
                    text,
                })),
                merge_requests: [],
                owners: ([] as ({
                    __typename: "tickets_owners";
                    added_at: string;
                    type: CommonEnumValue<"TicketOwnerType">;
                    user_id: number;
                } | null)[])
                    .concat([
                        ownerUserId
                            ? {
                                  __typename: "tickets_owners",
                                  added_at: now,
                                  type: CommonEnums.TicketOwnerType.OWNER,
                                  user_id: ownerUserId,
                              }
                            : null,
                    ])
                    .concat(
                        memberUserIds?.length
                            ? memberUserIds.map(userId => ({
                                  __typename: "tickets_owners",
                                  added_at: now,
                                  type: CommonEnums.TicketOwnerType.MEMBER,
                                  user_id: userId,
                              }))
                            : []
                    )
                    .filter(isDefined),
                user_plans: [],
                watchers: makeCurrentUserWatcher
                    ? [{ __typename: "tickets_watchers", user_id: ctx.currentUserId }]
                    : [],
            },
        });

        // See comment below.
        await P.each(attachments ?? [], ta =>
            api.ticketAttachments.insert({
                key: EntryKeys.buildKey({
                    type: EntryKeys.Type.TICKET_ATTACHMENT,
                    ids: { ticketAttachmentId: ta.id, ticketId },
                }),
                entry: {
                    __typename: "ticket_attachments",
                    id: ta.id,
                    attached_at: now,
                    attached_by_user_id: ctx.currentUserId,
                    deleted_at: null,
                    key: ta.key,
                    ticket_id: ticketId,
                    title: ta.title,
                    url: ta.url,
                },
            })
        );

        // As of February 2024, we observed a curious behavior in Replicache, probably a bug.
        // If these were Promise.all, Replicache would sometimes crash with a ChunkNotFoundError.
        // As a workaround, we do all of these insertions sequentially using P.each. This
        // is still extremely fast.
        await P.each(tasklists ?? [], async tl => {
            await api.tasklists.insert({
                key: EntryKeys.buildKey({
                    type: EntryKeys.Type.TASKLIST,
                    ids: { tasklistId: tl.id, ticketId },
                }),
                entry: {
                    __typename: "tasklists",
                    id: tl.id,
                    added_at: now,
                    deleted_at: null,
                    stage_id: tl.stageId,
                    ticket_id: ticketId,
                    ticket_pos: tl.ticketPos,
                    title: tl.title,
                    uuid: tl.uuid,
                },
            });

            let lastTasklistPos = generateDefaultPos();

            await P.each(tl.tasks, async t => {
                const tasklistPos =
                    t.tasklistPos ??
                    interpolateMissingPositions({
                        sortedPositions: [lastTasklistPos, null],
                    })[1];

                await api.tasks.insert({
                    key: EntryKeys.buildKey({
                        type: EntryKeys.Type.TASK,
                        ids: { taskId: t.id, ticketId },
                    }),
                    entry: {
                        __typename: "tasks",
                        id: t.id,
                        assigned_to_user_id: t.assignedToUserId,
                        child_ticket_id: t.childTicketId,
                        deleted_at: null,
                        due_date: t.dueDate,
                        is_complete: t.isComplete,
                        tasklist_id: tl.id,
                        tasklist_pos: tasklistPos,
                        task_type: t.childTicketId
                            ? CommonEnums.TaskType.CHILD_TICKET
                            : CommonEnums.TaskType.TASK,
                        ticket_id: ticketId,
                        title: t.title,
                    },
                });

                lastTasklistPos = tasklistPos;

                if (t.childTicketId) {
                    await appendChildOfTaskToTicket(
                        {
                            ticketId: t.childTicketId,
                            childOfTaskId: t.id,
                        },
                        api,
                        ctx
                    );
                }
            });
        });

        return ref;
    },

    async createTicketAttachment(
        { key, ticketAttachmentId, ticketId, title }: MutationArgs<"createTicketAttachment">,
        api: WriteApi,
        ctx: MutationCtx
    ) {
        await api.ticketAttachments.insert({
            key: EntryKeys.buildKey({
                type: EntryKeys.Type.TICKET_ATTACHMENT,
                ids: { ticketAttachmentId, ticketId },
            }),
            entry: {
                __typename: "ticket_attachments",
                id: ticketAttachmentId,
                attached_at: new Date(Date.now()).toISOString(),
                attached_by_user_id: ctx.currentUserId,
                deleted_at: null,
                key,
                ticket_id: ticketId,
                title,
                url: null,
            },
        });
    },

    async deleteLabel(
        { boardId, label }: MutationArgs<"deleteLabel">,
        api: WriteApi,
        ctx: MutationCtx
    ) {
        const board = await api.boards.getById({ id: boardId });

        if (!board) {
            return;
        }

        const newLabelsConfig = {
            ...board.labels_config,
            deleted: [
                ...board.labels_config.deleted
                    .filter(({ color, text }) => !(color === label.color && text === label.text))
                    .concat({ color: label.color, text: label.text }),
            ],
        };

        await api.boards.shallowUpdateById({
            id: boardId,
            fields: { labels_config: newLabelsConfig },
        });
    },

    async deleteStage({ stageId }: MutationArgs<"deleteStage">, api: WriteApi, ctx: MutationCtx) {
        await api.stages.shallowUpdateById({
            id: stageId,
            fields: {
                deleted_at: new Date(Date.now()).toISOString(),
            },
        });
    },

    async deleteTask({ taskId }: MutationArgs<"deleteTask">, api: WriteApi, ctx: MutationCtx) {
        await api.tasks.shallowUpdateById({
            id: taskId,
            fields: {
                deleted_at: new Date(Date.now()).toISOString(),
            },
        });
    },

    async deleteTasklist(
        { tasklistId }: MutationArgs<"deleteTasklist">,
        api: WriteApi,
        ctx: MutationCtx
    ) {
        await api.tasklists.shallowUpdateById({
            id: tasklistId,
            fields: {
                deleted_at: new Date(Date.now()).toISOString(),
            },
        });
    },

    async deleteTicketAttachment(
        { ticketAttachmentId }: MutationArgs<"deleteTicketAttachment">,
        api: WriteApi,
        ctx: MutationCtx
    ) {
        await api.ticketAttachments.shallowUpdateById({
            id: ticketAttachmentId,
            fields: {
                deleted_at: new Date(Date.now()).toISOString(),
            },
        });
    },

    async moveTask(
        { taskId, tasklistId, tasklistPos }: MutationArgs<"moveTask">,
        api: WriteApi,
        ctx: MutationCtx
    ) {
        await api.tasks.shallowUpdateById({
            id: taskId,
            fields: {
                tasklist_id: tasklistId,
                tasklist_pos: tasklistPos,
            },
        });
    },

    async promoteTask(
        { taskId, childTicketId }: MutationArgs<"promoteTask">,
        api: WriteApi,
        ctx: MutationCtx
    ) {
        await api.tasks.shallowUpdateById({
            id: taskId,
            fields: {
                assigned_to_user_id: null,
                child_ticket_id: childTicketId,
                due_date: null,
                task_type: CommonEnums.TaskType.CHILD_TICKET,
                title: null,
            },
        });

        await appendChildOfTaskToTicket(
            { ticketId: childTicketId, childOfTaskId: taskId },
            api,
            ctx
        );
    },

    async resolveThread(
        { threadId }: MutationArgs<"resolveThread">,
        api: WriteApi,
        ctx: MutationCtx
    ) {
        await api.threads.shallowUpdateById({
            id: threadId,
            fields: {
                resolved_at: new Date(Date.now()).toISOString(),
                resolved_by_user_id: ctx.currentUserId,
            },
        });
    },

    async reopenThread(
        { threadId }: MutationArgs<"reopenThread">,
        api: WriteApi,
        ctx: MutationCtx
    ) {
        await api.threads.shallowUpdateById({
            id: threadId,
            fields: {
                resolved_at: null,
                resolved_by_user_id: null,
            },
        });
    },

    async replyToThread(
        {
            commentId,
            threadId,
            comment,
            assignedToUserId,
            isThreadResolved,
        }: MutationArgs<"replyToThread">,
        api: WriteApi,
        ctx: MutationCtx
    ) {
        const now = new Date(Date.now()).toISOString();

        const ticketId = (await api.threads.getById({ id: threadId }))?.ticket_id;

        if (!ticketId) {
            logMissingEntity({ mutationName: "replyToThread", threadId });
            return;
        }

        await api.comments.insert({
            key: EntryKeys.buildKey({
                type: EntryKeys.Type.COMMENT,
                ids: { commentId, threadId, ticketId },
            }),
            entry: {
                __typename: "comments",
                id: commentId,
                author_user_id: ctx.currentUserId,
                comment_json: comment,
                comment_text: RichText.contentJsonToText(comment),
                last_edited_at: null,
                posted_at: now,
                thread_id: threadId,
                ticket_id: ticketId,
            },
        });

        if (assignedToUserId !== undefined) {
            await api.threads.shallowUpdateById({
                id: threadId,
                fields: {
                    assigned_at: now,
                    assigned_by_user_id: ctx.currentUserId,
                    assigned_to_user_id: assignedToUserId,
                },
            });
        }

        if (isThreadResolved) {
            await api.threads.shallowUpdateById({
                id: threadId,
                fields: {
                    resolved_at: now,
                    resolved_by_user_id: ctx.currentUserId,
                },
            });
        } else {
            await api.threads.shallowUpdateById({
                id: threadId,
                fields: {
                    resolved_at: null,
                    resolved_by_user_id: null,
                },
            });
        }
    },

    async trashTicket({ ticketId }: MutationArgs<"trashTicket">, api: WriteApi, ctx: MutationCtx) {
        await api.tickets.shallowUpdateById({
            id: ticketId,
            fields: {
                archived_at: null,
                trashed_at: new Date(Date.now()).toISOString(),
            },
        });
    },

    async unarchiveBoard(
        { boardId }: MutationArgs<"unarchiveBoard">,
        api: WriteApi,
        ctx: MutationCtx
    ) {
        await api.boards.shallowUpdateById({
            id: boardId,
            fields: {
                archived_at: null,
            },
        });
    },

    async unarchiveTicket(
        { ticketId, stagePos }: MutationArgs<"unarchiveTicket">,
        api: WriteApi,
        ctx: MutationCtx
    ) {
        await api.tickets.shallowUpdateById({
            id: ticketId,
            fields: {
                archived_at: null,
                ...(stagePos && { stage_pos: stagePos }),
            },
        });
    },

    async undeleteLabel(
        { boardId, label }: MutationArgs<"undeleteLabel">,
        api: WriteApi,
        ctx: MutationCtx
    ) {
        const board = await api.boards.getById({ id: boardId });

        if (!board) {
            return;
        }

        const newLabelsConfig = {
            ...board.labels_config,
            deleted: [
                ...board.labels_config.deleted.filter(
                    ({ color, text }) => !(color === label.color && text === label.text)
                ),
            ],
        };

        await api.boards.shallowUpdateById({
            id: boardId,
            fields: { labels_config: newLabelsConfig },
        });
    },

    async untrashTicket(
        { ticketId, stagePos }: MutationArgs<"untrashTicket">,
        api: WriteApi,
        ctx: MutationCtx
    ) {
        await api.tickets.shallowUpdateById({
            id: ticketId,
            fields: {
                trashed_at: null,
                ...(stagePos && { stage_pos: stagePos }),
            },
        });
    },

    async updateBoard(
        { boardId, accessType, displayName, code, dueDates, sizes }: MutationArgs<"updateBoard">,
        api: WriteApi,
        ctx: MutationCtx
    ) {
        const board = await api.boards.getById({ id: boardId });

        if (!board) {
            return;
        }

        const settings = {
            ...board.settings,
            ...(code && { [CommonEnums.BoardSettingType.CODE]: code }),
            ...(dueDates && { [CommonEnums.BoardSettingType.DUE_DATES]: dueDates }),
            ...(sizes && { [CommonEnums.BoardSettingType.SIZES]: sizes }),
        };

        await api.boards.shallowUpdateById({
            id: boardId,
            fields: {
                access_type: accessType,
                display_name: displayName,
                settings,
            },
        });
    },

    async updateBoardMembers(
        { boardId, userIdsToAdd = [], userIdsToRemove = [] }: MutationArgs<"updateBoardMembers">,
        api: WriteApi,
        ctx: MutationCtx
    ) {
        // As of May 2023, we are punting on incorporating this data into
        // Replicache, but are persisting it via a Replicache mutator.
    },

    async updateComment(
        { commentId, comment, threadAssignedToUserId }: MutationArgs<"updateComment">,
        api: WriteApi,
        ctx: MutationCtx
    ) {
        const now = new Date(Date.now()).toISOString();

        await api.comments.shallowUpdateById({
            id: commentId,
            fields: {
                last_edited_at: now,
                comment_json: comment,
            },
        });

        if (threadAssignedToUserId !== undefined) {
            const threadId = (await api.comments.getById({ id: commentId }))?.thread_id;

            if (!threadId) {
                logMissingEntity({ mutationName: "updateComment", commentId });
                return;
            }

            await api.threads.shallowUpdateById({
                id: threadId,
                fields: {
                    assigned_at: now,
                    assigned_by_user_id: ctx.currentUserId,
                    assigned_to_user_id: threadAssignedToUserId,
                    resolved_at: null,
                    resolved_by_user_id: null,
                },
            });
        }
    },

    async updateLabel(
        { boardId, oldLabel, newLabel }: MutationArgs<"updateLabel">,
        api: WriteApi,
        ctx: MutationCtx
    ) {
        // Update the label on every ticket on the board that has the label being updated, but
        // does not have another label that has the label being updated's new color and text.

        // (Without the last condition, we could end up with a ticket with two identical labels.)

        const ticketsToUpdate = (await api.tickets.findAll()).filter(
            ticket =>
                ticket.board_id === boardId &&
                ticket.label_attachments.find(
                    tla => tla.color === oldLabel.color && tla.text === oldLabel.text
                ) &&
                !ticket.label_attachments.find(
                    tla => tla.color === newLabel.color && tla.text === newLabel.text
                )
        );

        await Promise.all(
            ticketsToUpdate.map(ticket =>
                api.tickets.shallowUpdateById({
                    id: ticket.id,
                    fields: {
                        label_attachments: ticket.label_attachments.map(tla =>
                            tla.color === oldLabel.color && tla.text === oldLabel.text
                                ? {
                                      __typename: "ticket_label_attachments",
                                      color: newLabel.color,
                                      text: newLabel.text,
                                  }
                                : tla
                        ),
                    },
                })
            )
        );
    },

    async updateStage(
        { stageId, displayName, boardPos, role }: MutationArgs<"updateStage">,
        api: WriteApi,
        ctx: MutationCtx
    ) {
        await api.stages.shallowUpdateById({
            id: stageId,
            fields: {
                display_name: displayName,
                board_pos: boardPos,
                role,
            },
        });
    },

    async updateTask(
        { taskId, assignedToUserId, dueDate, isComplete, title }: MutationArgs<"updateTask">,
        api: WriteApi,
        ctx: MutationCtx
    ) {
        const formattedDueDate = dueDate ? formatDate(dueDate, "yyyy-MM-dd") : dueDate;

        await api.tasks.shallowUpdateById({
            id: taskId,
            fields: {
                assigned_to_user_id: assignedToUserId,
                due_date: formattedDueDate,
                is_complete: isComplete,
                title,
            },
        });
    },

    async updateTasklist(
        { tasklistId, stageId, ticketPos, title }: MutationArgs<"updateTasklist">,
        api: WriteApi,
        ctx: MutationCtx
    ) {
        await api.tasklists.shallowUpdateById({
            id: tasklistId,
            fields: {
                stage_id: stageId,
                ticket_pos: ticketPos,
                title,
            },
        });
    },

    async updateThread(
        { threadId, assignedToUserId, blocker }: MutationArgs<"updateThread">,
        api: WriteApi,
        ctx: MutationCtx
    ) {
        if (assignedToUserId) {
            await api.threads.shallowUpdateById({
                id: threadId,
                fields: {
                    assigned_at: new Date(Date.now()).toISOString(),
                    assigned_by_user_id: ctx.currentUserId,
                    assigned_to_user_id: assignedToUserId,
                    resolved_at: null,
                    resolved_by_user_id: null,
                },
            });
        }

        if (blocker) {
            await api.threads.shallowUpdateById({
                id: threadId,
                fields: {
                    ...(isTextBlocker(blocker)
                        ? {
                              blocker_type: CommonEnums.BlockerType.TEXT,
                              blocker_text: blocker.value.text,
                              blocker_ticket_id: null,
                          }
                        : {
                              blocker_type: CommonEnums.BlockerType.TICKET,
                              blocker_text: null,
                              blocker_ticket_id: blocker.value.ticket.id,
                          }),
                },
            });
        }
    },

    async updateTicket(
        { ticketId, title, description, dueDate, sizeSpec }: MutationArgs<"updateTicket">,
        api: WriteApi,
        ctx: MutationCtx
    ) {
        const ticket = await api.tickets.getById({ id: ticketId });

        if (!ticket) {
            logMissingEntity({ mutationName: "updateTicket", ticketId });
            return;
        }

        const contentYjs = description
            ? ticket.content_yjs
                ? RichText.hexEncodeYdocState(
                      RichText.replaceYdocState({
                          contentJson: description,
                          ydocState: RichText.hexDecodeYdocState(ticket.content_yjs),
                          field: "description",
                      })
                  )
                : RichText.hexEncodeYdocState(
                      RichText.ydocToState(
                          RichText.contentJsonToYdoc({
                              contentJson: description,
                              field: "description",
                          })
                      )
                  )
            : ticket.content_yjs;

        await api.tickets.shallowUpdateById({
            id: ticketId,
            fields: {
                title,
                content_yjs: contentYjs,
                due_date: dueDate,
                size_spec: sizeSpec,
            },
        });
    },

    async updateTicketLabels(
        { ticketId, labelsToAdd = [], labelsToRemove = [] }: MutationArgs<"updateTicketLabels">,
        api: WriteApi,
        ctx: MutationCtx
    ) {
        const ticket = await api.tickets.getById({ id: ticketId });

        if (!ticket) {
            logMissingEntity({ mutationName: "updateTicketLabels", ticketId });
            return;
        }

        await api.tickets.shallowUpdateById({
            id: ticketId,
            fields: {
                label_attachments: ticket.label_attachments
                    // Remove the ones we need to remove.
                    .filter(
                        tla =>
                            !labelsToRemove.find(
                                label => label.color === tla.color && label.text === tla.text
                            )
                    )

                    // If there's an existing record for a label we're going to add, remove it.
                    // We'll add it back below.
                    .filter(
                        tla =>
                            !labelsToAdd.some(
                                label => label.color === tla.color && label.text === tla.text
                            )
                    )

                    // Add in the labels.
                    .concat(
                        labelsToAdd.map(label => ({
                            __typename: "ticket_label_attachments",
                            color: label.color,
                            text: label.text,
                        }))
                    ),
            },
        });
    },

    async updateTicketMembers(
        { ticketId, userIdsToAdd = [], userIdsToRemove = [] }: MutationArgs<"updateTicketMembers">,
        api: WriteApi,
        ctx: MutationCtx
    ) {
        const now = new Date(Date.now()).toISOString();

        const ticket = await api.tickets.getById({ id: ticketId });

        if (!ticket) {
            logMissingEntity({ mutationName: "updateTicketMembers", ticketId });
            return;
        }

        await api.tickets.shallowUpdateById({
            id: ticketId,
            fields: {
                owners: ticket.owners
                    // Remove the ones we need to remove.
                    .filter(to => !userIdsToRemove.find(userId => userId === to.user_id))

                    // If there's an existing record for a user we're going to add, regardless
                    // of whether that record is a MEMBER, remove it. We'll it it back below
                    // as a member.
                    .filter(to => !userIdsToAdd.some(userId => userId === to.user_id))

                    // Add in the members.
                    .concat(
                        userIdsToAdd.map(userId => ({
                            __typename: "tickets_owners",
                            added_at: now,
                            user_id: userId,
                            type: CommonEnums.TicketOwnerType.MEMBER,
                        }))
                    ),
            },
        });
    },

    async updateTicketOwner(
        { ticketId, userId }: MutationArgs<"updateTicketOwner">,
        api: WriteApi,
        ctx: MutationCtx
    ) {
        const ticket = await api.tickets.getById({ id: ticketId });

        if (!ticket) {
            logMissingEntity({ mutationName: "updateTicketOwner", ticketId });
            return;
        }

        await api.tickets.shallowUpdateById({
            id: ticketId,
            fields: {
                owners: ticket.owners
                    .filter(
                        to => to.type !== CommonEnums.TicketOwnerType.OWNER && to.user_id !== userId
                    )
                    .concat(
                        [
                            userId
                                ? ({
                                      __typename: "tickets_owners",
                                      added_at: new Date(Date.now()).toISOString(),
                                      type: CommonEnums.TicketOwnerType.OWNER,
                                      user_id: userId,
                                  } as const)
                                : null,
                        ].filter(isDefined)
                    ),
            },
        });
    },

    async updateTicketPlan(
        { ticketId, planPos, planType }: MutationArgs<"updateTicketPlan">,
        api: WriteApi,
        ctx: MutationCtx
    ) {
        const ticket = await api.tickets.getById({ id: ticketId });

        if (!ticket) {
            logMissingEntity({ mutationName: "updateTicketPlan", ticketId });
            return;
        }

        await api.tickets.shallowUpdateById({
            id: ticketId,
            fields: {
                user_plans: ticket.user_plans
                    .filter(up => up.user_id !== ctx.currentUserId)
                    .concat([
                        {
                            __typename: "users_tickets_plans",
                            plan_pos: planPos,
                            plan_type: planType,
                            user_id: ctx.currentUserId,
                        } as const,
                    ]),
            },
        });
    },

    async updateTicketStage(
        { ticketId, toStageId, toStagePos }: MutationArgs<"updateTicketStage">,
        api: WriteApi,
        ctx: MutationCtx
    ) {
        const fromBoardId = (await api.tickets.getById({ id: ticketId }))?.board_id;

        if (!fromBoardId) {
            logMissingEntity({ mutationName: "updateTicketStage", ticketId });
            return;
        }

        const fromBoard = await api.boards.getById({ id: fromBoardId });

        if (!fromBoard) {
            logMissingEntity({ mutationName: "updateTicketStage", boardId: fromBoardId });
            return;
        }

        const toBoardId = (await api.stages.getById({ id: toStageId }))?.board_id;

        if (!toBoardId) {
            logMissingEntity({ mutationName: "updateTicketStage", stageId: toStageId });
            return;
        }

        if (
            fromBoard.access_type === CommonEnums.BoardAccessType.PRIVATE &&
            toBoardId !== fromBoardId
        ) {
            Log.error("Tried to move ticket out of private workspace", {
                mutationName: "updateTicketStage",
                ticketId,
                toStageId,
                toStagePos,
            });
            return;
        }

        await api.tickets.shallowUpdateById({
            id: ticketId,
            fields: {
                board_id: toBoardId,
                stage_id: toStageId,
                stage_pos: toStagePos,
            },
        });
    },

    async updateTicketWatchers(
        { ticketId, userIdsToAdd = [], userIdsToRemove = [] }: MutationArgs<"updateTicketWatchers">,
        api: WriteApi,
        ctx: MutationCtx
    ) {
        const ticket = await api.tickets.getById({ id: ticketId });

        if (!ticket) {
            logMissingEntity({ mutationName: "updateTicketWatchers", ticketId });
            return;
        }

        await api.tickets.shallowUpdateById({
            id: ticketId,
            fields: {
                watchers: ticket.watchers
                    // Remove the ones we need to remove.
                    .filter(tw => !userIdsToRemove.includes(tw.user_id))

                    // If there's an existing record for a user we're going to add,
                    // remove it - we'll add it back below.
                    .filter(tw => !userIdsToAdd.includes(tw.user_id))

                    // Add the ones we need to add.
                    .concat(
                        userIdsToAdd.map(userId => ({
                            __typename: "tickets_watchers",
                            user_id: userId,
                        }))
                    ),
            },
        });
    },
} as const;

export const buildMutators = ({
    currentUserId,
    currentUserOrgId,
}: {
    currentUserId: number;
    currentUserOrgId: number;
}) => {
    const ctx = { currentUserId, currentUserOrgId };

    return {
        // Individual/standard mutations
        async archiveBoard(txn: WriteTransaction, params: MutationArgs<"archiveBoard">) {
            return mutatorImpls.archiveBoard(params, new WriteApi({ txn }), ctx);
        },

        async archiveTicket(txn: WriteTransaction, params: MutationArgs<"archiveTicket">) {
            return mutatorImpls.archiveTicket(params, new WriteApi({ txn }), ctx);
        },

        async createChildTicketTask(
            txn: WriteTransaction,
            params: MutationArgs<"createChildTicketTask">
        ) {
            return mutatorImpls.createChildTicketTask(params, new WriteApi({ txn }), ctx);
        },

        async createStage(txn: WriteTransaction, params: MutationArgs<"createStage">) {
            return mutatorImpls.createStage(params, new WriteApi({ txn }), ctx);
        },

        async createTask(txn: WriteTransaction, params: MutationArgs<"createTask">) {
            return mutatorImpls.createTask(params, new WriteApi({ txn }), ctx);
        },

        async createTasklist(txn: WriteTransaction, params: MutationArgs<"createTasklist">) {
            return mutatorImpls.createTasklist(params, new WriteApi({ txn }), ctx);
        },

        async createThread(txn: WriteTransaction, params: MutationArgs<"createThread">) {
            return mutatorImpls.createThread(params, new WriteApi({ txn }), ctx);
        },

        async createTicket(
            txn: WriteTransaction,
            params: Parameters<typeof mutatorImpls.createTicket>[0]
        ) {
            return mutatorImpls.createTicket(params, new WriteApi({ txn }), ctx);
        },

        async createTicketAttachment(
            txn: WriteTransaction,
            params: MutationArgs<"createTicketAttachment">
        ) {
            return mutatorImpls.createTicketAttachment(params, new WriteApi({ txn }), ctx);
        },

        async deleteLabel(txn: WriteTransaction, params: MutationArgs<"deleteLabel">) {
            return mutatorImpls.deleteLabel(params, new WriteApi({ txn }), ctx);
        },

        async deleteStage(txn: WriteTransaction, params: MutationArgs<"deleteStage">) {
            return mutatorImpls.deleteStage(params, new WriteApi({ txn }), ctx);
        },

        async deleteTask(txn: WriteTransaction, params: MutationArgs<"deleteTask">) {
            return mutatorImpls.deleteTask(params, new WriteApi({ txn }), ctx);
        },

        async deleteTasklist(txn: WriteTransaction, params: MutationArgs<"deleteTasklist">) {
            return mutatorImpls.deleteTasklist(params, new WriteApi({ txn }), ctx);
        },

        async deleteTicketAttachment(
            txn: WriteTransaction,
            params: MutationArgs<"deleteTicketAttachment">
        ) {
            return mutatorImpls.deleteTicketAttachment(params, new WriteApi({ txn }), ctx);
        },

        async moveTask(txn: WriteTransaction, params: MutationArgs<"moveTask">) {
            return mutatorImpls.moveTask(params, new WriteApi({ txn }), ctx);
        },

        async promoteTask(txn: WriteTransaction, params: MutationArgs<"promoteTask">) {
            return mutatorImpls.promoteTask(params, new WriteApi({ txn }), ctx);
        },

        async resolveThread(txn: WriteTransaction, params: MutationArgs<"resolveThread">) {
            return mutatorImpls.resolveThread(params, new WriteApi({ txn }), ctx);
        },

        async reopenThread(txn: WriteTransaction, params: MutationArgs<"reopenThread">) {
            return mutatorImpls.reopenThread(params, new WriteApi({ txn }), ctx);
        },

        async replyToThread(txn: WriteTransaction, params: MutationArgs<"replyToThread">) {
            return mutatorImpls.replyToThread(params, new WriteApi({ txn }), ctx);
        },

        async trashTicket(txn: WriteTransaction, params: MutationArgs<"trashTicket">) {
            return mutatorImpls.trashTicket(params, new WriteApi({ txn }), ctx);
        },

        async unarchiveBoard(txn: WriteTransaction, params: MutationArgs<"unarchiveBoard">) {
            return mutatorImpls.unarchiveBoard(params, new WriteApi({ txn }), ctx);
        },

        async unarchiveTicket(txn: WriteTransaction, params: MutationArgs<"unarchiveTicket">) {
            return mutatorImpls.unarchiveTicket(params, new WriteApi({ txn }), ctx);
        },

        async undeleteLabel(txn: WriteTransaction, params: MutationArgs<"undeleteLabel">) {
            return mutatorImpls.undeleteLabel(params, new WriteApi({ txn }), ctx);
        },
        async untrashTicket(txn: WriteTransaction, params: MutationArgs<"untrashTicket">) {
            return mutatorImpls.untrashTicket(params, new WriteApi({ txn }), ctx);
        },

        async updateBoard(txn: WriteTransaction, params: MutationArgs<"updateBoard">) {
            return mutatorImpls.updateBoard(params, new WriteApi({ txn }), ctx);
        },

        async updateBoardMembers(
            txn: WriteTransaction,
            params: MutationArgs<"updateBoardMembers">
        ) {
            return mutatorImpls.updateBoardMembers(params, new WriteApi({ txn }), ctx);
        },

        async updateComment(txn: WriteTransaction, params: MutationArgs<"updateComment">) {
            return mutatorImpls.updateComment(params, new WriteApi({ txn }), ctx);
        },

        async updateLabel(txn: WriteTransaction, params: MutationArgs<"updateLabel">) {
            return mutatorImpls.updateLabel(params, new WriteApi({ txn }), ctx);
        },

        async updateStage(txn: WriteTransaction, params: MutationArgs<"updateStage">) {
            return mutatorImpls.updateStage(params, new WriteApi({ txn }), ctx);
        },

        async updateTask(txn: WriteTransaction, params: MutationArgs<"updateTask">) {
            return mutatorImpls.updateTask(params, new WriteApi({ txn }), ctx);
        },

        async updateTasklist(txn: WriteTransaction, params: MutationArgs<"updateTasklist">) {
            return mutatorImpls.updateTasklist(params, new WriteApi({ txn }), ctx);
        },

        async updateThread(txn: WriteTransaction, params: MutationArgs<"updateThread">) {
            return mutatorImpls.updateThread(params, new WriteApi({ txn }), ctx);
        },

        async updateTicket(txn: WriteTransaction, params: MutationArgs<"updateTicket">) {
            return mutatorImpls.updateTicket(params, new WriteApi({ txn }), ctx);
        },

        async updateTicketLabels(
            txn: WriteTransaction,
            params: MutationArgs<"updateTicketLabels">
        ) {
            return mutatorImpls.updateTicketLabels(params, new WriteApi({ txn }), ctx);
        },

        async updateTicketMembers(
            txn: WriteTransaction,
            params: MutationArgs<"updateTicketMembers">
        ) {
            return mutatorImpls.updateTicketMembers(params, new WriteApi({ txn }), ctx);
        },

        async updateTicketOwner(txn: WriteTransaction, params: MutationArgs<"updateTicketOwner">) {
            return mutatorImpls.updateTicketOwner(params, new WriteApi({ txn }), ctx);
        },

        async updateTicketPlan(txn: WriteTransaction, params: MutationArgs<"updateTicketPlan">) {
            return mutatorImpls.updateTicketPlan(params, new WriteApi({ txn }), ctx);
        },

        async updateTicketStage(txn: WriteTransaction, params: MutationArgs<"updateTicketStage">) {
            return mutatorImpls.updateTicketStage(params, new WriteApi({ txn }), ctx);
        },

        async updateTicketWatchers(
            txn: WriteTransaction,
            params: MutationArgs<"updateTicketWatchers">
        ) {
            return mutatorImpls.updateTicketWatchers(params, new WriteApi({ txn }), ctx);
        },

        // Miscellaneous non-standard mutations
        async bulkMutation(
            txn: WriteTransaction,
            { mutations }: { orgId: number; mutations: MutationSpec[] }
        ) {
            const api = new WriteApi({ txn });

            // The mutations must be executed sequentially, not in parallel, in case a later one
            // depends on an earlier one.
            for (const mutation of mutations) {
                // The TypeScript compiler isn't able to correlate the discriminated union using
                // mutation.name implies that the mutation.params are correct type for that
                // mutator implementation.
                //
                // We could do a big switch here and manually list out each mutation, but that
                // would be repetitive.
                //
                // Since we know that mutation.params are valid for the given mutation.name, by
                // virtue of the MutationSpec type, and *we* know that we're passing those params
                // to the right mutator implementation, it's OK to ignore the TypeScript error
                // here.
                //
                // See https://github.com/microsoft/TypeScript/issues/30581.
                //
                // @ts-expect-error
                await mutatorImpls[mutation.name](mutation.args, api, ctx);
            }
        },
    };
};
