import React, { useCallback, useEffect, useState } from "react";

import { BillingSchema, CommonEnumValue, CommonEnums } from "c9r-common";
import classNames from "classnames";
import { format as formatDate, parseISO } from "date-fns";
import { useRecoilValue } from "recoil";

import { Config } from "Config";
import { SupportMailto } from "components/shared/SupportMailto";
import { DetailedMenuItem } from "components/ui/common/DetailedMenuItem";
import { getPaymentCardBrandDisplayText } from "components/ui/common/PaymentCard";
import { AppToaster } from "components/ui/core/AppToaster";
import { BorderButton } from "components/ui/core/BorderButton";
import { Dialog } from "components/ui/core/Dialog";
import { DropdownButton } from "components/ui/core/DropdownButton";
import { Icon } from "components/ui/core/Icon";
import { Menu } from "components/ui/core/Menu";
import { MenuPopover } from "components/ui/core/MenuPopover";
import { Toggle } from "components/ui/core/Toggle";
import { Enums } from "lib/Enums";
import { useAsyncWatcher, useDialog } from "lib/Hooks";
import { useInstrumentation } from "lib/Instrumentation";
import { Log } from "lib/Log";
import { Link } from "lib/Routing";
import { useUrlBuilders } from "lib/Urls";

import { useBillingFormatters } from "./BillingFormatters";
import { useChangeSubscriptionPlan } from "./BillingMutations";
import { billingInfoState } from "./BillingState";
import { PaymentMethodDialog } from "./PaymentMethodDialog";
import styles from "./SubscriptionPlanDialog.module.scss";

const PlanChangeType = {
    DOWNGRADE: "DOWNGRADE",
    UPGRADE: "UPGRADE",
    SWITCH: "SWITCH",
    UNCANCEL: "UNCANCEL",
    ENROLL: "ENROLL",
    CHANGE_INTERVAL: "CHANGE_INTERVAL",
} as const;

/**
 * Identify whether a change of plan is an upgrade or a downgrade.
 *
 * Returns value from PlanChangeType or null if the plan isn't changing.
 */
const getChangePlanType = ({
    cancelAt,
    currentPlanKey,
    selectedPlanKey,
    currentBillingInterval,
    selectedBillingInterval,
}: {
    cancelAt?: string | null;
    currentPlanKey?: CommonEnumValue<"SubscriptionPlanKey">;
    selectedPlanKey: CommonEnumValue<"SubscriptionPlanKey">;
    currentBillingInterval?: CommonEnumValue<"SubscriptionBillingInterval">;
    selectedBillingInterval: CommonEnumValue<"SubscriptionBillingInterval">;
}) => {
    if (currentPlanKey === selectedPlanKey) {
        if (cancelAt) {
            return PlanChangeType.UNCANCEL;
        }

        if (currentBillingInterval !== selectedBillingInterval) {
            return PlanChangeType.CHANGE_INTERVAL;
        }

        return null;
    }

    if (!currentPlanKey) {
        return PlanChangeType.ENROLL;
    }

    const planGroups: CommonEnumValue<"SubscriptionPlanKey">[][] = [
        [
            CommonEnums.SubscriptionPlanKey.FREE_IN_BETA_PLAN,
            CommonEnums.SubscriptionPlanKey.FREE_PLAN,
        ],
        [CommonEnums.SubscriptionPlanKey.STANDARD_PLAN],
        [CommonEnums.SubscriptionPlanKey.ENTERPRISE_PLAN],
    ];

    const currentPlanGroupIndex = planGroups.findIndex(planGroup =>
        planGroup.includes(currentPlanKey)
    );
    const selectedPlanGroupIndex = planGroups.findIndex(planGroup =>
        planGroup.includes(selectedPlanKey)
    );

    return currentPlanGroupIndex === selectedPlanGroupIndex
        ? PlanChangeType.SWITCH
        : currentPlanGroupIndex > selectedPlanGroupIndex
        ? PlanChangeType.DOWNGRADE
        : PlanChangeType.UPGRADE;
};

type SubscriptionPlanEligibilityTextProps = {
    eligibility: BillingSchema.SubscriptionPlanEligibility;
    planKey: CommonEnumValue<"SubscriptionPlanKey">;
};

function SubscriptionPlanEligibilityText({
    eligibility,
    planKey,
}: SubscriptionPlanEligibilityTextProps) {
    const { buildSettingsUrl } = useUrlBuilders();

    switch (planKey) {
        case CommonEnums.SubscriptionPlanKey.FREE_IN_BETA_PLAN:
            return (
                <div>
                    <p>While Flat is in beta, teams of any size can use Flat completely free.</p>
                </div>
            );

        case CommonEnums.SubscriptionPlanKey.FREE_PLAN:
            if (
                eligibility.ineligibility_reason ===
                CommonEnums.SubscriptionPlanIneligibilityReason.TOO_MANY_USERS
            ) {
                return (
                    <div>
                        <p>
                            Your organization isn’t eligible for the Free Plan because it has more
                            than {Config.billing.freePlanMaxUsers} users.
                        </p>
                        <p>
                            To be eligible for the Free Plan, visit{" "}
                            <Link to={`${buildSettingsUrl().pathname}/people`}>
                                Admin Settings | People
                            </Link>{" "}
                            and deactivate users who no longer need to access Flat.
                        </p>
                    </div>
                );
            }

            return null;

        case CommonEnums.SubscriptionPlanKey.ENTERPRISE_PLAN:
            if (!eligibility.is_eligible) {
                return (
                    <div>
                        <p>
                            We would be happy to work with you. Please{" "}
                            <SupportMailto text="contact us" /> so we can discuss your needs.
                        </p>
                    </div>
                );
            }

            return null;

        default:
            return null;
    }
}

type ChargeConfirmationTextProps = {
    formattedPrice: string;
};

function ChargeConfirmationText({ formattedPrice }: ChargeConfirmationTextProps) {
    const billingInfo = useRecoilValue(billingInfoState);
    const cancelAt = billingInfo?.current_subscription_plan?.cancel_at;
    const paymentMethod = billingInfo?.payment_methods?.[0];
    const trialEndAt = billingInfo?.current_subscription_plan?.trial_end_at;

    if (cancelAt) {
        return (
            <div>
                <p>
                    Your subscription is currently set to cancel on{" "}
                    {formatDate(parseISO(cancelAt), "MMM d, yyyy")}.
                </p>
            </div>
        );
    }

    return (
        <div>
            <p>
                Your{" "}
                {paymentMethod ? (
                    <>
                        {getPaymentCardBrandDisplayText({ brand: paymentMethod.card.brand }) ||
                            "card"}{" "}
                        ending {paymentMethod.card.last4}
                    </>
                ) : (
                    "card"
                )}{" "}
                will be charged {formattedPrice}
                {trialEndAt ? (
                    <>
                        {" "}
                        after your free trial ends on{" "}
                        {formatDate(parseISO(trialEndAt), "MMM d, yyyy")}
                    </>
                ) : null}
                .
            </p>
        </div>
    );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function ComparePlansLink() {
    return (
        <div>
            <a href={Config.urls.pricing} target="_blank" rel="noopener noreferrer">
                Compare plans <Icon icon="external-link" iconSet="lucide" iconSize={16} />
            </a>
        </div>
    );
}

export type SubscriptionPlanDialogProps = {
    className?: string;
    isOpen: boolean;
    onChange: () => void;
    onClose: () => void;
};

export function SubscriptionPlanDialog({
    className,
    isOpen,
    onChange,
    onClose,
}: SubscriptionPlanDialogProps) {
    const { formatPrice } = useBillingFormatters();
    const billingInfo = useRecoilValue(billingInfoState);
    const cancelAt = billingInfo?.current_subscription_plan?.cancel_at;
    const currentPlanKey = billingInfo?.current_subscription_plan?.plan_key;
    const currentBillingInterval = billingInfo?.current_subscription_plan?.price.billing_interval;
    const trialEndAt = billingInfo?.current_subscription_plan?.trial_end_at;
    const paymentMethod = billingInfo?.payment_methods?.[0];
    const [selectedPlanKey, setSelectedPlanKey] = useState(
        currentPlanKey || CommonEnums.SubscriptionPlanKey.STANDARD_PLAN
    );
    const [selectedBillingInterval, setSelectedBillingInterval] = useState(
        currentBillingInterval || CommonEnums.SubscriptionBillingInterval.MONTH
    );
    const submission = useAsyncWatcher();
    const paymentMethodDialog = useDialog();
    const { changeSubscriptionPlan } = useChangeSubscriptionPlan();
    const { recordEvent } = useInstrumentation();

    const getAvailableSubscriptionPlan = useCallback(
        (planKey: CommonEnumValue<"SubscriptionPlanKey">) =>
            billingInfo?.available_subscription_plans?.find(plan => plan.plan_key === planKey),
        [billingInfo?.available_subscription_plans]
    );

    useEffect(() => {
        if (isOpen) {
            setSelectedPlanKey(currentPlanKey || CommonEnums.SubscriptionPlanKey.STANDARD_PLAN);
            setSelectedBillingInterval(
                currentBillingInterval || CommonEnums.SubscriptionBillingInterval.MONTH
            );
        }
    }, [currentPlanKey, currentBillingInterval, isOpen]);

    const standardPlanFormattedPrice = formatPrice({
        price: getAvailableSubscriptionPlan(
            CommonEnums.SubscriptionPlanKey.STANDARD_PLAN
        )?.prices.find(price => price.billing_interval === selectedBillingInterval)!,
    });

    const planItems = [
        {
            planKey: CommonEnums.SubscriptionPlanKey.FREE_IN_BETA_PLAN,
            caption: "Unlimited users. 1GB asset storage.",
            nickname: "Beta Period Plan",
        },
        {
            planKey: CommonEnums.SubscriptionPlanKey.FREE_PLAN,
            caption: `Up to ${Config.billing.freePlanMaxUsers} users. 1GB asset storage.`,
            nickname: "Free",
        },
        {
            planKey: CommonEnums.SubscriptionPlanKey.STANDARD_PLAN,
            subtitle: standardPlanFormattedPrice,
            formattedPrice: standardPlanFormattedPrice,
            caption: "Unlimited asset storage. Live chat support.",
            nickname: "Standard",
        },
        {
            planKey: CommonEnums.SubscriptionPlanKey.ENTERPRISE_PLAN,
            subtitle: "(contact us)",
            caption: "Single sign-on with SAML. Priority support.",
            nickname: "Enterprise",
        },
    ]
        .map(planItem => ({
            ...planItem,
            availableSubscriptionPlan: getAvailableSubscriptionPlan(planItem.planKey),
        }))
        .filter(planItem => planItem.availableSubscriptionPlan)
        .map(planItem => ({
            ...planItem,
            title: planItem.availableSubscriptionPlan!.display_name,
            hasAnnualBilling: planItem.availableSubscriptionPlan!.prices.some(
                price => price.billing_interval === CommonEnums.SubscriptionBillingInterval.YEAR
            ),
        }));

    const selectedPlan = getAvailableSubscriptionPlan(selectedPlanKey);
    const selectedPlanItem = planItems.find(planItem => planItem.planKey === selectedPlanKey);
    const changePlanType = getChangePlanType({
        cancelAt,
        currentPlanKey,
        selectedPlanKey,
        currentBillingInterval,
        selectedBillingInterval,
    });

    const changePlanCtaText =
        (changePlanType &&
            {
                [PlanChangeType.DOWNGRADE]: `Downgrade to ${selectedPlanItem?.nickname}`,
                [PlanChangeType.UPGRADE]: `Upgrade to ${selectedPlanItem?.nickname}`,
                [PlanChangeType.SWITCH]: `Switch to ${selectedPlanItem?.nickname}`,
                [PlanChangeType.UNCANCEL]: "Renew",
                [PlanChangeType.ENROLL]: `Choose ${selectedPlanItem?.nickname}`,
                [PlanChangeType.CHANGE_INTERVAL]: `Switch to ${
                    {
                        [CommonEnums.SubscriptionBillingInterval.MONTH]: "monthly",
                        [CommonEnums.SubscriptionBillingInterval.YEAR]: "annual",
                    }[selectedBillingInterval]
                } billing`,
            }[changePlanType]) ||
        "Switch";

    const handleChangeSubscriptionPlan = async () => {
        const availableSubscriptionPlan = getAvailableSubscriptionPlan(selectedPlanKey);
        const billingInterval = availableSubscriptionPlan?.prices.some(
            price => price.billing_interval === selectedBillingInterval
        )
            ? selectedBillingInterval
            : CommonEnums.SubscriptionBillingInterval.MONTH;

        const [result] = await Promise.all([
            changeSubscriptionPlan({
                planKey: selectedPlanKey,
                billingInterval,
            }),
            recordEvent({
                eventType: Enums.InstrumentationEvent.ELEMENT_SUBMIT,
                eventData: { planKey: selectedPlanKey },
                elementName: "settings.subscription_plan_dialog",
                dedupeKey: Date.now(),
            }),
        ]);

        if (!result.data?.change_subscription_plan.ok) {
            Log.error("Failed to change subscription plan");
            AppToaster.error({
                message: "Something went wrong updating your subscription.",
            });
        }

        await onChange?.();
        onClose();
    };

    const handleChangeSubscriptionPlanOrProvidePaymentMethod = async () => {
        if (
            !trialEndAt &&
            !paymentMethod &&
            selectedPlanKey !== CommonEnums.SubscriptionPlanKey.FREE_IN_BETA_PLAN &&
            selectedPlanKey !== CommonEnums.SubscriptionPlanKey.FREE_PLAN
        ) {
            paymentMethodDialog.open();
        } else {
            await handleChangeSubscriptionPlan();
        }
    };

    const handlePaymentMethodChange = async () => {
        await onChange?.();
        await handleChangeSubscriptionPlan();
    };

    return (
        <>
            <Dialog
                className={classNames(className, styles.subscriptionPlanDialog)}
                title="Change plan"
                isOpen={isOpen}
                onClose={onClose}
            >
                <Dialog.Body className={styles.body}>
                    <MenuPopover
                        content={
                            <Menu className={styles.planMenu}>
                                {planItems.map(planItem => (
                                    <DetailedMenuItem
                                        key={planItem.planKey}
                                        title={planItem.title}
                                        subtitle={planItem.subtitle}
                                        caption={planItem.caption}
                                        selected={selectedPlanKey === planItem.planKey}
                                        onClick={() => setSelectedPlanKey(planItem.planKey)}
                                        instrumentation={{
                                            elementName:
                                                "settings.subscription_plan_dialog.plan_btn",
                                            eventData: {
                                                planKey: planItem.planKey,
                                            },
                                        }}
                                    />
                                ))}
                            </Menu>
                        }
                        minimal
                        fill
                        placement="bottom-start"
                    >
                        <DropdownButton
                            className={styles.planDropdownBtn}
                            contentClassName={styles.planDropdownBtnContent}
                            text={
                                <div className={styles.planDropdownBtnText}>
                                    <span>
                                        {selectedPlan?.display_name || "No active subscription"}
                                    </span>
                                    {selectedPlanItem?.subtitle ? (
                                        <span>{selectedPlanItem.subtitle}</span>
                                    ) : null}
                                </div>
                            }
                            underline
                            instrumentation={null}
                        />
                    </MenuPopover>
                    {selectedPlanItem?.hasAnnualBilling ? (
                        <Toggle
                            className={styles.billingIntervalToggle}
                            checked={
                                selectedBillingInterval ===
                                CommonEnums.SubscriptionBillingInterval.YEAR
                            }
                            onChange={() =>
                                setSelectedBillingInterval(prev =>
                                    prev === CommonEnums.SubscriptionBillingInterval.YEAR
                                        ? CommonEnums.SubscriptionBillingInterval.MONTH
                                        : CommonEnums.SubscriptionBillingInterval.YEAR
                                )
                            }
                            label={
                                <span
                                    className={classNames(
                                        styles.billingIntervalToggleText,
                                        selectedBillingInterval !==
                                            CommonEnums.SubscriptionBillingInterval.YEAR &&
                                            styles.billingIntervalToggleTextWeak
                                    )}
                                >
                                    Bill annually{" "}
                                    <span className={styles.discountText}>(save 20%)</span>
                                </span>
                            }
                            instrumentation={{
                                elementName:
                                    "settings.subscription_plan_dialog.billing_interval_toggle",
                            }}
                        />
                    ) : null}
                    {selectedPlan ? (
                        <SubscriptionPlanEligibilityText
                            eligibility={selectedPlan.eligibility}
                            planKey={selectedPlan.plan_key}
                        />
                    ) : null}
                    {changePlanType && selectedPlanItem?.formattedPrice ? (
                        <ChargeConfirmationText formattedPrice={selectedPlanItem.formattedPrice} />
                    ) : null}

                    {/* As of May 2023, we removed this link when we rebranded to Flat, because the
                    Flat website didn't at the time have a pricing page. Once it does, we can add
                    back this link. */}
                    {/* <ComparePlansLink /> */}
                </Dialog.Body>

                <Dialog.Footer>
                    <Dialog.FooterActions>
                        <BorderButton
                            disabled={submission.isInFlight}
                            content="Cancel"
                            onClick={onClose}
                            instrumentation={null}
                        />
                        <BorderButton
                            disabled={
                                !changePlanType ||
                                (selectedPlan && !selectedPlan.eligibility.is_eligible)
                            }
                            loading={submission.isInFlight}
                            content={changePlanCtaText}
                            cta
                            onClick={submission.watch(
                                handleChangeSubscriptionPlanOrProvidePaymentMethod
                            )}
                            instrumentation={{
                                elementName: "settings.subscription_plan_dialog.submit_btn",
                            }}
                        />
                    </Dialog.FooterActions>
                </Dialog.Footer>
            </Dialog>
            {billingInfo && !paymentMethod ? (
                <PaymentMethodDialog
                    isMissingPaymentMethod
                    isOpen={paymentMethodDialog.isOpen}
                    onChange={submission.watch(handlePaymentMethodChange)}
                    onClose={paymentMethodDialog.close}
                />
            ) : null}
        </>
    );
}
