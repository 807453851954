import React, { useCallback, useEffect, useState } from "react";

import classNames from "classnames";
import { Resizable, ResizeCallback } from "re-resizable";
import { CSSTransition } from "react-transition-group";

import { Enums } from "lib/Enums";
import { useResettingState } from "lib/Hooks";
import { useLocation } from "lib/Routing";
import { Storage } from "lib/Storage";

import styles from "./SideDrawer.module.scss";

const MAX_WIDTH = "80%";

export type SideDrawerProps = {
    className?: string;
    children?: React.ReactNode;
    id: string;
    isOpen?: boolean;
    widthSpec?:
        | {
              type: typeof Enums.SideDrawerWidthType.FIXED;
              value: number;
          }
        | {
              type: typeof Enums.SideDrawerWidthType.VARIABLE;
              min: number;
              default: number;
          };
};

export function SideDrawer({ className, children, isOpen, id, widthSpec }: SideDrawerProps) {
    const location = useLocation();
    const [animate, setAnimate] = useResettingState(true, 0);

    useEffect(() => {
        setAnimate(false);
    }, [location.pathname, setAnimate]);

    const getStoredWidth = () => Storage.Local.getItem(id)?.width;
    const setStoredWidth = useCallback(
        (width: number) => Storage.Local.setItem(id, { ...Storage.Local.getItem(id), width }),
        [id]
    );

    const [width, setWidth] = useState(
        (() => {
            if (widthSpec?.type === Enums.SideDrawerWidthType.VARIABLE) {
                const storedWidth = getStoredWidth();

                return typeof storedWidth === "number" ? storedWidth : widthSpec.default;
            }

            return widthSpec?.value ?? 500;
        })()
    );

    const handleResizeEnd: ResizeCallback = (event, direction, refToElement, delta) => {
        const nextWidth = width + delta.width;

        setWidth(nextWidth);
        setStoredWidth(nextWidth);
    };

    const ENTER_TRANSITION_DURATION = 300;
    const EXIT_TRANSITION_DURATION = 150;

    const [style, setStyle] = useState<React.CSSProperties>({
        position: "absolute",
    });

    return (
        <CSSTransition
            in={isOpen}
            timeout={{
                enter: animate ? ENTER_TRANSITION_DURATION : 0,
                exit: animate ? EXIT_TRANSITION_DURATION : 0,
            }}
            classNames={{ ...styles }}
            onEnter={() =>
                setStyle(prev => ({
                    ...prev,
                    transform: `translateX(${width}px)`,
                }))
            }
            onEntering={() =>
                setStyle(prev => ({
                    ...prev,
                    transform: "translateX(0px)",
                    transition: `transform ${ENTER_TRANSITION_DURATION}ms cubic-bezier(0.16, 1.06, 0.21, 1.03)`,
                }))
            }
            onExit={() =>
                setStyle(prev => ({
                    ...prev,
                    transform: "translateX(0px)",
                }))
            }
            onExiting={() =>
                setStyle(prev => ({
                    ...prev,
                    transform: `translateX(${width}px)`,
                    transition: `transform ${EXIT_TRANSITION_DURATION}ms linear`,
                }))
            }
            mountOnEnter
            unmountOnExit
        >
            <Resizable
                as="div"
                className={classNames(className, styles.sideDrawer, animate && styles.animate)}
                enable={{
                    bottom: false,
                    bottomLeft: false,
                    bottomRight: false,
                    left: widthSpec?.type === Enums.SideDrawerWidthType.VARIABLE,
                    right: false,
                    top: false,
                    topLeft: false,
                    topRight: false,
                }}
                maxWidth={MAX_WIDTH}
                size={{ height: "100%", width }}
                style={style}
                {...(widthSpec?.type === Enums.SideDrawerWidthType.VARIABLE && {
                    minWidth: widthSpec.min,
                    onResizeStop: handleResizeEnd,
                })}
            >
                {children}
            </Resizable>
        </CSSTransition>
    );
}
