import {
    HocuspocusProvider,
    HocuspocusProviderWebsocket,
    HocuspocusProviderWebsocketConfiguration,
    WebSocketStatus,
} from "@hocuspocus/provider";
import { IndexeddbPersistence } from "y-indexeddb";
import { Awareness } from "y-protocols/awareness";
import * as Y from "yjs";

import { Config } from "Config";
import { Log } from "lib/Log";
import { Storage } from "lib/Storage";
import {
    getCurrentAccessToken,
    isAccessTokenAvailable,
    waitForAccessToken,
} from "lib/auth/AccessToken";

/**
 * Helper class to ensure document edits made while offline are eventually persisted, even if
 * the user is no longer viewing the document. All this class does is track a list of document
 * names in local storage and expose a method to get the current list.
 *
 * If a user has more than one tab open, each tab will have its own instance of this class. That's
 * fine, because if both tabs try to persist the same document, there's no problem, because
 * Yjs docs are commutative and idempotent.
 */
class OfflineUpdateTracker {
    documentNamesCache: Set<string>;

    private static storageKey = "pendingDocuments";

    constructor() {
        // Authoritative list of pending documents is in local storage. This in memory cache
        // just avoids reading/writing to storage on every edit.
        this.documentNamesCache = OfflineUpdateTracker.loadAllFromStorage();
    }

    getPendingDocuments() {
        this.documentNamesCache = OfflineUpdateTracker.loadAllFromStorage();
        return this.documentNamesCache;
    }

    onDocumentUpdate({ documentName }: { documentName: string }) {
        if (!this.documentNamesCache.has(documentName)) {
            this.enqueueDocument({ documentName });
        }
    }

    onDocumentPersisted({ documentName }: { documentName: string }) {
        this.dequeueDocument({ documentName });
    }

    private enqueueDocument({ documentName }: { documentName: string }) {
        this.documentNamesCache = OfflineUpdateTracker.loadAllFromStorage();
        this.documentNamesCache.add(documentName);
        OfflineUpdateTracker.saveAllToStorage({ documentNames: this.documentNamesCache });

        Log.debug("Enqueued document with offline edits", { documentName });
    }

    private dequeueDocument({ documentName }: { documentName: string }) {
        this.documentNamesCache = OfflineUpdateTracker.loadAllFromStorage();
        this.documentNamesCache.delete(documentName);
        OfflineUpdateTracker.saveAllToStorage({ documentNames: this.documentNamesCache });

        Log.debug("Dequeued document with offline edits", { documentName });
    }

    private static loadAllFromStorage() {
        return new Set((Storage.Local.getItem(OfflineUpdateTracker.storageKey) as string[]) ?? []);
    }

    private static saveAllToStorage({ documentNames }: { documentNames: Set<string> }) {
        Storage.Local.setItem(OfflineUpdateTracker.storageKey, Array.from(documentNames));
    }
}

type OfflineOptions = {
    onlineCheckIntervalMs: number;
    persistenceCheckIntervalMs: number;
    persistenceCheckTimeoutMs: number;
};

type Document = {
    awareness: Awareness;
    documentName: string;
    isDestroyed?: boolean;
    ydoc: Y.Doc;
};

type Providers = {
    hocuspocus: HocuspocusProvider;
    indexeddb: IndexeddbPersistence;
};

class HocuspocusManager {
    private offlineOptions: OfflineOptions;

    private offlineUpdateTracker: OfflineUpdateTracker;

    private documentsRegistry: Record<string, Document>;

    private providersRegistry: Record<string, Providers>;

    private websocketOptions: HocuspocusProviderWebsocketConfiguration;

    constructor({
        connectionCheckIntervalMs,
        offlineOptions,
        websocketOptions,
    }: {
        connectionCheckIntervalMs: number;
        offlineOptions: OfflineOptions;
        websocketOptions: HocuspocusProviderWebsocketConfiguration;
    }) {
        this.offlineOptions = offlineOptions;
        this.offlineUpdateTracker = new OfflineUpdateTracker();
        this.documentsRegistry = {};
        this.providersRegistry = {};
        this.websocketOptions = websocketOptions;

        window.setInterval(this.maybeForceReconnect.bind(this), connectionCheckIntervalMs);
        window.setInterval(
            this.maybePersistOfflineEdits.bind(this),
            offlineOptions.onlineCheckIntervalMs
        );
        window.addEventListener("online", this.maybePersistOfflineEdits.bind(this));
    }

    connect({ documentName, ydocState }: { documentName: string; ydocState?: Buffer }) {
        if (
            !this.documentsRegistry[documentName] ||
            this.documentsRegistry[documentName]?.isDestroyed
        ) {
            const ydoc = new Y.Doc();
            const awareness = new Awareness(ydoc);
            const document: Document = { awareness, documentName, ydoc };

            if (ydocState) {
                Y.applyUpdate(ydoc, ydocState);
            }

            if (this.documentsRegistry[documentName]) {
                Y.applyUpdate(
                    ydoc,
                    Y.encodeStateAsUpdate(this.documentsRegistry[documentName].ydoc)
                );
            }

            ydoc.on("update", () => {
                // If it appears the update may have occurred while we weren't connected,
                // track it so that we can try to persist it later.
                if (
                    !navigator.onLine ||
                    this.documentsRegistry[documentName]?.isDestroyed ||
                    this.providersRegistry[documentName].hocuspocus.configuration.websocketProvider
                        .status !== WebSocketStatus.Connected
                ) {
                    this.offlineUpdateTracker.onDocumentUpdate({ documentName });
                }
            });

            this.documentsRegistry[documentName] = document;
            this.providersRegistry[documentName] = this.createProviders({ document });
        } else {
            const document = this.documentsRegistry[documentName];

            if (ydocState) {
                Y.applyUpdate(document.ydoc, ydocState);
            }

            if (!this.providersRegistry[documentName]) {
                this.providersRegistry[documentName] = this.createProviders({ document });
            }
        }

        return {
            ...this.documentsRegistry[documentName],
            providers: this.providersRegistry[documentName],
        };
    }

    disconnect({ documentName }: { documentName: string }) {
        if (this.documentsRegistry[documentName]) {
            this.documentsRegistry[documentName].isDestroyed = true;
        }

        if (this.providersRegistry[documentName]) {
            const providers = this.providersRegistry[documentName];

            delete this.providersRegistry[documentName];

            HocuspocusManager.cleanupProviders({ providers });
        }
    }

    private createProviders({ document }: { document: Document }): Providers {
        // As of April 2024, we explored using a single, shared socket connection shared by
        // all documents ("multiplexed"), as per the example at
        // https://tiptap.dev/docs/hocuspocus/provider/examples#multiplexing.
        //
        // In practice that's not necessary, since a user can only ever view one topic at
        // a time. Nevertheless, it felt like the right architecture.
        //
        // However, we discovered a pitfall. When a user leaves a topic's page, we
        // want the server to unload that document (if no other clients are connected to it).
        // But evidently the server only does that if the entire socket is closed. Using
        // a multiplexed socket, the socket would never be closed (since it might be in
        // use for other documents).
        //
        // So, we went back to the simple (default) approach of just having separate sockets
        // per document.
        const websocket = new HocuspocusProviderWebsocket({ ...this.websocketOptions });

        return {
            hocuspocus: new HocuspocusProvider({
                awareness: document.awareness,
                document: document.ydoc,
                name: document.documentName,
                token: getCurrentAccessToken(),
                websocketProvider: websocket,
            }),
            indexeddb: new IndexeddbPersistence(document.documentName, document.ydoc),
        };
    }

    private maybeForceReconnect() {
        for (const providers of Object.values(this.providersRegistry)) {
            const isAccessTokenStale =
                providers.hocuspocus.configuration.token !== getCurrentAccessToken();
            const isDisconnected =
                providers.hocuspocus.configuration.websocketProvider.status ===
                WebSocketStatus.Disconnected;
            const shouldReconnect = isDisconnected || isAccessTokenStale;

            if (shouldReconnect) {
                Log.info("Reconnecting Hocuspocus websocket", {
                    isAccessTokenStale,
                    isDisconnected,
                });

                providers.hocuspocus.setConfiguration({ token: getCurrentAccessToken() });
                providers.hocuspocus.configuration.websocketProvider.disconnect();
                void providers.hocuspocus.configuration.websocketProvider.connect();
            }
        }
    }

    private async maybePersistOfflineEdits() {
        if (!navigator.onLine) {
            return;
        }

        try {
            await waitForAccessToken({ predicates: [isAccessTokenAvailable] });
        } catch (error) {
            Log.debug("Cannot persist without access token", { error });
            return;
        }

        const documentNames = this.offlineUpdateTracker.getPendingDocuments();

        // Here when persisting offline edits, it would be preferable to use a single shared
        // socket. That way, if the user made lots of offline edits to many documents, we only
        // open one socket, not many. Unlike in the online flow, that would be acceptable here
        // because we know we're only going to keep the socket only, for this one-off sync, and
        // then close it.
        //
        // However, as of April 2024, when we tried that, something in HocuspocusProvider or
        // HocuspocusProviderWebsocket caused it to try to reopen the socket.
        //
        // We get the sense that the lifecycle semantics in HocuspocusProvider and
        // HocuspocusProviderWebsocket are not properly defined/implemented, leading to weird
        // bugs like we experienced.
        try {
            await Promise.all(
                Array.from(documentNames).map(documentName =>
                    this.tryPersistDocument({ documentName })
                )
            );
        } catch (error) {
            Log.warn("Failed to persist documents edited offline", { error });
        }
    }

    private async tryPersistDocument({ documentName }: { documentName: string }) {
        const ydoc = new Y.Doc();
        const awareness = new Awareness(ydoc);
        const document: Document = { awareness, documentName, ydoc };
        const providers = this.createProviders({ document });
        const startTime = Date.now();

        try {
            await providers.indexeddb.whenSynced;

            while (providers.hocuspocus.hasUnsyncedChanges) {
                if (Date.now() - startTime > this.offlineOptions.persistenceCheckTimeoutMs) {
                    throw new Error("Timed out attempting to persist offline edits");
                }

                await new Promise(resolve =>
                    setTimeout(resolve, this.offlineOptions.persistenceCheckIntervalMs)
                );
            }

            this.offlineUpdateTracker.onDocumentPersisted({ documentName });
        } finally {
            HocuspocusManager.cleanupProviders({ providers });
        }
    }

    private static cleanupProviders({ providers }: { providers: Providers }) {
        providers.hocuspocus.disconnectBroadcastChannel();

        void providers.indexeddb.destroy();
        providers.hocuspocus.configuration.websocketProvider.destroy();
        providers.hocuspocus.destroy();
    }
}

export const hocuspocusManager = new HocuspocusManager({
    connectionCheckIntervalMs: Config.collaboration.connectionCheckIntervalMs,
    offlineOptions: Config.collaboration.offlineOptions,
    websocketOptions: {
        ...Config.collaboration.websocketOptions,
        connect: true,
        url: Config.collaboration.urlWs,
    },
});
