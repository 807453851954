import { useCallback } from "react";

import { v4 as uuidv4 } from "uuid";

import { AppToaster } from "components/ui/core/AppToaster";
import { useReplicache } from "lib/replicache/Context";
import { isError } from "lib/types/guards";

/**
 * Create a simple task on a tasklist.
 */
export function useCreateTask() {
    const { replicache } = useReplicache();

    const createTask = useCallback(
        async ({
            ticketId,
            tasklistId,
            tasklistPos,
            title,
            isComplete: _isComplete,
        }: {
            ticketId: string;
            tasklistId: string;
            tasklistPos: number;
            title: string;
            isComplete?: boolean;
        }) => {
            const taskId = uuidv4();

            await replicache.mutate.createTask({
                taskId,
                tasklistId,
                tasklistPos,
                title,
            });

            return taskId;
        },
        [replicache]
    );

    return { createTask };
}

/**
 * Create a child ticket task on a tasklist, referencing an existing ticket.
 */
export function useCreateChildTicketTask() {
    const { replicache } = useReplicache();

    const createChildTicketTask = useCallback(
        async ({
            ticketId,
            tasklistId,
            tasklistPos,
            childTicketId,
        }: {
            ticketId: string;
            tasklistId: string;
            tasklistPos: number;
            childTicketId: string;
        }) => {
            const taskId = uuidv4();

            try {
                await replicache.mutate.createChildTicketTask({
                    taskId,
                    tasklistId,
                    tasklistPos,
                    childTicketId,
                });

                return taskId;
            } catch (e) {
                if (isError(e) && e.message === "Cycle detected") {
                    AppToaster.danger({
                        message: "Sorry, you can't have parent/child form a loop.",
                    });
                }

                return null;
            }
        },
        [replicache]
    );

    return { createChildTicketTask };
}

/**
 * Promote an existing regular task to a child ticket task. The child ticket must already exist.
 */
export function usePromoteTaskToChildTicketTask() {
    const { replicache } = useReplicache();

    const promoteTaskToChildTicketTask = useCallback(
        async ({ taskId, childTicketId }: { taskId: string; childTicketId: string }) => {
            await replicache.mutate.promoteTask({ taskId, childTicketId });
        },
        [replicache]
    );

    return { promoteTaskToChildTicketTask };
}
