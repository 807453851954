import { useCallback, useEffect, useRef, useState } from "react";

import * as Y from "yjs";

import { hocuspocusManager } from "lib/hocuspocus/HocuspocusManager";
import { isDefined } from "lib/types/guards";

export type CollaborativeDocSpec = {
    documentName: string;
    ydocState: Buffer | undefined;
};

export type CollaborativeDoc = {
    awareness: ReturnType<typeof hocuspocusManager.connect>["awareness"];
    documentName: string;
    emptyCursor: HTMLSpanElement;
    providers: ReturnType<typeof hocuspocusManager.connect>["providers"];
    ydoc: ReturnType<typeof hocuspocusManager.connect>["ydoc"];
};

export type CollaborativeDocUser = {
    browserSessionId: string;
    id: number;
    name: string;
};

function createCollaborativeDoc({
    documentName,
    ydocState,
}: {
    documentName: string;
    ydocState?: Buffer;
}): CollaborativeDoc {
    const { awareness, providers, ydoc } = hocuspocusManager.connect({ documentName, ydocState });

    return {
        awareness,
        documentName,
        emptyCursor: document.createElement("span"),
        providers,
        ydoc,
    };
}

export function useCollaborativeDocConnectedUsers({
    collaborativeDoc,
}: {
    collaborativeDoc: CollaborativeDoc;
}) {
    const {
        ydoc: { clientID },
        awareness,
    } = collaborativeDoc;

    const getConnectedUsers = useCallback(() => {
        if (!awareness || !clientID) {
            return [];
        }

        return Array.from(awareness.getStates().values())
            .map(v => v.user as CollaborativeDocUser)
            .filter(isDefined);
    }, [awareness, clientID]);

    const [connectedUsers, setConnectedUsers] = useState(getConnectedUsers());

    const handleAwarenessUpdate = useCallback(() => {
        setConnectedUsers(getConnectedUsers());
    }, [getConnectedUsers]);

    useEffect(() => {
        awareness?.on("update", handleAwarenessUpdate);

        return () => {
            awareness?.off("update", () => handleAwarenessUpdate);
        };
    }, [awareness, handleAwarenessUpdate]);

    return connectedUsers;
}

export function useCollaborativeDoc({ spec }: { spec: CollaborativeDocSpec }) {
    const collaborativeDocRef = useRef<CollaborativeDoc>(
        createCollaborativeDoc({
            documentName: spec.documentName,
            ydocState: spec.ydocState,
        })
    );

    if (
        spec.documentName &&
        (!collaborativeDocRef.current ||
            collaborativeDocRef.current.documentName !== spec.documentName)
    ) {
        collaborativeDocRef.current = createCollaborativeDoc({
            documentName: spec.documentName,
            ydocState: spec.ydocState,
        });
    }

    useEffect(() => {
        const ydoc = collaborativeDocRef.current?.ydoc;

        if (ydoc && spec.ydocState) {
            Y.applyUpdate(ydoc, spec.ydocState);
        }
    }, [spec.ydocState]);

    useEffect(() => {
        const lastDocumentName = spec.documentName;

        return () => {
            if (lastDocumentName) {
                hocuspocusManager.disconnect({ documentName: lastDocumentName });
            }
        };
    }, [spec.documentName]);

    return collaborativeDocRef.current;
}
