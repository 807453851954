import React from "react";

import { Extension } from "@tiptap/core";
import { PluginKey } from "@tiptap/pm/state";
import Suggestion from "@tiptap/suggestion";
import NodeEmoji from "node-emoji";

import { Menu } from "components/ui/core/Menu";
import { MenuItem } from "components/ui/core/MenuItem";
import { buildSuggestionParams } from "components/ui/editor/extensions/helpers/Suggestions";

type EmojiSuggestionsMenuProps = {
    items: NodeEmoji.Emoji[];
    selectedIndex: number;
    onSelect: (selectedIndex: number) => void;
};

function EmojiSuggestionsMenu({ items, selectedIndex, onSelect }: EmojiSuggestionsMenuProps) {
    return (
        <Menu>
            {items.map((emojiItem, index) => (
                <MenuItem
                    key={emojiItem.key}
                    active={index === selectedIndex}
                    text={<span>{`${emojiItem.emoji} :${emojiItem.key}:`}</span>}
                    onClick={() => onSelect(index)}
                    instrumentation={{
                        elementName: "emoji_suggestions_menu",
                        eventData: { key: emojiItem.key },
                    }}
                />
            ))}
        </Menu>
    );
}

export default Extension.create({
    name: "emoji",

    addProseMirrorPlugins() {
        return [
            Suggestion({
                pluginKey: new PluginKey("emoji"),
                editor: this.editor,
                ...buildSuggestionParams({
                    char: ":",
                    type: "text",
                    search: query =>
                        NodeEmoji.search(query)
                            .slice(0, 5)
                            .map(emojiItem => ({
                                item: emojiItem,
                                isExactMatch: emojiItem.key === query,
                            })),
                    SuggestionsMenuComponent: EmojiSuggestionsMenu,
                    itemToNodeContent: emojiItem => ({ text: emojiItem.emoji }),
                    allowedPrefixes: [" ", "(", "["],
                    autoReplaceExactMatch: true,
                    endsWithTriggerChar: true,
                }),
            }),
        ];
    },
});
