import React, { useCallback, useState } from "react";

import { CommonEnums, DbColumnTypes, P } from "c9r-common";
import classNames from "classnames";
import { useDebouncedCallback } from "use-debounce";

import { useLiveViewQuery } from "components/loading/Loading";
import { BoardSettings } from "components/shared/BoardSettings";
import { AppToaster } from "components/ui/core/AppToaster";
import { TextInput } from "components/ui/core/TextInput";
import { useMutations } from "contexts/MutationsContext";
import { useCurrentUser } from "contexts/UserContext";
import { Log } from "lib/Log";
import { Queries } from "lib/Queries";
import { getFragmentData, gql } from "lib/graphql/__generated__";
import { GeneralSettings_orgFragment } from "lib/graphql/__generated__/graphql";

import styles from "./GeneralSettings.module.scss";

const fragments = {
    org: gql(/* GraphQL */ `
        fragment GeneralSettings_org on orgs {
            id
            display_name
            settings
        }
    `),
};

type TOrg = GeneralSettings_orgFragment;

type TCode = DbColumnTypes.BoardsSettings[typeof CommonEnums.BoardSettingType.CODE];
type TDueDates = DbColumnTypes.BoardsSettings[typeof CommonEnums.BoardSettingType.DUE_DATES];
type TSizes = DbColumnTypes.BoardsSettings[typeof CommonEnums.BoardSettingType.SIZES];

type GeneralSettingsContentProps = {
    org: TOrg;
};

function GeneralSettingsContent({ org }: GeneralSettingsContentProps) {
    const {
        updateOrgDisplayName: updateOrgDisplayNameMutation,
        updateOrgSettings: updateOrgSettingsMutation,
    } = useMutations();

    const [orgName, setOrgName] = useState(org.display_name ?? "");
    const maxOrgNameLength = 100;
    const defaultBoardSettings = org.settings[CommonEnums.OrgSettingType.DEFAULT_BOARD_SETTINGS];

    const saveOrgName = useCallback(
        async (newOrgName?: string) => {
            if (!newOrgName) {
                return;
            }

            try {
                await P.retry(() => updateOrgDisplayNameMutation({ displayName: newOrgName }), {
                    maxAttempts: 3,
                });
            } catch (error) {
                Log.error("Failed to save org name", { error });
                AppToaster.error({
                    message: "Sorry, something went wrong saving your changes.",
                });
            }
        },
        [updateOrgDisplayNameMutation]
    );

    const debouncedSaveOrgName = useDebouncedCallback(saveOrgName, 1000, {
        leading: true,
        trailing: true,
    }).callback;

    const saveCode = useCallback(
        async ({ code: newCode }: { code: TCode }) => {
            try {
                await updateOrgSettingsMutation({
                    settings: {
                        ...org.settings,
                        [CommonEnums.OrgSettingType.DEFAULT_BOARD_SETTINGS]: {
                            ...org.settings[CommonEnums.OrgSettingType.DEFAULT_BOARD_SETTINGS],
                            [CommonEnums.BoardSettingType.CODE]: newCode,
                        },
                    },
                });
            } catch (error) {
                Log.error("Failed to save organization settings", { error });
                AppToaster.error({
                    message: "Sorry, something went wrong saving your changes.",
                });
            }
        },
        [org.settings, updateOrgSettingsMutation]
    );

    const saveDueDates = useCallback(
        async ({ dueDates: newDueDates }: { dueDates: TDueDates }) => {
            try {
                await updateOrgSettingsMutation({
                    settings: {
                        ...org.settings,
                        [CommonEnums.OrgSettingType.DEFAULT_BOARD_SETTINGS]: {
                            ...org.settings[CommonEnums.OrgSettingType.DEFAULT_BOARD_SETTINGS],
                            [CommonEnums.BoardSettingType.DUE_DATES]: newDueDates,
                        },
                    },
                });
            } catch (error) {
                Log.error("Failed to save organization settings", { error });
                AppToaster.error({
                    message: "Sorry, something went wrong saving your changes.",
                });
            }
        },
        [org.settings, updateOrgSettingsMutation]
    );

    const saveSizes = useCallback(
        async ({ sizes: newSizes }: { sizes: TSizes }) => {
            try {
                await updateOrgSettingsMutation({
                    settings: {
                        ...org.settings,
                        [CommonEnums.OrgSettingType.DEFAULT_BOARD_SETTINGS]: {
                            ...org.settings[CommonEnums.OrgSettingType.DEFAULT_BOARD_SETTINGS],
                            ...(newSizes && { [CommonEnums.BoardSettingType.SIZES]: newSizes }),
                        },
                    },
                });
            } catch (error) {
                Log.error("Failed to save ticket size scheme", { error });
                AppToaster.error({
                    message: "Sorry, something went wrong saving your changes.",
                });
            }
        },
        [org.settings, updateOrgSettingsMutation]
    );

    return (
        <div className={styles.container}>
            <h1>General</h1>
            <form onSubmit={e => e.preventDefault()}>
                <div className={styles.formGroup}>
                    <h4>Organization name</h4>
                    <div className={styles.formGroupCaption}>
                        Flat will use this name in communications to you and other users in your
                        organization.
                    </div>
                    <TextInput
                        id={styles.orgNameInput}
                        maxLength={maxOrgNameLength}
                        placeholder="Organization name"
                        value={orgName}
                        onChange={e => {
                            setOrgName(e.target.value);
                            void debouncedSaveOrgName(e.target.value);
                        }}
                        onBlur={e => {
                            void saveOrgName(e.target.value);
                        }}
                    />
                    <p
                        className={classNames(
                            styles.invalidOrgNameMsg,
                            !orgName && styles.isOrgNameInvalid
                        )}
                    >
                        Organization name cannot be empty.
                    </p>
                </div>
                <div className={styles.formGroup}>
                    <h4>Default workspace options</h4>
                    <div className={styles.formGroupCaption}>
                        New workspaces will start with these settings by default, but you can still
                        configure each workspace individually.
                    </div>
                    <BoardSettings
                        code={defaultBoardSettings?.[CommonEnums.BoardSettingType.CODE]}
                        dueDates={defaultBoardSettings?.[CommonEnums.BoardSettingType.DUE_DATES]}
                        sizes={defaultBoardSettings?.[CommonEnums.BoardSettingType.SIZES]}
                        onCodeChange={saveCode}
                        onDueDatesChange={saveDueDates}
                        onSizesChange={saveSizes}
                    />
                </div>
            </form>
        </div>
    );
}

export function GeneralSettings() {
    const currentUser = useCurrentUser();
    const componentQuery = useLiveViewQuery({
        query: GeneralSettings.queries.component,
        variables: {
            orgId: currentUser.org_id,
        },
    });

    if (componentQuery.loading && !componentQuery.data) {
        return null;
    }

    if (componentQuery.error && !componentQuery.data) {
        throw componentQuery.error;
    }

    const org = getFragmentData(fragments.org, componentQuery.data?.org);

    if (!org) {
        return null;
    }

    return <GeneralSettingsContent org={org} />;
}

GeneralSettings.queries = {
    component: gql(/* GraphQL */ `
        query GeneralSettings($orgId: Int!) {
            org: orgs_by_pk(id: $orgId) {
                ...GeneralSettings_org
            }
        }
    `),
};

Queries.register({
    component: "GeneralSettings",
    gqlMapByName: GeneralSettings.queries,
});
