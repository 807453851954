import React from "react";

import { useRecoilValue } from "recoil";

import { Config } from "Config";
import { BrandLogomark } from "components/shared/BrandLogomark";
import { BorderButton } from "components/ui/core/BorderButton";
import { Dialog, dialogStateFamily, useDialogSingleton } from "components/ui/core/Dialog";
import { parsedUserAgent } from "lib/UserAgent";

import styles from "./OnboardingWelcomeDialog.module.scss";

export type OnboardingWelcomeDialogProps = {
    isUserOrgProvisioner?: boolean;
};

const dialogState = dialogStateFamily<OnboardingWelcomeDialogProps>("OnboardingWelcomeDialog");

export function useOnboardingWelcomeDialogState() {
    return useRecoilValue(dialogState);
}

export function useOnboardingWelcomeDialog() {
    return useDialogSingleton(dialogState);
}

export function OnboardingWelcomeDialog() {
    const { isOpen, props } = useRecoilValue(dialogState);
    const { isUserOrgProvisioner } = props ?? {};
    const dialog = useOnboardingWelcomeDialog();

    return (
        <Dialog
            className={styles.dialog}
            isOpen={isOpen}
            onClose={dialog.close}
            isCloseButtonShown={false}
        >
            <Dialog.Header className={styles.header}>
                <BrandLogomark height={24} />
            </Dialog.Header>

            <Dialog.Body className={styles.body}>
                <p>
                    👋 <strong>Hi!</strong> And welcome to your team’s new home base.
                </p>
                <p>
                    <strong>New to Flat?</strong>{" "}
                    {isUserOrgProvisioner ? (
                        <>
                            Don’t worry, we made a workspace called <em>Explore Flat</em> with some
                            sample topics to show you the basics.
                        </>
                    ) : (
                        <>
                            Check out the{" "}
                            <a
                                href={Config.urls.docs.gettingStartedGuide}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                getting started guide
                            </a>
                            .
                        </>
                    )}
                </p>
                <p>
                    <strong>Quick tip:</strong>{" "}
                    {parsedUserAgent.device.isMobile || parsedUserAgent.device.isTablet
                        ? "Tap in any column to create a new topic."
                        : "Click in any column to create a new topic."}
                </p>
            </Dialog.Body>

            <Dialog.Footer className={styles.footer}>
                <Dialog.FooterActions className={styles.footerActions}>
                    <BorderButton
                        brandCta
                        className={styles.submitBtn}
                        content="Let's go"
                        instrumentation={{
                            elementName: "onboarding_welcome_dialog.submit_btn",
                        }}
                        onClick={dialog.close}
                        sharper
                    />
                </Dialog.FooterActions>
            </Dialog.Footer>
        </Dialog>
    );
}
