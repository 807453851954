import React from "react";

import { CommonEnums } from "c9r-common";

import { QueryLoader, TQueryResult } from "components/loading/QueryLoader";
import { EllipsisButton } from "components/ui/common/EllipsisButton";
import { TimeAgo } from "components/ui/common/TimeAgo";
import { BorderButton } from "components/ui/core/BorderButton";
import { Icon } from "components/ui/core/Icon";
import { Menu } from "components/ui/core/Menu";
import { MenuItem } from "components/ui/core/MenuItem";
import { MenuPopover } from "components/ui/core/MenuPopover";
import { TextButton } from "components/ui/core/TextButton";
import { Tooltip } from "components/ui/core/Tooltip";
import { useMutations } from "contexts/MutationsContext";
import { useCurrentUser } from "contexts/UserContext";
import { useArchiveBoardDialog } from "dialogs/ArchiveBoardDialog";
import { useBoardImportNotesDialog } from "dialogs/BoardImportNotesDialog";
import { useCreateBoardDialog } from "dialogs/CreateBoardDialog";
import { useEditBoardDialog } from "dialogs/EditBoardDialog";
import { ExternalTicketSources } from "lib/Constants";
import { Enums } from "lib/Enums";
import { useInstrumentation } from "lib/Instrumentation";
import { Queries } from "lib/Queries";
import { NavLink } from "lib/Routing";
import { useUrlBuilders } from "lib/Urls";
import { getFragmentData, gql } from "lib/graphql/__generated__";
import {
    BoardSettingsQuery,
    BoardSettings_orgFragment,
    EditBoardDialog_boardFragmentDoc,
} from "lib/graphql/__generated__/graphql";

import styles from "./BoardSettings.module.scss";

const fragments = {
    org: gql(/* GraphQL */ `
        fragment BoardSettings_org on orgs {
            id

            all_boards: boards {
                id
                access_type
                archived_at
                display_name
                slug

                attached_users {
                    user {
                        id
                        disabled_at
                        role
                    }
                }

                import {
                    id
                    source
                    notes
                    processed_at
                }

                ...EditBoardDialog_board
            }
        }
    `),
};

type TOrg = BoardSettings_orgFragment;
type TBoard = BoardSettings_orgFragment["all_boards"][number];

type BoardRowProps = {
    board: TBoard;
};

function BoardRow({ board }: BoardRowProps) {
    const { recordCallback } = useInstrumentation();
    const { buildBoardUrl } = useUrlBuilders();
    const boardImportNotesDialog = useBoardImportNotesDialog();
    const editBoardDialog = useEditBoardDialog();
    const archiveBoardDialog = useArchiveBoardDialog();
    const { archiveBoard, unarchiveBoard } = useMutations();

    const isProcessedExternalImport = !!(
        board.import?.processed_at && ExternalTicketSources[board.import.source]
    );

    const attachedGuests = board.attached_users.filter(
        au => au.user.role === CommonEnums.UserRole.USER_ORG_GUEST && !au.user.disabled_at
    );

    return (
        <tr>
            <td>
                <div className={styles.boardRowLeftSide}>
                    <NavLink
                        className={styles.boardLink}
                        to={
                            buildBoardUrl({
                                boardSlug: board.slug,
                                vanity: { boardDisplayName: board.display_name },
                            }).pathname
                        }
                        onClick={recordCallback({
                            eventType: Enums.InstrumentationEvent.CLICK,
                            elementName: "board_settings.view_board",
                        })}
                        role="link"
                    >
                        {board.access_type === CommonEnums.BoardAccessType.PRIVATE ? (
                            <Icon icon="lock" iconSet="c9r" iconSize={16} strokeWidthAbsolute={2} />
                        ) : (
                            <Icon icon="spaceFrame" iconSet="c9r" iconSize={16} strokeWeight={1} />
                        )}
                        <span>{board.display_name}</span>
                    </NavLink>
                    {board.archived_at ? (
                        <div className={styles.boardArchiveDetails}>
                            (archived <TimeAgo date={board.archived_at} />)
                        </div>
                    ) : null}
                </div>
                <div className={styles.boardRowSpacer} />
                <div className={styles.boardRowRightSide}>
                    {isProcessedExternalImport ? (
                        <div className={styles.boardImportDetails}>
                            <span>
                                Imported from{" "}
                                {ExternalTicketSources[board.import!.source]!.displayName}{" "}
                                <TimeAgo date={board.import!.processed_at!} />.
                            </span>
                            {board.import!.notes ? (
                                <span>
                                    {" "}
                                    <TextButton
                                        instrumentation={{
                                            elementName: "board_settings.show_import_notes_btn",
                                            eventData: {
                                                boardId: board.id,
                                            },
                                        }}
                                        link
                                        onClick={() =>
                                            boardImportNotesDialog.openWithProps({
                                                board,
                                            })
                                        }
                                        text="View import notes."
                                    />
                                </span>
                            ) : null}
                        </div>
                    ) : null}
                    {attachedGuests.length ? (
                        <div className={styles.boardGuestDetails}>
                            {attachedGuests.length === 1
                                ? "1 guest"
                                : `${attachedGuests.length} guests`}
                        </div>
                    ) : null}
                    {board.archived_at ? (
                        <Tooltip content="Unarchive workspace" placement="bottom" small>
                            <BorderButton
                                className={styles.unarchiveButton}
                                content={
                                    <Icon
                                        icon="unarchive"
                                        iconSet="c9r"
                                        iconSize={20}
                                        strokeWidthAbsolute={1}
                                    />
                                }
                                instrumentation={{
                                    elementName: "board_settings.unarchive_btn",
                                    eventData: {
                                        boardId: board.id,
                                    },
                                }}
                                minimal
                                onClick={() => unarchiveBoard({ boardId: board.id })}
                            />
                        </Tooltip>
                    ) : (
                        <MenuPopover
                            content={
                                <Menu className={styles.boardMenu}>
                                    <MenuItem
                                        icon={
                                            <Icon icon="settings" iconSet="lucide" iconSize={18} />
                                        }
                                        instrumentation={{
                                            elementName: "board_settings.menu.setup",
                                            eventData: {
                                                boardId: board.id,
                                            },
                                        }}
                                        onClick={() => {
                                            editBoardDialog.openWithProps({
                                                board: getFragmentData(
                                                    EditBoardDialog_boardFragmentDoc,
                                                    board
                                                ),
                                                redirectOnNameChange: false,
                                            });
                                        }}
                                        text="Workspace setup"
                                    />
                                    <MenuItem
                                        icon={<Icon icon="archive" iconSet="c9r" iconSize={18} />}
                                        instrumentation={{
                                            elementName: "board_settings.menu.archive",
                                            eventData: {
                                                boardId: board.id,
                                            },
                                        }}
                                        onClick={() => {
                                            archiveBoardDialog.openWithProps({
                                                boardDisplayName: board.display_name,
                                                onSubmit: () => {
                                                    void archiveBoard({ boardId: board.id });
                                                    archiveBoardDialog.close();
                                                },
                                            });
                                        }}
                                        text="Archive workspace"
                                    />
                                </Menu>
                            }
                            minimal
                            modifiers={{
                                offset: {
                                    enabled: true,
                                    options: {
                                        offset: [8, -4],
                                    },
                                },
                            }}
                            placement="bottom-start"
                            shouldReturnFocusOnClose={false}
                        >
                            <EllipsisButton iconSize={18} instrumentation={null} vertical />
                        </MenuPopover>
                    )}
                </div>
            </td>
        </tr>
    );
}

type BoardSettingsContentProps = {
    org: TOrg;
};

function BoardSettingsContent({ org }: BoardSettingsContentProps) {
    const createBoardDialog = useCreateBoardDialog();

    const boardSections = [
        {
            title: "Active",
            boards: org.all_boards
                .filter(board => !board.archived_at)
                .sort((a, b) => a.display_name.localeCompare(b.display_name)),
        },
        {
            title: "Archived",
            boards: org.all_boards
                .filter(board => !!board.archived_at)
                .sort((a, b) => a.display_name.localeCompare(b.display_name)),
        },
    ];

    return (
        <div className={styles.boardSettings}>
            <div className={styles.header}>
                <h1 className={styles.title}>Workspaces</h1>
                <BorderButton
                    className={styles.createBoardButton}
                    contentClassName={styles.createBoardButtonContent}
                    content="Create a workspace"
                    brandCta
                    onClick={() => createBoardDialog.open()}
                    instrumentation={{ elementName: "board_settings.create_board_btn" }}
                />
            </div>

            <div className={styles.boardsTableWrapper}>
                <table className={styles.boardsTable}>
                    {boardSections.map(section =>
                        section.boards.length ? (
                            <tbody key={section.title}>
                                <tr className={styles.sectionTitleRow}>
                                    <td>
                                        <div className={styles.sectionTitleAndBoardCount}>
                                            <span className={styles.sectionTitle}>
                                                {section.title}
                                            </span>
                                            <span className={styles.sectionBoardCount}>
                                                ({section.boards.length})
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                                {section.boards.map(board => (
                                    <BoardRow key={board.id} board={board} />
                                ))}
                            </tbody>
                        ) : null
                    )}
                </table>
            </div>
        </div>
    );
}

type BoardSettingsImplProps = {
    queryResult: TQueryResult<BoardSettingsQuery>;
};

function BoardSettingsImpl({ queryResult }: BoardSettingsImplProps) {
    if (queryResult.loading && !queryResult.data) {
        return null;
    }

    if (queryResult.error && !queryResult.data) {
        throw queryResult.error;
    }

    const org = getFragmentData(fragments.org, queryResult.data?.org);

    if (!org) {
        return null;
    }

    return <BoardSettingsContent org={org} />;
}

export function BoardSettings() {
    const currentUser = useCurrentUser();

    return (
        <QueryLoader
            query={BoardSettings.queries.component}
            variables={{ orgId: currentUser.org_id }}
        >
            {({ queryResult }) => <BoardSettingsImpl queryResult={queryResult} />}
        </QueryLoader>
    );
}

BoardSettings.queries = {
    component: gql(/* GraphQL */ `
        query BoardSettings($orgId: Int!) {
            org: orgs_by_pk(id: $orgId) {
                ...BoardSettings_org
            }
        }
    `),
};

Queries.register({ component: "BoardSettings", gqlMapByName: BoardSettings.queries });
