import React, { useCallback, useReducer, useRef } from "react";

import { Editor as TEditor } from "@tiptap/react";
import { CommonEnums, Errors } from "c9r-common";
import classNames from "classnames";

import { TourStop, TourStopAnchor } from "components/shared/TourStop";
import { BorderButton } from "components/ui/core/BorderButton";
import { Icon } from "components/ui/core/Icon";
import { Editor } from "components/ui/editor/Editor";
import { EditorToolbar } from "components/ui/editor/EditorToolbar";
import { Enums } from "lib/Enums";
import { FragmentType, getFragmentData, gql } from "lib/graphql/__generated__";

import styles from "./DescriptionEditor.module.scss";
import { useDetailView } from "../context/DetailViewContext";

const fragments = {
    ticket: gql(/* GraphQL */ `
        fragment DescriptionEditor_ticket on tickets {
            id
            content_yjs

            board {
                id

                ...Editor_board
            }
        }
    `),
};

const linkUnfurlTypesToDisplay = Object.values(Enums.LinkUnfurlType);

type EditingAction = "blur" | "focus" | "toggleToolbar" | "update";

type EditingState = {
    isDirtySinceLastFocus: boolean;
    isFocused: boolean;
    isToolbarVisible: boolean;
};

function useEditingState(initialState?: Partial<EditingState>) {
    return useReducer(
        (prev: EditingState, action: EditingAction): EditingState => {
            switch (action) {
                case "blur":
                    return {
                        ...prev,
                        isToolbarVisible: false,
                        isFocused: false,
                    };
                case "focus":
                    return {
                        ...prev,
                        isDirtySinceLastFocus: false,
                        isFocused: true,
                    };
                case "toggleToolbar":
                    return {
                        ...prev,
                        isToolbarVisible: !prev.isToolbarVisible,
                    };
                case "update":
                    return {
                        ...prev,
                        isDirtySinceLastFocus: prev.isFocused,
                    };
                default:
                    throw new Errors.UnexpectedCaseError({ action });
            }
        },
        {
            isDirtySinceLastFocus: false,
            isFocused: false,
            isToolbarVisible: false,
            ...initialState,
        }
    );
}

export type DescriptionEditorProps = {
    autoFocus?: boolean;
    className?: string;
    ticket: FragmentType<typeof fragments.ticket>;
};

export function DescriptionEditor({
    autoFocus,
    className,
    ticket: _ticketFragment,
}: DescriptionEditorProps) {
    const ref = useRef<HTMLDivElement>(null);
    const ticket = getFragmentData(fragments.ticket, _ticketFragment);
    const { ticketDoc } = useDetailView();
    const [state, dispatch] = useEditingState({ isFocused: autoFocus });

    const handleBlur = useCallback(() => dispatch("blur"), [dispatch]);
    const handleFocus = useCallback(() => dispatch("focus"), [dispatch]);

    const handleUpdate = useCallback(() => {
        dispatch("update");
    }, [dispatch]);

    const handleClickBelow = useCallback(({ editor }: { editor: TEditor }) => {
        editor.chain().focus("end").run();

        if (!editor.isEmpty) {
            editor.chain().enter().run();
        }
    }, []);

    return (
        <TourStop
            tourId={CommonEnums.TourId.DETAIL_VIEW}
            subject={CommonEnums.TourSubject.DESCRIPTION}
            side="top"
        >
            <TourStopAnchor className={styles.tourStopAnchor} />
            <div className={classNames(className, styles.descriptionEditor)} ref={ref}>
                <Editor
                    autoFocus={autoFocus}
                    board={ticket.board}
                    className={styles.editor}
                    clickBelowProps={{
                        targetClassName: styles.editorClickBelowTarget,
                        targetOnClick: handleClickBelow,
                    }}
                    collaborativeDoc={ticketDoc}
                    collaborativeDocField="description"
                    editorContentClassName={styles.editorContent}
                    emoji
                    images
                    linkUnfurlTypes={linkUnfurlTypesToDisplay}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    onUpdate={handleUpdate}
                    placeholderText="Write a description..."
                    renderToolbar={({ toolbarProps }) => (
                        <div
                            className={classNames(
                                styles.editorToolbarWrapper,
                                !state.isFocused && styles.editorToolbarWrapperHidden
                            )}
                        >
                            <EditorToolbar
                                {...toolbarProps}
                                className={styles.editorToolbar}
                                containerRef={ref}
                                hidden={!state.isToolbarVisible}
                            />
                            <BorderButton
                                active={state.isToolbarVisible}
                                className={styles.formatButton}
                                content={<Icon icon="format" iconSet="c9r" iconSize={24} />}
                                contentClassName={styles.formatButtonContent}
                                minimal
                                onClick={() => dispatch("toggleToolbar")}
                                small
                                square
                                tighter
                                instrumentation={{
                                    elementName: "description_editor.toolbar_toggle_btn",
                                }}
                            />
                        </div>
                    )}
                    ticketReferences
                />
            </div>
        </TourStop>
    );
}
