import React, { useCallback, useMemo, useState } from "react";

import { CommonEnums, sortStages, sortTasks } from "c9r-common";
import { useRecoilValue } from "recoil";

import {
    TicketSelector,
    TicketSelectorItem,
    TicketSelectorItemType,
} from "components/shared/TicketSelector";
import { Dialog, dialogStateFamily, useDialogSingleton } from "components/ui/core/Dialog";
import { moveToLastPosition, moveToPositionByIndex } from "lib/EntityPositioning";
import { getFragmentData, gql } from "lib/graphql/__generated__";
import { SelectParentTicketDialog_parent_ticketFragment } from "lib/graphql/__generated__/graphql";
import { useCreateChildTicketTask, useCreateTasklist } from "lib/mutations";
import {
    useReplicacheGraphQLClient,
    useReplicacheGraphQLLiveQuery,
} from "lib/replicache/graphql/LocalServer";
import { isDefined } from "lib/types/guards";

import styles from "./SelectParentTicketDialog.module.scss";

const fragments = {
    parentTicket: gql(/* GraphQL */ `
        fragment SelectParentTicketDialog_parent_ticket on tickets {
            id

            board {
                id

                stages(where: { deleted_at: { _is_null: true } }) {
                    id
                    board_pos
                    display_name
                    role
                }
            }

            stage {
                id
                board_pos
            }

            tasklists(where: { deleted_at: { _is_null: true } }) {
                id

                ...SelectParentTicketDialog_tasklist
            }
        }
    `),

    tasklist: gql(/* GraphQL */ `
        fragment SelectParentTicketDialog_tasklist on tasklists {
            id
            ticket_pos

            stage {
                id
            }

            tasks(where: { deleted_at: { _is_null: true } }) {
                id
                tasklist_pos
            }
        }
    `),
};

export type SelectParentTicketDialogProps = {
    childTicketId: string;
    initialIneligibleTicketIds: string[];
};

const dialogState = dialogStateFamily<SelectParentTicketDialogProps>("SelectParentTicketDialog");

export function useSelectParentTicketDialog() {
    return useDialogSingleton(dialogState);
}

export function SelectParentTicketDialog() {
    const dialog = useSelectParentTicketDialog();
    const { isOpen, props } = useRecoilValue(dialogState);
    const [query, setQuery] = useState("");
    const { createTasklist } = useCreateTasklist();
    const client = useReplicacheGraphQLClient();
    const { createChildTicketTask } = useCreateChildTicketTask();

    const liveQueryResult = useReplicacheGraphQLLiveQuery({
        query: gql(/* GraphQL */ `
            query SelectParentTicketDialogChildTicket($childTicketId: uuid!) {
                tickets_by_pk(id: $childTicketId) {
                    id

                    board {
                        id
                        access_type
                    }

                    child_of_tasks(where: { deleted_at: { _is_null: true } }) {
                        id
                        ticket_id
                    }
                }
            }
        `),
        variables: {
            childTicketId: props?.childTicketId || "",
        },
    });

    const board = useMemo(() => liveQueryResult.data?.tickets_by_pk?.board, [
        liveQueryResult.data?.tickets_by_pk?.board,
    ]);

    const parentTicketIds = useMemo(
        () => liveQueryResult.data?.tickets_by_pk?.child_of_tasks.map(task => task.ticket_id) || [],
        [liveQueryResult.data?.tickets_by_pk?.child_of_tasks]
    );

    const excludedTicketIds = useMemo(
        () => [props?.childTicketId, ...parentTicketIds].filter(isDefined),
        [props?.childTicketId, parentTicketIds]
    );

    /**
     * Gets the stages of the parent ticket's workspace and returns:
     *     if it exists, the first working stage equal to or after the parent ticket's current stage,
     *     else, if it exists, the last working stage (before the parent ticket's current stage),
     *     else the first stage.
     */
    const pickParentTicketTasklistStage = useCallback(
        ({ parentTicket }: { parentTicket: SelectParentTicketDialog_parent_ticketFragment }) => {
            const stages = parentTicket.board.stages.sort(sortStages());

            return (
                stages.find(
                    stage =>
                        stage.role === CommonEnums.StageRole.IMPLEMENTATION &&
                        stage.board_pos &&
                        parentTicket.stage.board_pos &&
                        stage.board_pos >= parentTicket.stage.board_pos
                ) ||
                // `[...stages].reverse().find` is equivalent to `stages.findLast` (Node.js 18.0.0)
                [...stages].reverse().find(s => s.role === CommonEnums.StageRole.IMPLEMENTATION) ||
                stages[0]
            );
        },
        []
    );

    // get first working stage >= parent ticket current stage, else most recent working stage
    // use an existing checklist there, else make one
    const findOrCreateParentTicketTasklist = useCallback(
        async ({
            parentTicket,
        }: {
            parentTicket: SelectParentTicketDialog_parent_ticketFragment;
        }) => {
            const parentTasklistStage = pickParentTicketTasklistStage({ parentTicket });

            const parentTasklists = parentTicket.tasklists.map(_tasklistFragment =>
                getFragmentData(fragments.tasklist, _tasklistFragment)
            );

            const existingTasklist = parentTasklists.find(
                tasklist => tasklist.stage.id === parentTasklistStage.id
            );

            if (!existingTasklist) {
                const tasklistId = await createTasklist({
                    ticketId: parentTicket.id,
                    stageId: parentTasklistStage.id,
                    ticketPos: moveToLastPosition({
                        maxPos: Math.max(...parentTasklists.map(tl => tl.ticket_pos)),
                    }),
                    title: `${parentTasklistStage.display_name} tasks`,
                });

                if (!tasklistId) {
                    return null;
                }

                const data = await client.readQuery({
                    query: gql(/* GraphQL */ `
                        query SelectParentTicketDialogParentTicketTasklist($tasklistId: uuid!) {
                            tasklists_by_pk(id: $tasklistId) {
                                ...SelectParentTicketDialog_tasklist
                            }
                        }
                    `),
                    variables: { tasklistId },
                });

                return getFragmentData(fragments.tasklist, data?.tasklists_by_pk);
            }

            return existingTasklist;
        },
        [client, createTasklist, pickParentTicketTasklistStage]
    );

    const handleSelect = useCallback(
        async (selectedItem: TicketSelectorItem) => {
            if (!props) {
                return;
            }

            if (selectedItem.type === TicketSelectorItemType.TICKET) {
                const parentTicketId = selectedItem.ticket.id;

                const data = await client.readQuery({
                    query: gql(/* GraphQL */ `
                        query SelectParentTicketDialogParentTicket($id: uuid!) {
                            tickets_by_pk(id: $id) {
                                ...SelectParentTicketDialog_parent_ticket
                            }
                        }
                    `),
                    variables: {
                        id: parentTicketId,
                    },
                });

                const parentTicket = getFragmentData(fragments.parentTicket, data?.tickets_by_pk);

                if (!parentTicket) {
                    return;
                }

                const parentTasklist = await findOrCreateParentTicketTasklist({ parentTicket });

                if (!parentTasklist) {
                    return;
                }

                const tasks = parentTasklist.tasks.sort(sortTasks());

                await createChildTicketTask({
                    ticketId: parentTicket.id,
                    tasklistId: parentTasklist.id,
                    tasklistPos: moveToPositionByIndex({
                        sortedEntities: tasks,
                        posFieldName: "tasklist_pos",
                        toIndex: tasks.length,
                    })!,
                    childTicketId: props.childTicketId,
                });

                dialog.close();
            }
        },
        [client, createChildTicketTask, dialog, findOrCreateParentTicketTasklist, props]
    );

    const handleClose = useCallback(() => {
        dialog.close();
        setQuery("");
    }, [dialog]);

    return (
        <Dialog
            className={styles.dialog}
            portalClassName={styles.portal}
            canEscapeKeyClose={query === ""}
            isOpen={isOpen}
            onClose={handleClose}
        >
            <Dialog.Body>
                <TicketSelector
                    autoFocus
                    excludedTicketIds={excludedTicketIds}
                    onQueryChange={setQuery}
                    onSelect={handleSelect}
                    paddingRelaxed
                    query={query}
                    showRecentTicketsOnEmptyQuery
                    {...(board && {
                        ticketReferenceContextAndOriginBoard: {
                            context: CommonEnums.TicketReferenceContext.HIERARCHY,
                            originBoard: board,
                        },
                    })}
                />
            </Dialog.Body>
        </Dialog>
    );
}
